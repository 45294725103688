import {useEffect, useState} from "react";
import styled, { css } from "styled-components/macro";
import { useStore, useLanguage, useNotifications, useIsPortrait, useIsMobile } from '../hooks'
import { AlertMessage, Divider } from './UI';
import ReservationForm from "./ReservationForm";
import { parseFromObjectDate } from '../utility';

const CardContainer = styled.div`
  width: auto;
  color: #333;
  max-width: 100%;
  background-color: #f5f5f5;
  padding: 1.2rem;
  ${p => (p.isPortrait || p.isMobile) && css`
    padding: 0.25rem;
    margin: 0.5rem 1rem;
`}
`;

const CardItem = styled.div`
  background-color: white;
  border-radius: 5px;
  padding: 2.5rem 3rem;
  margin: 0.5rem 3rem;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);

  .row {
    display: flex;
    flex-wrap: wrap;
  }

  .mx-4 {
    margin-left: 4rem;
    margin-right: 4rem;
  }

  .w-50 {
    width: 50%;
  }

  ${p => (p.isPortrait || p.isMobile) && css`
    width: auto;
    max-width: 100%;
    padding: 1rem 1.5rem;
    margin: 0.5rem 0;
  `}
`;

const CloseButton = styled.button`
  display: flex;
  background-color: transparent;
  color: #333;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  font-size: 20px;
  padding: 10px;
  margin: 0 0 10px auto;
`;

const EvButton = styled.button`
  display: flex;
  background-color: ${p => p.theme.primary};
  color: ${p => p.theme.secondary};
  border: none;
  border-radius: 0.25rem;
  cursor: pointer;
  font-size: 20px;
  padding: 10px 20px;
  margin: auto;
`;

const HotelList = styled.ul`
  list-style: none;
  padding: 0;
`;

const RoomList = styled.ul`
  list-style: none;
  padding: 0;
`;

const HotelItem = styled.li`
  display: flex;
  justify-content: flex-start;
  border-bottom: 1px solid #f5f5f5;
  margin-bottom: 10px;

  input {
    cursor: pointer;
    margin: 0.5rem;
    border-radius: 50%;
    width: 1.1em;
    height: 1.1em;
    vertical-align: top;
    background-color: #fff;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    border: 1px solid rgba(0,0,0,.25);
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -webkit-print-color-adjust: exact;
    
    &:checked[type=radio] {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
        background-color: #0d6efd;
        border-color: #0d6efd;
    }
  }

  label {
    padding: 0.5rem 1.5rem;
  }
`;

const RoomItem = styled.li`
  display: flex;
  justify-content: flex-start;
  border-bottom: 1px solid #f5f5f5;
  margin-bottom: 10px;
  align-items: center;

  input {
    width: 2rem;
    cursor: pointer;
    margin: 0.5rem;
    display: block;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  }

  label {
    min-width: 5rem;
    padding: 0.5rem 0 0.5rem 1.5rem;
  }
`;

const SellersList = styled.div`
    display: flex;
    justify-content: center;
    padding: 1rem;
    margin: 0.5rem;
`;

const SellerSelect = styled.select`
    background-color: ${p => p.theme.primary};
    color: ${p => p.theme.secondary};
    border: none;
    border-radius: 10px;
    cursor: pointer;
    font-size: 1rem;
    padding: 10px 20px;
    margin: auto;

    display: block;
    padding: 0.375rem 2.25rem 0.375rem 0.75rem;
    -moz-padding-start: calc(0.75rem - 3px);
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='white' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right 0.75rem center;
    background-size: 16px 12px;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    option {

    }
`;

const EventCard = ({selectedEvent, handleCloseCard}) => {
    const [store, dispatch] = useStore()
    const isPortrait = useIsPortrait();
    const isMobile = useIsMobile();
    const language = useLanguage();
    const [hotelList, setHotelList] = useState([]);
    const [sellerList, setSellerList] = useState([]);
    const notifications = useNotifications();
    const [selectedHotel, setSelectedHotel] = useState(null);
    const [selectedSeller, setSelectedSeller] = useState(null);
    const [selectedRooms, setSelectedRooms] = useState(null);
    const [showReservationForm, setShowReservationForm] = useState(false);
	const [onSuccess, setOnSuccess] = useState(null);

    const onClose = () => {
        setOnSuccess(null);
		handleCloseCard();
    };

	const handleSelecHotel = (hotel) => {
        setSelectedHotel(hotel);
        setSelectedRooms(hotel.rooms);
    };

    const handleRoomsOnChange = (e, key) => {

        const newValue = parseInt(e.target.value);
        setSelectedRooms((prevSelectedRooms) => ({
            ...prevSelectedRooms,
            [key]: {
                ...prevSelectedRooms[key],
                quantity: newValue,
            },
        }));
    };

    const handleSelectSeller = (event) => {
        const selectedSellerId = event.target.value;
        const seller = sellerList.find(seller => seller.id === selectedSellerId);
        setSelectedSeller(seller);
      };

    async function getRelatedHotels() {

        let newList = [];

		if( store?.hotels ){       

            if( selectedEvent.hotels && Array.isArray(selectedEvent.hotels) ){
                newList = selectedEvent.hotels.map( hotelId => 
                    (store.hotels[hotelId] && store.hotels[hotelId]?.enabled ) ? store.hotels[hotelId] : null
                );
            }
        } else{
            notifications.notify(language.getText('EV_ERR_HOTEL_NOT_FOUND'));
        }
        
        setHotelList(newList);
	}

    const getSellers = () => {
        let sellers = []

        if( store?.users ){       
            sellers = Object.values(store.users).filter(u => u.email && u.isSeller);
        } else{
            notifications.notify(language.getText('EV_ERR_SELLERS_NOT_FOUND'));
        }

        setSellerList(sellers);
    };

    const handleCloseForm = () => {
        setShowReservationForm(false);
    };

    const startReservation = () => {
        if( 
            selectedEvent && 
            (selectedHotel || !selectedEvent.hotels ) &&
            selectedSeller
        ){
            setShowReservationForm(true);
        } else {
            notifications.notify(language.getText('EV_ERR_REQUIRED_FIELDS'));
        }
    };

    useEffect(() => {

        if(selectedEvent){
            getRelatedHotels();
            getSellers();
        }

    }, []);

	return selectedEvent && !showReservationForm ? (
        <>
            <CardContainer className="Card_Container" isPortrait={isPortrait} isMobile={isMobile} >
				<CloseButton onClick={onClose}>X</CloseButton>
                { onSuccess && (
					<AlertMessage success >
						{onSuccess || 'Done!'}
					</AlertMessage>
				)}
                <CardItem isPortrait={isPortrait} isMobile={isMobile} >
                    <h1 style={{textAlign: "center"}}>{selectedEvent.name}</h1>

                    <h3>{language.getText('EV_DESCRIPTION')}</h3>
                    <p>{selectedEvent.description}</p>

                    <div className="row">
                        <div class="w-50">
                            <h3>{language.getText('EV_LOCATION')}</h3>
                            <p>{selectedEvent.location}</p>
                        </div>
                        <div class="w-50">
                            <h3>{language.getText('EV_DEPARTURE_DATE')}</h3>
                            <p>{parseFromObjectDate(new Date(selectedEvent?.departure_date))}</p>
                        </div>
                        <div class="w-50">
                            <h3>{language.getText('EV_DAYS')}</h3>
                            <p>{selectedEvent.days}</p>
                        </div>

                        <div class="w-50">
                            <h3>{language.getText('EV_FLIGHT_COST')}</h3>

                            <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                                <label style={{margin: '0.5rem'}} >$</label><p style={{margin: '0.5rem'}} >{(selectedEvent.flight_cost)/100.0 || 0}</p>
                            </div>
                        </div>
                    </div>

                    <h3>{language.getText('EV_HOTELS')}</h3>
                    
                    <HotelList>
                        {hotelList && hotelList.filter(hotel => hotel.rooms).map((hotel, index) => (
                            <HotelItem key={index} >
                                <input 
                                    type="radio" 
                                    value={hotel.id} 
                                    onChange={ () => handleSelecHotel(hotel) } 
                                    checked={selectedHotel === hotel}
                                />
                                <label>{hotel.name}</label>
                            </HotelItem>)
                        )}
                    </HotelList>

                    <h3 >{language.getText('EV_ROOMS')}</h3>

                    { (selectedHotel && selectedRooms) && (<>
                        <RoomList>
                            {Object.keys(selectedRooms).map((key) => (<>
                                <RoomItem key={key}>
                                    <input
                                        type="number"
                                        min="0"
                                        className="form-control"
                                        value={selectedRooms[key]?.quantity || 0}
                                        placeholder="0"
                                        onChange={(e) => handleRoomsOnChange(e, key) }
                                    />
                                    <label>{selectedRooms[key]?.type}:</label>
                                    <label>${(selectedRooms[key]?.price) / 100.0} ({language.getText('EV_ROOM_COST')})</label>
                                </RoomItem>
                            </>))}
                        </RoomList>
                    </>)}
                </CardItem>
                <SellersList>
                    <SellerSelect value={selectedSeller ? selectedSeller.id : '' } onChange={handleSelectSeller}>
                        <option value="" disabled>{language.getText('EV_SELECT_SELLER')}</option>
                        {sellerList && sellerList.map((seller) => (
                            <option key={seller.id} value={seller.id}>
                                {seller.name}
                            </option>
                        ))}
                    </SellerSelect>
                </SellersList>
                <EvButton onClick={startReservation} >{language.getText('EV_RESERVE')}</EvButton>
            </CardContainer>
        </>
	)  : showReservationForm ?  (
		<ReservationForm
            selectedEvent={selectedEvent} 
            selectedSeller={selectedSeller} 
            selectedHotel={selectedHotel}
            selectedRooms={selectedRooms}
            handleCloseForm={handleCloseForm}
            setOnSuccess={setOnSuccess}
		/>
	) : (<></>);
};

export default EventCard;
