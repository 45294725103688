import React, { useState } from 'react'

const Viewport = React.createContext({})

export interface IViewport {
    state: IViewportState,
    setActive(value: boolean): void,
    setContent(value: any): void,
    setTitle(value: string): void,
}

export interface IViewportState {
    active: boolean,
    content?: any,
    title?: string,
}

export function ViewportContext(props: any) {
    const [state, set] = useState<IViewportState>({
        active: false,
    })

    const value: IViewport = {
        state: state,
        setActive(value) { set(prev => { return { ...prev, active: value, content: value ? prev.content : undefined }}) },
        setContent(value) { set(prev => { return { ...prev, content: value }})},
        setTitle(value) { set(prev => { return { ...prev, title: value }})},
    }

    return (
        <Viewport.Provider value={value}>
            { props.children }
        </Viewport.Provider>
    )
}

export default Viewport