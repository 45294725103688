import React from 'react'
import styled, { keyframes, css } from 'styled-components/macro'

const Container = styled.div`
    position: absolute;
    top: 0;
    width: 100vw;
    height: 100vh;
    background-color: ${p => p.theme.dark};
    z-index: 10;

    @supports (-webkit-touch-callout: none) {
        height: calc(var(--vh, 1vh) * 100);
    }
`

const LoadingContainer = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    width: ${p => p.small? '4rem' : '8rem' };
    height: ${p => p.small? '4rem' : '8rem' };
    transform: translate(-50%, -50%);
`

const skRotate = keyframes`
    100% { transform: rotate(360deg); -webkit-transform: rotate(360deg); }
`

const skBounce = keyframes`
    0%, 100% { 
        transform: scale(0.0);
        -webkit-transform: scale(0.0);
    } 50% { 
        transform: scale(1.0);
        -webkit-transform: scale(1.0);
    }
`

const Spinner = styled.div`
    /* margin: 100px auto; */
    width: 100%;
    height: 100%;
    position: relative;
    text-align: center;

    animation: ${skRotate} 2.0s infinite linear;
`

const Dot = styled.div`
    width: 50%;
    height: 50%;
    display: inline-block;
    position: absolute;
    top: 0;
    background-color: ${p => p.theme.primary};
    border-radius: 100%;

    animation: ${skBounce} 2.0s infinite ease-in-out;
`

export default function Loading(props) {
	return (
		<LoadingContainer {...props}>
			<Spinner>
				<Dot />
				<Dot style={{ top: 'auto', bottom: 0, animationDelay: '-1.0s', WebkitAnimationDelay: '-1.0s' }} />
			</Spinner>
		</LoadingContainer>
	)
}

export function ScreenLoading() {
	return (
		<Container>
			<Loading />
		</Container>
	)
}