import React, { useState, useEffect } from 'react'
import styled, { css } from 'styled-components/macro'
import { useHistory } from 'react-router-dom'

import Company from '../components/Company'
import Catalog from '../components/Catalog'
import Loading from '../components/Loading'

import { useParam, useStand, useStore, useIsPortrait, usePersonalChat, useAuth, useElementsManager, useLanguage, useCatalog, useIsMobile } from '../hooks'
import { IconButton, Image, Button } from '../components/UI'
import FloatingButton from '../components/FloatingButton'
import MobileArea from '../components/MobileArea'
import PresentationImages from '../components/PresentationImages'
import PasswordPanel from '../components/PasswordPanel'
import View from '../components/View'

import nextIcon from '../graphics/icons/arrow-right.svg'
import prevIcon from '../graphics/icons/arrow-left.svg'
import { logEvent } from '../firebase/analytics'
//import icons from '../graphics/icons'
import { Text, Divider } from '../components/UI'

const Container = styled.div`
	height: ${p => p.isPortrait ? '40vh' : '100vh'};
	padding-top: ${p => (p.isPortrait || p.isMobile) ? ' 11.5vh' : '0'};
`

const NextBackButtons = styled.div`
    position: fixed;
    top: 50%;
    left: 1rem;
    right: 1rem;
    transform: translateY(-50%);
    height: 2.2rem;
    display: grid;
    grid-template-columns: 2.5rem auto 2.5rem;
    z-index: 7;
    pointer-events: none;
    * {
        pointer-events: all;
    }

	${p => p.isPortrait && css`
		top: 12.5vh;	
	`}
`

const ButtonPanel = styled.div`
    position: fixed;
    bottom: .5rem;
    left: .5rem;

    width: 15rem;

    display: grid;
    grid-gap: .5rem;
`

export default function Stand(props) {
	const history = useHistory()
	const pavillionId = useParam('pavillionId')
	const isPortrait = useIsPortrait()
	const isMobile = useIsMobile();
	const standId = useParam('id')
	const [store, dispatch] = useStore()
	const [stand, pavillion] = useStand(standId, pavillionId)
	const [components, setComponents] = useState([])
	const personalChat = usePersonalChat()
	const auth = useAuth()
	const elementsManager = useElementsManager()
	const [state, setState] = useState({
		moreInfo: false,
		company: {},
	})
	const [stands, setStands] = useState([])
	const language = useLanguage()
	const [catalog, setCatalog] = useCatalog()
	const [panelContainerDisplayed, setDisplayPanelState] = useState(false);

	useEffect(() => {
		if (!pavillion || !store.stands) {
			return
		} else {
			if (pavillion?.stands && store?.stands) {
				setStands(Object.keys(pavillion?.stands || {})
					.map(key => store.stands[key])
					.filter(s => s)
					.sort((a, b) => {
						const av = a.order ? parseFloat(a.order) : Number.MAX_SAFE_INTEGER
						const bv = b.order ? parseFloat(b.order) : Number.MAX_SAFE_INTEGER
						return av > bv ? 1 : -1
					})
					.map(s => s.id))
			}
		}
	}, [pavillion, pavillion.stands, store.stands])

	useEffect(() => {
		if (stand && stand.name) logEvent(stand.name)
	}, [stand])

	useEffect(() => {
		elementsManager.setBoth(!(state?.moreInfo || state?.catalog))
	}, [state?.moreInfo, state?.catalog])

	useEffect(() => {
		if (!stand || !store.stands || !store.companies) return

		const c = []
		if (stand.backgrounds) c.push(<PresentationImages key='backgrounds' backgrounds={stand.backgrounds} />)
		if (stand.background) c.push(<Image fit url={stand.background} style={{ zIndex: '-1', position: 'relative', height: (isPortrait ? '90%' : '100%'), backgroundSize: (isPortrait ? 'contain' : '100% 100%') }} />)
		if (stand.password && !auth?.user?.universalAccess && (!stand.whitelist || !(auth?.user?.id in stand.whitelist))) c.push(<PasswordPanel password={stand.password} title={stand.passwordTitle} />)
		if (isPortrait) {
			const buttons = stand.buttons || {}
			buttons['knowmore'] = {
				id: 'knowmore',
				type: 'hardcoded',
				subtype: 'knowmore',
				name: 'Conocer Más',
				icon: 'info.svg',
				data: () => {
					setMoreInfo(true)
				}
			}
			buttons['catalog'] = {
				id: 'catalog',
				type: 'hardcoded',
				subtype: 'catalog',
				name: 'Catálogo',
				icon: 'cart.svg',
				data() {
					showCatalog()
				}
			}
			if (stand.views || buttons){
				c.push(<MobileArea
				buttons={elementsManager.state.buttons ? buttons : undefined}
				views={elementsManager.state.panels ? stand.views : undefined}
				hideArrows={(value) => {setDisplayPanelState(value)}}
			/>)
			}
		}
		else {
			if (stand.views && elementsManager.state.panels) c.push(Object.values(stand.views).map(view => <View key={view.id} view={view} />))
			if (stand.buttons && elementsManager.state.buttons) c.push(Object.values(stand.buttons).map(btn => <FloatingButton key={btn.id} button={btn} />))
		}
		setComponents(c)
		setState({ ...state, company: store.companies[stand.company] })
	}, [stand, store, elementsManager.state])

	const goTo = id => {
		if (!pavillionId || !id) return 
		history.push(`/stand?pavillionId=${pavillionId}&id=${id}`)
	}

	const nextHandler = () => {
		let i = stands.indexOf(standId)
		i = (i + 1) >= stands.length ? 0 : (i + 1)
		goTo(stands[i])
	}

	const prevHandler = () => {
		let i = stands.indexOf(standId)
		i = (i - 1) < 0 ? stands.length - 1 : (i - 1)
		goTo(stands[i])
	}

	const setMoreInfo = v => setState({ ...state, moreInfo: v })

	function showCatalog() {
		setCatalog(p => ({
			...p,
			stand,
			active: true
		}))
	}

	return (
		<>
			{
				stand ?
					<Container isPortrait={isPortrait} className="Stand_Container">
						
						{ (isPortrait || isMobile) && (
							<>
								<Text center bold H12 style={{height: 'auto'}} >
									{ store?.stands?.[standId]?.name || 'Stand' }
								</Text>
								<Divider primary width='90vw'/>
							</>
						)}
						{state.moreInfo && <Company
							onStandClick={id => {
								setMoreInfo(false)
								goTo(id)
							}}
							company={state.company}
							onClose={() => setMoreInfo(false)}
						/>}

						{!isPortrait &&
							<ButtonPanel>
								{state.company && <Button onClick={() => setMoreInfo(true)}>{language.getText('Conocer más')}</Button>}
								{stand.catalog && <Button onClick={() => showCatalog()}>{language.getText('Catálogo')}</Button>}
							</ButtonPanel>
						}

						{(
						!(isPortrait && personalChat.active) && 
						!store?.configurations?.hideStandArrows &&
						!panelContainerDisplayed 
						) && ( 
							<NextBackButtons isPortrait={isPortrait} >
								<IconButton onClick={prevHandler} image={prevIcon} style={{height: '2.2rem'}} />
								<div style={{ pointerEvents: 'none' }} />
								<IconButton onClick={nextHandler} image={nextIcon} style={{height: '2.2rem'}} />
							</NextBackButtons>
						)}

						{/* <Image fit={!isPortrait} contain={isPortrait} url={stand.background}> */}
						{components}
						{/* </Image> */}
					</Container>
					: <Loading />
			}
		</>
	)
}