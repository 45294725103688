import React, { useState, useEffect, useRef } from 'react'
import styled, { css } from 'styled-components/macro'
import Reveal from 'react-reveal/Reveal';

import { useUrl, useLanguage} from '../hooks'
import { useExternalAnalytics } from '../hooks/index';

export const Background = styled.div`
    background-color: ${p => p.dark ? p.theme.dark : p.primary ? p.theme.primary : p.medium ? p.theme.medium : p.theme.light};
    ${p => p.shadow && css`
        box-shadow: ${p => p.theme.shadow};
    `}

    height: 100%;
`

export const Divider = styled.div`
	width: ${p => p.width ? p.width : '80vw'};
	height: 0.25rem;
	margin: 0.25rem auto;
	border-radius: ${p => p.theme.roundCorners};
    background-color: ${p => p.theme.light};
	background-color: ${p => p.color ? p.color : ( p.primary? p.theme.primary : p.theme.light )};
    opacity: ${p => p.opacity ? p.opacity : '1'};
`

const AlertWrapper = styled.div`
  padding: 1rem;
  margin: 1rem 0;
  border: 1px solid transparent;
  border-radius: 0.25rem;

  background-color: #d1ecf1; border-color: #bee5eb; color: #0c5460;
  
  ${ p => p.success && 'background-color: #d4edda; border-color: #c3e6cb; color: #155724;'}
  ${ p => p.warning && 'background-color: #fff3cd; border-color: #ffeeba; color: #856404;'}
  ${ p => p.danger && 'background-color: #f8d7da; border-color: #f5c6cb; color: #721c24;'}
  ${ p => p.info && 'background-color: #d1ecf1; border-color: #bee5eb; color: #0c5460;'}

`;

export const AlertMessage = (props) => {
  return (
    <AlertWrapper {...props} >
      {props.children}
    </AlertWrapper>
  );
};

export const Span = styled.span`
	${p => p.menu && css`
        width: 80%;
		display: inline-block;
    `}
	${p => p.onClick && css`
        cursor: pointer;
        :hover {
            color: ${p => p.theme.primary};
        }
    `}
`

export const Text = styled.div`
    box-sizing: border-box;
    font-size: ${p => p.H1 ? '2rem' : p.H2 ? '1rem' : p.H12 ? '1.5rem' : '.8rem'};
    font-weight: ${p => p.bold ? 'bold' : 'normal'};
    color: ${p => p.color ? p.color : p.dark ? p.theme.dark : p.primary ? p.theme.primary : p.medium ? p.theme.medium : p.theme.light};
    height: 100%;
    white-space: ${p => p.oneline ? 'nowrap' : 'pre-line'};
    
    ${p => p.mt45 && css`
        margin-top: 45%;
    `}
    
    ${p => p.center && css`
        text-align: center;
    `}

	${p => p.active && css`
        color: ${p.theme.primary};
    `}

    ${p => p.onClick && css`
        cursor: pointer;
        :hover {
            color: ${p => p.theme.primary};
        }
    `}

    ${p => p.middle && css`
        display: flex;
        align-items: center;

        ${p => p.center && css`
            justify-content: center;
        `}
    `}

    ${p => p.bottom && css`
        display: flex;
        align-items: flex-end;

        ${p => p.center && css`
            justify-content: center;
        `}
    `}

    ${p => p.truncated && css`
        overflow: hidden;
        text-overflow: ellipsis;
    `}

    ${p => p.verticalAlign && css`
        display: flex;
        align-items: center;
    `}

    ${p => p.horizontalAlignRight && css`
        text-align: right;
    `}

    ${p => p.reverse && css`
        transform: scaleX(-1);
    `}
`

export const SVG = styled.div`
    mask-image: url(${p => p.image});
    mask-size: ${p => p.contain ? 'contain' : 'cover'};
    mask-position: center;
    mask-repeat: no-repeat;
    background-color: ${p => p.dark ? p.theme.dark : p.primary ? p.theme.primary : p.gray ? p.theme.gray : p.theme.light};
    height: 100%;

    ${p => p.onClick && css`
        cursor: pointer;
        :hover { background-color: ${p.theme.primary}; }
    `}

    ${p => p.onlyMobile && css`
        display: none;
        @media (max-width: 768px) {
            display: block;
        }
    `}
`

const ImageContainer = styled.div`
    box-sizing: border-box;
    background-image: url(${p => p.image}), url(${p => p.placeholder});
    background-size: ${p => p.contain ? 'contain' : p.fit ? '100% 100%' : 'cover'};
    background-repeat: no-repeat;
    background-position: ${p => p.top ? 'top' : 'center'};
    height: 100%;
`

const VideoContainer = styled.div`
    position: ${p => p.width ? '' : (  p.inherit ? 'inherit' : 'fixed')}; 
    width: ${p => p.width ? p.width : '100vw'}; 
    height: ${p => p.heightauto ? 'auto' : (p.contain ? '40vh' : '100vh')};
    overflow: hidden;
`

const VideoElement= styled.video`
    object-fit: ${p => p.width ? '' : 'cover'};;
    width: ${p => p.width ? p.width : '100vw'};
    height: ${p => p.heightauto ? 'auto' : (p.contain ? '40vh' : '100vh')};
    position: ${p => p.width ? '' : 'fixed'};
    top: 0;
    left: 0;
`

export function Image(props) {
    const analytics = useExternalAnalytics()
	const url = useUrl(props.url)

    if (analytics && props.trackPicture) {
        // TODO: firebase storage img won't show in Mixpanel because of permissions
        // Find a way to get the img url from somewhere else (or set firebase storage public)
        
        // Set avatar to user's profile in analytics
        // analytics.setPeopleProps({
            // $avatar: url
        // })
    }

	return (
		<ImageContainer
			style={props.style}
			top={props.top}
			image={url ? url : (props.image ? props.image : null)}
			contain={props.contain}
			fit={props.fit}
            trackPicture={props.trackPicture}
		> {props.children} </ ImageContainer>
	)
}

export function Video(props) {
	const url = useUrl(props.src)
	return (
		<VideoContainer inherit={props.inherit} width={props.width} heightauto={props.heightauto} className="VideoContainer" >
            <VideoElement className="VideoElement"
            autoPlay={props.autoPlay}
            loop={props.loop}
            muted={props.muted}
            playsInline={props.playsInline}
			style={props.style}
			src={url ? url : props.video}
            poster={props.poster || ''}
            contain={props.contain}
            preload={props.preload}
            width={props.width}
            heightauto={props.heightauto}
            />
        </VideoContainer>
	)
}

export function Animation(props) {
	const [current, setCurrent] = useState(0)

	const setNext = () => {
		setCurrent(c => {
			let next = c + 1
			if (next >= props.images.length) next = 0
			return next
		})
	}

	useEffect(() => {
		const interval = setInterval(setNext, 40)
		return () => clearInterval(interval)
	}, props.images)

	return (
		<Image style={props.style} contain={props.contain} fir={props.fit} image={props.images[current]} />
	)
}

const ButtonContainer = styled.div`
	border-radius: ${p => p.theme.roundCorners};
    box-sizing: border-box;
    height: ${p => p.theme.minSize};
    background-color: ${p => p.dark ? p.theme.dark : p.light ? p.theme.light : p.gray ? p.theme.medium : p.theme.primary};

	${p => !p.disabled ? css`
		cursor: pointer;
		:hover {
			background-color: ${p => p.dark ? p.theme.darkLight : p.light ? p.theme.primary : p.theme.primaryHover};
			${Text} {
				color: ${p => p.theme.light};
			}
			${SVG} {
				background-color: ${p => p.theme.light};
			}
		}
	` : css`
		filter: brightness(90%);
		opacity: .6;
	`}

    ${Text} {
        display: flex;
        align-items: center;
        justify-content: center;
    }

`

const TextIconContianer = styled.div`
    height: 100%;
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: ${p => p.inverse ? 'auto 2rem' : '2rem auto'};
    box-sizing: border-box;

    ${p => p.selected && css`
        ${SVG} { background-color: ${p => p.theme.primary}; }
        ${Text} { color: ${p => p.theme.primary}; }
    `}

    ${p => p.interactable && css`
        cursor: pointer;
        :hover {
            ${SVG} { background-color: ${p => p.theme.primary}; }
            ${Text} { color: ${p => p.theme.primary}; }
        }
    `}
`

export function TextIcon(props) {
	return (
		<TextIconContianer selected={props.selected} onClick={props.onClick} interactable={props.onClick} inverse={props.inverse} style={props.style}>
			<Text oneline style={{ gridRow: '1/2', gridColumn: props.inverse ? '1/2' : '2/3' }} H2 bold>{props.children}</Text>
			<SVG style={{ gridRow: '1/2', gridColumn: props.inverse ? '2/3' : '1/2' }} contain image={props.image} />
		</TextIconContianer>
	)
}

export function FadeInUp(props) {
	const [fire, setFire] = useState(false)
	const [init, setInit] = useState(true)

	if (init) {
		setInit(false)
		setTimeout(() => setFire(true), props.delay ? props.delay : 0)
	}

	return (
		<Reveal when={fire} effect='fadeInUp' duration={500}>
			{props.children}
		</Reveal>
	)
}

export function IconButton(props) {
	const style = props.style ? props.style : {}
	style.padding = '.5rem'

	return (
		<Button icon style={style} onClick={props.onClick} dark={props.dark} gray={props.gray} light={props.light}>
			<SVG style={{ boxSizing: 'border-box' }} contain image={props.image} primary={props.light} light={props.gray} />
		</Button>
	)
}

export function Button(props) {
	return (
		<ButtonContainer disabled={props.disabled} style={props.style} onClick={props.disabled ? () => { } : props.onClick} gray={props.gray} dark={props.dark} light={props.light}>
			{
				props.icon ? props.children :
					<Text style={{ whiteSpace: 'nowrap', margin: '0 1rem' }} center H2 bold primary={props.light}>
						{props.children}
					</Text>
			}
		</ButtonContainer>
	)
}
const ErrorFieldContainer = styled.span`
    width: 100%;
    color: red;
    font-size: .75rem;
`

const InputFieldContainer = styled.input`
	border: .1rem solid ${p => p.theme.light};
    width: 100%;
    box-sizing: border-box;

    color: ${p => p.title ? p.theme.dark : p.theme.light};
    ${p => p.isRequired && !p.errorState?.valid && css`
		border-color: ${p.theme.error};
	`}

    font-size: 1rem;

    height: 2rem;
    background-color: rgba(0,0,0,0);
    ${p => p.center && css`
        text-align: center;
    `}

    ::placeholder {
        color: ${p => p.title ? p.theme.dark : p.theme.light};
        opacity: 1;
    }
    /* padding: 0 1rem; */

    border-bottom: .1rem solid ${p => p.theme.dark};

`

const SInputField = styled.div`
    height: min-content;
`

const STextArea = styled.div`
    height: max-content;
`

const TextAreaContainer = styled.textarea`
    border: .1rem solid ${p => p.theme.light};
    width: 100%;
    box-sizing: border-box;

    color: ${p => p.title ? p.theme.dark : p.theme.light};
    ${p => p.isRequired && !p.errorState?.valid && css`
		border-color: ${p.theme.error};
	`}

    resize: ${p => p.resize ? 'vertical' : 'none'};
    font-size: 1rem;
    font-family: sans-serif;
    height: 2rem;
    background-color: rgba(0,0,0,0);
    ${p => p.center && css`
        text-align: center;
    `}

    ::placeholder {
        color: ${p => p.title ? p.theme.light : p.theme.darkLight};
        opacity: 1;
    }
    /* padding: 0 1rem; */

    border-bottom: .1rem solid ${p => p.theme.dark};
`

export function InputField(props) {
	const inputRef = useRef()
    const language = useLanguage();

	useEffect(() => {
		if (props.autoSelect && inputRef.current) inputRef.current.select()
	}, [inputRef.current])

	const inputField = <InputFieldContainer
        errorState={props.errorState}
        setErrorState={props.setErrors}
		title={props.title}
		ref={inputRef}
		onKeyDown={props.onKeyDown}
		center={props.center}
		placeholder={props.placeholder}
		value={props.value ? props.value : ''}
		onChange={e => props.onChange(e.target.value)}
		type={props.type ? props.type : 'text'}
		style={props.style}
        isRequired={props.isRequired}
        readOnly={props.inputField}
	/>

	return props.title ? <SInputField>
		<Text H4 bold dark>{props.title}</Text>
		{inputField}
		{props.errorState?.displayMessage && <ErrorFieldContainer H5 bold>{props.errorState.customMessage ? props.errorState.customMessage : language.getText('CAMPO_OBLIGATORIO')}</ErrorFieldContainer>}
	</SInputField> : inputField
}

export function TextArea(props) {
	const textAreaRef = useRef()

	useEffect(() => {
		if (props.autoSelect && textAreaRef.current) textAreaRef.current.select()
	}, [textAreaRef.current])

	const textAreaField = <TextAreaContainer
        errorState={props.errorState}
        setErrorState={props.setErrors}
		title={props.title}
		ref={textAreaRef}
		onKeyDown={props.onKeyDown}
		center={props.center}
		placeholder={props.placeholder}
		value={props.value ? props.value : ''}
		onChange={e => props.onChange(e.target.value)}
		type={props.type ? props.type : 'text'}
		style={props.style}
        isRequired={props.isRequired}
	/>

	return props.title ? <STextArea>
		<Text H4 bold dark>{props.title}</Text>
		{textAreaField}
		{props.errorState?.displayMessage && <ErrorFieldContainer H5 bold>{props.errorState.customMessage ? props.errorState.customMessage : "Este campo es obligatorio"}</ErrorFieldContainer>}
	</STextArea> : textAreaField
}

const UploadFileHolder = styled.input`
    box-sizing: border-box;
    height: 100%;
    background-color: ${p => p.dark ? p.theme.dark : p.light ? p.theme.light : p.theme.primary};
    cursor: pointer;
    :hover {
        background-color: ${p => p.dark ? p.theme.darkLight : p.light ? p.theme.primary : p.theme.primaryHover};
    }
    color: white;
    font-weight: bold;
`

export function UploadFile(props) {
	const [state, setState] = useState()

	const handleChange = e => {
        let file = e.target.files[0]
        if (file && file.type.matches === 'video/mp4') {
            props.fileHanlder(file)
            // setState(file)
            // props.onChange(file)
        } else {
            props.fileHanlder(e.target.files[0])
        }
	}

	return (
		<UploadFileHolder
			style={props.style}
			type='file'
			onChange={handleChange}
		/>
	)
}

const AspectRatioContainer = styled.div`
    width: ${p => p.width ? p.width : '100%'};
    padding-top: ${p => p.paddingTop ? p.paddingTop : '100%'};
    position: relative;
    background-color: red;
`

const AspectRatioChild = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
`

export function AspectRatio(props) {
	return (
		<AspectRatioContainer width={props.width} paddingTop={props.padding}>
			<AspectRatioChild>
				{props.children}
			</AspectRatioChild>
		</AspectRatioContainer>
	)
}
