import { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useStore, useLanguage } from '../../hooks'
import List from 'react-virtualized/dist/commonjs/List'
import AutoSizer from 'react-virtualized/dist/commonjs/AutoSizer'
import { Text, Button, FadeInUp, InputField, IconButton, Divider } from '../UI'
import closeIcon from '../../graphics/icons/arrow-left.svg'
import { addUpdateHotel } from '../../services/hotels';
import { key } from 'firebase-key'

const CloseButton = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 2.5rem;
    height: 2.5rem;
`;

const EditContainer = styled.div`
    display: grid;
    grid-gap: 2rem;
`;

const WhiteListContainer = styled.div`
    width: 80%;
    height: 20rem;
    overflow: auto;
    border: .1rem solid ${p => p.theme.light};
    padding: 1rem;
`;

const WhiteListItem = styled.div`
    display: grid;
    grid-template-columns: auto 1.5rem;
    grid-gap: 1rem;
    cursor: pointer;
`;

const Checkbox = styled.div`
    height: 100%;
    background-color: ${p => p.selected ? p.theme.primary : p.theme.light};
    border: .2rem solid ${p => p.theme.light};
    box-sizing: border-box;
`;

const RoomListLabel = styled.label`
	color: white;
	width: 10rem; 
	margin-left: 0; 
	margin-right: 1rem;
`;

const RoomListButton = styled.button`
	background-color: ${p => p.theme.primary};
	color: white;
	width: 10rem; 
	margin-left: 0; 
	margin-right: 1rem;
`;

const Hotel_Default = {
	id: null,
    name: '',
    reserve_type: '',
    rooms: {},
}

const Room_Default = {
	type: '',
    price: 0,
}

export default function EditHotel({type, hotel, backHandler}) {
	const [hotelState, sethotelState] = useState({
        ...Hotel_Default,
        ...hotel
    });
	const [newRoom, setNewRoom] = useState({
        ...Room_Default,
    });
	const [roomUpdate, setRoomUpdate] = useState(false);
	const [store, dispatch] = useStore();
	const language = useLanguage();

	// Save hotel to store and local eveny.
	const saveHotel = () => {
		addUpdateHotel(hotelState.id, hotelState);
	}

	const deleteHotel = () => {
		// To do: add remove hotel method
		backHandler()
	}

	const handleUpdateRoom = (r) => {
		setRoomUpdate(true);
		setNewRoom(r);
	};

	const roomsList = Object.values(hotelState.rooms)
        .map( r => 
            <div style={{ display: 'flex', justifyContent: 'space-between' }} onClick={() => {}}>
                <RoomListLabel >{r.type}</RoomListLabel>
				<RoomListLabel >{(r.price)/100.0}</RoomListLabel>
				<RoomListButton onClick={ () => handleUpdateRoom(r) } >{language.getText('EV_EDIT')}</RoomListButton>
            </div>
        );

	const rowRenderer = ({ key, index, style }) => {

	return (
			<div key={key} style={style}>
				{roomsList[index]}
			</div>
		)
	}

    const handleOnClose = () => {
        saveHotel();
        backHandler();
    };

	const saveNewRoom = () => {
		let k = key();
		let r = { ...hotelState.rooms};
		r[k] = {...newRoom, id: k};
		sethotelState({ ...hotelState, rooms: { ...r } })
		setNewRoom({ ...Room_Default });
		saveHotel();
	};

	const updateRoom = () => {
		let r = { ...hotelState.rooms};
		r[newRoom.id] = {...newRoom};
		sethotelState({ ...hotelState, rooms: { ...r } })
		saveHotel();
		setNewRoom({ ...Room_Default });
		setRoomUpdate(false);
	};

	const deleteRoom = () => {
		let r = { ...hotelState.rooms};
		delete r[newRoom.id];
		sethotelState({ ...hotelState, rooms: { ...r } })
		saveHotel();
		setNewRoom({ ...Room_Default });
		setRoomUpdate(false);
	};

	return hotelState ? (
		<EditContainer>
			<CloseButton>
                <IconButton onClick={handleOnClose} image={closeIcon} />
            </CloseButton>

			<Text H1 bold>{(hotelState.name ?? '')}</Text>

            <div style={{ width: '20rem' }}>
				<WhiteListItem >
                    <Text H2 bold>{language.getText('Nombre')}</Text>
	    	    	<InputField
		    	    	style={{ width: '20rem' }}
			    	    value={hotelState.name}
		    		    onChange={v => sethotelState({ ...hotelState, name: v })}
			        />
                </WhiteListItem>
            </div>
			
			<div style={{ width: '20rem' }}>
				<WhiteListItem onClick={() => sethotelState({ ...hotelState, enabled: !hotelState.enabled })}>
					<Text middle H2 bold>{language.getText('EV_ENABLED')}</Text>
					<Checkbox selected={hotelState.enabled} />
				</WhiteListItem>
			</div>

            <div style={{ width: '20rem' }}>
				<WhiteListItem >
					<Text middle H2 bold>{language.getText('EV_RESERVE_TYPE')}</Text>
					<InputField
				        style={{ width: '20rem' }}
	    			    value={hotelState.reserve_type}
		    		    onChange={v => sethotelState({ ...hotelState, reserve_type: v })}
			        />
				</WhiteListItem>
			</div>

			<div style={{ width: '100%' }}> 
				<Divider style={{height: '0.05rem', width: '80%', marginLeft: '0' }} opacity='0.8' />
            </div>

			<div style={{ width: '20rem' }}>
				<WhiteListItem >
					<Text middle H2 bold>{roomUpdate ? language.getText('EV_UPDATE_ROOM') : language.getText('EV_ADD_ROOMS')}</Text>
				</WhiteListItem>
			</div>

			<div style={{ width: '20rem' }}>
				<WhiteListItem >
                    <Text H2 bold>{language.getText('EV_ROOM_TYPE')}</Text>
	    	    	<InputField
		    	    	style={{ width: '20rem' }}
			    	    value={newRoom.type}
		    		    onChange={v => setNewRoom({ ...newRoom, type: v })}
			        />
                </WhiteListItem>
            </div>

			<div style={{ width: '20rem' }}>
				<WhiteListItem >
                    <Text H2 bold>{language.getText('EV_ROOM_COST')}</Text>
	    	    	<InputField
						type="number"
						min="0"
		    	    	style={{ width: '20rem' }}
			    	    value={(newRoom.price)/100.0}
		    		    onChange={v => setNewRoom({ ...newRoom, price: (v >= 0 ? parseInt(v * 100) : 0) })}
			        />
                </WhiteListItem>
            </div>

			{ roomUpdate ? (<>
				<Button onClick={updateRoom} style={{ height: '2rem', width: '15rem' }}>{language.getText('EV_UPDATE_ROOM')}</Button>
				<Button onClick={deleteRoom} style={{ height: '2rem', width: '15rem' }}>{language.getText('EV_DELETE_ROOM')}</Button>
			</>) : (
				<Button onClick={saveNewRoom} style={{ height: '2rem', width: '15rem' }}>{language.getText('EV_SAVE_NEW_ROOM')}</Button>
			)}

			<div style={{ width: '100%' }}> 
				<Divider style={{height: '0.05rem', width: '80%', marginLeft: '0' }} opacity='0.8' />
            </div>

			<div style={{ width: '20rem' }}>
				<WhiteListItem >
					<Text middle H2 bold>{language.getText('EV_ROOM_LIST')}</Text>
				</WhiteListItem>
			</div>

			<WhiteListContainer className="Room_List" >
				<AutoSizer>
					{
						({ height, width }) => (
							<FadeInUp>
								<List
									height={height}
									rowCount={ roomsList ? roomsList.length : 0}
									rowHeight={64}
									rowRenderer={rowRenderer}
									width={width}
								/>
							</FadeInUp>
						)
					}
				</AutoSizer>
			</WhiteListContainer>


            { <Button onClick={saveHotel} style={{ height: '2rem', width: '15rem' }}>{language.getText('EV_SAVE_HOTEL')}</Button>}
            { <Button onClick={backHandler} style={{ height: '2rem', width: '15rem' }}>{language.getText('EV_CANCEL')}</Button>}

		</EditContainer >
	) : (<></>);
}