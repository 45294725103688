import React, { useEffect } from 'react'
import useState from 'react-usestateref'

const Catalog = React.createContext({})

interface IState {
	active: boolean,
	productActive: boolean,
	product?: any,
	stand?: any,
	amount?: number,
	purchaseId?: string,
	search: string,
}

export function CatalogContext(props) {
	const [state, set, stateRef] = useState<IState>({
		active: false,
		productActive: false,
		search: '',
	})

	useEffect(() => {
		if (!state.productActive) set(p => ({
			...p,
			amount: 1,
			purchaseId: undefined,
		}))
	}, [state.productActive])

	return (
		<Catalog.Provider value={[state, set, stateRef]}>
			{props.children}
		</Catalog.Provider>
	)
}

export default Catalog