import mixpanel from 'mixpanel-browser';

export default class MixPanel {
    constructor (token: string, config?: object) {
        this.init(token, config);
    }
    init(token: string, config?: object) {
        mixpanel.init(token, config);
    }
    /**
     * Function to track events
     * @param eventName 
     * @param data 
     * @returns object or false
     */
    track(eventName: string, data?: object) {
        mixpanel.track(eventName, data);
    }
    /**
     * Function to track Time Events
     * TODO: validate behaviour with API Reference
     * @param eventName 
     */
    timeEvent(eventName) {
        mixpanel.time_event(eventName)
    }
    /**
     * Function to identify user with MixPanel
     * @param userId 
     */
    identify(userId: string) {
        mixpanel.identify(userId);
    }
    /**
     * Function to disable event tracking
     * @param Array of eventNames, if not provided, all events will be disabled
     */
    disable () {
        mixpanel.disable();
    }
    /**
     * Function to set current user properties
     * use it on login or profile update
     * @param data 
     */
    setPeople(data: object) {
        mixpanel.people.set(data);
    }
}