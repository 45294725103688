import React, { useState, useEffect } from 'react'
import styled, { css } from 'styled-components/macro'
import { Text, Image, InputField, FadeInUp, SVG } from '../components/UI'
import FloatingButton from './FloatingButton'
import ChatView from './ChatView'
import { useHistory } from 'react-router-dom'
import Youtube from './Youtube'
import Vimeo from './Vimeo'
import EasyPromo from './EasyPromo'
import Counter from './Counter'
import { useIsPortrait, useAuth, useExternalAnalytics, useIsMobile, useKeyBoardOpen } from '../hooks'

import { useStore } from '../hooks'
import Companies from './panels/Companies'
import AutoSizer from 'react-virtualized/dist/commonjs/AutoSizer';
import List from 'react-virtualized/dist/commonjs/List';
import icons from '../graphics/icons'

const _timestamp = Date.now() / 1000

const CloseButton = styled.div`
    position: absolute;
    top: 1rem;
    right: 0.7rem;
    width: 2vh;
    height: 2vh;
    cursor: pointer;
	padding: 2%;
	border-radius: 50%;
	z-index: 100 !important;
	background-color: ${p => p.theme.primary};

    :hover {
        * {
            background-color: ${p => p.theme.shadow};
			width: 80%;
    		height: 80%;
			margin: auto;
			z-index: inherit;
        }
    }
	
`

const Parent = styled.div`
    ${p => (p.isPortrait || p.isMobile ) && css`
        height: 100%;
        position: relative;
        display: grid;
        grid-auto-rows: 1fr;
    `}
`

const PanelContainer = styled.div`

	background-color: rgba(0,0,0,0.9);
	z-index: 4;

    ${p => (p.isPortrait || p.isMobile) && css`

		position: fixed;
    	top: 7.5vh;
		top: calc(var(--vh, 1vh) * 7.5);
		height: 70vh;
		height: calc(var(--vh, 1vh) * 70);
	    width: 90vw;
	    padding: 2.25rem 0 0.25rem 0;
    	margin: auto;
    `}

    ${p => (!p.isPortrait && !p.isMobile) && css`
        position: absolute;

        top: ${p => p.view.position.y};
        left: ${p => p.view.position.x};

        width: ${p => p.view.size.x};
        height: ${p => p.view.size.y};
    `}

	${p => ( p.panelChat && p.keyBoardOpen && (p.isPortrait || p.isMobile) ) &&
		css`
		@supports (-webkit-touch-callout: none) {
			top: calc(var(--vh, 1vh) * 35);
			height: ${p => p.keyBoardOpen ? p.keyBoardOpen+'px' : 'calc(var(--vh, 1vh) * 50)'};
		}
		`
	};

	border-radius: ${p => p.theme.roundCorners};
	overflow: hidden;

	* {
		z-index: 0;
	}
`

const SVideo = styled.div`
    z-index: 50;
	height: 70vh;
	width: auto;
	padding: 0.25rem 0;
	margin: auto;
	opacity: 1;

	*{
		width: 100%;
		margin: auto;
	}
`

function Video(props) {
	const onEnd = () => { if (props.onEnd) props.onEnd() }

	let content = <Vimeo id={props.id} />
	if (props.youtube) content = <Youtube id={props.id} onEnd={() => onEnd()} />

	if (props.isPortrait) return <SVideo>{content}</SVideo>
	else return content
}

const ListContainer = styled.div`
    /* height: min-content; */
    ${p => !p.isPortrait && css`
        overflow: auto;
        position: absolute;
        top: ${p => p.list.position.y};
        left: ${p => p.list.position.x};

        width: ${p => p.list.size.x};
        height: ${p => p.list.size.y};
    `}
`

const ListGrid = styled.div`
    display: grid;
    grid-auto-rows: 3rem;
    grid-gap: .5rem;
`

const Label = styled.div`
    grid-column: 1/2;
    grid-row: 1/3;
    background-color: ${p => p.theme.medium};
    * {
        display: flex;
        align-items: center;
        justify-content: center;
    }
`

const ItemContainer = styled.div`
    background-color: ${p => p.theme.dark};
    display: grid;
    grid-template-columns: ${p => p.selected ? '2rem' : '1.5rem'} auto;
    grid-template-rows: 1fr;
    grid-column-gap: .3rem;
    transition: .3s;
    cursor: pointer;

    ${p => p.selected && css`
        ${Label} { background-color: ${p => p.theme.primary}; }
    `}

    :hover {
        grid-template-columns: 2rem auto;
        ${Label} { background-color: ${p => p.theme.primary}; }
    }
`

function Item(props) {
	return (
		<ItemContainer onClick={props.onClick} selected={props.selected}>
			<Label><Text center bold H2>{props.i}</Text></Label>
			<Text style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', display: 'flex', alignItems: 'center' }} H2>{props.item.name}</Text>
		</ ItemContainer>
	)
}

function ItemsList(props) {
	const analytics = useExternalAnalytics();
	const isPortrait = useIsPortrait()
	return (
		<ListContainer isPortrait={isPortrait} list={props.view.list}> <ListGrid>
			{Object.values(props.view.items)
				.sort((a, b) => a.order > b.order ? 1 : -1)
				.map((item, i) =>
					<Item onClick={() => {
						if (analytics) {
							// Track Panel View on deliberated click
							analytics.logPanelView(item)
						}
						props.clickHandler(item.id)} 
					}
						key={item.id} 
						i={i + 1} 
						item={item} 
						selected={props.selected === item.id} 
					 />)}
		</ListGrid> </ListContainer>
	)
}

function getFirst(items) {
	const list = []
	Object.keys(items).forEach(key => list.push({ key: key, value: items[key] }))
	return list.sort((a, b) => a.value.order > b.value.order ? 1 : -1)[0].key
}

export default function View(props) {
	const auth = useAuth()
	const isPortrait = useIsPortrait()
	const isMobile = useIsMobile();
	const [selected, setSelected] = useState(getFirst(props?.view?.items))
	let item = props?.view?.items[selected]
	//const [open, setOpen] = useState(true);
	const keyBoardOpen = useKeyBoardOpen();

	const vhValue = window.innerHeight;
	const vwValue = window.innerWidth;

	if (!item) return (<div></div>)

	if (!props.view.position) props.view.position = { x: '50%', y: '50%' }
	if (!props.view.size) props.view.size = { x: '20%', y: '20%' }

	const handleOnOpen = () => {
		if(props.hideArrow !== undefined) props.hideArrow(true);
	};
	
	const handleOnClose = () => {
		if(props.hideArrow !== undefined) props.hideArrow(false);
		if(props.closeView !== undefined) props.closeView();
	};

	// eslint-disable-next-line react-hooks/rules-of-hooks
	useEffect(() => {
		handleOnOpen();
		return () => {
			handleOnClose();
		}
	}, []);

	const onVideoEnd = () => {
		if (props.view.playlist) {
			const keys = Object.values(props.view.items).sort((a, b) => a.order > b.order ? 1 : -1).map(i => i.id)
			let nextIndex = keys.indexOf(selected) + 1
			if (nextIndex >= keys.length) {
				if (props.view.loop) nextIndex = 0
				else return
			}

			setSelected(keys[nextIndex])
		}
	}

	function encodeToBase64(text) { return Buffer.from(text, 'base64') }

	function getWebUrl() {
		let url = item.data ?? ''

		// For web games.
		if (item.userParams) {
			url += `?nombre=${auth?.user?.name}&apellido=${auth?.user?.lastName}&email=${auth?.user?.email}`
		}

		// For the travelway login.
		if (item.travelWayLogin) {
			const emailBase64 = Buffer.from(auth?.user?.email ?? '').toString('base64')
			const timestamp = Math.floor(_timestamp).toString()
			const token = Buffer.from('c4f31702d5b2d9bef6bfa708caadfcff66cab73e39a69bb3d8dbef82fab449fb' + '_@@@_' + timestamp).toString('base64')

			if (url.length != 0 && url[url.length - 1] != '/') url += '/'
			url += `${emailBase64}/${token}`
		}

		return url
	}

	const getContent = () => {	

		let content = null
		if (item.type === 'video') content = <Video name="Video_Youtube" isPortrait={isPortrait} id={item.data} onEnd={onVideoEnd} youtube />
		if (item.type === 'vimeo') content = <Video isPortrait={isPortrait} id={item.data} />
		if (item.type === 'web') content = <iframe width="100%" height="100%" src={getWebUrl()} allowFullScreen frameBorder="0" allow="camera *;microphone *" />
		if (item.type === 'image') content = <Image contain url={item.data} />
		if (item.type === 'chat') {
			content = <ChatView onlyAdmins={item.onlyAdmins} id={props.view.id} panelChat={true} isPortrait={isPortrait} isMobile={isMobile} />
		}
		if (item.type === 'stands') content = <StandList />
		if (item.type === 'companies') content = <Companies panel= {item} viewId={props.view.id} />
		if (item.type === 'promos') content = <EasyPromo gameUrl={item.data} />
		if (item.type === 'counter') content = <Counter {...item.values} />;

		return (
			<PanelContainer name="PanelContainer" isPortrait={isPortrait} isMobile={isMobile} vwValue={vwValue} vhValue={vhValue} view={props.view} panelChat={(item.type === 'chat')} keyBoardOpen={keyBoardOpen} >
				{ isPortrait && (
					<CloseButton name="Close_Button" onClick={() => {handleOnClose()}}>
						<SVG image={icons.cross} />
					</CloseButton>
				)}
				{content}
			</PanelContainer>
		);
	}

	const getButtons = () => {
		if (item.buttons) return Object.keys(item.buttons).map(b => <FloatingButton key={b.id} button={b} />)
	}

	if ((!isPortrait && props?.view?.hideInDesktop) || (isPortrait && props?.view?.hideInMobile)) return null

	return (
		<Parent name="View_Parent_Component" isPortrait={isPortrait} isMobile={isMobile}>
			{getContent()}
			{getButtons()}
			{props.view.list && <ItemsList isPortrait={isPortrait} clickHandler={setSelected} view={props.view} selected={selected} />}
		</Parent>
	)
}

// Stands Search.
const StandListContainer = styled.div`
    padding: 1rem;
    height: 100%;
    display: grid;
    grid-template-rows: 3rem auto;
    grid-gap: 1rem;
    position: relative;
    background-color: rgba(0, 0, 0, .8);
`

const StandsGrid = styled.div`
    height: 100%;
`

export const AutoRow = styled.div`
    display: grid;
    grid-auto-flow: column;
    grid-gap: 1.5rem;
    height: 2rem;
`

function StandList(props) {
	const [store, dispatch] = useStore()
	const [search, setSearch] = useState('')
	const history = useHistory()

	const selectStandHandler = stand => {
		history.push(`/stand?pavillionId=${stand.pavillion}&id=${stand.id}`)
	}

	const filter = s => {
		if (search === '') return true
		const v = search.toLowerCase()

		if (s.name && s.name.toLowerCase().includes(v)) return true
		if (s.description && s.description.toLowerCase().includes(v)) return true
		if (s.tags && s.tags.toLowerCase().includes(v)) return true

		return false
	}

	const list = store.stands && Object.values(store.stands)
		.filter(filter)
		.sort((a, b) => {
			const av = a.order ? parseFloat(a.order) : Number.MAX_SAFE_INTEGER
			const bv = b.order ? parseFloat(b.order) : Number.MAX_SAFE_INTEGER
			return av > bv ? 1 : -1
		})
		.map(s => <StandPreview
			key={s.id}
			stand={s}
			pavillionName={s.pavillion && store.pavillions && s.pavillion in store.pavillions && store.pavillions[s.pavillion].name}
			onClick={() => selectStandHandler(s)}
		/>)

	const rowRenderer = ({ key, index, style }) => {
		return (
			<div key={key} style={style}>
				{list[index]}
			</div>
		)
	}

	return (
		<StandListContainer>
			<AutoRow>
				<Text bold H1>Stands</Text>
				<InputField
					placeholder='Buscar Stand'
					value={search}
					onChange={setSearch}
				/>
			</AutoRow>
			<StandsGrid>
				<AutoSizer>
					{
						({ height, width }) => (
							<FadeInUp>
								<List
									height={height}
									rowCount={list ? list.length : 0}
									rowHeight={100}
									rowRenderer={rowRenderer}
									width={width}
								/> </FadeInUp>
						)
					}
				</AutoSizer>
			</StandsGrid>
		</StandListContainer>
	)
}

const StandPreviewContainer = styled.div`
    background-color: ${p => p.theme.medium};
    display: grid;
    grid-template-columns: 1fr .5fr 1fr 1.5fr;
    cursor: pointer;
    transition: ${p => p.theme.transitionDuration};
    :hover {
        border: .1rem solid ${p => p.theme.primary};
    }
`

const StandPreviewInfo = styled.div`
    background-color: ${p => p.theme.light};
    padding: 1rem;
`

function StandPreview(props) {
	return (
		<StandPreviewContainer onClick={props.onClick}>
			<Image url={props.stand.background} />
			<Image contain url={props.stand.icon} />
			<Text bold H3 middle center>{props.pavillionName}</Text>
			<StandPreviewInfo>
				<Text bold dark H3>{props.stand.name}</Text>
			</StandPreviewInfo>
		</StandPreviewContainer>
	)
}