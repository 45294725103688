import firebase from './'
import { key } from 'firebase-key'
import { storageUrl } from '../context/Brand'

const storage = firebase.storage()

export const getUrl = path => new Promise(async (resolve, reject) => {
    try {
        const url = await storage.ref(path).getDownloadURL()
        resolve(url)
    } catch (error) { }
})

export function getFastUrl(path) {
    if (!path) return ''
    // path = path.replaceAll('/', '%2F')
    path = path.replace(/\//g, '%2F'); 
    const url = `${storageUrl}${path}?alt=media`
    return url
}

export const uploadImage = (file, name) => new Promise(async (resolve, reject) => {
    if (file.size) {
        const mb = file.size / 1024 / 1024
        if (mb > 1) {
            reject('La imagen no puede superar los 1mb')
            return
        }
    }
    try {
        const id = name ? name : key()
        const snapshot = await storage.ref().child(id).put(file)
        resolve(id)
    } catch (error) { reject(error) }
})

export const uploadBackgroundVideo = (file, name) => new Promise(async (resolve, reject) => {
    if (file.size) {
        const mb = file.size / 1024 / 1024
        if (mb > 3) {
            reject('El video de fondo no puede superar los 3MB')
            return
        }
    }
    try {
        const id = name ? name : key()
        const snapshot = await storage.ref().child(id).put(file)
        resolve(id)
    } catch (error) { reject(error) }
})

export const uploadDownloadableFile = (file) => new Promise(async (resolve, reject) => {
    try {
        const name = file.name ? file.name : key()
        const folderName = key()
        const path = `downloads/${folderName}/${name}`
        await storage.ref().child(path).put(file)
        const url = await getUrl(path)
        resolve(url)
    } catch (error) { reject(error) }
})