import React, { useState } from 'react'
import styled from 'styled-components'
import { useStore, useLanguage } from '../../hooks'
import icons from '../../graphics/icons'
import { Button, SVG, Text } from '../UI'

const SConfiguration = styled.div`
	display: grid;	
	grid-gap: ${p => p.theme.gridGap};
`

const SToggleRow = styled.div`
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: 2rem 2rem auto;
    height: 2rem;
    transition: .3s;
`

const SCheckbox = styled.div`
    background-color: ${p => p.selected ? p.theme.primary : p.theme.light};
    border: .2rem solid ${p => p.theme.primary};
    box-sizing: border-box;
    transition: .3s;
    :hover {
        transform: scale(.9);
    }
    cursor: pointer;
`

interface IState {
	isPrivate: boolean,
	isHiddenInChat: boolean
}

export default function Configuration(props: { confirmHandler(isPrivate: boolean, isHiddenInChat: boolean): void }) {
	const [state, set] = useState<IState>({
		isPrivate: false,
		isHiddenInChat: false
	})
	const [store] = useStore()
	const language = useLanguage() as any

	return (
		<SConfiguration>
			{store?.configurations?.allowPrivateInfo && <SToggleRow key='isPrivate'>
				<SCheckbox onClick={() => set(p => ({ ...p, isPrivate: !p.isPrivate }))} selected={!state.isPrivate} />
				<SVG image={icons.privacy} />
				<Text middle H2 bold primary>{language.getText('Entrar con perfil público')}</Text>
			</SToggleRow>}
			{store?.configurations?.allowHiddenInChat && <SToggleRow key='isHidden'>
				<SCheckbox onClick={() => set(p => ({ ...p, isHiddenInChat: !p.isHiddenInChat }))} selected={!state.isHiddenInChat} />
				<SVG image={icons.chat} />
				<Text middle H2 bold primary>{language.getText('Entrar visible en el chat')}</Text>
			</SToggleRow>}
			<Button key='3' style={{ padding: '.6rem 0' }} onClick={() => props.confirmHandler(state.isPrivate, state.isHiddenInChat)}>{language.getText('Aceptar')}</Button>
		</SConfiguration>
	)
}
