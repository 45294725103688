import React, { useState, useEffect } from 'react'
import styled, { css } from 'styled-components/macro'

import UsernameView from './UsernameView'
import FormView from './FormView'
import Configuration from './Configuration'
import ResetPassword from './ResetPassword'

import Loading from '../Loading'
import { sendEmail } from '../../utility'

import icons from '../../graphics/icons'
import { Image, Text, SVG } from '../UI'
import { CloseButton, MultiDropdown } from '../NewUI'
import { useAwakeBool, useAuth, useLanguage, useStore, useIsPortrait,useLocalStorage, useIsMobile } from '../../hooks'
import { setContent } from '../../firebase/database'
import { databasePaths } from '../../firebase'

export const SBackground = styled.div`
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 13;
    background-color: rgba(0, 0, 0, .8);
    display: grid;
    place-items: center;
`

const SPanel = styled.div<{ show: boolean, isPortrait: boolean }>`
    position: relative;
    padding: 1.5rem 2.5rem;
    background-color: ${p => p.theme.light};
    border-radius: ${p => p.theme.rounderCorners};
    width: min-content;
    height: min-content;

    transition: .3s;

    display: grid;
    grid-gap: 1rem;
    grid-template-rows: 5rem auto;

    ${p => !p.show && css`
        opacity: 0;    
        transform: translateY(1rem);
    `}

	${p => (p.isPortrait || p.isMobile) && css`
		width: 90vw;
		height: 78vh;
		box-sizing: border-box;
		overflow: auto;
		margin: auto;
		padding: 1.5rem 1.8rem;

		grid-template-rows: 4rem auto;
	`}

	
	@supports (-webkit-touch-callout: none) {
		top: 3vh;
	}

	@supports not (-webkit-touch-callout: none) {
	}

`

const SLogo = styled.div<{ language?: boolean, isPortrait: boolean }>`
    padding: 0 2rem;
	width: 15rem;

	${p => p.language && css`
		display: grid;
		grid-template-columns: auto min-content;
		place-items: center;
		grid-gap: .5rem;
	`}

	${p => p.isPortrait && css`
		width: 10rem;
	`}
`

interface IProps {
	foreceLogin: boolean,
	onClose(): void
}

export type TUsernameView = 'login' | 'register'

interface IState {
	view: 'username' | 'form' | 'resetPassword' | 'configuration',
	usernameView: TUsernameView,
	feedback?: string,
	loading: boolean,
	form: { email: string, password: string, name: string, lastName: string, extraInfo: any },
	isSpecialRegister: boolean,
	user?: any,
}

export default function Login(props: IProps) {
	const awake = useAwakeBool()
	const auth = useAuth()
	const language = useLanguage() as any
	const [store, dispatch] = useStore()
	const isPortrait = useIsPortrait()
	const isMobile = useIsMobile();
	const TempUser = 'TempUser';
	const [localUser, setLocalUser] = useLocalStorage(TempUser, { });
	const [state, set] = useState<IState>({
		view: 'username',
		usernameView: 'login',
		loading: false,
		form: { email: '', password: '', name: '', lastName: '', extraInfo: {} },
		isSpecialRegister: false,
	})

	//const [languageState, setLanguageState] = useState({ current: language.state.selected, prev: null, });

	const [languageOptions, setLanguageOptions] = useState({
		'english': { id: 'english', name: 'EN', value: language.state.selected === 'english' },
		'spanish': { id: 'spanish', name: 'ES', value: language.state.selected === 'spanish' }
	})

	useEffect(() => {
		if (store?.configurations.registerFirst) set(p => ({ ...p, usernameView: 'register' }))
	}, [store?.configurations])

	useEffect(() => {
		Object.values(languageOptions).forEach(o => {
			if (o.value) {
				auth.editLanguage(o.id)
				language.selectLanguage(o.id)
			}
		})
	}, [languageOptions]);

	useEffect(() => {
		if (state.view === 'configuration') {
			if (!store?.configurations?.allowPrivateInfo && !store?.configurations?.allowHiddenInChat) props.onClose()
		}
	}, [state.view])

	// Set loading state.
	function setLoading(value: boolean): void {
		set(p => ({ ...p, loading: value }))
	}

	function disconnectLocalUser() {
		const id = localUser.id;
		
		if (id) {
			setContent(`${databasePaths.chat}/${localUser.id}/chat`, false);
		}

		setLocalUser(undefined);
	}

	async function loginHandler(email: string, password: string): Promise<void> {
		setLoading(true)
		try {
			await auth.logIn(email, password)
			set(p => ({ ...p, view: 'configuration' }))

			disconnectLocalUser();
		} catch (error) {
			feedback(error.message)
		}
		setLoading(false)
	}

	const verifyEmail = async (email: string) => { 
		const value = await auth.verifyExistingEmail(email).then((val) => val);

		if(value?.code === 'auth/invalid-email'){
			feedback(language.getText('DIRECCION_DE_CORREO_MAL_FORMATEADA'));
		} else if(value) {
			feedback(language.getText('EMAIL_YA_EN_USO'));
		} else {
			feedback('');
		}

		return !value;
	};

	async function registerHandler(email: string, password: string, name: string, lastName: string, extraInfo: any, aditionalLink: string): Promise<void> {
		setLoading(true)
		try {
			if (state.isSpecialRegister && state.user) {
				const u = { ...state.user, name, lastName, extraInfo, aditionalLink }
				auth.editUser(u)
				dispatch({ type: 'set-user', payload: u })
				sendWelcomeEMail(state.user)
			}

			else {
				const newUser = await auth.register(
					email,
					password,
					name,
					lastName,
					extraInfo,
					aditionalLink
				)
				sendWelcomeEMail(newUser)
			}

			set(p => ({ ...p, view: 'configuration' }))
		} catch (error) { 
			feedback(error.message) 
		}
		setLoading(false)
	}

	function feedback(text: string): void {
		set(p => ({ ...p, feedback: text }))
	}

	// On Confirm Handler
	async function continueHandler(email: string, password: string): Promise<void> {
		
		if (email === '' || password === '') {
			feedback(language.getText('LLenar todos los campos'))
			return
		}

		if (password.length < 6) {
			feedback(language.getText('AL_MENOS_SEIS_CARACTERES'))
			return
		}

		if (state.usernameView === 'login') loginHandler(email, password)
		else {
			// Save email & password, then go to Form view.
			let algo = await verifyEmail(email);
			if(algo){
				set(p => ({ ...p, form: { ...p.form, email, password }, view: 'form' }))
			}
		}
	}

	function sendWelcomeEMail(user): void {
		// Send welcome image if enabled.
		if (user.email && store?.configurations?.sendWelcomeEmail && store?.configurations?.welcomeEmailHTML) {
			sendEmail([user.email], {
				subject: store.configurations.welcomeEmailSubject,
				html: store.configurations.welcomeEmailHTML,
				from: store.configurations.cloudFunctionSenderEmail,
				pass: store.configurations.cloudFunctionSenderEmailPass
			})
			.catch((e) => console.error(e));
		}
	}

	// Set user configuration and close panel.
	function confirmConfig(isPrivate: boolean, isHiddenInChat: boolean): void {
		if (!auth?.user) return
		const u = { ...auth.user, isPrivate: isPrivate, isHiddenInChat: isHiddenInChat }

		auth.editUser(u)
		dispatch({ type: 'set-user', payload: u })

		props.onClose()
	}

	// Handler Google/Facebook case.
	async function specialHandler(type: 'google' | 'facebook'): Promise<void> {
		let user: any = {}
		switch (type) {
			case 'google': user = await auth.googleLogin(); break
			case 'facebook': user = await auth.facebookLogin(); break
		}

		// Probably is a new user (Register)
		if (!user.name) {
			set(p => ({ ...p, user, isSpecialRegister: true, view: 'form' }))
		}

		// Login.
		else set(p => ({ ...p, view: 'configuration' }))
	}

	// Get current view from selected state.
	function getView() {

		if (state.view === 'username') return (
			<UsernameView
				usernameView={state.usernameView}
				setUsernameView={usernameView => set(p => ({ ...p, usernameView: usernameView }))}
				continueHandler={continueHandler}
				feedback={state.feedback}
				resetPasswordHandler={() => set(p => ({ ...p, view: 'resetPassword' }))}
				specialHandler={specialHandler}
			/>
		);
		if (state.view === 'form') return <FormView
			feedback={state.feedback}
			isEdit={state.isSpecialRegister}
			confirmHandler={(name, lastName, extraInfo, aditionalLink) => {
				// Save extra data.
				set(p => ({ ...p, form: { ...p.form, name, lastName, extraInfo, aditionalLink } }));
				// Attempt registering.
				registerHandler(state.form.email, state.form.password, name, lastName, extraInfo, aditionalLink);
			}}
		/>
		if (state.view === 'configuration') return <Configuration
			confirmHandler={(isPrivate, isHiddenInChat) => confirmConfig(isPrivate, isHiddenInChat)}
		/>

		if (state.view === 'resetPassword') return <ResetPassword backHandler={() => set(p => ({ ...p, view: 'username' }))} />
	}

	return (
		<SBackground className="SBackground">
			{
				state.loading ? <Loading /> :

					<SPanel className="SPanel" show={awake} isPortrait={isPortrait} isMobile={isMobile}>
						<CloseButton
							onClick={() => props.onClose()}
						/>
						<SLogo className="SLogo" isPortrait={isPortrait} language={store?.configurations?.allowEnglishVersion}>
							<Image contain url='logo.png' style={{ width: '100%' }} />
							{
								store?.configurations?.allowEnglishVersion &&
								<MultiDropdown
									small
									primary
									options={languageOptions}
									title={language.getText('Idioma')}
									clickHandler={id => setLanguageOptions(p => ({ ...p, [id]: { ...p[id], value: !p[id].value } }))}
									singleOption
								/>
							}
						</SLogo>
						{getView()}
					</SPanel>
			}
		</SBackground>
	)
}

const SWarning = styled.div`
	height: 2rem;
	display: grid;
	grid-template-columns: 2rem auto;
	grid-gap: .5rem;
`

export function Feedback(props: { feedback: string }) {
	return (
		<SWarning>
			<SVG style={{ backgroundColor: 'red' }} contain image={icons.warning} />
			<Text bold style={{ color: 'red' }}>{props.feedback}</Text>
		</SWarning>
	)
}
