import {useEffect, useState} from "react";
import styled, { css } from "styled-components/macro";
import { useStore, useLanguage, useNotifications, useIsPortrait, useIsMobile, useAuth } from '../hooks'
import { sendEmail } from '../utility'
import reservationEmail from './ReservationEmail'
import Loading from './Loading'
import DatePicker from 'react-date-picker';
import { addReservation } from '../services/reservation'
import { AlertMessage, Divider } from './UI'

const FormContainer = styled.div`
  width: auto;
  max-width: 100%;
  background-color: #f5f5f5;
  padding: 1.2rem;
  ${p => (p.isPortrait || p.isMobile) && css`
    padding: 0.25rem;
    margin: 0.5rem 1rem;
  `}
`;

const PassengerQtyContent = styled.div`
  background-color: white;
  border-radius: 5px;
  padding: 2.5rem 3rem;
  margin: 0.5rem 3rem;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);

  ${p => (p.isPortrait || p.isMobile) && css`
    width: auto;
    max-width: 100%;
    padding: 1rem 1.5rem;
    margin: 0.5rem 0;
  `}
`;

const CloseButton = styled.button`
  display: flex;
  background-color: transparent;
  color: #333;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  font-size: 20px;
  padding: 10px;
  margin: 0 0 10px auto;
`;

const EvButton = styled.button`
  display: flex;
  background-color: ${p => p.theme.primary};
  color: ${p => p.theme.secondary};
  border: none;
  border-radius: 0.25rem;
  cursor: pointer;
  font-size: 20px;
  padding: 10px 20px;
  margin: 20px auto 0 auto;
`;

const FormConteiner  = styled.div`
  background-color: white;
  border: 1px solid black;
  border-radius: 10px;
  padding: 1rem;
  margin: 1rem;
`;

const Item = styled.div`
  display: flex;
  
  margin-bottom: 10px;
  padding: 0 1rem;

  label, p, h3{
	padding: 0.5rem 1.5rem;
  }

  .form-control {
	min-width: 10rem;
    cursor: pointer;
    margin: 0.5rem;
    display: block;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;

	.react-date-picker__wrapper {
		border: none;
	}
  }

  label {
	min-width: 10rem;
	margin-right: auto;
  }

  .faqs {
	font-size: 0.7rem;
	margin: 0;
	margin-rigth: auto;
  }
  
  .cost {
	text-align: end;
	margin: auto;
	margin-right: 0;
  }
  
  .dateContent {
	display: block;	
  }

  ${p => (p.isPortrait || p.isMobile) && css`
    flex-wrap: wrap;

	.form-control {
		margin: 0.5rem 1.5rem;
	}
  `}
`;

const Dimmer = styled.div`
    position: absolute;
    top: 0;
	left: 0;
    width: 100%;
    height: 100%;
    background-color: ${p => p.theme.dark};
	opacity 0.9;
    z-index: 10;

    @supports (-webkit-touch-callout: none) {
        height: calc(var(--vh, 1vh) * 100);
    }
`

const Passenger_Default ={
	name: '',
	lastName: '',
	birthday: '',
	identification: '', 
}

const Contact_Default ={
	userId: '',
	name: '',
	phoneNumber: '',
	email: '',
	agencyName: '',
}

const Reservation_Default = {
    email: '',
    id: null,
    persons: 0,
    parent_package: null,
    seller: null,
};

const ReservationForm = ({selectedEvent, selectedSeller, selectedHotel, selectedRooms, handleCloseForm, setOnSuccess}) => {
    const [store, dispatch] = useStore()
    const isPortrait = useIsPortrait();
    const isMobile = useIsMobile();
    const language = useLanguage();
    const notifications = useNotifications();
	const auth = useAuth();
    const [passengers, setPassengers] = useState(0);
    const [minorPassengers, setMinorPassengers] = useState(0);
    const [infants, setInfants] = useState(0);
    const [passengersState, setPassengersState] = useState(null);
    const [contactState, setContactState] = useState({ 
		...Contact_Default, 
		userId: auth.user?.id,
		name: auth.user?.name,
		email: auth.user?.email,
	});
	const [sendingEmail, setSendingEmail] = useState(false);
	const [onError, setOnError] = useState(null);
	const [cost, setCost] = useState({
		flight: 0,
		hotel: 0,
		infant: 0,
		total: 0,
	});
	const [additionalInfo, setAdditionalInfo] = useState('');

	const maxPassangersQty = 10;
	const currentDate = new Date();
    
    const onClose = () => {
		handleCloseForm();
    };

    const handlePassengers = () => {

        if( passengers > 0 && minorPassengers >= 0 ){

			if( (passengers + minorPassengers) > maxPassangersQty ){
				notifications.notify(language.getText('EV_ERR_MAX_PASSENGER_QTY') + ' - max: ' + maxPassangersQty);
				return;
			}
			
			if( contactState.name && contactState.phoneNumber && contactState.email ){
				let passargerList = [];

				for( let i=0; i < (passengers + minorPassengers); i++ ){
					passargerList.push({ ...Passenger_Default });
				}
			
            	setPassengersState(passargerList);

			} else {
				notifications.notify(language.getText('EV_ERR_INVALID_CONTACT_DATA'));
			}

        } else {
            notifications.notify(language.getText('EV_ERR_INVALID_PASSENGER_QTY'));
        } 
    };

	const updatePassenger = (index, field, value) => {
		const updatedPassengers = [...passengersState];
		updatedPassengers[index][field] = value;
		setPassengersState(updatedPassengers);
	};

	const sendReservation = async () => {

		setOnError(null);

		if(
			!passengersState.find(p => (
				!p.name || !p.lastName || !p.birthday || !p.identification 
			))
		){
			setSendingEmail(true);

			const res = await addReservation(null, {
				...Reservation_Default,
				email: contactState.email,
				persons: passengersState.length,
    			parent_package: selectedEvent.id,
    			seller: selectedSeller.id,
			});

			if(res > 0){
				await processEmail();
			} else {
				setSendingEmail(false);
				setOnError(`${language.getText('EV_RESERVATION_PROCESS_ERROR')}: ${res == -1 ? language.getText('EV_NO_STOCK_ERROR') : language.getText('EV_UNKNOW_ERROR')}`);
			}

		} else {
			notifications.notify(language.getText('EV_ERR_REQUIRED_FIELDS'));
			setOnError(language.getText('EV_ERR_REQUIRED_FIELDS'));
		}
	};

	const processEmail = async () => {
		setSendingEmail(true);
		setOnError(null);

		try{

			const backupEmail = store.configurations?.cloudFunctionSenderEmail;
    		const emailBody = reservationEmail({
				event: selectedEvent, 
				seller: selectedSeller, 
				hotel: selectedHotel,
				rooms: selectedRooms,
				client: contactState, 
				passengers: passengersState, 
				infants: infants,
				additionalInfo: additionalInfo,
				cost: cost,
				title: language.getText('EV_EMAIL_TITLE') || '',
			});

			const res = await sendEmail([selectedSeller.email, backupEmail], {
				subject: `[${language.getText('EV_EMAIL_TITLE')}] - ${selectedEvent?.name}`,
				text: '',
				html: emailBody,
				picture: '',
				from: backupEmail,
				pass: store.configurations?.cloudFunctionSenderEmailPass
			})

			setSendingEmail(false);

			if( res?.error  ){
				notifications.notify(language.getText('EV_SEND_EMAIL_ERROR'));
				setOnError(language.getText('EV_SEND_EMAIL_ERROR'));
			} else {
				setOnSuccess(language.getText('EV_SEND_EMAIL_SUCCESS'));
				handleCloseForm();
			}
		
		} catch(e) {
			console.error('An error occurred while trying to send the email: ', e?.message);
			setSendingEmail(false);
			notifications.notify(language.getText('EV_SEND_EMAIL_ERROR'));
			setOnError(language.getText('EV_SEND_EMAIL_ERROR'));
		}		
	};

	const calcTotal = () => {
		// to do

		let hotelTotalCost = 0;
				
		Object.values(selectedRooms).forEach((e) => {
			hotelTotalCost += (e.price * (e?.quantity || 0) ) / 100.0;
		});

		let flight = ( ( selectedEvent?.flight_cost || 0 ) * (passengers + minorPassengers) ) / 100.0;
		let infantCost = ( ( selectedEvent?.infant_cost || 0 ) * infants ) / 100.0;
		let total = flight + infantCost + hotelTotalCost;

		setCost({
			flight: flight,
			hotel: hotelTotalCost,
			infant: infantCost,
			total: total,
		});
	
	}

	useEffect(() => {
		calcTotal();
	}, [passengers, minorPassengers, infants]);

	return selectedEvent ? (
        <>
            <FormContainer className="Card_Container" isPortrait={isPortrait} isMobile={isMobile} >
				<CloseButton onClick={onClose}>X</CloseButton>
				{ onError && (
					<AlertMessage danger >
						{onError || 'Opps!'}
					</AlertMessage>
				)}
                { !passengersState ? (<>
                	<PassengerQtyContent isPortrait={isPortrait} isMobile={isMobile} >

						<Item>
                    	    <h3>{language.getText('EV_RESERVATION_DATA')}</h3>
                	    </Item>
						<Item  isPortrait={isPortrait} isMobile={isMobile}  >
                    	    <label>{language.getText('EV_CONTACT_NAME')}</label>
                        	<input className="form-control" 
                            	type="text"
								value={contactState.name}
								placeholder={language.getText('EV_CONTACT_NAME')}
								required
        	                    onChange={(e) => setContactState({...contactState, name: e.target.value}) }
            	            />
                	    </Item>
						<Item  isPortrait={isPortrait} isMobile={isMobile}  >
                    	    <label>{language.getText('EV_CONTACT_AGENCY_NAME')}</label>
                        	<input className="form-control" 
                            	type="text"
								value={contactState.agencyName}
								placeholder={language.getText('EV_CONTACT_AGENCY_NAME')}
        	                    onChange={(e) => setContactState({...contactState, agencyName: e.target.value}) }
            	            />
                	    </Item>
						<Item isPortrait={isPortrait} isMobile={isMobile} >
                    	    <label>{language.getText('EV_CONTACT_NUMBER')}</label>
                        	<input className="form-control" 
                            	type="text"
								value={contactState.phoneNumber}
								placeholder="000000000"
								required
        	                    onChange={(e) => setContactState({...contactState, phoneNumber: e.target.value}) }
            	            />
                	    </Item>
						<Item isPortrait={isPortrait} isMobile={isMobile} >
                    	    <label>{language.getText('EV_CONTACT_EMAIL')}</label>
                        	<input className="form-control" 
                            	type="email"
								value={contactState.email}
								placeholder="example@mail.com"
								required
        	                    onChange={(e) => setContactState({...contactState, email: e.target.value}) }
            	            />
                	    </Item>

	                    <Item isPortrait={isPortrait} isMobile={isMobile} >
    	                    <label>{language.getText('EV_PASSENGER_QTY')}</label>
        	                <input className="form-control" 
            	                type="number"
                	            min="0"
								value={passengers}
								required
                        	    onChange={(e) => setPassengers( e.target.value > 0 ? parseInt(e.target.value) : 0)}
                        	/>
	                    </Item>
    	                <Item isPortrait={isPortrait} isMobile={isMobile} >
        	                <label>{language.getText('EV_MINOR_PASSENGER_QTY')}</label>
            	            <input className="form-control" 
                	            type="number"
                    	        min="0"
								value={minorPassengers}
                            	onChange={(e) => setMinorPassengers( e.target.value > 0 ? parseInt(e.target.value) : 0)}
                        	/>
	                    </Item>
    	                <Item>
        	                <label>{language.getText('EV_HAVE_BABYS')}</label>
            	        </Item>
                	    <Item isPortrait={isPortrait} isMobile={isMobile} >
                    	    <label>{language.getText('EV_BABYS_QTY')}*</label>
                        	<input className="form-control" 
                            	type="number"
	                            min="0"
								value={infants}
        	                    onChange={(e) => setInfants( e.target.value > 0 ? parseInt(e.target.value) : 0)}
            	            />
                	    </Item>

						<Item isPortrait={isPortrait} isMobile={isMobile} >
                    	    <label>{language.getText('EV_ADDITIONAL_INFO')}</label>
                        	<textarea className="form-control" 
                            	row="5"
								value={additionalInfo}
        	                    onChange={(e) => setAdditionalInfo(e.target.value)}
            	            />
                	    </Item>

						<Divider color={'black'} style={{height: '0.05rem', width: '80%', margin: '2rem auto' }} opacity='0.8' />

						<Item isPortrait={isPortrait} isMobile={isMobile} >
                    	    <h3>{language.getText('EV_TOTAL_COST')}</h3>
                    	    <label className="cost" >${cost.total}</label>
                	    </Item>

						<Item isPortrait={isPortrait} isMobile={isMobile} >
                    	    <label>{language.getText('EV_TOTAL_FLIGHT_COST')} (${(selectedEvent?.flight_cost || 0)/100.0} {language.getText('EV_BY_PASSENGER')})</label>
                    	    <label className="cost" >${cost.flight}</label>
                	    </Item>
						<Item isPortrait={isPortrait} isMobile={isMobile}  >
                    	    <label>{language.getText('EV_TOTAL_BABY_COST')} (${(selectedEvent?.infant_cost || 0)/100.0} {language.getText('EV_BY_INFANT')})</label>
                    	    <label className="cost" >${cost.infant}</label>
                	    </Item>
						<Item isPortrait={isPortrait} isMobile={isMobile} style={{ justifyContent: 'space-between' }} >
                    	    <label>{language.getText('EV_TOTAL_HOTEL_COST')}</label>
                    	    <label className="cost" >${cost.hotel}</label>
                	    </Item>

						<Divider color={'black'} style={{height: '0', width: '80%', margin: '2.5rem auto' }} opacity='0.8' />

					
						<Item style={{ flexDirection: 'column' }} >
							<p className="faqs" >*{language.getText('EV_BABYS_FAQS')}</p>
                    	    <p className="faqs" >*{language.getText('EV_TOTAL_COST_FAQS')}</p>
                	    </Item>
                	</PassengerQtyContent>
                	<EvButton onClick={handlePassengers} >{language.getText('CONTINUAR')}</EvButton>
                </>) :
				(<>
                	{ passengersState && passengersState.map( (p, i) => 
						<FormConteiner key={'passarger'+i}>
							<Item>
                    		    <h3>{language.getText('EV_PASSENGER')} N° {i+1}</h3>
                	    	</Item >
							<Item  isPortrait={isPortrait} isMobile={isMobile} >
                    		    <label>{language.getText('EV_PASSENGER_NAME')}</label>
                        		<input className="form-control" 
                            		type="text"
									value={p.name}
									placeholder="Your Name"
        	                    	onChange={ (e) => updatePassenger(i, 'name', e.target.value) }
            	            	/>
                	    	</Item>
							<Item  isPortrait={isPortrait} isMobile={isMobile} >
                    		    <label>{language.getText('EV_PASSENGER_LAST_NAME')}</label>
                        		<input className="form-control" 
                            		type="text"
									value={p.lastName}
									placeholder="Your Last Name"
        	                    	onChange={ (e) => updatePassenger(i, 'lastName', e.target.value) }
            	            	/>
                	    	</Item>
							<Item  isPortrait={isPortrait} isMobile={isMobile} >
                    		    <label>{language.getText('EV_PASSENGER_BIRTHDAY')}</label>
								<div className="dateContent">
									<DatePicker className="form-control" 
										maxDate={currentDate}
										value={p.birthday} 
										onChange={ (v) => updatePassenger(i, 'birthday', v.toString()) } 
									/>
								</div>
                	    	</Item>
							<Item  isPortrait={isPortrait} isMobile={isMobile} >
                    		    <label>{language.getText('EV_PASSENGER_IDENTIFICATION')}</label>
                        		<input className="form-control" 
                            		type="text"
									value={p.identification}
									placeholder="40100100"
        	                    	onChange={ (e) => updatePassenger(i, 'identification', e.target.value) }
            	            	/>
                	    	</Item>
	                    </FormConteiner>
					)}
    	            <EvButton onClick={sendReservation} >{language.getText('EV_FINISH_RESERVATION')}</EvButton>
					{ sendingEmail && (
						<Dimmer>
							<Loading />
						</Dimmer>
					)}
                </>)}     
            </FormContainer>
        </>
	) : (<></>);
};

export default ReservationForm;
