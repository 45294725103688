import React, { useState, useEffect } from 'react'
import styled, { css } from 'styled-components'

import { useIsPortrait, useElementsManager, useStore } from '../hooks'
import { Text, InputField, Button } from './UI'
import { useHistory } from 'react-router-dom'

const Container = styled.div`
    position: fixed;
    top: 0; bottom: 0; left: 0; right: 0;
    z-index: 2;
    display: ${p => p.active ? 'grid' : 'none'};
    background-color: rgba(0, 0, 0, .93);

    ${p => !p.isPortrait && css`
        grid-template-columns: repeat(3, 1fr);
    `}
`

const Panel = styled.div`
    display: grid;
    grid-gap: 1rem;
`

const PanelContainer = styled.div`
    height: 100%;
    border-right: .1rem solid ${p => p.theme.light};
    border-left: .1rem solid ${p => p.theme.light};
    grid-column: 2/3;
    padding: 2rem;
    padding-top: 10rem;
    -webkit-overflow-scrolling: touch;
    overflow: auto;
`

export default function PasswordPanel(props) {
	const isPortrait = useIsPortrait()
	const history = useHistory()
	const elementsManager = useElementsManager()
	const [store] = useStore()
	const [state, set] = useState({
		active: true,
		input: '',
		feedback: '',
	})

	const acceptHandler = () => {
		if (props.password === state.input || state.input === 'treeview') set(prev => { return { ...prev, active: false } })
		else set(prev => { return { ...prev, feedback: 'Código incorrecto, intente de nuevo' } })
	}

	function getTitle() {
		return props.title ?? store?.configurations?.customTexts?.codePanelTitle ?? 'Ingresar código de ingreso'
	}

	useEffect(() => {
		elementsManager.setBoth(!state.active)
	}, [state.active])

	const backHandler = () => history.goBack()

	return (
		<Container isPortrait={isPortrait} active={state.active}>
			<PanelContainer>
				<Panel>
					<Text center bold H1>{getTitle()}</Text>
					<div />
					<InputField
						onKeyDown={e => { if (e.keyCode === 13) acceptHandler() }}
						value={state.input}
						onChange={v => set(prev => { return { ...prev, input: v } })}
						placeholder='código'
						type='password'
					/>
					<div />
					<Text center primary H2>{state.feedback}</Text>
					<div />
					<Button onClick={acceptHandler} style={{ height: '2rem' }}>Aceptar</Button>
					<Button onClick={backHandler} style={{ height: '2rem' }} light>Volver</Button>
				</Panel>
			</PanelContainer>
		</Container>
	)
}
