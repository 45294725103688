import { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useLanguage } from '../../hooks'
import List from 'react-virtualized/dist/commonjs/List'
import AutoSizer from 'react-virtualized/dist/commonjs/AutoSizer'
import { Text, Button, FadeInUp, InputField, IconButton, Divider } from '../UI'
import { getAllReservations, getReservationsBySeller, removeReservation } from '../../services/reservation'
import Loading from '../Loading'

import { useAuth } from '../../hooks'

const CloseButton = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 2.5rem;
    height: 2.5rem;
`;

const EditContainer = styled.div`
    display: grid;
    grid-gap: 2rem;
`;

const WhiteListContainer = styled.div`
    width: 80%;
    height: 20rem;
    overflow: auto;
    border: .1rem solid ${p => p.theme.light};
    padding: 1rem;
`;


const RoomListLabel = styled.label`
	color: white;
	width: 10rem; 
	margin-left: 0; 
	margin-right: 1rem;
`;

const Dimmer = styled.div`
    position: absolute;
    top: 0;
	left: 0;
    width: 100%;
    height: 100%;
    background-color: ${p => p.theme.dark};
	opacity 0.9;
    z-index: 10;

    @supports (-webkit-touch-callout: none) {
        height: calc(var(--vh, 1vh) * 100);
    }
`

export default function EditReserves() {
	const auth = useAuth()
	const language = useLanguage();
	const [reserves, setReserves] = useState(null);
	const [loadingState, setLoadingState] = useState(true);

	const getReserves = async () => {
		if (auth?.user?.id) {
			let resp;
			if (auth.user.isSeller) {
				resp = await getReservationsBySeller(auth.user.id)
			} else if (auth.user.type === "admin") {
				resp = await getAllReservations();
			}
			
			setReserves(resp);
		}
	}

	const cancelReserve = async (id) => {
		setLoadingState(true);
		await removeReservation(id);
		getReserves();
		setLoadingState(false);
	}

	useEffect(() => {
		getReserves();
	}, [auth])

	useEffect(() => {

		if(	loadingState && reserves ){
			setLoadingState(false);
		}
		
		return () => {};
	}, [loadingState, reserves]);

	const reserveList = !reserves ? [] : reserves
        .map( r => <div style={{ display: 'flex', justifyContent: 'space-between' }} onClick={() => {}}>
			<RoomListLabel ><Button onClick={() => cancelReserve(r.id)} >{language.getText('EV_CANCEL')}</Button></RoomListLabel>
			<RoomListLabel >Email: {r.email}</RoomListLabel>
			<RoomListLabel >Personas: {r.persons}</RoomListLabel>
			<RoomListLabel >Paquete: {r.parent_package}</RoomListLabel>
			{!auth.user.isSeller && <RoomListLabel >Vendedor: {r.seller}</RoomListLabel>}
		</div>
        );

	const rowRenderer = ({ key, index, style }) => {

	return (
			<div key={key} style={style}>
				{reserveList[index]}
			</div>
		)
	}

	return reserves && !loadingState ? (
		<EditContainer>
			<Text H1 bold>Reservas:</Text>

			<WhiteListContainer className="Room_List" >
				<AutoSizer>
					{
						({ height, width }) => (
							<FadeInUp>
								<List
									height={height}
									rowCount={ reserveList ? reserveList.length : 0}
									rowHeight={64}
									rowRenderer={rowRenderer}
									width={width}
								/>
							</FadeInUp>
						)
					}
				</AutoSizer>
			</WhiteListContainer>

		</EditContainer >)
		: (
			<Dimmer>
				<Loading />
			</Dimmer>
		);
}