import React, { useState } from 'react'
import styled from 'styled-components'

import { Text } from '../components/UI'

const SPlayground = styled.div`
	position: fixed;
	width: 100vw;
	height: 100vh;
	background-color: #111111;
	z-index: 10;
`

const SHolder = styled.div`
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
`

export default function Playground() {
	const [state, set] = useState({
		'a': { id: 'a', name: 'test', value: false },
		'b': { id: 'b', name: 'some longer test', value: false },
		'c': { id: 'c', name: 'and one final', value: true },
	})

	return (
		<SPlayground>
			<SHolder>
				{/* <MultiDropdown
					title='Options'
					options={state}
					clickHandler={id => set(p => ({ ...p, [id]: { ...p[id], value: !p[id].value } }))}
				/> */}
			</SHolder>
		</SPlayground>
	)
}
