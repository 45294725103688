import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useStore, useLanguage } from '../../hooks'
import { key } from 'firebase-key'
import { Text, Button, Background, FadeInUp, } from '../UI'
import EditHotel from './EditHotel';
import { addUpdateHotel } from '../../services/hotels';

const Hotel_Default = {
	id: null,
    name: '',
    reserve_type: '',
    rooms: {},
}

export default function Hotel() {
	const [state, setState] = useState({
		menu: 'LIST',
		type: 'hotel',
		selected: null,
	})

	const selectHotel = (e) => setState({ ...state, selected: e, menu: 'SELECTED', type: 'hotel' });

	// Get List of hotels or Edit hotel UI.
	const getContent = () => {
		if (state.menu === 'LIST') {
			return (
				<HotelList selectHotel={selectHotel} />
			);
		} else if (state.menu === 'SELECTED') {
			return (
				<EditHotel
					type={state.type}
					hotel={state.selected}
					backHandler={() => {
						setState({ ...state, selected: null, menu: 'LIST' })
					}}
				/>
			);
		} else {
			return (<></>);
		}
	};

	return getContent();
}

const ListContainer = styled.div`
    padding: 2rem 1rem;
    display: grid;
    grid-gap: 2rem;
`

const Row = styled.div`
    display: grid;
    width: min-content;
    grid-gap: 1rem;
    grid-auto-flow: column;
    height: 3rem;
`

const Grid = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 15rem);
    grid-auto-rows: 9rem;
    grid-gap: 2rem;
`

function HotelList({ selectHotel }) {
	const [store, dispatch] = useStore()
	const language = useLanguage()

	const createAreaHandler = async () => {
		const newHotel = {
			...Hotel_Default,
			id: key(),
			name: "Nuevo Hotel",
		}

        await addUpdateHotel(newHotel.id, newHotel);

		selectHotel(newHotel);
	}

	return (
		<FadeInUp>
			<ListContainer className="ListContainer" >
				<Row>
					<Text oneline bold H1>{language.getText('Hoteles')}</Text>
					{<Button onClick={createAreaHandler}>{language.getText('Agregar nuevo Hotel')}</Button>}
				</Row>
				<Grid> 
					{store?.hotels && Object.values(store.hotels).map(e => 
						<HotelPreview onClick={() => selectHotel(e)} hotel={e} />
					)} 
				</Grid>

			</ListContainer>
		</FadeInUp>
	)
}

const HotelPreviewContainer = styled.div`
    display: grid;
    grid-template-rows: auto 2rem;
    cursor: pointer;
    transition: ${p => p.theme.transitionDuration};
    :hover {
        transform: translateY(.3rem);
    }
`

function HotelPreview({hotel, onClick}) {
	const content = hotel ? (
		<HotelPreviewContainer onClick={onClick}>
			{/* <Background medium><Image contain url={getBackground()} /></Background> */}
			<Background><Text dark H2 bold center>{hotel.name}</Text></Background>
		</HotelPreviewContainer>
	) : null

	return content
}