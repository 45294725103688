import React, { useState, useEffect } from 'react'
import styled from 'styled-components/macro'

import { SVG, Divider } from './UI'
import FloatingButton from './FloatingButton'
import View from './View'

import chatIcon from '../graphics/icons/chat.svg'
import videoIcon from '../graphics/icons/video.svg'
import imageIcon from '../graphics/icons/photo.svg'
import webIcon from '../graphics/icons/monitor.svg'
import searchIcon from '../graphics/icons/search.svg'
import icons from '../graphics/icons'

const ShowNavegationButtons = false

const Container = styled.div`
    position: fixed;
    width: 100vw;
	top: 50vh;
	top: calc(var(--vh, 1vh) * 50);
    bottom: 0;
	box-sizing: border-box;
	padding-top: auto;
    background: linear-gradient(0deg, rgba(40, 40, 40, 1) 70%, rgba(40, 40, 40, .3) 100%);
`

const SGrid = styled.div<{ buttonsBar?: boolean, stand?: boolean }>`
	height: 100%;
    display: grid;
	align-content: stretch;
	grid-template-rows: 1fr auto 5rem;
`

const ButtonsBarContainer = styled.div`
    width: 90vw;
	margin: auto;
`

const ButtonsBarGrid = styled.div`
    display: grid;
    grid-auto-flow: column;
    height: 100%;
    grid-gap: 1rem;
`

const TabButtonContainer = styled.div<{ selected?: boolean }>`
    transition: .1s;
    display: flex;
    justify-content: center;
    align-items: center;
`
const SelectedTabPopUp = styled.div`
	width: 100%;
	height: 0.25rem;
	margin: 0.25rem auto;
	border-radius: ${p => p.theme.roundCorners};
    background-color: ${p => p.theme.primary};
`

const TabIconContainer = styled.div`
    width: 2rem;
    height: 2rem;
`

const ContentContainer = styled.div`
    overflow-y: scroll;
    overflow-x: hidden;
	height: 26vh;
    padding: 1rem 1rem 0 1rem;
	background-image: url("https://firebasestorage.googleapis.com/v0/b/eventovirtualalpha.appspot.com/o/ContentContainer-Background.jpg?alt=media&token=e9a41052-2491-4918-9565-f36c3625d852");
	opacity: ${ p => p.showContent? '1' : '0.9'};
	background-size: cover;
`

const ContentButtonsContainer = styled.div`
    display: grid !important;
    grid-column-gap: 5%;
	grid-row-gap: 1rem;
	grid-template-columns: repeat(4, minmax(50px, 1fr));
`

const ContentViewContainer = styled.div`
    width: 100%;
    height: 100%;
    overflow: auto;
`

interface IMobileArea {
	selectedTab?: string,
	setSelectedTab(t: string): void,
	save(): void
}

export default function MobileArea(props) {
	const [state, set] = useState<IMobileArea>({
		setSelectedTab(t) {
			this.selectedTab = t
			this.save()
		},
		save() { set({ ...this }) }
	});

	const {
		buttons,
		views,
	} = props;

	const BUTTONS_ORDER = {
		'chat': 1,
		'message': 2,
		'hardcoded': 3,
		'catalog': 4,
		'internalLink': 5,
		'link': 6,
		'ranking': 7,
		'download': 8,
		'navegation': 999, // should not apply
	}

	useEffect(() => {
		let hasButtons = false

		if (buttons && (Object.values(buttons) as any[]).some(b => !b.hideInMobile)) {
			if (ShowNavegationButtons && (Object.values(buttons) as any[]).some(b => b.type === 'navegation')) state.setSelectedTab('navegation')
			else state.setSelectedTab('buttons')

			hasButtons = true
		}

		if (views) {
			for (const view of Object.values(views) as any[]) {
				if (view.items && !view.hideInMobile) {
					let doBreak = false
					for (const item of Object.values(view.items) as any[]) {
						if (!hasButtons) {
							state.setSelectedTab(view.id)
							doBreak = true
							break
						}
					}

					if (doBreak) break
				}
			}
		}

	}, [views, buttons])

	function getViewIcon(view) {
		if (view.items) {
			const type = (Object.values(view.items) as any[])[0].type
			if (type === 'video' || type === 'vimeo') return videoIcon
			if (type === 'image') return imageIcon
			if (type === 'chat') return chatIcon
			if (type === 'stands') return searchIcon
			if (type === 'web') return webIcon
			if (type === 'companies') return icons.stand
			if (type === 'promos') return icons.game
			if (type === 'counter') return icons.counter
		}
	}

	function getTabButtons() {
		const tabButtons = []
		if (buttons) {
			const navegationButtons = [];
			const otherButtons = [];

			(Object.values(buttons) as any[]).forEach(button => {
				if (!button.hideInMobile) {
					if (button.type === 'navegation' && ShowNavegationButtons) navegationButtons.push(button)
					else otherButtons.push(button)
				}
			})

			if (navegationButtons.length !== 0 && ShowNavegationButtons) {
				tabButtons.push(
					<TabButtonContainer 
						name="Navegation_TabButtons"
						key='navegation' 
						selected={state.selectedTab === 'navegation'} 
						onClick={() => state.setSelectedTab('navegation')}
					>
						<TabIconContainer >
							<SVG image={icons.menu}/>
						</TabIconContainer>
					</TabButtonContainer>
					)
				if (!state.selectedTab) state.setSelectedTab('navegation')
			}

			if (otherButtons.length !== 0) {
				tabButtons.push(<></>)
				if (!state.selectedTab) state.setSelectedTab('buttons')
			}
		}

		if (views) {
			(Object.values(views) as any[]).forEach(view => {
				if (!view.hideInMobile) {
					tabButtons.push(
						<TabButtonContainer 
							name="View_TabButtons" 
							key={view.id} 
							selected={state.selectedTab === view.id} 
							onClick={() => state.setSelectedTab(view.id)}
						>
							<TabIconContainer>
								<SVG image={getViewIcon(view)} primary={ (state.selectedTab === view.id) } />
								{ (state.selectedTab === view.id) && ( <SelectedTabPopUp/> )}
							</TabIconContainer>
							
						</TabButtonContainer>
					);

					if (!state.selectedTab && view.id) state.setSelectedTab(view.id)
				}
			})
		}

		return tabButtons
	}

	function getButtons(buttonList) {
		try {
			return buttonList ? (Object.values(buttonList) as any[])
				.filter(b => {
					const isNav = b.type === 'navegation'
					return state.selectedTab === 'navegation' ? isNav : !isNav
				})
				.sort( function(a, b) {
						
					var textA = (a?.type).toString();
					var textB = (b?.type).toString();
					
					return BUTTONS_ORDER[textA] ? ( BUTTONS_ORDER[textB] ? ( (BUTTONS_ORDER[textA] < BUTTONS_ORDER[textB]) ? -1 : (BUTTONS_ORDER[textA] > BUTTONS_ORDER[textB]) ? 1 : 0 ) : -1 ) : 1;
				}
				)
				.map(b => <FloatingButton key={b.id} button={b} /> )
				: [];
		} catch (e) { 
			console.error(e);
		}
	}

	const closeView = () => {
		state.setSelectedTab('buttons');
	};

	function getView() {
		try {
			if (views[state.selectedTab]) return (
				<View 
					name="View_In_Mobile_Area" 
					closeView={ () => closeView() } 
					hideArrow={props.hideArrows ? (value) => props.hideArrows(value) : undefined } 
					view={views[state.selectedTab]} 
				/>
			);
		} catch (e) { 
			console.log(e);
			return;
		}
	}

	function getContent( buttonList ) {
		if (state.selectedTab) {
			if (state.selectedTab === 'buttons' || state.selectedTab === 'navegation') return <ContentButtonsContainer> {getButtons( buttonList )} </ContentButtonsContainer>
			else if (state.selectedTab === 'none') return <></>;
			else return <ContentViewContainer name="Content_View_Container" >{getView()}</ContentViewContainer>
		}
	}

	const tabButtons = getTabButtons()

	return (		
		<Container>
			<SGrid showButtonsBar={tabButtons.length > 1} stand={window.location.pathname === '/stand'}>
				<ContentContainer showContent={state.selectedTab != 'none'}> 
					{ getContent( { ...buttons } ) } 
				</ContentContainer>
				<Divider style={{height: '0.05rem'}} opacity='0.8' />
				{tabButtons.length > 1 && (
					<ButtonsBarContainer>
						<ButtonsBarGrid>
							{tabButtons}
						</ButtonsBarGrid>
					</ButtonsBarContainer>
				)}
			</SGrid>
		</Container>
	);
}
