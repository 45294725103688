import React, {useEffect, useState} from "react";
import styled, { css } from "styled-components/macro";
import { useIsPortrait, useIsMobile, useLanguage } from '../hooks'
import { Text } from './UI'
import EventCard from './EventCard';


const SidebarWrapper = styled.div`
  width: auto;
  max-width: 100%;
  height: auto;
  color: #333;
  max-height: 100%;
  background-color: #f5f5f5;
  padding: 1.2rem;

  ${p => (p.isPortrait || p.isMobile) && css`
	width: auto;
  	padding: 0;
	margin: 0.5rem
  `}
`;

const CardList = styled.ul`
  list-style: none;
  padding: 0 1.5rem;
`;

const CardItem = styled.li`
  background-color: white;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 10px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  cursor: pointer;
`;

const ToggleButton = styled.button`
  display: flex;
  background-color: transparent;
  color: #333;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  font-size: 20px;
  padding: 10px;
  margin: 0 0 10px auto;
`;

const Sidebar = ({ cards, selectedDate, closeSideBar }) => {
	const isPortrait = useIsPortrait();
  	const isMobile = useIsMobile();
  	const language = useLanguage();
	const [selectedCard, setSelectedCard] = useState(null);

    // setIsOpen(true);

    const toggleSidebar = () => {
		closeSideBar();
    };

	const handleOnClick = (currentEvent) => {
		setSelectedCard(currentEvent);
	};

	/**
     * This function closes the event card by removing the selected card state
     */
	const closeEventCard = () => {
		setSelectedCard(null);
	};

	const formatedDate = (day, month, year) => {
		return (day < 10 ? '0' + day : day) + '/' + (month < 10 ? '0' + month : month) + '/' + year;
	};

	const parseFromObjectDate = (dateValue) => {
		const year = dateValue.getFullYear();
		let month = dateValue.getMonth() + 1; // Months start at 0!
		let day = dateValue.getDate();
		return formatedDate(day, month, year);
	};

	const showDateRange = (card) => {

		if(!card?.departure_date || !card?.days) return '';

		let dateFrom = parseFromObjectDate(new Date(card.departure_date));
		let dateTo = new Date(card.departure_date);
		dateTo.setDate(dateTo.getDate() + parseInt(card.days - 1));
		dateTo = parseFromObjectDate(dateTo)

		return (
			`${language.getText('EV_DATE_FROM')} ${dateFrom} ${language.getText('EV_DATE_TO')} ${dateTo}`
		);
	};

	return selectedDate && !selectedCard ? (
        <>
            <SidebarWrapper isPortrait={isPortrait} isMobile={isMobile} className="Sidebar_Wrapper" >
				<ToggleButton onClick={toggleSidebar}>
                	X
            	</ToggleButton>
                <h2 style={{padding: '0 1.5rem'}} >Eventos Disponibles</h2>
                <CardList>
                    {cards.map((card, index) => (
                    <CardItem key={index} onClick={() => handleOnClick(card)} >
						<div style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }} >
							<h3>{card.name}</h3>&nbsp;-&nbsp;<p>{showDateRange(card)}</p>
						</div>
                        <Text color={'black'} truncated>{card.description}</Text>
                    </CardItem>
                    ))}
                </CardList>
            </SidebarWrapper>
        </>
	) : ( selectedCard ?  (
		<EventCard
			key={selectedCard.id}
			selectedEvent={selectedCard} 
			handleCloseCard={closeEventCard} 
		/>
	) :	
	<></> );
};

export default Sidebar;
