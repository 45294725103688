import React from 'react'
import styled, { css } from 'styled-components'

import { Text, Image } from './UI'
import logoImage from '../graphics/logos/logo-white.png'
import { useStore, useIsPortrait, useLanguage } from '../hooks'

const Container = styled.div<{ active?: boolean }>`
    display: ${p => p.active ? 'block' : 'none'};
    pointer-events: none;
    position: fixed;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
`

const Panel = styled.div<{ isPortrait: boolean }>`
    position: absolute;
    bottom: .5rem;
    right: .5rem;

    height: 2rem;
    
    background-color: ${p => p.theme.primary};
    padding: 0 1rem;

    display: grid;
    grid-template-columns: min-content 5rem;
    grid-gap: 1rem;
	border-radius: ${p => p.theme.roundCorners};

	${p => p.isPortrait && css`
		bottom: -2.5rem;
		right: 0;
	`}
`

export default function PoweredBy() {
	const [store] = useStore()
	const isPortrait = useIsPortrait()
	const language = useLanguage();

	return (
		<Container active={store?.configurations?.showPoweredBy}>
			<Panel isPortrait={isPortrait}>
				<Text oneline bold middle>{language.getText('Powered By')}</Text>
				<Image image={logoImage} contain />
			</Panel>
		</Container>
	)
}
