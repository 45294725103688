import React, { useState } from 'react'
import styled, { css } from 'styled-components/macro'

import { useCompanyPoster, useAwakeBool, useIsPortrait, useStore, useUserMoreInfo, useIsMobile } from '../../hooks'
import { SBackground, SGrid, SGridHolder } from '../topbar/SearchBar'
import { Text, SVG, Image } from '../UI'
import { CloseButton, CloseButton2 } from '../NewUI'
import icons from '../../graphics/icons'

const SCompanyPoster = styled.div<{ active: boolean }>`
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 50;

	display: ${p => p.active ? 'grid' : 'none'};	
	place-items: center;
`

const SPanel = styled.div<{ isPortrait: boolean }>`
	position: relative;
	background-color: ${p => p.theme.light};
	border-radius: ${p => p.theme.roundCorners};
	padding: 2.5rem 1rem 1rem 1rem;

	display: grid;
	grid-template-columns: 1fr 1fr min-content;
	grid-gap: 1rem;
	width: 40vw;
	height: 70vh;

	transition: .3s;
	${p => !p.active && css`
		opacity: 0;
		transform: translateY(1rem);
	`}

	${p => p.isPortrait && css`
		grid-template-columns: 1fr;
		grid-template-rows: 1fr 1fr min-content;

		overflow: auto;

		width: 80vw;
		height: 75vh;
		height: ${p => p.vhValue && p.vhValue < 640 ? '70vh' : '75vh'};

		margin-top: 1.2vh;
		margin-bottom: 5vh;
	`}
`

const SLeft = styled.div`
	display: grid;
	grid-gap: 1rem;
	grid-template-rows: auto 5rem;
	align-content: space-between;

	${p => (p.isPortrait || p.isMobile) && css`
		grid-template-rows: auto 1fr;
	`}
`

const SRight = styled.div<{ isPortrait: boolean }>`
	display: grid;
	grid-gap: 1rem;
	grid-template-rows: 1fr .1rem 1fr;
	height: inherit;
	border-right: .1rem solid ${p => p.theme.dark};
	padding-right: 1rem;
	overflow: hidden;

	${p => p.isPortrait && css`
		height: min-content;
		min-height: 10rem;
		grid-template-rows: min-content .1rem min-content;
	`}
`

const SImage = styled.div<{ isPortrait: boolean }>`
	border-radius: ${p => p.theme.roundCorners};
	overflow: hidden;
	aspect-ratio: 1/1.4;
	width: 100%;

	${p => p.isPortrait && css`
		height: 40vh;
	`}

	cursor: pointer;
`

const SBigImage = styled.div<{ active: boolean }>`
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	display: ${p => p.active ? 'grid' : 'none'};
	place-items: center;
	cursor: pointer;
	z-index: 2;
`

const SLine = styled.div`
	background-color: ${p => p.theme.dark};
`

export default function CompanyPoster() {
	const isPortrait = useIsPortrait()
	const isMobile = useIsMobile();
	const [companyPoster, setCompanyPoster] = useCompanyPoster()
	const awakeActive = useAwakeBool(companyPoster.active)
	const [bigImage, setBigImage] = useState(false)

	const vhValue = window.innerHeight;
	const vwValue = window.innerWidth;

	function closeHandler(): void {
		setCompanyPoster(p => ({ ...p, active: false, company: undefined }))
	}

	function getValues(): any[] {
		const list = []
		function add(name: string, value: string): void {
			if (!name || !value) return

			// list.push(<Text key={name} bold dark oneline H2>{name}</Text>)
			list.push(<Text key={value} dark bold H2>{value}</Text>)
			list.push(<div key={`${name}div`} />)
		}

		add('Empresa', companyPoster?.company?.name)
		add('Subtitle', companyPoster?.company?.subName)
		add('Contacto', companyPoster?.company?.email)
		add('Teléfono', companyPoster?.company?.phone)

		return list
	}

	return (companyPoster?.active && companyPoster?.company) ? (
		<SCompanyPoster className="SCompanyPoster" active={companyPoster.active}>
			<SBackground onClick={() => closeHandler()} />
			<SPanel isPortrait={isPortrait} active={awakeActive} vhValue={vhValue} vwValue={vwValue} >
				<CloseButton onClick={() => closeHandler()} />
				<SLeft isPortrait={isPortrait} isMobile={isMobile} className="SLeft">
					<SImage onClick={() => { setBigImage(true) }} isPortrait={isPortrait}  style={ { margin: 'auto', backgroundColor: 'rgb(1,1,1,.75)' } } >
						<Image url={companyPoster?.company?.fullImage} contain />
					</SImage>
					{ (isPortrait || isMobile) ? 
						(<Column isPortrait={isPortrait} isMobile={isMobile} company={companyPoster?.company} />) : 
						(<SocialsRow company={companyPoster?.company} />)
					}
				</SLeft>
				<SRight className="SRight" isPortrait={isPortrait}>
					<SGridHolder>
						<SGrid style={{ gridGap: '.2rem' }}>
							{getValues()}
						</SGrid>
					</SGridHolder>
					<SLine />
					<SGridHolder>
						<Text dark bold H3>{companyPoster?.company?.description ?? ''}</Text>
					</SGridHolder>
				</SRight>
				{ (isPortrait || isMobile) ? 
					(<SocialsRow company={companyPoster?.company} />) : 
					(<Column isPortrait={isPortrait} isMobile={isMobile} company={companyPoster?.company} />)
				}
			</SPanel>

			<SBigImage active={bigImage} onClick={() => setBigImage(false)}>
				<SBackground />
				<CloseButton2 onClick={() => setBigImage(false)} />
				<Image contain style={{ width: '85%', height: '85%' }} url={companyPoster?.company?.fullImage} />
			</SBigImage>
		</SCompanyPoster>
	) : (<></>);
}

const SSocialsRow = styled.div`
	overflow-x: auto;
`

const SHorizontalGrid = styled.div`
	display: grid;
	grid-gap: .5rem;
	grid-auto-flow: column;
	width: min-content;
	height: 100%;
`

const SSocial = styled.div`
	background-color: ${p => p.theme.primary};
	border-radius: ${p => p.theme.roundCorners};
	width: 1.5rem;
	height: 1.5rem;
	cursor: pointer;
	margin: auto;
	padding: .5rem;
`

function SocialsRow({ company }: { company: any }) {

	function getButtons(): any[] {
		const buttons = []
		function add(url: string, icon: string): void {
			if (!url) return

			buttons.push(
				<SSocial key={icon} onClick={() => window.open(url)}>
					<SVG contain image={icon} />
				</SSocial>)
		}

		add(company?.facebook, icons.facebook)
		add(company?.instagram, icons.instagram)
		add(company?.twitter, icons.twitter)
		add(company?.linkedin, icons.linkedin)
		add(company?.youtube, icons.youtube)
		add(company?.whatsapp, icons.whatsapp)


		return buttons
	}

	return (
		<SSocialsRow>
			<SHorizontalGrid>
				{getButtons()}
			</SHorizontalGrid>
		</SSocialsRow>
	)
}

const SColumn = styled.div`

`

 
const ContentContainer = styled.div`
	height: 100%;
    overflow: auto;
`

const ContactConteiner = styled.div<{ isPortrait?: boolean, isMobile?: boolean }>`
	display: grid;
    grid-gap: 0.5rem;
	grid-auto-flow: column;
	width: min-content;

	${p => (!p.isPortrait && !p.isMobile) && css`
		grid-auto-flow: row;
	`}
	
`

const SButton = styled.div`
	display: grid;
	grid-gap: .5rem;
	place-items: center;
`

function Column({ company, isPortrait, isMobile }: { company: any, isPortrait: boolean, isMobile: boolean}) {
	const [store] = useStore()
	const [userMoreInfo] = useUserMoreInfo()

	function getButtons(): any[] {
		const buttons = []
		function add(url: string, icon: string, title: string): void {
			if (!url) return

			buttons.push(
				<SButton>
					<SSocial key={icon} onClick={() => window.open(url)}>
						<SVG contain image={icon} />
					</SSocial>
					<Text center bold online dark>{title}</Text>
				</SButton>)
		}

		try {
			const user = (Object.values(store.users) as any[]).find(u => u.email === company.email)

			if (user) buttons.push(
				<SButton>
					<SSocial key='contacto' onClick={() => userMoreInfo.setUser(user)}>
						<SVG contain image={icons.chat} />
					</SSocial>
					<Text center bold online dark>Contacto</Text>
				</SButton>
			)

		} catch (e){ 
			console.error(e);
		}

		add(company?.email ? `mailto:${company.email}?subject=Consulta` : undefined, icons.email, 'Email')
		add(company?.web, icons.web, 'Website')
		add(company?.file, icons.downlaod, 'Descarga')

		return buttons
	}


	return (
		<SColumn>
			<ContentContainer>
				<ContactConteiner isPortrait={isPortrait} isMobile={isMobile} >
					{getButtons()}
				</ContactConteiner>
			</ContentContainer>
		</SColumn>
	)
}
