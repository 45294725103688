import React from "react";
import ReactDOMServer from 'react-dom/server';

const parseFromObjectDate = (date) => {
  const dateValue = new Date(date);
  const year = dateValue.getFullYear();
  let month = dateValue.getMonth() + 1; // Months start at 0!
  let day = dateValue.getDate();
  return (day < 10 ? '0' + day : day) + '/' + (month < 10 ? '0' + month : month) + '/' + year;
};

const esEmailTemplate = (event, seller, hotel, rooms, client, passengers, infants, additionalInfo, cost) => {

  return (
    <React.Fragment >
      <tr style={{ padding: "20px" }} >
        <td>
          <p><b>Mensaje en Español</b></p>
          <br />
          <p>
            Han solicitado reservar <b>{passengers ? passengers.length : 0}</b> lugares para <b>"{event?.name}"</b>, y para <b>{infants}</b> infantes menores de 2 años
            <br />
            Lo a solicitado: <b>{client?.name}</b>
            <br />
            De la agencia: <b>{client?.agencyName}</b>
            <br />
            A proporcionado el siguiente email de contacto: <b>{client?.email}</b>
            <br />
            Y el siguiente numero de contacto: <b>{client?.phoneNumber}</b>
            <br />
            Ha seleccionado como vendedor a: <b>{seller?.name + seller?.lastName ? ' ' + seller?.lastName : ''}</b> - email: <b>{seller?.email}</b>
            <br />
            Ha seleccionado el siguientes hotel: <b>{hotel?.name}</b>
            <br />
            { rooms && Object.values(rooms).filter((r) => r.quantity > 0).
              map((r) => (
                <p key={r.id} >
                  <b>{r.quantity}</b> habitaciones del tipo <b>{r.type}</b>
                  <br />
                </p>
              )
            )}
          </p>
          <br />
        </td>
      </tr>
      <tr>
        <td>
          <p><b>Observaciones:</b></p>
          <p>{additionalInfo || ''}</p>
          <br />
        </td>
      </tr>
      <tr>
      <td>
        <p><b>Resumen de costos proporcionados por el sistema.</b></p>
        <br />
        { cost && (
          <p>
            Costo de pasajes: <b>$ {cost?.flight || 0}</b><br />
            Costo de hotel: <b>$ {cost?.hotel || 0}</b><br />
            Cargo por infantes: <b>$ {cost?.infant || 0}</b><br />
            Costo total: <b>$ {cost?.total || 0}</b><br />
          </p>
        )}
        <br />
      </td>
    </tr>
      <tr>
        <td>
          <p>Los datos de los pasajeros son:</p>
          { passengers && passengers.map((p, i) => (
            <p key={'passenger-'+i} >
              <b>Pasajero N° {i+1}</b>
              <br />
              Nombre: <b>{p.name}</b>
              <br />
              Apellido: <b>{p.lastName}</b>
              <br />
              Fecha de nacimiento: <b>{parseFromObjectDate(p.birthday)}</b>
              <br />
              DNI o Identificación: <b>{p.identification}</b>
            </p>
          ))}
          <br />
        </td>
      </tr>
    </React.Fragment>
  );
};

const enEmailTemplate = (event, seller, hotel, rooms, client, passengers, infants, additionalInfo, cost) => {
  return (
    <React.Fragment >
      <tr style={{ padding: "20px" }}>
        <td>
          <p><b>Message in English</b></p>
          <br />
          <p>
            They have requested to reserve <b>{passengers ? passengers.length : 0}</b> places for <b>"{event?.name}"</b>, and for <b>{infants}</b> infants under 2 years of age
            <br />
            Has requested it: <b>{client?.name}</b>
            <br />
            From the agency: <b>{client?.agencyName}</b>
            <br />
            The following contact email has been provided: <b>{client?.email}</b>
            <br />
            And the following contact number: <b>{client?.phoneNumber}</b>
            <br />
            You have selected as seller: <b>{seller?.name + seller?.lastName ? ' ' + seller?.lastName : ''}</b> - email: <b>{seller?.email}</b>
            <br />
            They have selected the following hotel: <b>{hotel?.name}</b>
            <br />
            { rooms && Object.values(rooms).filter((r) => r.quantity > 0).
              map((r) => (
                <p key={r.id} >
                  <b>{r.quantity}</b> rooms of the type <b>{r.type}</b>
                  <br />
                </p>
              )
            )}
          </p>
          <br />
        </td>
      </tr>
      <tr>
        <td>
          <p><b>Additional Info:</b></p>
          <p>{additionalInfo || ''}</p>
          <br />
        </td>
      </tr>
      <tr>
        <td>
          <p><b>Summary of costs provided by the system</b></p>
          <br />
          { cost && (
            <p>
              Fligth cost: <b>$ {cost?.flight || 0}</b><br />
              Hotel cost: <b>$ {cost?.hotel || 0}</b><br />
              Charge by infant: <b>$ {cost?.infant || 0}</b><br />
              Total ost: <b>$ {cost?.total || 0}</b><br />
            </p>
          )}
          <br />
        </td>
      </tr>
      <tr>
        <td>
          <p>The passenger data is:</p>
          { passengers && passengers.map((p, i) => (
            <p key={'passenger-'+i} >
              <b>Passenger N° {i+1}</b>
              <br />
              Name: <b>{p.name}</b>
              <br />
              Last Name: <b>{p.lastName}</b>
              <br />
              Birthdate: <b>{parseFromObjectDate(p.birthday)}</b>
              <br />
              DNI or Identification: <b>{p.identification}</b>
            </p>
          ))}
          <br />
        </td>
      </tr>
      </React.Fragment>
  );
};

const reservationEmail = ({event, seller, hotel, rooms, client, passengers, infants, additionalInfo, cost, title}) => {

  const esContent = esEmailTemplate(event, seller, hotel, rooms, client, passengers, infants, additionalInfo, cost);
  const enContent = enEmailTemplate(event, seller, hotel, rooms, client, passengers, infants, additionalInfo, cost);

  const email = ReactDOMServer.renderToStaticMarkup(<table
      width="100%"
      cellPadding="0"
      cellSpacing="0"
      style={{ fontFamily: 'Arial, sans-serif', fontSize: '16px', lineHeight: '1.5' }}
    >
      <tbody>
			<tr>
				<td style={{ textAlign: 'center', padding: '20px' }}>
					<h1 style={{ margin: '0', color: '#333' }}>[{(title || 'Solicitud de Reserva' )}] - {event?.name}</h1>
				</td>
			</tr>
			{esContent}
			<tr>
        <td><hr/><br/></td>
      </tr>
			{enContent}
      </tbody>
    </table>);

  return email;
};

export default reservationEmail;
