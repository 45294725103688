import React, { useEffect, useState } from 'react'
import styled, { css } from 'styled-components/macro'
import { NumberContext } from 'twilio/lib/rest/pricing/v1/voice/number';
import { useIsPortrait, useIsMobile } from '../hooks'

const CounterContainer = styled.div`
    display: block;
    position: relative;
    width: ${p => p.width ? p.width+'px' : 'auto'};
    height: ${p => p.height ? p.height+'px' : 'auto'};
    margin: auto;
    padding: 1.5rem;
    border-radius: ${p => p.theme.roundCorners};
    z-index: 300;
    background-color: white;
    background-color: ${p => p.theme.primary};
    opacity: 0.9;
    text-align: center;

    *{
        overflow: auto;
        opacity: 1;

        font-weight: bold;
        text-align: center;

        ::-webkit-scrollbar {
            display: none;
        }
    }

    ${ p => ( p.isPortrait || p.isMobile ) && css`
        width: 68vw;
        margin: 25% auto; 
        padding: 2rem 1rem;
    `};
`

const CounterValue = styled.div`
    width: auto;
    height: 59%;
    margin: auto;
    padding-bottom: 1%;
    z-index: inherit;
    box-sizing: border-box;

    font-size: clamp(2.5rem, 100%, 3.5rem);
    color: ${p => p.dark ? p.theme.dark : p.primary ? p.theme.primary : p.medium ? p.theme.medium : p.theme.light};

    ${ p => ( p.isPortrait || p.isMobile ) && css`
        font-size: ${p => p.counting ? 'clamp(2.5rem, 100%, 4.5rem)' : '2.5rem'};    
    `};
`;

const Title = styled.div`
    width: auto;
    height: 39%;
    margin: auto;
    padding-bottom: 1%;
    z-index: inherit;
    box-sizing: border-box;

    font-size: clamp(1.2rem, 100%, 2.5rem);
    color: ${p => p.dark ? p.theme.dark : p.primary ? p.theme.primary : p.medium ? p.theme.medium : p.theme.light};
`;

const Message = styled.div`
    width: auto;
    height: 10%;
    margin: auto;
    z-index: inherit;
    box-sizing: border-box;

    font-size: 1rem;
    color: ${p => p.dark ? p.theme.dark : p.primary ? p.theme.primary : p.medium ? p.theme.medium : p.theme.light};
`;


function decimalRound(num, decimals) {
    if(!decimals) return Math.round(num);
    let m = Number((Math.abs(num) * 100).toPrecision(15));
    return Math.round(m) / 100 * Math.sign(num);
}

export default function Counter(props) {
    const isPortrait = useIsPortrait();
    const isMobile = useIsMobile();

    const {
        title,
        dateStart, // YYYY-MM-DDTHH:MM:SS format
        dateEnd, // YYYY-MM-DDTHH:MM:SS format
        deltaValue,
        deltaTime,
        initialValue, 
        endMessage,
    } = props;

    const oneHour = 3600000;
    const oneMinute = 60000;

    const [currentValue, setValue] = useState(null);
    const [run, setRun] = useState(false);
    const [counterFinished, setFinished] = useState(false);
    const [counterId, setCounterId] = useState(null);
    const [total, setTotal] = useState(null);

    useEffect(() => {
        if(!currentValue){
            verifyDate();
        }
    }, []);

    useEffect(() => {
        if(run){
            setCounterId(startCounterInRealTime);
        }
    }, [run]);

    const startCounterInRealTime = () => {
        const intervalId = setInterval(incrementInDelta, deltaTime*oneMinute);
        return intervalId
    };

    useEffect(() => {
        if(total && total === Math.round(currentValue)){
            setRun(false);
            clearInterval(counterId);
            setCounterId(null);
            setFinished(true);
        }
    }, [currentValue]);


    const incrementInDelta = async () => {
        await setValue( ( prevState ) => { return ( Number(prevState) + Number(deltaValue) ) } );
    };

    const verifyDate = () => {

        const current = decimalRound( ( new Date().getTime() / oneHour ), !(deltaTime*oneMinute === oneHour) );
        const start = decimalRound( ( new Date(dateStart).getTime() / oneHour ), !(deltaTime*oneMinute === oneHour) );
        const end = decimalRound( ( new Date(dateEnd).getTime() / oneHour ), !(deltaTime*oneMinute === oneHour) );

        if(current >= start){
            const total = initialValue+((end-start)*60*deltaValue);
            if(current >= end){
                setRun(false);
                clearInterval(counterId);
                setCounterId(null);
                setValue(total);
            } else{
                setRun(true);
                const value = initialValue+((current-start)*60*deltaValue);
                setValue(value);
            }
            setTotal(Math.round(total));
        } else {
            setValue(null);
        }
    };

    return (
        <CounterContainer className="CounterContainer" isPortrait={isPortrait} isMobile={isMobile} >
            <CounterValue H1 light isPortrait={isPortrait} isMobile={isMobile} counting={typeof currentValue === 'number'}>
                {
                    Math.round(currentValue) || currentValue || 'Esperando...'
                }
            </CounterValue>
            <Title H1 light isPortrait={isPortrait} isMobile={isMobile} >
                {title}
            </Title>
            { counterFinished && (<Message H1 dark isPortrait={isPortrait} isMobile={isMobile} >
                    {endMessage || 'Este contador ha finalizado'}
                </Message>)
            }
        </CounterContainer>
    );
}
