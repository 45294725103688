import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

import AutoSizer from 'react-virtualized/dist/commonjs/AutoSizer'
import List from 'react-virtualized/dist/commonjs/List'

import TimePeriod from './Analytics/TimePeriod'

import { IVisitState } from '../../context/AnalyticsContext'

import { Text, FadeInUp, Image, InputField, IconButton, Button } from '../UI'

import backIcon from '../../graphics/icons/arrow-left.svg'

import { downloadAnalytics, downloadAll } from '../../utility/analyticsCSV'

import { useStore, useAnalyticsDatabase, useUserMoreInfo, useAuth, useLanguage } from '../../hooks'

enum TabTypes {
	areas = 'Áreas',
	stands = 'Stands',
	videoRooms = 'Salas de video',
	pavillions = 'Pabellones'
}

interface IAnalyticsState {
	title: string, // Topbar Title.
	tab: TabTypes, // Current selected tab.
	view: 'list' | 'selected', // List areas, or inspect selected area.
	search: string, // Search bar input value.
	loadingZip?: Boolean, // Is generating a zip (download data).

	// Set functions.
	setLoadingZip(v: Boolean),
	setSearch(v: string): void,
	setTab(tab: TabTypes): void,
	selectArea(visitState: IVisitState, area: any, title: string), // Select current area.
	selected?: {
		visitState: IVisitState,
		area: any
	},
	back(): void,
	save(): void, // Save state.
}

interface ITimePeriod {
	active: boolean,
	panelState: boolean,
	from: Date,
	to: Date
}

const AnalyticsContainer = styled.div`
    height: 100%;
    position: relative;
    display: grid;
    grid-gap: 1rem;
    grid-template-rows: ${p => p.view === 'list' ? 'min-content min-content auto' : 'min-content auto'};
`

const AnalyticsBackButton = styled.div`
    position: absolute;
    top: -2.5rem;
    left: 0;
    height: 2.5rem;
    width: 2.5rem;
`

const DownloadButton = styled.div`
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: min-content;
    display: ${p => p.show ? 'grid' : 'none'};
    grid-gap: 1rem;
    grid-auto-flow: column;
`

export default function Analytics(props: { selectedUserId?: string, clearSelectedUser(): void }) {
	const [store] = useStore()
	const language = useLanguage()
	const [totalInteractions, setTotalInteractions] = useState(0)
	const [state, set] = useState<IAnalyticsState>({
		title: getTitle(),
		tab: TabTypes.areas,
		view: 'list',
		search: '',
		setLoadingZip(v) {
			this.loadingZip = v
			this.save()
		},
		setSearch(v) {
			this.search = v
			this.save()
		},
		setTab(tab) {
			this.tab = tab
			this.save()
		},
		selectArea(visitState, area, title) {
			this.view = 'selected'
			this.title = `${getTitle()} | ${title} | ${area.name}`
			this.selected = { visitState: visitState, area: area }
			this.save()
		},
		back() {
			this.title = getTitle()
			this.view = 'list'
			this.selected = null
			this.save()
		},
		save() { set({ ...this }) }
	})
	const [content, setContent] = useState<{ loading: boolean, database?: any }>({ loading: true })
	const [timePeriod, setTimePeriod] = useState<ITimePeriod>({
		active: false,
		panelState: false,
		from: new Date(),
		to: new Date(),
	})
	const database: any = useAnalyticsDatabase()

	const auth = useAuth()
	const isExpositor = auth.isExpositor()

	function getTitle() {
		if (props.selectedUserId && store?.users[props.selectedUserId]) {
			return `Actividad de ${store.users[props.selectedUserId].name} ${store.users[props.selectedUserId].lastName} `
		}
		return 'Estadísticas'
	}


	useEffect(() => {
		if (isExpositor) state.setTab(TabTypes.stands)
	}, [isExpositor])

	function videoRoomClickHandler(visitState: IVisitState) {
		const area = store?.videoRooms[visitState.id]
		if (area) state.selectArea(visitState, area, 'Video Rooms')
	}

	function pavillionClickHandler(visitState: IVisitState) {
		const area = store?.pavillions[visitState.id]
		if (area) state.selectArea(visitState, area, 'Pabellones')
	}

	function areaClickHandler(visitState: IVisitState) {
		const area = store?.areas[visitState.id]
		if (area) state.selectArea(visitState, area, 'Areas')
	}

	function standClickHandler(visitState: IVisitState) {
		const stand = store?.stands[visitState.id]
		if (stand) state.selectArea(visitState, stand, 'Stands')
	}

	async function setFilteredDatabase() {
		setContent({ loading: true })

		let filteredDatabase = JSON.parse(JSON.stringify(database))

		// Filter data by selected user.
		if (props.selectedUserId) {
			for (const area of Object.keys(filteredDatabase)) {
				for (const visitState of Object.values(filteredDatabase[area]) as IVisitState[]) {
					const activity = visitState.users[props.selectedUserId] ?? {}

					visitState.users = { [props.selectedUserId]: activity }

					visitState.totalVisits = Object.values(activity).length
				}
			}
		}

		// Filter data by time frame
		if (timePeriod.active) {

			const from = timePeriod.from.getTime()
			const to = timePeriod.to.getTime() + 86400000

			for (const area of Object.keys(filteredDatabase)) {
				for (const visitState of Object.values(filteredDatabase[area]) as IVisitState[]) {
					let visits = 0
					try {
						for (const userId of Object.keys(visitState.users)) {
							try {
								let filteredTimeStamps = {}
								let deleteUser = true

								for (let timeStamp of Object.values(visitState.users[userId]) as string[]) {
									try {
										timeStamp = timeStamp.toString()
										try {
											if (timeStamp.includes("\"")) timeStamp = timeStamp.replace(/\"/g, '')
										} catch (error) { }

										const number = timeStamp ? parseFloat(timeStamp) * 1000 : (Date.now() / 100)

										if (number > from && number < to) {
											visits++
											filteredTimeStamps[timeStamp] = timeStamp
											deleteUser = false
										}
									} catch (error) { }
								}

								filteredDatabase[area][visitState.id].users[userId] = filteredTimeStamps
								if (deleteUser) delete filteredDatabase[area][visitState.id].users[userId]
							} catch { }
						}

						filteredDatabase[area][visitState.id].totalVisits = visits
					} catch (error) { }
				}
			}
		}

		setContent({ loading: false, database: filteredDatabase })
	}

	useEffect(() => {
		if (database) setFilteredDatabase()
	}, [database, timePeriod])

	useEffect(() => {
		let total = 0
		Object.values(store.areas).map(a => {
			const area = a as any
			const visitState = content?.database?.areas[area.id] as IVisitState
			if (visitState) {
				total += visitState.totalVisits
			}
		})

		setTotalInteractions(total)
	}, [content?.database?.areas])

	// Get List of analytic areas, depending on state.tab, Filter them by user permissions and sort them by visits amount.
	function getList() {
		try {
			if (!database) return [<Text middle center oneline H2 bold>{language.getText('Descargando Datos...')}</Text>]

			let list: { visitState: IVisitState, item: any }[] = []
			// let filteredDatabase = getFilteredDatabase()

			if (content.loading) return [<Text middle center oneline H2 bold>{language.getText('Filtrando Datos...')}</Text>]

			if (state.tab === TabTypes.areas) list = Object.values(store.areas).map(a => {
				const area = a as any
				const visitState = content?.database?.areas[area.id] as IVisitState

				if (visitState) return { visitState: visitState, item: <Item key={area.id} name={area.name} visits={visitState.totalVisits} onClick={() => areaClickHandler(visitState)} /> }
			})

			if (state.tab === TabTypes.pavillions) list = Object.values(store.pavillions).map(v => {
				const area = v as any
				const visitState = content?.database?.pavillions[area.id] as IVisitState
				if (visitState) return { visitState: visitState, item: <Item key={area.id} name={area.name} visits={visitState.totalVisits} onClick={() => pavillionClickHandler(visitState)} /> }
			})

			if (state.tab === TabTypes.videoRooms) list = Object.values(store.videoRooms).map(p => {
				const area = p as any
				try {
					const visitState = content?.database['video-rooms'][area.id] as IVisitState
					if (visitState) return { visitState: visitState, item: <Item key={area.id} name={area.name} visits={visitState.totalVisits} onClick={() => videoRoomClickHandler(visitState)} /> }
				} catch { }
			})

			if (state.tab === TabTypes.stands) list = Object.values(store.stands)
				.filter(s => {
					const stand = s as any
					if (isExpositor) {
						if (auth?.user?.typeData?.company != stand.company) return false
					}
					if (state.search === '') return true
					if (stand.name) return stand.name.toLowerCase().includes(state.search.toLowerCase())
				})
				.map(s => {
					const stand = s as any
					const visitState = content?.database?.stands[stand.id] as IVisitState
					if (visitState) return { visitState: visitState, item: <Item key={stand.id} name={stand.name} visits={visitState.totalVisits} onClick={() => standClickHandler(visitState)} /> }
				})

			list = list.filter(e => e)

			return list.sort((a, b) => (a?.visitState?.totalVisits || 0) > (b?.visitState?.totalVisits || 0) ? -1 : 1).map(e => e.item)
		} catch (error) { console.log(error); return []; }
	}

	// Get Content (list of data) or (Selected data info)
	function getContent() {
		const items = []
		if (state.view === 'list') {
			if (!isExpositor) items.push(<TabRow key='tabrow' state={state} />)
			else items.push(<div />)

			items.push(<ListItems key='list' list={getList()} />)
		}

		if (state.view === 'selected' && state.selected)
			items.push(<SelectedView key='selected' selected={state.selected} />)

		return items
	}

	// Download as csv.
	function downloadHandler(): void { downloadAnalytics(store, state.selected.area, state.selected.visitState) }

	// Download all as zip.
	async function downloadAllHandler(): Promise<void> {
		if (state.loadingZip || content.loading) return
		state.setLoadingZip(true)
		let areas = {}
		let visitStates = {}
		let zipName = ''

		if (state.tab === TabTypes.areas) {
			areas = store?.areas
			visitStates = content?.database?.areas
			zipName = 'Areas'
		}

		if (state.tab === TabTypes.stands) {
			areas = store?.stands
			visitStates = content?.database?.stands
			zipName = 'Stands'
		}

		if (state.tab === TabTypes.pavillions) {
			areas = store?.pavillions
			visitStates = content?.database?.pavillions
			zipName = 'Pabellones'
		}

		if (state.tab === TabTypes.videoRooms) {
			areas = store?.videoRooms
			visitStates = content?.database['video-rooms']
			zipName = 'Salas-de-video'
		}

		await downloadAll(store, areas, visitStates, zipName)

		state.setLoadingZip(false)
	}

	return (
		<AnalyticsContainer view={state.view}>
			{state.view === 'selected' && <AnalyticsBackButton><IconButton onClick={() => state.back()} image={backIcon} /></AnalyticsBackButton>}
			<Text style={{ position: 'relative' }} H1 bold>
				{state.title}
				{state.view === 'list' && <span style={{ fontSize: '13px' }}> Total interacciones: {totalInteractions}</span>}

				{(state.tab === TabTypes.stands && state.view === "list") &&
					<InputField
						style={{ marginLeft: '2rem' }}
						value={state.search}
						onChange={v => state.setSearch(v)}
						placeholder='Buscar Stand'
					/>}

				<DownloadButton show={state.view === 'selected' && state.selected}>
					<Button onClick={() => downloadHandler()}>Descargar</Button>
				</DownloadButton>

				<DownloadButton show={state.view === 'list'}>
					<Button onClick={() => setTimePeriod(prev => { return { ...prev, panelState: true } })}>Filtrar</Button>
					{!isExpositor && <Button onClick={() => downloadAllHandler()}>{state.loadingZip ? 'Generando Zip...' : 'Descargar Todo'}</Button>}
				</DownloadButton>
			</Text>

			{getContent()}

			{timePeriod.panelState && <TimePeriod
				from={timePeriod.from}
				setFrom={d => setTimePeriod(prev => { return { ...prev, from: d } })}
				to={timePeriod.to}
				setTo={d => setTimePeriod(prev => { return { ...prev, to: d } })}
				filter={value => setTimePeriod(prev => { return { ...prev, active: value, panelState: false } })}
			/>}

		</AnalyticsContainer>
	)
}

const SelectedViewContainer = styled.div`
    display: grid;
    grid-template-rows: min-content min-content min-content auto; 
    grid-gap: 1rem;
`
const SelectedViewPicture = styled.div`
    height: 7rem;
    width: 12rem;
`

const SelectedViewVisits = styled.div`
    display: grid;
    grid-template-columns: min-content auto min-content;
`

function SelectedView({ selected }) {
	const [search, setSearch] = useState('')
	const [store] = useStore()
	const language = useLanguage()
	const [userMoreInfo] = useUserMoreInfo()

	// Get area's background image.
	function getBackground(): string {
		try {
			return Object.values(selected.area.backgrounds.images)[0] as string
		} catch { return selected.area.background }
	}

	// Get List of users that visited this area.
	function getList() {
		try {

			let usersList = []
			for (let id of Object.keys(selected.visitState.users)) {
				const user = store.users[id as string]
				if (user && user.email && user.name) usersList.push(user)
			}

			return usersList
				.filter(u => {
					if (search === '') return true
					return u.email.toLowerCase().includes(search.toLowerCase()) || u.name.toLowerCase().includes(search.toLowerCase())
				}
				).map(user => <UserItem user={user} onClick={() => userMoreInfo.setUser(user)} />)
		} catch { return [] }
	}

	// Total users count.
	function getUserCount() {
		if (selected?.visitState?.users) return Object.keys(selected.visitState.users).length
		else return 0
	}

	return (
		<SelectedViewContainer>
			<SelectedViewPicture><Image url={getBackground()} /></SelectedViewPicture>
			<SelectedViewVisits>
				<Text H2 bold oneline>{language.getText('Usuarios')} : {getUserCount()}</Text><div />
				<Text H2 bold oneline>{language.getText('Visitas')} : {selected?.visitState?.totalVisits}</Text>
				{/* <Text H2 bold oneline>Visitas Anónimas : {selected?.visitState?.anonymousVisits}</Text> */}
			</SelectedViewVisits>
			<InputField
				placeholder={language.getText('Buscar usuario')}
				value={search}
				onChange={setSearch}
			/>
			<ListItems list={getList()} />
		</SelectedViewContainer>
	)
}

const TabRowDiv = styled.div`
    height: 3rem;
    display: grid;
    grid-gap: .5rem;
    grid-template-columns: repeat(4, 1fr);
`

const TabRowButton = styled.div`
    cursor: pointer;
    color: ${p => p.selected ? p.theme.light : p.theme.dark};
    display: flex;    
    align-items: center;
    justify-content: center;
    font-weight: bold;
    background-color: ${p => p.selected ? p.theme.primary : p.theme.light};
`

function TabRow({ state }) {
	return (
		<TabRowDiv>
			{Object.values(TabTypes).map(t =>
				<TabRowButton key={t} selected={state.tab === t} onClick={() => state.setTab(t)}>{t}</TabRowButton>)}
		</TabRowDiv>
	)
}

const ListItemsContainer = styled.div`height: 100%;`

function ListItems({ list }) {

	const rowRenderer = ({ key, index, style }) => {
		return (
			<div key={key} style={style}>
				{list[index]}
			</div>
		)
	}

	return (
		<ListItemsContainer>
			{list && <AutoSizer>
				{
					({ height, width }) => (
						<FadeInUp>
							<List
								height={height}
								rowCount={list.length}
								rowHeight={64}
								rowRenderer={rowRenderer}
								width={width}
							/>
						</FadeInUp>
					)
				}
			</AutoSizer>}
		</ListItemsContainer>
	)
}

const UserItemContainer = styled.div`
    height: 90%;
    width: 100%;
    background-color: rgb(50, 50, 50);
    display: grid;
    grid-template-columns: 2rem min-content auto min-content;
    grid-gap: 1rem;
    padding: 0 1rem;
    box-sizing: border-box;

    cursor: pointer;
    transition: .3s;
    :hover { width: 90%; }
`

const UserItemPicture = styled.div`
    background-color: darkgray;
    overflow: hidden;
    border-radius: 50%;
    height: 2rem;
    width: 2rem;
    margin: auto;
`

function UserItem({ user, onClick }) {
	return (
		<UserItemContainer onClick={() => onClick()}>
			<UserItemPicture><Image url={user?.profile} /></UserItemPicture>
			<Text oneline middle H2 bold>{user?.name || ''} {user?.lastName || ''}</Text>
			<div />
			<Text oneline middle H2 bold>{user?.email || ''}</Text>
		</UserItemContainer>
	)
}

const ItemContainer = styled.div`
    height: 80%;
    width: 100%;

    display: grid;
    background-color: ${p => p.theme.light};
    grid-gap: .1rem;
    grid-template-columns: auto 5rem;

    transition: .3s;
    :hover {
        width: 90%;
        border: .15rem solid ${p => p.theme.primary};
        box-sizing: border-box;
    }

    cursor: pointer;
`

const ItemNumber = styled.div`
    background-color: ${p => p.theme.primary};
`

function Item({ name, visits, onClick }) {
	return (
		<ItemContainer onClick={() => onClick()}>
			<Text style={{ padding: '0 1rem' }} bold middle H2 dark>{name}</Text>
			<ItemNumber><Text bold middle center H2 light>{visits}</Text></ItemNumber>
		</ItemContainer>
	)
}