import firebase from '../firebase';

/** Definitions */
const packagesNode = 'packages';

export interface Hotels {
    [key: number]: string
}

export interface Package {
    id?: string;
    name: string;
    location: string;
    departure_date: Date;
    days: number;
    hotels: Hotels;
    description: string;
    flight_cost: number;
    flight_total: number;
    flight_available: number;
    enabled: boolean;
}

const database = firebase.database()

/** Functions */
/**
 * Function to get a particular package
 * @param id - Id of the package to get
 * @returns Package
 */
export const getPackage = async (id: String) : Promise<Package> => {
    const snapshot = await database.ref(`${packagesNode}/${id}`).once('value');
    return { id, ...snapshot.val() };
}

/**
 * Function to get a list of packages from the database.
 * @param ids - Array of ids to get from the database
 * @returns Array of Packages
 */
export const getPackages = async (ids: Array<String>) : Promise<Package[]> => {
    const packages: Array<Package> = [];
    for (const id of ids) {
        const resp = await getPackage(id);
        packages.push(resp);
    }
    
    return packages;
}

/**
 * Function to add a new Package in the Database
 * @param id (optional) - The UUID of the node, if it's empty (equal to 0) we will generate an automatic UUID
 * @param data - The information to be saved
 * @returns boolean (true - false)
 */
export const addUpdatePackages = async(id: String, data: Package): Promise<boolean> => {
    try {
        if (!id) {
            let key = await database.ref(`${packagesNode}`).push().key;
            await database.ref(`${packagesNode}/${key}`).set({ id: key, ...data });
        } else {
            await database.ref(`${packagesNode}/${id}`).set(data);
        }
        
        return true;
    } catch(error) { 
        return false;
    }
}