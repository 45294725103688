import React from 'react'
import styled from 'styled-components'

import { useHistory } from 'react-router-dom'

import { IconButton, InputField, Image, Text } from './UI'
import { useSearch } from '../hooks'

import nextIcon from '../graphics/icons/arrow-right.svg'
import prevIcon from '../graphics/icons/arrow-left.svg'

const Container = styled.div<{navegation?: boolean}>`
    position: fixed;
    width: 100vw;
    height: 70vh;
    bottom: 0;

    box-sizing: border-box;
    display: grid;
    grid-gap: 1rem;
    grid-template-rows: ${p => p.navegation ? '2.5rem 2.5rem auto' : '2.5rem auto'};

    background: linear-gradient(0deg, rgba(40, 40, 40, 1) 70%, rgba(40, 40, 40, .3) 100%);
    padding: 1rem;
`

const NavegationBarContainer = styled.div`
    display: grid;
    grid-template-columns: 2.5rem auto 2.5rem;
    grid-gap: 1rem;
`

const SearchContainer = styled.div`
    * { width: 100%; box-sizing: border-box; }
`

const StandsContainer = styled.div`
    overflow: auto;
`

const StandsGrid = styled.div`
    display: grid;
    grid-gap: 1rem;
`

export default function PavillionMobile({ pageList, selectPage, pavillion, nextHandler, prevHandler }) {
    const [search, setSearch] = useSearch()

    return (
        <Container navegation={pageList?.totalPages > 1}>
            {pageList?.totalPages > 1 && 
            <NavegationBarContainer>
                <IconButton light onClick={prevHandler} image={prevIcon}/>
                <PageNavegation 
                    totalPages={pageList.totalPages}
                    selectPage={selectPage}
                    currentPage={pageList.currentPage}
                />
                <IconButton light onClick={nextHandler} image={nextIcon}/>
            </NavegationBarContainer>}
            <SearchContainer>
                <InputField 
                    value={search.value}
                    onChange={v => setSearch(prev => {return {...prev, value: v}})}
                    placeholder='Filtrar'
                />
            </SearchContainer>
            <StandsContainer>
                <StandsGrid>
                    {pageList?.page && (Object.values(pageList.page) as any[]).map(s => <Stand key={s.id} pavillion={pavillion} stand={s}/>)}
                </StandsGrid>
            </StandsContainer>
        </Container>
    )
}

const StandContainer = styled.div`
    height: 6rem;
    background-color: ${p => p.theme.light};
    display: grid;
    grid-template-columns: 2fr 1fr;
`

function Stand({ stand, pavillion }) {
    const history = useHistory()

    const clickHandler = () => {
        history.push(`/stand?pavillionId=${pavillion.id}&id=${stand.id}`)
    }

    return (
        <>
        <Text light bold H2 center oneline>{stand.name}</Text>
        <StandContainer onClick={() => clickHandler()}>
            <Image cover url={stand?.background}/>
            <Image contain url={stand?.icon}/>
        </StandContainer>
        </>
    )
}

const PageNavegationContainer = styled.div<{show?: boolean}>`
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 1.5rem;
    height: 1.5rem;
    margin: auto;
    width: min-content;
    grid-gap: 1rem;
`

const PageNavegationItem = styled.div`
    border-radius: 50%;
    border: .3rem solid ${p => p.theme.light};
    transition: .3s;
    :hover { background-color: ${p => p.theme.light}; transform: translateY(-.2rem)}
    background-color: ${p => p.selected ? p.theme.light : 'none'};
    cursor: pointer;
`

function PageNavegation(props) {
    return (
        <PageNavegationContainer show={props.totalPages > 0}>
            {Array.from(Array(props.totalPages)).map((v, i) => <PageNavegationItem onClick={() => props.selectPage(i)} selected={props.currentPage === i}/>)}
        </PageNavegationContainer>
    )
}