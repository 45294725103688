import firebase, { databasePaths } from './'
import { getContent } from './database'

const spanishMessages = {
    'auth/user-not-found' : 'El email no está registrado.',
    'auth/wrong-password' : 'Contraseña incorrecta.',
    'auth/email-already-in-use' : 'El email ya está registrado.'
}

export const googleLogin = () => new Promise(async (resolve, reject) => {
    const provider = new firebase.auth.GoogleAuthProvider()
    firebase.auth()
        .signInWithPopup(provider)
        .then((result) => {
            resolve(result.user)
        }).catch((error) => { reject(error) })
})

export const facebookLogin = () => new Promise(async (resolve, reject) => {
    const provider = new firebase.auth.FacebookAuthProvider()
    firebase.auth()
        .signInWithPopup(provider)
        .then((result) => {
            resolve(result.user)
        })
        .catch((error) => { reject(error) })
})

export const logIn = (email, password) => new Promise(async (resolve, reject) => {
    try {
        await firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL)
        const response = await firebase.auth().signInWithEmailAndPassword(email, password)
        resolve(response)
    }

    catch(error) { 
        if (error.code in spanishMessages) error.message = spanishMessages[error.code]
        reject(error) 
    }
})

export const logOut = () => new Promise(async (resolve, reject) => {
    try {
        const response = await firebase.auth().signOut()
        resolve(response)
    } catch(error) { reject(error) }
})

export const register = (email, password) => new Promise(async (resolve, reject) => {
    try {
        const response = await firebase.auth().createUserWithEmailAndPassword(email, password)
        resolve(response)
    } catch(error) { 
        if (error.code in spanishMessages) error.message = spanishMessages[error.code]
        reject(error) 
    }
})

export const trackAuthState = observer => { 
    return firebase.auth().onAuthStateChanged(observer)
}

export const sendResetPasswordEmail = email => new Promise((resolve, reject) => {
    firebase.auth().sendPasswordResetEmail(email).then(resolve).catch(reject);
})

export const verifyEmail = (email) => new Promise(async (resolve, reject) => {
    try {
        const response = await firebase.auth().fetchSignInMethodsForEmail(email);
        resolve(response)
    } catch(error) { 
        if (error.code in spanishMessages) error.message = spanishMessages[error.code]
        reject(error) 
    }
})

export const getCurrentUser = () => firebase.auth().currentUser

export const currentUser = firebase.auth().currentUser