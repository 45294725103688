import React, { useState, useEffect } from 'react'
import styled, { css } from 'styled-components/macro'

import { useStore, useAuth, useLanguage, useIsMobile, useIsPortrait } from '../../hooks'
import { Text, Button } from '../UI'
import { getCurrentUser } from '../../firebase/auth'

const SVerifyEmail = styled.div<{ show: boolean }>`
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;

	background-color: ${p => p.theme.dark};
	z-index: 10;

	display: ${p => p.show ? 'block' : 'none'};
`

const SPanel = styled.div<{ isPortrait: boolean, isMobile: boolean }>`
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	padding: 1rem;
	background-color: ${p => p.theme.light};
	border-radius: ${p => p.theme.roundCorners};

	display: grid;
	grid-gap: 1rem;

	${p => (p.isPortrait || p.isMobile) && css`
        width: 80vw;
		height: 40%;
		margin: auto;
		grid-gap: 0.5rem;
    `}
`

const SRow = styled.div<{ isPortrait: boolean, isMobile: boolean }>`
	display: grid;
	height: 2rem;
	grid-gap: 1rem;
	grid-template-columns: 1fr 1fr;
	
	${p => (p.isPortrait || p.isMobile) && css`
		height: auto;
		grid-gap: 0.5rem;
		grid-template-columns: none;
    `}
`

export default function VerifyEmail() {
	const [store] = useStore()
	const isMobile = useIsMobile();
	const isPortrait = useIsPortrait();
	const auth = useAuth()
	const [show, setShow] = useState<boolean>(false)
	const [emailSended, setEmailSended] = useState<boolean>(false)
	const language = useLanguage();

	useEffect(() => {
		if (!getCurrentUser() || !store?.configurations?.requireEmailVerification) setShow(false)
		else if (auth?.user?.type === 'admin') setShow(false) // Admins get free pass.
		else setShow(!getCurrentUser()?.emailVerified)
	}, [getCurrentUser()?.emailVerified, store?.configurations?.requireEmailVerification])

	function sendVerificationEmail(): void {
		getCurrentUser().sendEmailVerification()
			.then(() => setEmailSended(true))
			.catch(error => { console.log(error) })
	}

	return (
		<SVerifyEmail show={show}>
			<SPanel isPortrait={isPortrait} isMobile={isMobile} >
				<Text verticalAlign oneline={!(isPortrait || isMobile)} bold H2 dark center >{language.getText('NECECITAMOS_VERIFICAR_TU_EMAIL')}</Text>
				<Text verticalAlign oneline={!(isPortrait || isMobile)} bold H2 dark center >{language.getText('REVISA_TU_EMAIL') }</Text>
				{ !(isPortrait || isMobile) && <div />}
				{emailSended && <Button style={{ height: '2rem' }} onClick={() => window.location.reload()}>Refrescar</Button>}
				<SRow isPortrait={isPortrait} isMobile={isMobile} >
					<Button onClick={() => auth.logOut()} gray>Cerrar sesión</Button>
					{emailSended ?
						<Text oneline middle bold H2 primary center>Email enviado correctamente</Text> :
						<Button onClick={() => sendVerificationEmail()}>Enviar email de verificación</Button>
					}
				</SRow>

			</SPanel>
		</SVerifyEmail>
	)
}
