import React, { useRef, useEffect, useState } from 'react'
import styled, { css } from 'styled-components/macro'
import { UserMediaError, useUserMedia } from '@vardius/react-user-media'

import { useVideoCall, useAuth, useStore, useElementsManager,useLocalStorage, useNotifications } from '../hooks'
import { Text, IconButton, Image, SVG } from './UI'

import Loading from './Loading'
import ChatView from './ChatView'

import phoneIcon from '../graphics/icons/phoneoff.svg'

import chatIcon from '../graphics/icons/chat.svg'
import phoneRingingIcon from '../graphics/icons/phoneRinging.svg'
import profileIcon from '../graphics/icons/profile.svg'

import useSound from 'use-sound'
import callSound from '../sounds/call.wav'
import { useLanguage } from '../hooks/index';

const TempUser = 'TempUser';

const Container = styled.div`
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 900;
    pointer-events: none;
`
const VideoCallContainer = styled.div`
    height: 100%;
    pointer-events: all;
    background-color: rgba(0, 0, 0, .9);
    display: grid;
    position: relative;
    box-sizing: border-box;

    grid-template-columns: 1fr 3fr 3fr 1fr;
    grid-template-rows: 1rem 2.5fr 2rem 2.5fr 1rem;
    grid-row-gap: 1rem;
    grid-column-gap: 3rem;
`

const FinishButton = styled.div`
    ${p => !p.left && !p.right && css`
        position: fixed;
        top: 60%;
        left: 50%;
        transform: translateX(-5rem);
    `}
    
    ${p => p.right && css`
        position: absolute;
        top: 1px;
    `}

    ${p => p.left && css`
        position: absolute;
        top: 1px;
        right: 0px;
    `}

    z-index: 900;
    
    ${p => !p.small && css`
        width: 10rem;
        height: 2.5rem;
    `}

    ${p => p.small && css`
        width: 2.5rem;
        height: 2.0rem;
    `}

    ${p => p.onlymobile && css`
        display: none;
        @media (max-width: 768px) {
            display: block;
        }
    `}
`

const VideoContainer = styled.div`
    position: ${p => p.fullscreen ? 'fixed' : 'relative'};
    background: black;
    border: .2rem solid ${p => p.theme.primary};
    ${p => !p.float && css`
        grid-column: ${p => p.left ? '2/3' : '3/4'};
        grid-row: 2/3;
    `}
    width: ${p => p.float ? '25%': '75%'};
    height: ${p => p.float ? '25%': '100%'};

    ${p => p.float && css`
        right: 26%;
        bottom: 10px;
        position: absolute;
    `}

    @media (max-width: 768px) {
        width: ${p => p.float ? '50%': '100%'};
        height: ${p => p.float ? '25%': '100%'};
        right: ${p => p.float ? '10px' : '0'};
        ${p => p.float && css`
            bottom: 25px;
        `}  
    }

    box-sizing: border-box;

    transform: scaleX(-1);
`

const ChatContainer = styled.div`
    background: ${p => p.theme.dark};
    height: 100%;
    width: 25%;
    position: absolute;
    right: 0;

    transition-property: transform;
    transition-duration: 400ms;

    @media (max-width: 768px) {
        ${p => p.open && css`
            transform: translateX(0);
        `}

        ${p => !p.open && css`
            transform: translateX(100%);
        `}
        width: 100%;
    }
`

const LoadingContainer = styled.div`
    position: absolute;
    transform: scaleX(-1);
    height: 100%;
    width: 100%;
`

const LoadingMessage = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    bottom: 1rem;
    * { height: 100%; }
`

const Default_Local_User = {
	id: 'UserLocal',
	email: '',
	emailVerified: false,
	name: "Unregistered User",
	extraInfo: {},
	profilePicture: '',
	type: 'unregistered'
};

export default function VideoCall(props) {
    const [videoCall, setVideoCall] = useVideoCall() // Get video call context.
    const auth = useAuth() // Get current user.
    const [store, dispatch] = useStore()
    const elementsManager = useElementsManager()
    const [localUser, setLocalUser] = useState(undefined);

    useEffect(() => {
        if (window) {
            const local = window.localStorage.getItem(TempUser);
            if (local !== "undefined"){
                setLocalUser(JSON.parse(local || '{}'));
            }
        }
    }, [store]);


    const setUserStatus = value => {
        if (auth.user && store.users[auth.user.id])
            dispatch({ type: 'set-user', payload: { ...store.users[auth.user.id], onACall: value } })
    }

    const answerHandler = () => {
        videoCall.startAnswer()
    }

    const hangupHandler = () => {
        videoCall.hangup()
        setUserStatus(false)
    }

    useEffect(() => {
        elementsManager.setBoth(!videoCall.active)
    }, [videoCall.active])

    let targetUser = { name: '', lastName: '' }
    if (store.users && videoCall.firebaseCall) {
        const targetId = (videoCall.firebaseCall.from === auth?.user?.id || videoCall.firebaseCall.from === localUser?.id) ? videoCall.firebaseCall.to : videoCall.firebaseCall.from
        if (targetId in store.users) targetUser = store.users[targetId]
    }

    return (
        <Container>
            {videoCall.active && <VideoCallPanel
                localUser={localUser}
                videoCall={videoCall}
                setUserStatus={setUserStatus}
                hangupHandler={hangupHandler}
                auth={auth}
                targetUser={targetUser}
            />}
            <RingingCallAlert
                user={targetUser}
                active={videoCall.active}
                ringing={videoCall.firebaseCall && videoCall.firebaseCall.ringing}
                owner={videoCall.firebaseCall && (videoCall.firebaseCall.from == auth?.user?.id || videoCall.firebaseCall.from == localUser?.id )}
                answerHandler={answerHandler}
                hangupHandler={hangupHandler}
            />
        </Container>
    )
}

function getChatId(a, b) {
    try { return a.id > b.id ? a.id + b.id : b.id + a.id } catch { return 'null' }
}

function VideoCallPanel(props) {
    const { stream, error } = useUserMedia({ audio: true, video: true }) // Get media stream.
    const [state, set] = useState({ loading: true })
    const language = useLanguage(); 
    const notifications = useNotifications();

    const {
        localUser,
    } = props;

    const setRemoteStream = remoteStream => {
        set(prev => {
            props.setUserStatus(true)
            return { ...prev, remoteStream: remoteStream, loading: false }
        })
    }

    const [chatOpen, setChatOpen] = useState(false)

    const handleChatClose = () => {
        setChatOpen(!chatOpen)
    }

    useEffect(() => {
        if (error) props.hangupHandler()

        if (props.videoCall.isCalling && stream && props.auth?.user && props.auth.user.id) {
            // Call target, and get stream.
            props.videoCall.call(stream, setRemoteStream, props.auth.user.id)
        } else if (props.videoCall.isCalling && stream && localUser?.id) {
            // Call target, and get stream.
            props.videoCall.call(stream, setRemoteStream, localUser.id)
        }

        return
    }, [props.videoCall.isCalling, stream, error])

    useEffect(() => {
        if (!props.videoCall.isAnswering || !stream) return

        props.videoCall.answer(stream, setRemoteStream)
    }, [props.videoCall, stream, error])

    useEffect(() => {
        if (!props.videoCall.firebaseCall && !props.videoCall.isCalling) props.hangupHandler()
    }, [props.videoCall.firebaseCall])

    const userMediaErrorHandling = () => {
        switch(error?.name){
            case 'NotFoundError':
                notifications.notify('No se ha encontrado dispositivo disponible para video chat');
                break;
            case 'DevicesNotFoundError':
                notifications.notify('No se ha encontrado dispositivo disponible para video chat');
                break;
            case 'NotReadableError':
                notifications.notify('Otra aplicación o pestaña está usando la cámara web o el micrófono.');
                break;
            case 'TrackStartError':
                notifications.notify('Otra aplicación está usando la cámara web o el micrófono.');
                break;
            case 'OverconstrainedError':
                notifications.notify('Las capacidades deseadas para el actual dispositivo de medios se puede cumplir actualmente.');
                break;
            case 'ConstraintNotSatisfiedError':
                notifications.notify('Las capacidades deseadas para el actual dispositivo de medios se puede cumplir actualmente.');
                break;
            case 'NotAllowedError':
                notifications.notify('El permiso para usar el dispositivo fue denegado por el usuario o por el sistema');
                break;
            case 'PermissionDeniedError':
                notifications.notify('El permiso para usar el dispositivo fue denegado por el usuario o por el sistema');
                break;
            case 'TypeError':
                notifications.notify('Se requiere audio y/o video. Por favor verifique los permisos y que la conexión sea segura.');
                console.error('An error has occurred with useUserMedia', error.name + ": " + error.message);
                break;
            case 'AbortError':
                notifications.notify('ABORT ERROR');
                console.error('An error has occurred with useUserMedia', error.name + ": " + error.message);
                break;  
            case 'SecurityError':
                notifications.notify('SECURITY ERROR: El permiso para usar el dispositivo fue denegado por el usuario o por el sistema');
                console.error('An error has occurred with useUserMedia', error.name + ": " + error.message);
                break;
            default:
                notifications.notify('Ha ocurrido un error inesperado intentando conectar la llamada');
                console.error('An error has occurred with useUserMedia', error.name + ": " + error.message);
        }
    }

    if (error) {
        userMediaErrorHandling();
        return (
          <UserMediaError error={error} />
        );
    }
    return (
        <VideoCallContainer>
            <VideoContainer fullscreen>
                {state.remoteStream && 
                    <>
                        <Video stream={state.remoteStream} />
                        {props.targetUser?.id && <Text H2 bold center reverse style={{ gridColumn: '2/3', gridRow: '3/4', marginTop: '-20px' }}>{`${props.targetUser.name} ${props.targetUser.lastName || ''}`}</Text>}
                    </>
                }
                {state.loading &&
                    <LoadingContainer>
                        <LoadingMessage><Text bottom center H1 bold>{language.getText('Iniciando Llamada...')}</Text></LoadingMessage>
                    </LoadingContainer>
                }
            </VideoContainer>

            {!state.loading &&
            <VideoContainer float>
                {props?.targetUser?.id && <FinishButton small left onlymobile><IconButton onClick={handleChatClose} image={chatIcon} style={{ height: '90%'}} /></FinishButton>}
                <Video muted stream={stream} />
                {props.auth.user && <Text H2 bold center reverse style={{ gridColumn: '2/3', gridRow: '3/4', marginTop: '-20px' }}>{`${props.auth.user.name || localUser.name} ${props.auth.user.lastName || ''}`}</Text>}
                <FinishButton small right><IconButton onClick={props.hangupHandler} image={phoneIcon} style={{ height: '90%', background: 'red'}} /></FinishButton>
            </VideoContainer>
            }

            <ChatContainer open={chatOpen}>
                {props?.targetUser?.id && <ChatView
                    personal
                    videoChat
                    id={getChatId(props.auth?.user || localUser, props?.targetUser)}
                    name='Chat'
                    target={props.targetUser}
                    handleChatClose={handleChatClose}
                    localUser={localUser}
                />}
            </ChatContainer>
        </VideoCallContainer>
        
        
    )
}

const RingingCallAlertContainer = styled.div`
    pointer-events: all;
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    background-color: ${p => p.theme.dark};

    // padding: .8rem;
    display: ${p => p.active ? 'flex' : 'none'};
    grid-template-columns: ${p => p.owner ? '2rem auto' : '2rem auto 2rem 2rem'};
    grid-gap: 2rem;
    align-items: center;
    justify-content: center;
`

const RingingCall = styled.div`
    width: 100%;
`

const RingingCallButtons = styled.div`
    display: grid;
    margin: 20px auto;
    width: 500px;
    
    @media (max-width: 768px) {
        width: 90%;
    }

    ${p => !p.owner && css`
        grid-template-columns: auto auto;
    `}

    grid-column-gap: 10px;
`

const SImage = styled.div`
    border-radius: 50%;
    overflow: hidden;
    background-color: ${p => p.theme.darkerLight};
    width: 16rem;
    height: 16rem;
    margin: 30px auto;
`
function RingingCallAlert(props) {
    const [startCallSound] = useSound(callSound)
    const language = useLanguage();
    useEffect(() => {
        let interval = null
        if (props.ringing) {
            startCallSound()
            interval = setInterval(startCallSound, 4000)
        }

        function cleanup() {
            if (interval) clearInterval(interval)
        }
        return cleanup
    }, [startCallSound, props.ringing])

    return (
        <RingingCallAlertContainer owner={props.owner} active={props.ringing || (props.active && props.ringing !== false)}>
            <RingingCall>
                <SImage >
						{
							props.user?.profilePicture ? 
							<Image trackPicture={true} url={props.user?.profilePicture} />
							: <SVG gray image={profileIcon} />
						}
					</SImage> 
                {props.user && <Text H2 bold center>{`${props.user.name} ${props.user.lastName || ''}`}</Text>}
                
                {!props.ringing && <Text H2 bold center>{language.getText('Iniciando Llamada...')}</Text>}
                {props.ringing && <Text H2 bold center style={{ marginTop: '5px' }}>{language.getText('Llamando...')}</Text>}

                <RingingCallButtons owner={!props.ringing || props.owner}>
                    {props.ringing && !props.owner && <IconButton onClick={props.answerHandler} image={phoneRingingIcon} style={{ background: 'green'}} />}
                    <IconButton onClick={props.hangupHandler} image={phoneIcon} style={{ background: 'red'}} />
                </RingingCallButtons>
            </RingingCall>
        </RingingCallAlertContainer>
    )
}

function Video({ stream, muted = false, autoPlay = true, ...props }) {
    const element = useRef(null)

    useEffect(() => {
        if (element.current && stream) {
            element.current.srcObject = stream
        }
    }, [stream, element])

    return <video style={{ height: '100%', width: '100%', minHeight: 0 }}{...props} autoPlay={autoPlay} playsInline muted={muted} ref={element} />
}