import * as firebase from 'firebase/app'
import { firebaseConfig } from '../context/Brand'

import 'firebase/auth'
import 'firebase/database'
import 'firebase/storage'
import 'firebase/analytics'
import 'firebase/messaging'

firebase.initializeApp(firebaseConfig)

export default firebase

export const databasePaths = {
    users: 'users',
    pavillions: 'pavillions',
    videoRooms: 'videoRooms',
    lobby: 'lobby',
    home: 'areas/home',
    stands: 'stands',
    companies: 'companies',
    networking: 'networking',
    chatGroups: 'chatGroups',
    configurations: 'configurations',
    areas: 'areas',
    ranking: 'ranking',
    productCategories: 'productCategories',
    purchaseHistory: 'purchaseHistory',
    notifications: 'notifications',
    posters: 'posters',
    chat: 'chat',
    call: 'call',
    userNotifications: 'userNotifications',
    trackUsers: 'trackUsers',
    unregisteredUsers: 'unregisteredUsers',
    packages: 'packages',
    hotels: 'hotels',
}

// export const messaging = firebase.messaging()
