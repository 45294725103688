import React from 'react'
import YouTube from 'react-youtube'
import styled from 'styled-components'

const Container = styled.div`
    height: 100%;
    width: 100%;
    position: relative;
    margin: auto;
    * {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: inherit;
    }
`

export default function Youtube(props) {

    return (
            <Container>
                <YouTube 
                    name="YouTube_Player"
                    videoId={props.id}
                    onEnd={props.onEnd}
                    opts={{
                        playerVars: {
                            autoplay: 1,
                            loop: 1,
                            playlist: props.id
                        }
                    }}
                />
            </Container>
    )
}
