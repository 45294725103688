import firebase from '../firebase';

/** Definitions */
const hotelsNode = 'hotels';

export interface Room {
    type: string;
    price: number;
}

export interface Rooms {
    [key: string]: Room;
}

export interface Hotel {
    id?: string;
    name: string;
    reserve_type: string;
    rooms: Rooms
}

const database = firebase.database()

/** Functions */
/**
 * Function to get a particular hotel
 * @param id - Id of the hotel to get
 * @returns Hotel
 */
export const getHotel = async (id: String) : Promise<Hotel> => {
    const snapshot = await database.ref(`${hotelsNode}/${id}`).once('value');
    return { id, ...snapshot.val() };
}

/**
 * Function to get a list of hotels from the database.
 * @param ids - Array of ids to get from the database
 * @returns Array of Hotels
 */
export const getHotels = async (ids: Array<String>) : Promise<Hotel[]> => {
    const hotels: Array<Hotel> = [];
    for (const id of ids) {
        const resp = await getHotel(id);
        hotels.push(resp);
    }
    
    return hotels;
}

/**
 * Function to add a new Hotel in the Database
 * @param id (optional) - The UUID of the node, if it's empty (equal to 0) we will generate an automatic UUID
 * @param data - The information to be saved
 * @returns boolean (true - false)
 */
export const addUpdateHotel = async(id: String, data: Hotel): Promise<boolean> => {
    try {
        if (!id) {
            let key = await database.ref(`${hotelsNode}`).push(data).key;
            await database.ref(`${hotelsNode}/${key}`).set({ ...data, id: key });
        } else {
            await database.ref(`${hotelsNode}/${id}`).set(data);
        }
        
        return true;
    } catch(error) { 
        return false;
    }
}