import firebase from '../firebase';
import { getPackage, addUpdatePackages } from './packages';

/** Definitions */
const reservationNode = 'reservations';

export const NOT_ENOUGH_FLIGHTS = -1;
export const ERROR = 0;
export const SUCCEED = 1;

export interface Reservation {
    email: string;
    id: string;
    persons: number;
    parent_package: string;
    seller: string;
}

export interface Reservations {
    [key: string]: Reservation;
}

const database = firebase.database()

/** Functions */
/**
 * Function to get a particular reservation
 * @param id - Id of the reservation to get
 * @returns Reservation
 */
export const getReservation = async (id: String) : Promise<Reservation> => {
    const snapshot = await database.ref(`${reservationNode}/${id}`).once('value');
    return { id, ...snapshot.val() };
}

/**
 * Function to get a list of reservations from the database.
 * @param ids - Array of ids to get from the database
 * @returns Array of Reservations
 */
export const getReservations = async (ids: Array<String>) : Promise<Reservation[]> => {
    const reservations: Array<Reservation> = [];
    for (const id of ids) {
        const resp = await getReservation(id);
        reservations.push(resp);
    }
    
    return reservations;
}

/**
 * Function to get a list of reservations from the database based on seller.
 * @param ids - Array of ids to get from the database
 * @returns Array of Reservations
 */
export const getReservationsBySeller = async (sellerId: string) : Promise<Reservation[]> => {
    let reservations = [];
    await database.ref(`${reservationNode}`).orderByChild('seller').equalTo(sellerId).on('value', function(snapshot) {
        const reservation = snapshot.val();
        
        if (reservation) {
            reservations = Object.values(reservation).map((r) => r );
        }
    });

    const packages = [];
    await reservations.forEach(async (r, i) => {
        if (!packages[r.parent_package]) {
            const p = await getPackage(r.parent_package);
            packages[p.id] = p.name
        }
        
        reservations[i].parent_package = packages[r.parent_package]
    });
    
    return reservations;
}

/**
 * Function to get a list of reservations from the database based on seller.
 * @param ids - Array of ids to get from the database
 * @returns Array of Reservations
 */
export const getAllReservations = async () : Promise<Reservation[]> => {
    let reservations = [];
    await database.ref(`${reservationNode}`).on('value', function(snapshot) {
        const reservation = snapshot.val();

        if (reservation) {
            reservations = Object.values(reservation).map((r) => r);
        }
    });
    const packages = [];
    await reservations.forEach(async (r, i) => {
        if (!packages[r.parent_package]) {
            const p = await getPackage(r.parent_package);
            packages[p.id] = p.name
        }
        
        reservations[i].parent_package = packages[r.parent_package]
    });
    
    return reservations;
}

/**
 * Function to add a new Reservation in the Database
 * @param id - The UUID of the node
 * @param data - The information to be saved
 * @returns number - Constant number error
 */
export const addReservation = async(id: String, data: Reservation): Promise<number> => {
    try {

        let key = null;

        if (!id) {
            key = await database.ref(`${reservationNode}`).push().key;
        } else {
            key = id;
        }
        
        const parent_package = await getPackage(data.parent_package);
        if (!parent_package) return ERROR;
        if (parent_package.flight_available < data.persons) return NOT_ENOUGH_FLIGHTS;

        await database.ref(`${reservationNode}/${key}`).set({...data, id: key});

        parent_package.flight_available = parent_package.flight_available - data.persons;

        await addUpdatePackages(parent_package.id, parent_package);
        
        return SUCCEED;
    } catch(error) { 
        return ERROR;
    }
}

/**
 * Function to remove a Reservation in the Database
 * @param id - The UUID of the node
 * @param data - The information to be saved
 * @returns boolean (true - false)
 */
export const removeReservation = async(id: String): Promise<number> => {
    try {
        if (!id) return ERROR;
        
        const reservation = await getReservation(id);
        const parent_package = await getPackage(reservation.parent_package);
        if (!parent_package || !reservation) return ERROR;

        await database.ref(`${reservationNode}/${id}`).set(null);

        parent_package.flight_available = parent_package.flight_available + reservation.persons;

        await addUpdatePackages(parent_package.id, parent_package);
        
        return SUCCEED;
    } catch(error) { 
        return ERROR;
    }
}