import React, { useState, useEffect, useContext, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { getUrl, getFastUrl } from '../firebase/storage'
import { databasePaths } from '../firebase'

import Store from '../store'
import Auth from '../context/AuthContext'
import Search from '../context/SearchContext'
import Popup from '../context/PopupContext'
import Viewport from '../context/ViewportContext'
import PersonalChat from '../context/PersonalChatContext'
import UserMoreInfo from '../context/UserMoreInfoContext'
import VideoCall from '../context/VideoCallContext'
import Notifications from '../context/NotificationsContext'
import AnalyticsContext from '../context/AnalyticsContext'
import ExternalAnalyticsContext from '../context/ExternalAnalyticsContext'
import LanguageContext from '../context/LanguageContext'
import ShoppingListContext from '../context/ShoppingListContext'
import ElementsManager from '../context/ElementsManagerContext'
import TrackUser from '../context/TrackUserContext'
import Catalog from '../context/CatalogContext'
import CompanyPoster from '../context/CompanyPosterContext'

import { Image, Video } from '../components/UI'
import FloatingButton from '../components/FloatingButton'
import PasswordPanel from '../components/PasswordPanel'
import PresentationImages from '../components/PresentationImages'
import MobileArea from '../components/MobileArea'
import View from '../components/View'
import { ScreenLoading } from '../components/Loading'
import { logEvent } from '../firebase/analytics'
import Embed from '../components/Embed'
import CalendarComponent from '../components/CalendarComponent';


export function useReactPath() {
	const history = useHistory()
	const [path, setPath] = useState(history.location.pathname + history.location.search)

	useEffect(() => setPath(history.location.pathname + history.location.search), [history.location])

	return path
}

export function useAnalytics() {
	const analytics = useContext(AnalyticsContext)
	return analytics
}

export function useExternalAnalytics() {
	const externalAnalytics = useContext(ExternalAnalyticsContext)
	return externalAnalytics
}

export function useCompanyPoster() {
	const [state, set] = useContext(CompanyPoster)
	return [state, set]
}

export function useTrackUser() {
	const trackUser = useContext(TrackUser)
	return trackUser
}

export function useElementsManager() {
	const elementsManager = useContext(ElementsManager)
	return elementsManager
}

export function useShoppingList() {
	const shoppingList = useContext(ShoppingListContext)
	return shoppingList
}

export function useLanguage() {
	const language = useContext(LanguageContext)
	return language
}

export function useAnalyticsDatabase() {
	const analytics = useContext(AnalyticsContext)
	const [database, setDatabase] = useState()

	useEffect(() => {
		(async function () {
			const content = await analytics.getDatabase()
			setDatabase(content)
		})()
	}, [])

	return database
}

export function useNotifications() {
	const notifications = useContext(Notifications)
	return notifications
}

export function usePopup() {
	const [popup, setPopup] = useContext(Popup)
	return [popup, setPopup]
}

export function useViewport() {
	const viewport = useContext(Viewport)
	return viewport
}

export function usePersonalChat() {
	const personalChat = useContext(PersonalChat)
	return personalChat
}

export function useUserMoreInfo() {
	const [userMoreInfo, setUserMoreInfo] = useContext(UserMoreInfo)
	return [userMoreInfo, setUserMoreInfo]
}

export function useVideoCall() {
	const [videoCall, setVideoCall] = useContext(VideoCall)
	return [videoCall, setVideoCall]
}

export function useCatalog() {
	const [catalog, setCatalog, catalogRef] = useContext(Catalog)
	return [catalog, setCatalog, catalogRef]
}

export function useAuth() {
	const auth = useContext(Auth)
	return auth
}

export function useSearch() {
	const [search, setSearch] = useContext(Search)
	return [search, setSearch]
}

export function useStore() {
	const [store, dispatch] = useContext(Store)
	return [store, dispatch]
}

export function useParam(param) {
	const history = useHistory()
	const params = new URLSearchParams(history.location.search)
	return params.get(param)
}

export function useUrl(path) {
	const [url, setUrl] = useState(getFastUrl(path))

	useEffect(() => {
		if (path) getUrl(path).then(setUrl)
	}, [path])

	return url
}

export function useIsPortrait() {
	return window.matchMedia("(orientation: portrait)").matches
}

export function useIsMobile() {
	return window.matchMedia('(max-width: 768px)').matches
}

// Verify if the keyboard is open
// Return false if the keyboard is closed, or the viewport height value if it is open
export function useKeyBoardOpen() {
	const [keyBoardOpen, setKeyBoardState] = useState(false);
	const [viewportHeight, setVh] = useState(window.innerHeight); // set te total viewport heigth when the screem is render

	const keyBoardShow = () => {
		try{

			if( window.innerHeight <= (viewportHeight*0.7) ) setKeyBoardState(window.innerHeight);
			else setKeyBoardState(false);
		} catch(e){
			console.error('Error checking if the keyboard is open: ', e);
		}
	}

	try {
		if(window){
			if(window.visualViewport){
				window.visualViewport.addEventListener('resize', () => keyBoardShow() );
			} else {
				window.addEventListener('resize', () => keyBoardShow() );
			}
		}
	} catch (e) {
		console.error('Error with window resize: ', e);
	}

	return keyBoardOpen;
}

export function useArea(id) {
	const isPortrait = useIsPortrait()
	const isMobile = useIsMobile()
	const [store, dispatch] = useStore()
	const [components, setComponents] = useState([<ScreenLoading key='loading' />])
	const [areaName, setAreaName] = useState()
	const auth = useAuth()
	const elementsManager = useElementsManager()

	const readPage = async page => {
		const c = []
		// const imageTest= "https://miro.medium.com/max/1200/1*xMuIOwjliGUPjkzukeWKfw.jpeg"
		// const videoTest= "https://youtu.be/QC8iQqtG0hg"

		if (page.password && !auth?.user?.universalAccess && (!page.whitelist || !(auth?.user?.id in page.whitelist))) c.push(<PasswordPanel className="PasswordPanel" password={page.password} title={page.passwordTitle} />)

		// If is iframe background, show embed url instead of images.
		if (page.isIFrameBackground) c.push(<Embed className="Embed" url={page.iFrameBackgroundUrl} />)
		else if (page.isVideoBackground)
			c.push(
				<Video className="Video" autoPlay loop muted playsInline fit={!isPortrait} contain={isMobile} key='videoBackground' preload={'metadata'} src={page.backgroundVideo.videoId} 
					style={{ zIndex: '-1', position: 'relative', objectFit: (isPortrait ? 'contain' : 'cover'), height: (isPortrait ? 'auto' : '100vh'), margin: 'auto', }}
				/>);
		else if (!page.isVideoBackground && page.background)
			c.push(
				<Image className="Image" fit={!isPortrait} top={isPortrait} contain={isMobile} key='background' url={page.background} 
					style={{ zIndex: '-1', position: 'relative', height: (isPortrait ? '90%' : '100%'), backgroundSize: (isPortrait ? 'contain' : '100% 100%') }}
				/>);
		else if (page.backgrounds) c.push(<PresentationImages className="PresentationImages" key='backgrounds' backgrounds={page.backgrounds} />)

		if (isPortrait) {
			if (page.buttons || page.views) c.push(<MobileArea
				views={elementsManager.state.panels ? page.views : undefined}
				buttons={elementsManager.state.buttons ? page.buttons : undefined}
			/>)
		}

		else {
			if (page.buttons && elementsManager.state.buttons) c.push(Object.values(page.buttons).map(btn => <FloatingButton key={btn.id} button={btn} />))
			if (page.views && elementsManager.state.panels) c.push(Object.values(page.views).map(view => <View key={view.id} view={view} />))
		}

		// Add calendar component to EventCalendar area
		if (page.calendarView) {
			c.push(<CalendarComponent
				{...page.calendarView}
			/>);
		}

		setComponents(c)
	}

	useEffect(() => {
		if (areaName) logEvent(areaName)
	}, [areaName])

	useEffect(() => {
		if (!store || !store.areas || !store.areas[id]) return
		readPage(store.areas[id])
		setAreaName(store.areas[id].name)
	}, [id, store, elementsManager.state])

	return components
}

export function usePavillion(id) {
	const [store, dispatch] = useStore()
	const [pavillion, setPavillion] = useState({})
	useEffect(() => {
		if (!store || !store[databasePaths.pavillions]) return
		setPavillion(store[databasePaths.pavillions][id])
	}, [id, store])

	return pavillion
}

export function useStand(standId, pavillionId) {
	const [store] = useStore()
	const pavillion = usePavillion(pavillionId)
	const [stand, setStand] = useState()

	useEffect(() => {
		let standsLoaded = false;
		
		standsLoaded = Object.keys(store?.stands || {}).length > 0
		
		if (!standsLoaded) return

		if (!pavillion.stands || !store?.stands[standId]) return
		setStand(store?.stands[standId])
	}, [pavillion, standId, store])

	return [stand, pavillion]
}

export function useCompanyStands(company) {
	const [store] = useStore()
	const [stands, setStands] = useState([])

	useEffect(() => {
		if (!store.stands || !company || !company.id) return

		setStands(
			Object.values(store.stands)
				.filter(s => s.company === company.id)
		)

	}, [store, company])

	return stands
}

export function useOutsideAlerter(ref, callback) {
	useEffect(() => {
		// Alert if clicked on outside of element
		function handleClickOutside(event) {
			if (ref.current && !ref.current.contains(event.target)) {
				callback()
			}
		}

		// Bind the event listener
		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			// Unbind the event listener on clean up
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [ref]);
}

export function useAwakeBool(trigger) {
	const [state, set] = useState(false)
	useEffect(() => {
		if (trigger != undefined) setTimeout(() => set(trigger), 10)
		else if (!state) set(true)
	}, [state, trigger])
	return state
}

export function useLocalStorage(key, initialValue) {
	const [storedValue, setStoredValue] = useState(() => {
		if (typeof window === "undefined") {
			return initialValue;
		}
		try {
			const item = window.localStorage.getItem(key);
			return item ? JSON.parse(item) : initialValue;
		} catch (e) {
			console.error(e);
			return initialValue;
		}
	});

	const setValue = (value) => {
		try {
			const valueToStore =
			value instanceof Function ? value(storedValue) : value;
			setStoredValue(valueToStore);
			if (typeof window !== "undefined") {
				window.localStorage.setItem(key, JSON.stringify(valueToStore));
			}
		} catch (e) {
		  console.error(e);
		}
	};

	return [storedValue, setValue];
}