import { useEffect, useState } from 'react'
import styled, { css } from 'styled-components/macro'
import { useStore, useIsPortrait, useIsMobile } from '../hooks'
import Calendar, {MonthView} from 'react-calendar';
import './CalendarComponent.css';
import SideBar from './SideBar';

const Panel = styled.div`

	background: none;
	z-index: 4;

    ${p => (p.isPortrait || p.isMobile) && css`
        background: none;
		position: fixed;
    	top: 5.6vh;
		top: calc(var(--vh, 1vh) * 6.5);
		height: 70vh;
		height: calc(var(--vh, 1vh) * 70);
	    width: 100vw;
	    padding: 0.25rem 0 0.25rem 0;
    	margin: 2rem auto;
    `}

    ${p => (!p.isPortrait && !p.isMobile) && css`
        position: absolute;

        top: 15%;
        left: 25%;

        width: 50%;
        height: 50%;
    `}

	overflow: hidden;

	* {
		z-index: 0;
	}
`;

const CalendarContainer = styled.div`
    display: block;
    width: 80%;
    height: 100%;
    margin: auto;
    z-index: 10;
    background-color: ${p => p.theme.primary};
    border-radius: ${p => p.theme.roundCorners};

    text-align: center;

    *{
        overflow: auto;
        opacity: 1;

        font-weight: bold;
        text-align: center;

        ::-webkit-scrollbar {
            display: none;
        }
    }

    ${ p => ( p.isPortrait || p.isMobile ) && css`
        width: 68vw;
        height: auto;
        margin: 15% auto;
        padding: 1rem 1rem;
    `};
`

const CalendarContent = styled.div`
    width: 100%;
    height: auto;
    padding: 1rem 0;
    margin: auto;
`;

const Title = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    height: 10%;
    margin: auto;
    z-index: inherit;

    font-size: clamp(1.2rem, 100%, 2.5rem);
    color: ${p => p.dark ? p.theme.dark : p.primary ? p.theme.primary : p.medium ? p.theme.medium : p.theme.light};
`;

const AppWrapper = styled.div`
    display: block;
    right: 0;
    top: 0;
    position: absolute;
    z-index: 15;
    background-color: #f5f5f5;
    transform: translateX(100%);
    transition: all 0.5s ease 0s;
    width: 70vw;
    height: 100vh;
  	height: calc(var(--vh, 1vh) * 100);
    overflow-y: scroll;

    ${ p => ( p.isPortrait || p.isMobile ) && css`
        width: auto;
        max-width: 100%;
    `};
`;

export default function CalendarComponent(props) {
    const isPortrait = useIsPortrait();
    const isMobile = useIsMobile();
    const [store, dispatch] = useStore()
    const [showEvents, setShowEvents] = useState( false );
    const [filteredEvents, setFilteredEvents] = useState( false );
    const [selectedDate, setSelectedDate] = useState(null);

    const [value, onChange] = useState(new Date());
    const currentDate = new Date();

    const {
        title,
    } = props;

    useEffect(() => {
		if (selectedDate) {
			setTimeout(() => {
				const sidebar = document.getElementById('Calendar_SidebarWrapper');
				sidebar.classList.add('show');
			}, 100)
		}
	}, [selectedDate])

    const calendarProps = {
        defaultView: 'month', // month | year | decade | century
        minDate: currentDate,
    };

    /**
     * Retrieve active events for the selected day
     */
    const getCurrentEvents = (v) => {

        const selectDate = new Date(v);

        return showEvents.filter( e => {

            const dateFrom = new Date(e.departure_date);
		    const dateTo = new Date(e.departure_date);
		    dateTo.setDate(dateTo.getDate() + parseInt(e.days));

            return ( 
                selectDate >= dateFrom && selectDate <= dateTo
            );
        });
    };

    /**
     * Retrieve active events
     */
    const getActiveEvents = () => {
        let events = [];

        if( store?.packages ) events = Object.values(store.packages).filter( e => 
            e.departure_date && 
            e.enabled && 
            (new Date(e.departure_date) > currentDate) 
        );

        setShowEvents([...events]);
    };

    /**
     * Function to Handle the date select
     * @param {*} v state value
     * @param {*} e event
     */
    const dateOnChange = (v, e) => {

        let eventsToDisplay = getCurrentEvents(v);

        setFilteredEvents(eventsToDisplay);
        setSelectedDate(v);
    };

    /**
     * @param {*} v state value
     * @param {*} e event
     */
    const handleSelectMonth = (v, e) => {
        
    }

    /**
     * This function verify if have events on the received date
     * @param {string} date the date to verify
     * @returns boolean value
     */
    const haveEvents = (date) => {

        let auxDate = new Date(date);

        for (let e of showEvents) {

            let minDate = new Date(e.departure_date);
            let maxDate = new Date(e.departure_date);
            maxDate.setDate(maxDate.getDate() + parseInt(e.days - 1));

            let inInterval = ( auxDate >= minDate && auxDate <= maxDate );

            if(inInterval){
                return inInterval;
            }
        }

        return false;
    };

    /**
     * This function highlights the date if  have events
     * @param date the 
     * @param view the calendar view, "month" | "year" | "decade" | "century"
     */
    const highlightDates = ({ date, view }) => {

        return ( view === 'month' && haveEvents(date) ) ? 'highlighted' : '';
    };

    /**
     * This function closes the sidebar by removing the selected date state
     */
    const closeSideBar = () => {
        setSelectedDate(null);
    };

    useEffect(() => {
        if( !showEvents ){
            getActiveEvents();
        }
    }, []);

    return (<>
        <Panel isPortrait={isPortrait} isMobile={isMobile}>
            <CalendarContainer className="CounterContainer" isPortrait={isPortrait} isMobile={isMobile} >
                <Title H1 light isPortrait={isPortrait} isMobile={isMobile} >
                    {title}
                </Title>
                <CalendarContent>
                    {Array.isArray(showEvents) ? (
                        <Calendar 
                            onChange={(v, e) => dateOnChange(v, e)}
                            value={value} 
                            {...calendarProps}
                            tileClassName={highlightDates} 
                        />             
                    ) : (<></>)}     
                </CalendarContent>
            </CalendarContainer>
        </Panel>
        { selectedDate ? (
            <AppWrapper id="Calendar_SidebarWrapper" isPortrait={isPortrait} isMobile={isMobile} >
                <SideBar cards={filteredEvents} selectedDate={selectedDate} closeSideBar={closeSideBar} />
            </AppWrapper>
        ) : (<></>)}
    </>);
}
