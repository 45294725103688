import React, { useState, useEffect } from 'react'
import styled, { css } from 'styled-components/macro'
import { List, AutoSizer, CellMeasurer, CellMeasurerCache } from 'react-virtualized'

import { useStore, useAuth, usePersonalChat, useIsPortrait, useIsMobile, useLanguage, useLocalStorage } from '../hooks'
import { setContent, getContentEqualTo } from '../firebase/database'
import { databasePaths } from '../firebase'
import { getUsers as getUsersFromFirebase, } from '../store';
import { getTimeStamp } from './ChatView'

import { Text, InputField, IconButton, SVG, Image, Button } from './UI'
import { MultiDropdown } from './NewUI'
import ChatView from './ChatView'
import Loading from './Loading';
import backIcon from '../graphics/icons/arrow-left.svg'
import closeIcon from '../graphics/icons/close.svg'
import profileIcon from '../graphics/icons/profile.svg'
import phoneIcon from '../graphics/icons/phone.svg'

const TempUser = 'TempUser';

const Container = styled.div`
    position: fixed;
    top: 0;
	bottom: 0;
    width: 25rem;
    right: ${p => p.active ? 0 : '-25rem'};

    ${p => (p.isPortrait || p.isMobile) && css`
        width: 100vw;
        right: ${p.active ? 0 : '-100%'};
        padding-top: 2.5rem;
    `}

    background-color: rgba(0, 0, 0, .8);
    transition: .3s;
	z-index: 5;

	${p => p.keyBoardOpen &&
		css`
		width: 100vw;
		right: ${p.active ? 0 : '-100%'};
		padding-top: 2.5rem;

		@supports (-webkit-touch-callout: none) {
			top: ${p => p.top ? p.top+'px' : '0px'};
		}

		@supports not (-webkit-touch-callout: none) {
		}
		
		`
	};
`

function getChatId(a, b) {
	try{
		return a?.id ? (b?.id ? (a.id > b.id ? a.id + b.id : b.id + a.id) : (a.id) ) : (b?.id ? b.id : '') ;
	} catch (e) {
		console.error(e);
	}
}

const BackButton = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 2.5rem;
    height: 2.5rem;
    cursor: pointer;
    background-color: ${p => p.theme.primary};
    :hover { background-color: ${p => p.theme.primaryHover}; }
    display: flex;
    * {
        margin: auto;
        width: 60%;
        height: 60%;
    }
` 

const DimmerScreen = styled.div`
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 2;
    background-color: rgba(0, 0, 0, .8);
    display: grid;
    place-items: center;
`

const SPanel = styled.div`
    padding: 2%;
    background-color: ${p => p.theme.light};
    border-radius: ${p => p.theme.rounderCorners};
    width: 25%;
    height: 30%;

	${p => p.isPortrait && css`
        width: 70%;
    	height: 50%;
        padding: 5%;
    `}

    transition: .3s;

    display: grid;
    grid-gap: 1rem;
    grid-template-rows: auto;
	align-items: center;
`

const getTimeEpoch = () => {
    return new Date().getTime().toString();                             
}

const Default_Local_User = {
	id: 'UserLocal',
	email: '',
	emailVerified: false,
	name: "Unregistered User",
	extraInfo: {},
	profilePicture: '',
	type: 'unregistered'
};

export default function PersonalChat(props) {
	const [store, dispatch] = useStore()
	const [state, updateState] = useState();
	const [modalState, setModalState] = useState({
		canSeeChats: true,
		showModal: false,
		tempName: '',
		tempMail: '',
		errorName: null,
		errorMail: null,
	});
	const isPortrait = useIsPortrait()
	const isMobile = useIsMobile();
	const auth = useAuth()
	const language = useLanguage();
	const personalChat = usePersonalChat();
	const forceUpdate = () => {
		setTimeout(() => updateState({}), 200);
	};
	const [localUser, setLocalUser] = useLocalStorage(TempUser, { ...Default_Local_User, id: 'UserLocal'+getTimeEpoch(), });
	const {
		active,
		localUserId,
		setLocalUserId,
	} = props;

	let userPingInterval;

	const [viewportHeight, setVh] = useState(window.innerHeight);
	const [keyBoardOpen, setKeyBoardState] = useState(false);

	useEffect(() => {
		if(active && auth.user == null && (localUser.name === null || localUser.name === "Unregistered User" || localUser.name === '')){
			setModalState({...modalState, canSeeChats:false, showModal: true});
		}
	}, [active]);

	useEffect(() => {
		if(active && auth.user == null && localUser.name !== "Unregistered User"){
			setUnregisteredUser();
		}
		if (localUser && localUser.name !== "Unregistered User") {
			startUnregisteredUsersChat();
		}
	}, [modalState.canSeeChats]);

	const logPin = () => {
		if (localUser && localUser.name !== "Unregistered User") {
			setContent(`${databasePaths.unregisteredUsers}/${localUser.id}/lastPing`, Date.now())
		}
	}

	const startUnregisteredUsersChat = () => {
		const userPayload = localUser;
		if (!auth?.user?.id && userPayload?.id) {
			setContent(`${databasePaths.chat}/${userPayload.id}/`, { online: true });

			if (!auth?.user?.id && userPayload?.id) {
				logPin();
				setContent(`${databasePaths.chat}/${userPayload.id}/`, { online: true });
				userPingInterval = setInterval(logPin, 600000)
			}
		}
		
		// When user leave the page set chat to disable.
		window.onbeforeunload = () => {
			setContent(`${databasePaths.chat}/${userPayload.id}/`, { online: false });
			if (userPingInterval) {
				clearInterval(userPingInterval)
				userPingInterval = undefined;
			}
		}

	}

	const onlyDirectMessages =
		(store?.configurations?.showPersonalChatButton && auth?.user?.type === 'normal') ||
		(store?.configurations?.organizersChatOnlyDirect && auth?.user?.type === 'organizer') ||
		(store?.configurations?.expositorsChatOnlyDirect && auth?.user?.type === 'expositor')

	const setUnregisteredUser = () => {
		if((!localUserId || localUserId !== localUser.id) && active){
			const userPayload = {
				...localUser,
				lastPing: Date.now()
			}
			try{
				setContent(`${databasePaths.unregisteredUsers}/${userPayload.id}`, userPayload);
				setLocalUserId(userPayload.id);
				startUnregisteredUsersChat();
			} catch (e) {
				console.error(e);
			}
		}
	};

	try {
		if(window){
			if(window.visualViewport){
				window.visualViewport.addEventListener('resize', () => keyBoardShow() );
			} else {
				window.addEventListener('resize', () => keyBoardShow() );
			}
		}
	} catch (e) {
		console.error('Error with window resize: ', e);
	}

	const keyBoardShow = () => {
		try{

			if( window.innerHeight <= (viewportHeight*0.7) ) setKeyBoardState(window.innerHeight);
			else setKeyBoardState(false);
		} catch(e){
			console.error(e);
		}
	}

	const getContent = () => {

		if (personalChat.menu === 0) return (

			<UserList		
				show={true}
				name="userList"
				onlyDirectMessages={onlyDirectMessages}
				store={store}
				dispatch={dispatch}
				active={active}
				auth={auth}
				onUserClick={u => personalChat.openChat(u) }
				localUser={localUser}
			/>
			);

			if (personalChat.menu === 1 && personalChat.selected) {
			return (
				<Chat
					id={ getChatId( ( auth.user ? auth.user : localUser ), personalChat.selected ) }
					selected={personalChat.selected}
					name={`${personalChat.selected.name} ${personalChat.selected.lastName ? personalChat.selected.lastName : ''}`}
					backHandler={() => { 
						forceUpdate();
						personalChat.closeChat();
					}}
					store={store} 
					dispatch={dispatch} 
					isPortrait={ (isPortrait || isMobile) }
					auth={auth} 
					localUser={localUser}
					show={true}
				/>
			);
		}
	}

	const setTempUserData = (name, mail) => {

		let re = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
		let errorMail = !re.test(mail) && mail.trim() !== '';
		let errorName = (!name || name.trim() === '');
		if( errorName || errorMail ){
			errorName = errorName ? 'Falta completar el campo de nombre' : null;
			errorMail = errorMail && mail.trim() !== '' ? 'Por favor ingrese un mail valido' : null;
			setModalState({...modalState, errorName: errorName, errorMail: errorMail});		
		} else{
			setLocalUser({...localUser, name: name, email: mail});
			setModalState({...modalState, canSeeChats: true, showModal: false });
		}
	};
	
	const inputName= () =>{
		return modalState.showModal ? (
			<DimmerScreen>
				<SPanel isPortrait={ (isPortrait || isMobile) }>
					<Button name="closeButton" 
						onClick={() => { setModalState({...modalState, showModal: false}); personalChat.toggle(); } }
						style={{ width: '12%', marginLeft: 'auto' } }
					>
						X
					</Button>
					<InputField
						title={language.getText('NOMBRE_')}
						value={modalState.tempName}
						onChange={(o) => {
							setModalState({ ...modalState, tempName: o });
						}}
						errorState={
							{
								displayMessage: modalState.errorName ? true : false,
								customMessage: modalState.errorName,
							}
						}
						isRequired
					/>
					<InputField
						title={language.getText('EMAIL')}
						value={modalState.tempMail}
						onChange={(o) => {
							setModalState({ ...modalState, tempMail: o });
						}}
						errorState={
							{
								displayMessage: modalState.errorMail ? true : false,
								customMessage: modalState.errorMail,
							}
						}
					/>
					<Button onClick={() => { setTempUserData(modalState.tempName, modalState.tempMail) } }>{ "Continuar al chat"}</Button>
				</SPanel>
			</DimmerScreen>
		)
		: (<></>);
	};

	return active ? (
		<Container name="PersonalChat_Container" isPortrait={ (isPortrait || isMobile) } active={props.active} top={ viewportHeight- keyBoardOpen} keyBoardOpen={keyBoardOpen} bottom={viewportHeight} >
			{ (isPortrait || isMobile) && (
				<BackButton onClick={() => personalChat.toggle()}>
					<SVG contain image={backIcon} />
				</BackButton>
			)}
			{ modalState.canSeeChats ? getContent() : inputName()}
		</Container>
	)
	: ( <></>);
}

const UserListContainer = styled.div`
    height: 100%;
    display: ${props => props.show ? 'grid' : 'none'};
    grid-gap: 1rem;
    grid-template-rows: 2rem 2rem auto;

	${p => p.showUserKeyWords && css`
		grid-template-rows: 2rem 2rem 2rem auto;
	`}

    padding: 2rem 1rem;
`

const UserListScroll = styled.div`
    overflow-x: hidden;
`

const Line = styled.div`
    background-color: ${p => p.theme.light};
    height: .05rem;
`

function UserList(props) {
	const [search, setSearch] = useState('')
	const language = useLanguage();
	
	// Refactor UserList using CellMeasurer & CellMeasurerCache
	// For more info visit: https://blog.logrocket.com/rendering-large-lists-with-react-virtualized-82741907a6b3/
	const rowCache = new CellMeasurerCache({
			fixedWidth: true,
			defaultHeight: 5
		})

	const [state, set] = useState({
		filterOptions: {},
	});
	
	const [allUsers, setAllUsers] = useState({map: new Map(), complete: false});

	const [allChats, setChats] = useState({
		recentUsers: [],
		onlineUsers: [],
		offlineUsers: [],
		complete: false,
	});

	const [ itemState, setItemState]  = useState({list: [], complete: false});

	const [ updateChat, setUpdateChat]  = useState(false);

	const {
		onlyDirectMessages,
		store,
		dispatch,
		active,
		auth,
		onUserClick,
		localUser,
		show,
	} = props;

	const userToUse = auth.user ? auth.user : localUser;

	useEffect(() => {
		return () => {
			set({});
			setAllUsers({});
			setChats({});
			setItemState({});
		};
	}, []);

	useEffect(() => {
		setTimeout(() => {
			getAllUsers();
		}, 500)
	}, [store.users])

	// Set user key words state.
	useEffect(() => {
		if (store?.configurations?.userKeyWords && active) {
			set(p => ({
				...p,
				filterOptions: Object.values(store.configurations.userKeyWords).reduce((obj, option) => ({
					...obj,
					[option.id]: {
						id: option.id,
						name: option.name,
						value: false
					}
				}), {})
			}))
		}
		
	}, [store.configurations, active])

	useEffect(() => {
		if(active && search !== ''){
			setChats({...allChats, complete: false});
			getAllUsers();
		}
	}, [search]);

	useEffect(() => {
		if(active && allChats.complete && itemState.complete){
			setUpdateChat(true);
		}
	},[store.chatGroups]);

	useEffect(() => {
		if((active && allUsers.map.size > 2  && allUsers.complete && !allChats.complete) || updateChat){
			loadChats();
			setUpdateChat(false);
		}
	}, [allUsers, updateChat]);

	function getShowFilter() {
		if (!store?.configurations?.userKeyWords) return false
		return auth?.user?.type !== 'normal'
	}

	const getAllUsers = async () => {
		if (
			( !store?.users || (store?.users && Object.keys(store.users).length < 2))
		){
			await getUsersFromFirebase(dispatch, store.configurations?.enableChatForUnregisteredUsers)
		}

		const showFilter = getShowFilter()
		try{
			if(store.users){

				
				const usersMap = new Map();
				
				Object.values(store.users).forEach((u) => {
					if (!u.name || u.id === userToUse.id) return;
					if (store?.configurations?.allowHiddenInChat && u.idHiddenInChat) return;

					let filter = true

					if (showFilter) {
						const userKeyWords = Object.values(state.filterOptions)
						// If at least 1 is true, then filter.
						if (userKeyWords.some(o => o.value)) {
						// Foreach option, if it's on, return true only if user has it.
							for (const o of userKeyWords) {
								if (o.value) {
									if (u.keyWords && o.id in u.keyWords) filter = true;
									else filter = false;
									break;
								}
							}
						}
					}

					if (search === '' && filter){
						usersMap.set(u.id, u);
					} else{
						const s = search.toLowerCase()
						if (u.extraInfo) {
							for (const v of Object.values(u.extraInfo)) {
								if (v?.toLowerCase && v.toLowerCase().includes(s) && filter ) {
									usersMap.set(u.id, u);
									return;
								}
							}
						}
						if ((`${u.name} ${u.lastName}`.toLowerCase().includes(s)) && filter){
							usersMap.set(u.id, u);
						}
					}
					
				});		

				
				setAllUsers({...allUsers, map: usersMap, complete: usersMap.size > 2 ? true : false, });
			}
		} catch (e) {
			console.error(e);
		}
	};

	let chatUsers = Object.keys(store.chat || {});

	const getUnregisteredUsers = async (userLocalId) => {
		try{
			const user = await getContentEqualTo(`${databasePaths.unregisteredUsers}`, 'id', userLocalId).then(u => u).catch(() => {});
			return user;
		} catch (e) {
			console.error(e);
			return null;
		}
	};

	const loadChats = () => {

		try {
			
		let recentUsers = [];
		const onlineUsers = [];
		const offlineUsers = [];

		if (store.configurations.chatOnlyWithAdmins && ((auth.user && auth.user.type === 'normal') || !auth.user) ){
			for (const u of allUsers) {
				if(u.type === 'normal') allUsers.map.delete(u.id);
			}
		}

		for (const u of allUsers.map.values()) {
			const chatID = getChatId(u, userToUse)

			if (store.chatGroups && chatID !== userToUse.id && chatID in store.chatGroups && store.chatGroups[chatID].messages && Object.keys(store.chatGroups[chatID].messages).length > 0) recentUsers.push(u);
			else if (!onlyDirectMessages) {
				if (chatUsers.find(id => id === u.id)) {
					onlineUsers.push(
						<UserTag  store={store} dispatch={dispatch} auth={auth} localUser={localUser} online={true} onClick={() => onUserClick(u)} key={u.id} user={u} />
					);
				} else {
					offlineUsers.push(
						<UserTag store={store} dispatch={dispatch} auth={auth} localUser={localUser} onClick={() => onUserClick(u)} key={u.id} user={u} />
					);
				}
			}
		}
		
		recentUsers = recentUsers.sort((a, b) => {
			
			const chatIDA = getChatId(a, userToUse)
			const chatIDB = getChatId(b, userToUse)
			const aMessages = store.chatGroups[chatIDA]?.messages ? Object.values(store.chatGroups[chatIDA].messages) : 0;
			const bMessages = store.chatGroups[chatIDB]?.messages ? Object.values(store.chatGroups[chatIDB].messages) : 0;

			return aMessages[aMessages.length - 1]?.timestamp < bMessages[bMessages.length - 1]?.timestamp ? 1 : -1
		})

		const recentUsersTag = recentUsers.map(u => <UserTag store={store} dispatch={dispatch} auth={auth} localUser={localUser} online={chatUsers.find(id => id === u.id)} onClick={() => onUserClick(u)} key={u.id} user={u} />)
		
		setChats({
			...allChats, 
			recentUsers: recentUsersTag,
			onlineUsers: onlineUsers,
			offlineUsers: offlineUsers,
			complete: true,
		});

		} catch (e) {
			console.error(e); 
		}
	};

	useEffect(() => {

		let listItems = []
		if(allChats.complete){

		if (allChats.recentUsers.length !== 0) {
			listItems.push(<Text key='recent' style={{padding: '.5rem', borderBottom: '.05rem solid white' }} bold H2>{language.getText('Mensajes Recientes')}</Text>)
			for (const u of allChats.recentUsers) listItems.push(u)
		}

		if (allChats.onlineUsers.length !== 0) {
			listItems.push(<Text key='online' style={{ padding: '.5rem', borderBottom: '.05rem solid white' }} bold H2>{allChats.onlineUsers.length} {allChats.onlineUsers.length > 1 ? language.getText('Usuarios Online'): language.getText('Usuario Online')}</Text>)
			for (const u of allChats.onlineUsers) listItems.push(u)
		}

		if (allChats.offlineUsers.length !== 0) {
			listItems.push(<Text key='offline' style={{ padding: '.5rem', borderBottom: '.05rem solid white' }} bold H2>{allChats.offlineUsers.length} {allChats.offlineUsers.length > 1 ? language.getText('Usuarios Offline'): language.getText('Usuario Offline')}</Text>)
			for (const u of allChats.offlineUsers) listItems.push(u)
		}

		setItemState({...itemState, list: [...listItems], complete: true});
		}

	}, [allChats]);

	const rowRenderer = ({ key, index, style, parent }) => {
		return (
			<CellMeasurer 
				key={key}
				cache={rowCache}
				parent={parent}
				columnIndex={0}
				rowIndex={index}
			>
				<div key={key} style={style}>
					{itemState.list[index]}
				</div>
			</CellMeasurer>
		)
	}

	return active && show ? (
		<UserListContainer name="UserList_UserListContainer" showUserKeyWords={getShowFilter()} show={show}>
			<Text bold H12>{language.getText('CHAT')}</Text>
			<InputField
				placeholder={language.getText('Buscar...')}
				value={search}
				onChange={setSearch}
				style={{padding: '1.3rem', borderRadius: '.3rem', border: '0.1rem solid #ffff', backgroundColor: 'transparent'}}
			/>
			{getShowFilter() && <MultiDropdown
				clickHandler={id => {
					set(p => ({
						...p, filterOptions: {
							...p.filterOptions,
							[id]: {
								...p.filterOptions[id],
								value: !p.filterOptions[id].value
							}
						}
					}))
				}}
				options={state.filterOptions}
				title='Filtrar'
			/>}
			{ !allChats.complete && (
				<Loading />
			)}
			{ allChats.complete && itemState.complete && (
			<UserListScroll>
				<AutoSizer>
					{
						({ height, width }) => (
							<List
								width={width}
								height={height}
								rowCount={itemState.list ? itemState.list.length : 0}
								deferredMeasurementCache={rowCache}
      							rowHeight={rowCache.rowHeight}
								rowRenderer={rowRenderer}
							/>
						)
					}
				</AutoSizer>
			</UserListScroll>
			)}
		</UserListContainer>
	) : (<></>);
}

const UserTagContainer = styled.div`
    position: relative;
    padding: 1rem ;
	margin: 0.1rem .1rem;
    cursor: pointer;
    transition: .3s;
	background-color: transparent;
	border-radius: 1rem;
    :hover {
        background-color: ${p => p.theme.primary}C0;
        transform: translateX(0.5rem);
    }

    display: grid;
    grid-template-columns: 3rem auto 1fr;
    grid-gap: 0 0.5rem;
    height: 3rem;
	
	${p => (p.withConversation ||  p.withUnreadMessages) && css`
	    grid-template-columns: 3rem auto 2fr;
		grid-template-rows: auto min-content;
		background-color: white;
	    height: 3rem;
	    padding: 1rem 1rem;
	    margin: .5rem .5rem;
		border-radius: 1rem;
	`}
`
export const ProfilePictureContainer = styled.div`
	max-width: 3rem;
	max-height: 3rem; 
	position: relative;
    border-radius: 50%;		
	background-color: transparent;
`

export const ProfilePicture = styled.div`
    position: relative;
    border-radius: 50%;
    background-color: ${p => p.theme.light};
	overflow: hidden;
	width: 3rem;
	height: 3rem;
`
const TextContainer = styled.div`
	position: relative;
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: 1fr;
		${p => p.withConversation && css`
			grid-template-rows: 1fr auto
		`}
	grid-column-gap: 1px;
	grid-row-gap: 1px;
	width: 100%;
`

const LastMessageContainer = styled.div`
    margin: auto 0;
	line-height: .95rem;
	max-width: 15rem;
	max-height: 1.5rem;
	overflow: hidden;
	text-overflow: ellipsis;
`
const ExtraInfoContainer = styled.div`
	margin: auto 0;
`

const TimeContainer = styled.div`
	grid-column: 3;
	grid-row: 2 / 3;
	justify-items: end;
	padding-right: .5rem;
`

export const StatusContainer = styled.div`
    position: absolute;
    bottom: 0.05rem;
    right: 0.2rem;
    width: .5rem;
    height: .5rem;
	border: 0.2rem solid white;
    border-radius: 50%;
    background-color: ${p => p.status ? 'green' : 'gray'};
`

function Status(props) {
	const [status, setStatus] = useState(false)
	useEffect(() => {
		setStatus(props.user.online || props.online)
	}, [props.user.online || props.online])

	return (
		<StatusContainer status={status}>
		</StatusContainer>
	)
}

const UnreadMessagesQtyContainer = styled.div`
    position: absolute;
	display: flex;
	justify-content: center;
    background-color: ${p => p.theme.primary};
    border-radius: 50%;
    width: 1.5rem;
    height: 1.5rem;
    right: 1rem;
    * {
        width: min-content;
        height: min-content;
    }
`

function UserTag(props) {
	const [unreadMessagesQty, setUnreadMessagesQty] = useState(0)
	const [lastMessage, setLastMessage] = useState({
		message: undefined,
		read: undefined,
		sentBy: undefined,
		timestamp: undefined
	})

	const {
		store,
		dispatch,
		auth,
		localUser,
		onClick,
	} = props;

	const [chatId] = useState( getChatId( (auth.user ? auth.user : localUser) , props.user) );
	const callUsers = Object.keys(store.call || {});
	const onACall = callUsers.find(id => id === props.user.id);
	const language = useLanguage();

	useEffect(() => {
		if (store.chatGroups && chatId && chatId in store.chatGroups && store.chatGroups[chatId].messages) {
			
			const unreadLength = Object.values(store.chatGroups[chatId].messages).filter(m => !m.read && (( auth.user != null && m.user !== auth.user?.id) || (auth.user == null && m.user !== localUser.id) )).length;
			setUnreadMessagesQty(unreadLength)
			
			// Get last message and assign it to state
			let unreadMessages = Object.values(store.chatGroups[chatId].messages)
			setLastMessage({
				message: unreadMessages[unreadMessages.length - 1]?.text,
				read: unreadMessages[unreadMessages.length - 1]?.read,
				sentBy : unreadMessages[unreadMessages.length - 1]?.user,
				timestamp: unreadMessages[unreadMessages.length - 1]?.timestamp
			})
		}
	}, [store.chatGroups, chatId, auth.user?.id])

	function getExtraName() {
		try {
			for (const extraInfo of Object.values(store.configurations.userExtraInfo)) {
				if (extraInfo.primary) return ` - ${props.user.extraInfo[extraInfo.id]}`
			}

			return ''
		} catch { return '' }
	}

	const userCompleteName = `${props.user.name || ''} ${props.user.lastName || ''} ${getExtraName()}`;

	return (
		<UserTagContainer 
			withUnreadMessages={unreadMessagesQty !== 0}
			withConversation = {lastMessage.message} 
			onClick={props.onClick}>
			<ProfilePictureContainer>
				<ProfilePicture>
					{props?.user?.profilePicture ? 
					<Image url={props?.user?.profilePicture} />
					: <SVG style={{ backgroundColor: 'gray', maskSize: '100%', WebkitMaskSize: '100%' }} 
						image={profileIcon}
					  /> }			
				</ProfilePicture>
				<Status user={props.user} online={props.online} />
			</ProfilePictureContainer>
			<TextContainer withConversation={lastMessage.message}>
				<Text oneline H2 bold truncated dark={lastMessage.message}>{userCompleteName}</Text>
				{lastMessage.message && 
				<LastMessageContainer>
					{lastMessage.message ? <Text dark={!unreadMessagesQty} truncated oneline primary={unreadMessagesQty} bold={unreadMessagesQty} small>{lastMessage.sentBy === auth?.user?.id ? `${language.getText('Tú')}: ` + lastMessage.message : lastMessage.message}</Text> : 
					null}
				</LastMessageContainer>} 
			</TextContainer>
			<ExtraInfoContainer>
				{onACall && <SVG style={{ maskSize: '60%', webkitMaskSize: '60%' }} image={phoneIcon} contain light />}
				{unreadMessagesQty !== 0 && <UnreadMessagesQtyContainer><Text verticalAlign center bold>{unreadMessagesQty}</Text></UnreadMessagesQtyContainer>}
			</ExtraInfoContainer>
			{ lastMessage.message && lastMessage.timestamp &&
			<TimeContainer>
				<Text oneline horizontalAlignRight medium>{getTimeStamp(lastMessage.timestamp, true)}</Text>
			</TimeContainer>
			}
		</UserTagContainer>
	)
}

const ChatContainer = styled.div`
    height: 100%;
	display: grid;
`

const CloseButton = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    width: 2.4rem;
    height: 2.4rem;
`

function Chat(props) {
	let messages = {} 

	const {
		store,
		isPortrait,
		selected,
		auth,
		localUser,
		name,
	} = props;
	
	if (store?.chatGroups) {
		if (store?.chatGroups[props.id] && store?.chatGroups[props.id].messages) messages = store.chatGroups[props.id].messages
	}

	useEffect(() => {
		if (store?.chatGroups) {
			if (store.chatGroups[props.id] && store.chatGroups[props.id].messages && store.chatGroups[props.id].messages) {
				Object.values(store.chatGroups[props.id].messages).filter(m => !m.read && (( auth.user != null && m.user !== auth.user?.id) || (auth.user == null && m.user !== localUser.id) )).forEach(m => {
					setContent(`${databasePaths.chatGroups}/${props.id}/messages/${m.id}/read`, true)
				})
			}
		}
	}, [props.id, messages])

	return (
		<ChatContainer name="Chat_ChatContainer">
			{isPortrait && <CloseButton isPortrait={isPortrait}><IconButton onClick={props.backHandler} contain image={closeIcon} /></CloseButton>}
			<ChatView
				personal
				id={props.id}
				target={selected}
				name={name}
				isPortrait={isPortrait}
				closeHandler={props.backHandler}
				localUser={localUser}
			/>
		</ChatContainer>
	)
}
