import React, { useState, useEffect } from 'react'
import styled from 'styled-components/macro'
import { useStore, useLanguage, useAuth } from '../../hooks'
import { Button, InputField, Text as OldText } from '../UI'
import { MultiDropdown, CheckBox } from '../NewUI'
import { Feedback } from './'

interface Dictionary<T> { [key: string]: T }

const SFormView = styled.div`
	height: 30rem;
    display: grid;
	grid-template-rows: auto min-content;
	grid-gap: 1rem;
`

const SGridHolder = styled.div`
	overflow: auto;
	overflow-x: hidden;
`

const SGrid = styled.div`
    height: min-content;
    display: grid;
    grid-gap: ${p => p.theme.gridGap};
`

interface IState {
	name: string,
	lastName: string,
	extraInfo: {},
	aditionalLink: string,
}

export default function FormView(props: { feedback?: string, isEdit?: boolean, confirmHandler(name: string, lastName: string, extraInfo: {}, aditionalLink: string): void }) {
	const language = useLanguage() as any
	const [state, set] = useState<IState>({
		name: '',
		lastName: '',
		extraInfo: {},
		aditionalLink: '',
	})
	const [store] = useStore()
	const auth = useAuth()
	const unfilledFields = {};
	const [acceptTerms, setAcceptTerms] = useState(false)
	const [acceptPrivacy, setAcceptPrivacy] = useState(false)
	const [errorState, setErrorState] = useState({
		name: {
			valid:  true,
			displayMessage: false,
			customMessage: language.getText('CAMPO_NOMBRE_OBLIGATORIO')
		},
		lastName: {
			valid:  true,
			displayMessage: false,
			customMessage: language.getText('CAMPO_APELLIDO_OBLIGATORIO')
		},
		additionalLink: {
			valid:  true,
			displayMessage: false,
			customMessage: language.getText('CAMPO_OBLIGATORIO')
		},
		privacy: {
			valid:  !store.configurations?.otherLinks?.privacy,
			displayMessage: false,
			customMessage: (language.getText('CAMPO_OBLIGATORIO') as string),
		},
		terms: {
			valid:  !store.configurations?.otherLinks?.termsAndConditions,
			displayMessage: false,
			customMessage: (language.getText('CAMPO_OBLIGATORIO')  as string),
		}
	})

	// Set current user values if is editing.
	useEffect(() => {
		if (props.isEdit && auth?.user) {
			set(p => ({
				...p,
				name: auth?.user?.name ?? '',
				lastName: auth?.user?.lastName ?? '',
				extraInfo: auth?.user?.extraInfo ?? {},
				aditionalLink: auth?.user?.aditionalLink ?? '',
			}))
		}
	}, [props.isEdit])

	function confirmHandler(): void {
		// Validation for extraInfo fields
		const error = {...errorState};

		if( !state.name || state.name?.trim() === '' ){
			error.name = {
				...error.name,
				valid:  false,
				displayMessage: true,
			};
		}

		if( !state.lastName && state.lastName?.trim() === '' ){
			error.lastName = {
				...error.lastName,
				valid:  false,
				displayMessage: true,
			};
		}

		for (const field of requiredFields) {
			if (
				(
					!((field.id as string) in state.extraInfo) ||
					!state.extraInfo[field.id]
				) &&
				!error[field.id]
			){
				error[field.id] = {
					valid: false, 
					displayMessage: true,
					customMessage: language.getText('CAMPO_OBLIGATORIO')
				}
			}
		}

		if( store.configurations?.otherLinks?.privacy && !acceptPrivacy){
			error.privacy = {
				...error.privacy,
				valid:  false,
				displayMessage: true,
			};
		}

		if( store.configurations?.otherLinks?.termsAndConditions && !acceptTerms ){
			error.terms = {
				...error.terms,
				valid:  false,
				displayMessage: true,
			};
		}

		// If after these validations there are errors, return
		if (Object.values(errorState).filter(field => field.valid === false).length > 0) {
			setErrorState({
				...error,
			});
			return;
		} else { //Otherwise,continue to save the data
			props.confirmHandler(state.name, state.lastName, state.extraInfo, state.aditionalLink)
		}

	}

	function setErrors (fieldId, value, errorMessage = language.getText('CAMPO_OBLIGATORIO')) {
		if (!value) {
			setErrorState(errorState => ({...errorState, 
				[fieldId]: {
					valid:  false,
					displayMessage: true,
					customMessage: errorMessage
				}
			}))
		} else {
			setErrorState(errorState => ({...errorState, 
				[fieldId]: {
					valid:  true,
					displayMessage: false,
					customMessage: ''
				}
			}))
		}	
	}

	let requiredFields = [];

	function getExtraData() {
		interface IOption {
			id: string,
			name: string,
			value: boolean
		}
		if (!store?.configurations?.userExtraInfo) return []

		// Get required fields in the form
		requiredFields = (Object.values(store?.configurations?.userExtraInfo) as any[])
		.filter(p => p.required)


		return (Object.values(store.configurations.userExtraInfo) as any[])
			.filter((info) => info.id !== 'additionalLink')
			.sort((a, b) => {
				const av = a.order ? parseFloat(a.order) : Number.MAX_SAFE_INTEGER
				const bv = b.order ? parseFloat(b.order) : Number.MAX_SAFE_INTEGER
				return av > bv ? 1 : -1
			})
			.map(info => {
				if (info.type === 'boolean') {
					if (!errorState[info.id]) errorState[info.id] = {
						valid: info.required ? false : true, 
						displayMessage: info.required ? true : false,
						customMessage: language.getText('CAMPO_OBLIGATORIO')
					}
					return(
							<CheckBox
								key={info.id}
								isRequired={info.required}
								errorState={errorState[info.id]}
								onClick={() => {
									// Validation on click
									setErrorState(p => ({ ...p, [info.id]: {
									    valid: true, 
										displayMessage: false,
										customMessage: language.getText('CHECKBOX_OBLIGATORIO')
								} }))
	
									state.extraInfo[info.id] = !(state.extraInfo[info.id] ? state.extraInfo[info.id] : false)
									set({ ...state })
								}}
								title={info.required ? info.name + ' *' : info.name}
								value={state?.extraInfo ? state.extraInfo[info.id] : false}
							/>
						)
				}
				else if (info.type === 'dropdown' && info.options) {
					if (!errorState[info.id]) errorState[info.id] = {
						valid: info.required ? false : true, 
						displayMessage: info.required ? true : false,
						customMessage: language.getText('ELIGE_AL_MENOS_UNA_OPCION')
					}
					return (
						<MultiDropdown
							key={info.id}
							isRequired={info.required}
							errorState={errorState[info.id] || {}}
							collapseText = {true}
							singleOption={!info.multi}
							title={info.required ? info.name + ' *' : info.name}
							options={Object.values(info.options).reduce((obj: Dictionary<IOption>, option: IOption) => ({
								...obj,
								[option.id]: {
									id: option.id,
									name: option.name,
									value: (state?.extraInfo && state?.extraInfo[info.id]) ? option.id in state?.extraInfo[info.id] : false
								}
							}), {}) as Dictionary<IOption>}
							clickHandler={id => {
								if (!state.extraInfo) state.extraInfo = {}
								if (!state.extraInfo[info.id]) state.extraInfo[info.id] = {}
	
								if (!errorState[info.id]) errorState[info.id] = {}
								setErrors(info.id, id, language.getText('CAMPO_OBLIGATORIO'))
	
								if (id in state.extraInfo[info.id]) delete state.extraInfo[info.id][id]
								else state.extraInfo[info.id][id] = id
	
								set({ ...state })
							}}
							style={{ width: '100%' }}
						/>)
				}

				else return (<InputField
					key={info.id}
					isRequired={info.required}
					errorState = {errorState[info.id] || {}}
					setErrors={setErrors}
					title={info.required ? info.name + ' *' : info.name}
					value={state.extraInfo[info.id]}
					onKeyDown={e => { if (e.keyCode === 13) confirmHandler() }}
					onChange={v => {
						setErrors(info.id, v, `El campo ${info.name} es obligatorio`)
						state.extraInfo[info.id] = v
						set({ ...state })
					}}
				/>)
			})
	}

	return (
		<SFormView>
			<SGridHolder>
				<SGrid>
					<InputField
						key="name"
						title={`${language.getText('Nombre')} *`}
						isRequired ={true}
						value={state.name}
						errorState={errorState["name"]}
						setErrors={setErrors}
						onChange={v => {	
							setErrorState( (prevState) => ({ 
								...prevState, 
								name: {
									...prevState.name,
									valid: v, 
									displayMessage: !v,
								} 
							}));
							set(p => ({ ...p, name: v }))}
						}
						onKeyDown={e => { if (e.keyCode === 13) confirmHandler() }}
					/>
					<InputField
						key="lastName"
						title={`${language.getText('Apellido')} * `}
						isRequired ={true}
						errorState={errorState["lastName"]}
						setErrors={setErrors}
						value={state.lastName}
						onChange={v => {
							setErrorState( (prevState) => ({ 
								...prevState, 
								lastName: {
									...prevState.lastName,
									valid: v, 
									displayMessage: !v,
								} 
							}));
							set(p => ({ ...p, lastName: v }))
						}}
						onKeyDown={e => { if (e.keyCode === 13) confirmHandler() }}
					/>
					{getExtraData()}
					{store?.configurations?.additionalLinkEnable && 
					<InputField
						key="additionalLink"
						isRequired= {store?.configurations?.userExtraInfo?.additionalLink?.required}
						errorState = {errorState['additionalLink']}
						setErrors={setErrors}
						title={`${language.getText(store?.configurations?.userExtraInfo?.additionalLink.name)}`}
						value={state.aditionalLink}
						onChange={v => {
							setErrorState( (prevState) => ({ 
								...prevState, 
								additionalLink: {
									...prevState.additionalLink,
									valid: v, 
									displayMessage: !v,
								} 
							}));
							set(p => ({ ...p, aditionalLink: v }))
						}}
						onKeyDown={e => { if (e.keyCode === 13) confirmHandler() }}
					/>}				

					{ store.configurations?.otherLinks?.privacy && (<>
						<OldText dark bold
							onClick={() => window.open(store.configurations.otherLinks.privacy, language.getText('POLITICAS_DE_PRIVACIDAD'))}  
						>
							{language.getText('POLITICAS_DE_PRIVACIDAD')}
						</OldText>
						<CheckBox 
							isRequired={store.configurations?.otherLinks?.privacy}
							errorState={ ( ( errorState.privacy as unknown ) as Dictionary<{ customMessage: string, valid: boolean, displayMessage: boolean }> ) }
							onClick={() => { 
								setErrorState( (prevState) => ({ 
										...prevState, 
										privacy: {
											...prevState.privacy,
											valid: !acceptPrivacy, 
											displayMessage: acceptPrivacy,
										} 
									}));
								setAcceptPrivacy(!acceptPrivacy);
							} }
							title={language.getText('ACEPTAR_POLITICAS_DE_PRIVACIDAD')}
							value={acceptPrivacy}
						/>
					</>)}
					{ store.configurations?.otherLinks?.termsAndConditions && (<>
						
						<OldText dark bold
							onClick={() => window.open(store.configurations.otherLinks.termsAndConditions, language.getText('TERMINOS_Y_CONDICIONES'))}  
						>
							{language.getText('TERMINOS_Y_CONDICIONES')}
						</OldText>
						<CheckBox 
							isRequired={store.configurations?.otherLinks?.termsAndConditions}
							errorState={ ( ( errorState.terms as unknown ) as Dictionary<{ customMessage: string, valid: boolean, displayMessage: boolean }> ) }
							onClick={() => { 
								setErrorState( (prevState) => ({ 
										...prevState, 
										terms: {
											...prevState.terms,
											valid: !acceptTerms, 
											displayMessage: acceptTerms,
										} 
									}));
								setAcceptTerms(!acceptTerms);
							} }
							title={language.getText('ACEPTAR_TERMINOS_Y_CONDICIONES')}
							value={acceptTerms}
						/>
					</>)}

					{props.feedback && <Feedback feedback={props.feedback} />}
					
				</SGrid>
			</SGridHolder>
			<Button onClick={confirmHandler}>{language.getText(props.isEdit ? 'Guardar' : 'Crear Usuario')}</Button>
		</SFormView>
	)
}
