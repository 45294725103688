import React, { useEffect, useRef } from 'react'
import styled, { css } from 'styled-components/macro'
import useState from 'react-usestateref'

import { Text, Button, Image } from '../UI'
import { SBackground } from './SearchBar'
import { useStore, useCatalog, useLanguage, useIsPortrait } from '../../hooks'
import { CloseButton, Inputfield } from '../NewUI'
import { useHistory } from 'react-router-dom'
import { ILanguage } from '../../context/LanguageContext'

const SProducts = styled.div<{ active: boolean }>`
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 10;
`

const SGridHolder = styled.div`
	height: 100%;
    overflow: auto;
    /* overflow-x: hidden; */
	width: 100%;
	/* box-sizing: border-box; */
`

const SGrid = styled.div`
    display: grid;
    grid-gap: 1rem;
	width: 100%;
	box-sizing: border-box;
`

const SOutside = styled.div`
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	cursor: pointer;
`

const SPanel = styled.div`
	position: absolute;
	right: 1rem;
	left: 1rem;
    bottom: 1rem;
	top: 3.5rem;

    display: grid;
	grid-gap: 1rem;
    grid-template-rows: min-content auto;
    padding: 1.5rem 2.5rem;
`

const STopbar = styled.div`
	height: 2rem;
	display: grid;
	grid-auto-flow: column;
	justify-content: right;
`

const SRow = styled.div`
	display: grid;
	grid-auto-flow: column;
`

interface IState {
	search: string,
	stands: any[],
	loading: boolean
}

export default function Catalog() {
	const [store] = useStore()
	const [catalog, setCatalog] = useCatalog()
	const language = useLanguage() as ILanguage
	const panelRef = useRef()
	const [state, set, stateRef] = useState<IState>({
		search: '',
		stands: [],
		loading: false,
	})

	useEffect(() => {
		set(p => ({ ...p, search: catalog.active ? catalog.search : '' }))
	}, [catalog.active])

	function sleep(ms): Promise<void> { return new Promise(resolve => setTimeout(resolve, ms)) }

	// Get all stands with products.
	useEffect(() => {
		(async function () {
			const result = []
			function push(stand: any) {
				result.push(<Stand key={stand.id} stand={stand} search={state.search} closeHandler={() => closeHandler()} />)
			}

			if (!catalog.active) return

			set(p => ({ ...p, loading: true, stands: [] }))

			if (store?.stands) {
				for (const stand of (Object.values(store.stands) as any[])) {
					if ((catalog.stand && catalog.stand.id != stand.id) || !stand.catalog) continue
					const search = state.search.toLowerCase()
					if (search === '') push(stand)
					else for (const product of (Object.values(stand.catalog) as any[])) {
						await sleep(1)
						if (state.search != stateRef?.current?.search) {
							return
						}
						if (product.name && product.name.toLowerCase().includes(search) ||
							product.description && product.description.toLowerCase().includes(search)) {
							push(stand)
							break
						}
					}
				}
			}

			set(p => ({ ...p, stands: result, loading: false }))
		})()

	}, [state.search, catalog.stand, catalog.active])

	// close catalog.
	function closeHandler(): void {
		setCatalog(p => ({ ...p, active: false, stand: undefined }))
	}

	return catalog.active ? (
		<SProducts>
			<SOutside onClick={() => closeHandler()} />
			<SPanel>
				<SBackground />
				<CloseButton onClick={() => closeHandler()} light style={{ width: '1.2rem', height: '1.2rem' }} />
				<STopbar>
					<SRow>
						<Inputfield
							onChange={v => set(p => ({ ...p, search: v }))}
							value={state.search}
							placeholder={language.getText('Buscar')}
						/>
					</SRow>
				</STopbar>
				<SGridHolder>
					<SGrid>
						{state.loading ? <Text bold H1 oneline>Buscando...</Text> : state.stands}
					</SGrid>
				</SGridHolder>
			</SPanel>
		</SProducts>
	) : null
}

const SStand = styled.div`
	display: grid;
	grid-template-rows: min-content auto;
	grid-gap: 1rem;
	width: 100%;
	box-sizing: border-box;
`

const SProductGrid = styled.div<{ isPortrait: boolean }>`
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	${p => p.isPortrait && css`
		grid-template-columns: repeat(1, 1fr);
	`}
	grid-gap: 1rem;
	width: 100%;
	box-sizing: border-box;
`

function Stand(props: { stand: any, search: string, closeHandler(): void }) {
	const [store] = useStore()
	const history = useHistory()
	const [catalog, setCatalog] = useCatalog()
	const isPortrait = useIsPortrait()

	// Go to stand.
	function openProduct(item) {
		setCatalog(p => ({
			...p,
			product: item,
			stand: props.stand,
			productActive: true
		}))
	}

	// Get all products.
	function getProducts() {
		return (Object.values(props.stand.catalog) as any[])
			.filter(product => {
				if (!product.name) return false
				if (props.search === '') return true
				const search = props.search.toLowerCase()
				if (product.name && product.name.toLowerCase().includes(search)) return true
				if (product.description && product.description.toLowerCase().includes(search)) return true
				return false
			})
			.map(product => <Product key={product.id} product={product} clickHandler={() => openProduct(product)} />)
	}

	function standClickHandler(): void {
		try { history.push(`/stand?pavillionId=${store?.pavillions[props.stand.pavillion].id}&id=${props.stand.id}`) } catch { }
		props.closeHandler()
	}

	return (
		<SStand>
			<Text H1 bold oneline onClick={() => standClickHandler()}>{props.stand?.name?.toUpperCase()}</Text>
			<SProductGrid isPortrait={isPortrait}>
				{getProducts()}
			</SProductGrid>
		</SStand>
	)
}

const SProduct = styled.div`
	background-color: ${p => p.theme.light};
	border-radius: ${p => p.theme.roundCorners};
	padding: .5rem;
	height: 20rem;

	display: grid;
	grid-template-rows: auto min-content min-content min-content;
	grid-gap: .5rem;
`

const SPicture = styled.div`
	background-color: ${p => p.theme.medium};
`

function Product(props: { product: any, clickHandler(): void }) {
	return (
		<SProduct>
			<SPicture>
				<Image url={props.product.image} />
			</SPicture>
			<Text H2 online dark bold>{props.product.name}</Text>
			<Text H1 online dark bold>$ {props.product.price}</Text>
			<Button onClick={() => props.clickHandler()}>Ver más</Button>
		</SProduct>
	)
}
