import React, { useState, useEffect } from 'react'

const ElementsManager = React.createContext({})

export interface IElementsManagerState {
    panels: boolean,
    buttons: boolean,
    blocked: boolean
}

export interface IElementsManager {
    state: IElementsManagerState,
    setPanels(value: boolean): void,
    setButtons(value: boolean): void,
    setBoth(value: boolean): void,
    blockUser(): void,
}

export function ElementsManagerContext(props) {
    const [state, set] = useState<IElementsManagerState>({
        panels: true,
        buttons: true,
        blocked: false
    })

    const value: IElementsManager = {
        state: state,
        setPanels(value) {
            set(prev => { return { ...prev, panels: value } })
        },
        setButtons(value) {
            set(prev => { return { ...prev, buttons: value } })
        },
        setBoth(value) {
            set(prev => { return { ...prev, panels: value, buttons: value } })
        },
        blockUser() {
            set(p => ({ ...p, panels: false, buttons: false, blocked: true }))
        }
    }

    return <ElementsManager.Provider value={value}> {props.children} </ ElementsManager.Provider>
}

export default ElementsManager