import React, { useState, useEffect } from 'react'
import styled, { css } from 'styled-components'

import { useStore, useCompanyPoster, useLanguage, useIsPortrait } from '../../hooks'
import { Inputfield } from '../NewUI'
import { Text, Image, Button } from '../UI'
import { getContentEqualTo } from '../../firebase/database'
import { databasePaths } from '../../firebase/index.js'
import Loading from '../Loading'

const SCompanies = styled.div`
	height: 100%;
	padding: 1rem;

	display: grid;
	grid-gap: 1rem;
	grid-template-rows: min-content auto;
	background-color: rgba(0, 0, 0, .8);
	border-radius: ${p => p.theme.roundCorners};
`

interface IState {
	search: string,
	loading: boolean,
}

// Sort areas function.
function sort(a, b) {
	const valueA = a?.order ? parseFloat(a.order) : 9999
	const valueB = b?.order ? parseFloat(b.order) : 9999

	return valueA > valueB ? 1 : -1
}

let panelState = {};

export default function Companies(props) {
	let { panel, viewId } = props;
	function getPostersInPanel(panelId, viewId) {
		if(panelId === "a") {
			panelId = viewId
		}
		const res = getContentEqualTo(databasePaths.posters, "id_panel", panelId)

		return res;
	}

	
	const [state, set] = useState<IState>({
		search: '',
		loading: false
	})

	
	const [companyPoster, setCompanyPoster] = useCompanyPoster()
	const [store] = useStore()
	const language = useLanguage() as any
	
	/* Quick fix for keys as "a"
	* TODO: review behavior for storing data
	* in firebase 
	*/
	const uniqueId = (panel.id === "a" ? viewId : panel.id)
	
	// useEffect to clen up global variable
	useEffect(() => {
        return () => {
			panelState[uniqueId] = undefined;
        }
    }, [])

	if(panelState[`${uniqueId}`] === undefined) {
		set(p => ({ ...p, loading: true }))
		panelState[`${uniqueId}`] = {  
			postersInPanel: null, 
			panelId: `${uniqueId}`
		};

		getPostersInPanel(panel.id, viewId).then((data)=>{
			if (data && Object.keys(data).length) {
				panelState[`${uniqueId}`] = {
					postersInPanel: data, 
					panelId: `${uniqueId}`
				}
			};
			set(p => ({ ...p, loading: false }))
		})

	}
	
	/**
	 * Since ticket #90625 
	 * @returns array of companies assigned to Panel
	 */
	function getCompaniesInPanel(): any[] {
		if(!store.companies) return [];

		return (Object.values(store.companies) as any [])
		.filter(company => {
			if (!company.name || !company.isPoster) return false

			const isInPanel = Object.keys(panelState[`${uniqueId}`].postersInPanel || {}).includes(company.id);
			
			if (isInPanel && state.search === '') return true

			const search = state.search.toLowerCase()
				if (isInPanel && company.name.toLowerCase().includes(search)) return true
				const subName = company?.subName?.toLowerCase() ?? ''
				if (isInPanel && subName.includes(search)) return true
				const description = company?.description?.toLowerCase() ?? ''
				if (isInPanel && description.includes(search)) return true
				const tags = company?.tags?.toLowerCase() ?? ''
				if (isInPanel && tags.includes(search)) return true
				return false
		})
		.sort(sort);
	}

	/**
	 * Not in use since ticket #90625
	 * 
	 */
	function getCompanies(): any[] {
		if (!store.companies) return []
		return (Object.values(store.companies) as any[])
			.filter(company => {
				if (!company.name || !company.isPoster) return false
				if (state.search === '') return true

				const search = state.search.toLowerCase()
				if (company.name.toLowerCase().includes(search)) return true
				const subName = company?.subName?.toLowerCase() ?? ''
				if (subName.includes(search)) return true
				const description = company?.description?.toLowerCase() ?? ''
				if (description.includes(search)) return true
				const tags = company?.tags?.toLowerCase() ?? ''
				if (tags.includes(search)) return true
				return false
			})
			.sort(sort)
	}

	return (
		<SCompanies>
			<Inputfield
				value={state.search}
				onChange={v => set(p => ({ ...p, search: v }))}
				placeholder={`${language.getText('Buscar')}...`}
			/>
			{state.loading && <Loading />}
			{!state.loading && <Grid companies= {getCompaniesInPanel()} companyClickHandler={company => setCompanyPoster(p => ({ ...p, company, active: true }))} />}
		</SCompanies>
	)
}

const SGridHolder = styled.div`
	overflow: auto;
	overflow-x: hidden;
	padding-bottom: 2rem;
`

const SGrid = styled.div<{ isPortrait: boolean }>`
	display: grid;
	grid-gap: .5rem;
	grid-template-columns: repeat(auto-fit, ${p => p.isPortrait ? '1fr' : '14rem'});
	box-sizing: border-box;
`

function Grid(props: { companies: any[], companyClickHandler(company: any): void }) {
	const isPortrait = useIsPortrait()
	return (
		<SGridHolder>
			{!props.companies.length &&
			<Text center middle>No se encontraron resultados</Text>
			}
			
			{props.companies.length &&
			<SGrid isPortrait={isPortrait}>
				{props.companies.map(company => <Company key={company.id} company={company} clickHandler={() => props.companyClickHandler(company)} />)}	
			</SGrid>
			}
		</SGridHolder>
	)
}

const SCompany = styled.div`
	height: 17rem;
	/* aspect-ratio: 4/5; */
	background-color: ${p => p.theme.light};
	border-radius: ${p => p.theme.roundCorners};

	display: grid;
	grid-template-rows: auto min-content min-content 2rem;
	grid-gap: .5rem;
	padding: 1rem .5rem;

	* {
		overflow: hidden;
		white-space: nowrap; 
		text-overflow: ellipsis;
	}
`

const SPicture = styled.div`
	background-color: ${p => p.theme.darkerLight};
	border-radius: ${p => p.theme.roundCorners};
	min-height: 5rem;
	overflow: hidden;
`

function Company(props: { company: any, clickHandler(): void }) {
	const language = useLanguage() as any
	return (
		<SCompany>
			<SPicture>
				<Image url={props.company.logo} />
			</SPicture>
			<Text H2 online dark bold oneline>{props.company?.name ?? ''}</Text>
			<Text H3 online dark bold oneline>{props.company?.subName ?? ''}</Text>
			<Button onClick={() => props.clickHandler()}>{language.getText('Ver más')}</Button>
		</SCompany>
	)
}