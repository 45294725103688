import React, { useEffect, useRef } from 'react'
import styled, { css } from 'styled-components'
import useState from 'react-usestateref'

import { useHistory } from 'react-router-dom'
import { SInputfield, SInputfieldIcon, SIcon } from '../NewUI'
import { Text, Image, Button } from '../UI'
import { useStore, useCatalog, useLanguage, useOutsideAlerter, useCompanyPoster, useIsPortrait } from '../../hooks'
import icons from '../../graphics/icons'

import { IItem } from '../control-panel/Stands/Catalog'

export const SBackground = styled.div<{ onClick?(): void }>`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: ${p => p.theme.dark};
    opacity: .85;
    z-index: -1;
    border-radius: ${p => p.theme.roundCorners};

	${p => p.onClick && css`
		cursor: pointer;
		transition: .3s;
		:hover {
			opacity: .8;
		}
	`}
`

export const SArrow = styled.div<{ left?: string, right?: string }>`
    position: absolute;
    width: 0;
    height: 0;
    border-left: .6rem solid transparent;
    border-right: .6rem solid transparent;
    border-bottom: .6rem solid ${p => p.theme.dark};
    top: -.5rem;
    left: ${p => p.left};
    right: ${p => p.right};
`

const SSmallArrow = styled.div`
    width: 0;
    height: 0;
    border-left: .4rem solid transparent;
    border-right: .4rem solid transparent;
    border-top: .4rem solid ${p => p.theme.primary};
	transform: translateY(.08rem);
`

export const SGridHolder = styled.div`
	height: 100%;
    overflow: auto;
    overflow-x: hidden;
`

export const SGrid = styled.div`
    display: grid;
    grid-gap: 1rem;
`

const SSearchBar = styled.div<{ active: boolean, isPortrait: boolean }>`
    position: relative;
    border: .1rem solid ${p => p.theme.light};
    border-radius: ${p => p.theme.roundCorners};
    box-sizing: border-box;
    height: 2.2rem;
    display: ${p => p.active ? 'grid' : 'none'};
    grid-gap: .5rem;
    grid-template-columns: 1.2rem auto 7rem;
    align-items: center;
    padding: 0 .5rem;
	background: white;

	${p => p.isPortrait && css`
		top: 3rem;
		left: -1.2rem;
		display: none;
	`}
`

const SCategory = styled.div`
	padding: 0 .5rem;
    border-left: .1rem solid ${p => p.theme.dark};
	display: grid;
	grid-template-columns: auto min-content;
	place-items: center;
	cursor: pointer;
	:hover {
		* {
			color: ${p => p.theme.primary};
		}
	}
`

interface IState {
	search: string,
	category: TCategory,
	categoryMenuState: boolean,
	results: any[],
	loading: boolean,
}

type TCategory = 'stands' | 'products' | 'companies'

export default function SearchBar(props) {
	const [state, set, stateRef] = useState<IState>({
		search: '',
		category: 'stands',
		categoryMenuState: false,
		results: [],
		loading: false,
	})
	const history = useHistory()
	const [store] = useStore()
	const [catalog, setCatalog, catalogRef] = useCatalog()
	const language = useLanguage() as any
	const [companyPoster, setCompanyPoster] = useCompanyPoster()
	const isPortrait = useIsPortrait()

	const menuRef = useRef()
	useOutsideAlerter(menuRef, () => {
		if (stateRef.current.search != '' && !catalogRef?.current?.productActive) set(p => ({ ...p, search: '' }))
	})

	const categoryRef = useRef()
	useOutsideAlerter(categoryRef, () => {
		if (stateRef?.current?.categoryMenuState) setTimeout(() => set(p => ({ ...p, categoryMenuState: false })), 100)
	})

	// Set local search, and catalog search.
	function setSearch(value: string): void {
		set(p => ({ ...p, search: value }))
	}

	useEffect(() => {
		setCatalog(p => ({ ...p, search: state.search }))
	}, [state.search])

	function goToStand(stand): void {
		set(p => ({ ...p, search: '' }))
		try {
			let url = `/stand?pavillionId=${store?.pavillions[stand.pavillion].id}&id=${stand.id}`
			history.push(url)
		} catch { }
	}

	function openProduct(product, stand): void {
		setCatalog(p => ({
			...p,
			product,
			stand,
			productActive: true
		}))
	}

	// Open catalog.
	function viewAllHandler(): void {
		setCatalog(p => ({ ...p, active: true }))
	}

	// Search for results.
	useEffect(() => {
		function sleep(ms): Promise<void> { return new Promise(resolve => setTimeout(resolve, ms)) }
		(async function () {
			let results = []
			const search = state.search.toLowerCase()
			if (search === '') { }
			else if (state.category === 'stands' && store?.stands) {
				results = (Object.values(store?.stands) as any[])
					.filter(s => {
						const name = s?.name?.toLowerCase() ?? ''
						const tags = s?.tags?.toLowerCase() ?? ''
						let company = ''
						try {
							company = store.companies[s.company].name
						} catch { }

						s.companyName = company

						if (name.includes(search)) return true
						if (tags.includes(search)) return true
						if (company.includes(search)) return true
						return false
					})
					.map(s => <ResultItem
						key={s.id}
						name={s.name}
						subName={s.companyName}
						image={s.icon}
						button='Visitar'
						onClick={() => {
							goToStand(s)
						}}
					/>)
			}

			else if (state.category === 'products' && store?.stands) {
				set(p => ({ ...p, loading: true, results: [] }))
				for (const stand of Object.values(store.stands) as any[]) {
					if (!stand.catalog) continue
					let count = 0
					const max = 100

					for (const item of Object.values(stand.catalog) as IItem[]) {
						const name = item?.name?.toLowerCase() ?? ''
						const description = item?.description?.toLowerCase() ?? ''
						if (name.includes(search) || description.includes(search))
							results.push(<ResultItem
								key={item.id}
								name={item.name}
								subName={item.description}
								image={item.image}
								companyName={stand.name ?? ''}
								price={item.price ? `$${item.price}` : ''}
								button='Ver'
								onClick={() => {
									openProduct(item, stand)
								}}
							/>)
						if (count++ > max) {
							count = 0
							await sleep(1)
						}
					}
					await sleep(1)
					if (stateRef?.current?.search != state.search) return
				}
				set(p => ({ ...p, loading: false }))
			}

			else if (state.category === 'companies' && store?.companies) {
				results = (Object.values(store.companies) as any[])
					.filter(company => {
						if (!company.name || !company.isPoster) return false

						if (company.name.toLowerCase().includes(search)) return true
						const description = company?.description?.toLowerCase() ?? ''
						if (description.includes(search)) return true
						const tags = company?.tags?.toLowerCase() ?? ''
						if (tags.includes(search)) return true
						return false
					})
					.map(c => <ResultItem
						key={c.id}
						name={c.name}
						subName={c.subName}
						image={c.logo}
						button='Ver más'
						onClick={() => {
							setCompanyPoster(p => ({ ...p, company: c, active: true }))
						}}
					/>)
			}

			set(p => ({ ...p, results: results }))
		})()
	}, [state.search, state.category])

	// Select the default category.
	useEffect(() => {
		if (isEnabled('standsEnabled')) set(p => ({ ...p, category: 'stands' }))
		else if (isEnabled('productsEnabled')) set(p => ({ ...p, category: 'products' }))
		else if (isEnabled('companiesEnabled')) set(p => ({ ...p, category: 'companies' }))
	}, [])

	function isEnabled(key: string): boolean {
		try {
			return store?.configurations?.globalSearchConfig[key] ?? false
		} catch { return false }
	}

	function getCategoryName(key: string): string {
		try {
			if (store?.configurations?.globalSearchConfig[key]) return store?.configurations?.globalSearchConfig[key]
		} catch { }

		if (key === 'standsName') return 'Stands'
		if (key === 'productsName') return language.getText('Productos')
		if (key === 'companiesName') return language.getText('Empresas')

		return ''
	}

	function shouldShow(): boolean {
		if (isEnabled('standsEnabled')) return true
		if (isEnabled('productsEnabled')) return true
		if (isEnabled('companiesEnabled')) return true
		return false
	}

	return (
		<SSearchBar active={shouldShow()} ref={menuRef} isPortrait={isPortrait} style={props.personalChatActive ? { width: '20rem' } : {}}>
			<SInputfieldIcon><SIcon icon={icons.lupa} style={{ backgroundColor: 'black' }} /></SInputfieldIcon>
			<SInputfield
				style={{ color: 'black' }}
				placeholder={`${language.getText('Buscar')}...`}
				value={state.search}
				onChange={e => setSearch(e.target.value)}
				dark
			/>
			<SCategory onClick={() => {
				if (!state.categoryMenuState) set(p => ({ ...p, categoryMenuState: true }))
			}
			}>
				<Text H5 center middle primary>{getCategoryName(`${state.category}Name`)}</Text>
				<SSmallArrow />
			</SCategory>

			<ResultsMenu
				active={state.search != '' && !catalog.active}
				results={state.results}
				viewAllHandler={state.category === 'products' ? viewAllHandler : null}
				loading={state.loading}
			/>

			<SCategoriesMenu ref={categoryRef} active={state.categoryMenuState}>
				<SBackground>
					<SArrow right='3rem' />
				</SBackground>

				{isEnabled('standsEnabled') && <Text bold primary={state.category === 'stands'} onClick={() => set(p => ({ ...p, categoryMenuState: false, category: 'stands' }))}>{getCategoryName('standsName')}</Text>}
				{isEnabled('productsEnabled') && <Text bold primary={state.category === 'products'} onClick={() => set(p => ({ ...p, categoryMenuState: false, category: 'products' }))}>{getCategoryName('productsName')}</Text>}
				{isEnabled('companiesEnabled') && <Text bold primary={state.category === 'companies'} onClick={() => set(p => ({ ...p, categoryMenuState: false, category: 'companies' }))}>{getCategoryName('companiesName')}</Text>}
			</SCategoriesMenu>
		</SSearchBar>
	)
}

const SCategoriesMenu = styled.div<{ active: boolean }>`
    position: absolute;

    width: 6rem;
    bottom: ${p => p.active ? '-1.2rem' : '-.7rem'};
    right: -1rem;
    opacity: ${p => p.active ? 1 : 0};
    pointer-events: ${p => p.active ? 'all' : 'none'};
    transform: translate(-.5rem, 100%);
    transition: .3s;

    display: grid;
    grid-template-rows: min-content auto;
    grid-gap: 1rem;
    padding: 1.5rem 1rem;
`

const SResultsMenu = styled.div<{ active: boolean, small: boolean }>`
    position: absolute;

    width: 23rem;
    bottom: ${p => p.active ? '-1.2rem' : '-.7rem'};
    left: .5rem;
    opacity: ${p => p.active ? 1 : 0};
    pointer-events: ${p => p.active ? 'all' : 'none'};
    transform: translate(-.5rem, 100%);
    transition: .3s;

    display: grid;
    grid-template-rows: min-content auto;
    padding: 1.5rem 1rem;

	${p => !p.small && css`
		grid-gap: 1rem;
		height: 25rem;
	`}
`

const SRow = styled.div`
	display: grid;
	grid-template-columns: auto min-content;
`

function ResultsMenu(props: { active: boolean, results: any[], viewAllHandler?(): void, loading: boolean }) {
	const language = useLanguage() as any

	return (
		<SResultsMenu active={props.active} small={props.results.length === 0}>
			<SBackground>
				<SArrow left='.6rem' />
			</SBackground>

			<SRow>
				{
					props.loading ? <Text bold H4 oneline>Buscando...</Text> :
						<Text bold H4 oneline>{props.results.length != 0 ? language.getText('RESULTADOS') : language.getText('No se encontraron resultados')}</Text>
				}
				{props.viewAllHandler && <Text onClick={() => props.viewAllHandler()} oneline H4 bold>Ver todos</Text>}
			</SRow>
			<SGridHolder>
				<SGrid style={{ gridGap: '.5rem' }}>
					{props.results}
				</SGrid>
			</SGridHolder>
		</SResultsMenu>
	)
}

const SResultItem = styled.div`
    position: relative;
    background-color: ${p => p.theme.light};
    border-radius: ${p => p.theme.roundCorners};
    height: 7rem;
    padding: .5rem;

    display: grid;
    grid-template-columns: 1.3fr 2fr;
    grid-gap: .5rem;
	overflow: hidden;
`

const SResultItemText = styled.div`
    display: grid;
    grid-gap: .3rem;
    height: 100%;
	overflow: auto;
`

const SResultImage = styled.div`
    background-color: ${p => p.theme.darkerLight};
`

function ResultItem(props: { image?: string, name: string, subName: string, price?: string, companyName?: string, button: string, onClick(): void }) {
	return (
		<SResultItem>
			<SResultImage>
				<Image contain url={props.image} />
			</SResultImage>
			<SResultItemText>
				<Text subtext={props.price} H4={!props.price} bold dark>{props.name ?? ''}</Text>
				<Text dark>{props.subName ?? ''}</Text>
				<Text H3 bold dark>{props.companyName ?? ''}</Text>
				<Text H3 bold dark>{props.price ?? ''}</Text>
			</SResultItemText>
			<Button
				small
				onClick={() => props.onClick()}
				style={{
					height: '2rem',
					position: 'absolute',
					bottom: '.5rem',
					right: '.5rem'
				}}
			>{props.button}</Button>
		</SResultItem>
	)
}
