import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Text, Divider } from '../components/UI'
import { useArea, useIsMobile, useIsPortrait, useStore, useLanguage } from '../hooks'
import { ScreenLoading } from '../components/Loading';
import { getUsers as getUsersFromFirebase, getAllPackages, getAllHotels } from '../store';

const Container = styled.div`
    position: relative;
    height: ${p => p.isPortrait ? '40vh' : '100vh'};
	padding-top: ${p => (p.isPortrait || p.isMobile) ? ' 11.5vh' : '0'};
`

export default function EventCalendar() {
	const isPortrait = useIsPortrait()
	const isMobile = useIsMobile();
	const components = useArea('eventCalendar')
	const language = useLanguage();
	const [store, dispatch] = useStore();
    const [loadState, setLoadState] = useState(false);

	async function retreiveHotels() {
		await getAllHotels(dispatch);
	}

	async function retreiveEvents() {
		await getAllPackages(dispatch);
	}

	async function retreiveUsers() {
		await getUsersFromFirebase(dispatch, store?.configurations?.enableChatForUnregisteredUsers);
	}

    useEffect(() => {
        if(store?.configurations && !loadState){
            setLoadState(true);
        }
    }, [loadState, store?.configurations]);

	useEffect(() => {

		if(	loadState ){
			retreiveEvents();
			retreiveHotels();

			if(
                !store?.users || (store?.users && Object.keys(store.users).length < 2)
            ){
                retreiveUsers();
            }
		}
		
		return () => {};
	}, [loadState]);

	return loadState && store?.packages ? (
		<React.Fragment key={Date.now()} >
			<Container className="EventCalendar_Container" isPortrait={isPortrait}>
				{ (isPortrait || isMobile) && (
					<>
						<Text center bold H12 style={{height: 'auto'}} >
							{language.getText('EV_AREA_NAME')}
						</Text>
						<Divider primary width='90vw'/>
					</>
				)}
				{components}
			</Container>
		</React.Fragment>
	) : (<ScreenLoading />);
}