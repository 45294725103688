import React, { useContext, useRef } from 'react'
import styled from 'styled-components'

import { useIsPortrait, useOutsideAlerter } from '../hooks'
import { Text, SVG } from './UI'
import profileIcon from '../graphics/icons/profile.svg'
import Auth from '../context/AuthContext'

const Container = styled.div`
	position: relative;
    background-color: ${p => p.theme.primary};
    cursor: pointer;
    :hover {
        background-color: ${p => p.theme.primaryHover};
    }

    display: grid;
    grid-gap: 1rem;
    padding-left: ${p => p.isPortrait ? 0 : '.5rem'};
    grid-template-columns: ${p => p.isPortrait ? '2.5rem' : 'auto 2.5rem'};
    pointer-events: all;
	border-radius: ${p => p.theme.roundCorners};
`

export default function ProfileButton(props) {
	const auth = useContext(Auth)
	const isPortrait = useIsPortrait()
	const ref = useRef()

	useOutsideAlerter(ref, () => props.profileHandleClick(false))

	const clickHandler = () => {
		if (auth.user) props.profileHandleClick(!props.active)
		else props.loginHandler()
	}


	return (
		<Container isPortrait={isPortrait} onClick={() => clickHandler()} ref={ref}>
			{!isPortrait && <Text middle bold H2 oneline>{auth.user ? auth.user.name : 'Ingresar'}</Text>}
			<SVG style={{ width: '60%', height: '60%', margin: 'auto' }} image={profileIcon} />

			{props.children}
		</Container>
	)
}
