import React, { useState } from 'react'
import styled from 'styled-components'
import { useUrl } from '../hooks'
import { useHistory } from 'react-router-dom'

import { Image, FadeInUp } from './UI'

const Container = styled.div`
    height: 100%;
    width: 60%;
    margin: auto;

    cursor: pointer;
    transition: ${p => p.theme.transitionDuration};

    :hover {
        transform: scale(1.1) translateY(-.4rem);
    }

    border-radius: 50%;

    * {
        height: 100%;
    }
`

export default function Stand(props) {
    const history = useHistory()
    const icon = useUrl(props.stand.icon)
    const delay = (props.key + 1) * 40

    const clickHandler = () => {
        if (!props.pavillion.id || !props.stand.id) return
        history.push(`/stand?pavillionId=${props.pavillion.id}&id=${props.stand.id}`)
    }

    return (
        <Container onMouseEnter={() => props.mouseEnter(props.stand)} onMouseLeave={props.mouseLeave} onClick={clickHandler}>
            <FadeInUp delay={delay}>
                <Image contain image={icon}/>
            </FadeInUp>
        </Container>
    )
}