import { sendResetPasswordEmail } from '../firebase/auth'
import { firebaseConfig } from '../context/Brand'

const fetch = require('node-fetch')

const sendEmailCloudFunction = `https://us-central1-${firebaseConfig.projectId}.cloudfunctions.net/sendEmail`
const treeviewCORS = 'https://treeview-cors.herokuapp.com/'


// data: { subject: string, text?: string, html?: string, picture?: url, from?: string, pass?: string}
export const sendEmail = (emails, data) => new Promise(async (resolve, reject) => {
    if (emails.length === 0) return

    let to = emails[0]
    if (emails.length > 1) emails.forEach((e, i) => { if (i != 0) to += `, ${e}` })

    const body = {
        to: to,
        subject: data.subject,
        text: data.text,
        html: data.html,
        picture: data.picture,
        from: data.from,
        pass: data.pass
    }

    fetch(sendEmailCloudFunction, {
        method: 'post',
        body: JSON.stringify(body),
        headers: { 'Content-Type': 'application/json' },
    })
    .then(res => res.json())
    .then(json => { 
        console.log(json)
        resolve(json)})
    .catch(error => {
        console.log(error)
        reject(error)
    })
})

export function deleteUser(id) {
    fetch(`https://us-central1-${firebaseConfig.projectId}.cloudfunctions.net/deleteuser`, {
        method: 'POST',
        body: JSON.stringify({id: id}),
        headers: { 'Content-Type': 'application/json' }
    })
}

export const formatedDate = (day, month, year) => {
    return (day < 10 ? '0' + day : day) + '/' + (month < 10 ? '0' + month : month) + '/' + year;
};

export const parseFromObjectDate = (dateValue) => {
    const year = dateValue.getFullYear();
    let month = dateValue.getMonth() + 1; // Months start at 0!
    let day = dateValue.getDate();
    return formatedDate(day, month, year);
};