import React, { useState, useEffect } from 'react'
import styled, { css } from 'styled-components'
import ReactHtmlParser from 'react-html-parser'

import { useNotifications, useStore, useAuth } from '../../hooks'
import { Text, SVG, InputField, Button, UploadFile, Image } from '../UI'
import registerIcon from '../../graphics/icons/team.svg'
import menuIcon from '../../graphics/icons/burgerMenu.svg'
import chatIcon from '../../graphics/icons/chat.svg'
import loginIcon from '../../graphics/icons/privacy.svg'
import videoIcon from '../../graphics/icons/video.svg'
import analyticsIcon from '../../graphics/icons/line-chart.svg'
import bellIcon from '../../graphics/icons/bell.svg'

import closeIcon from '../../graphics/icons/close.svg'

import { uploadImage } from '../../firebase/storage'
import { key } from 'firebase-key'

const Container = styled.div`
    display: grid;
    grid-gap: 2rem;
    /* padding: 3rem 2rem; */
`

const Checkbox = styled.div`
    background-color: ${p => p.selected ? p.theme.primary : p.theme.light};
    border: .2rem solid ${p => p.theme.light};
    box-sizing: border-box;
    transition: .3s;
	cursor: pointer;
`

const ToggleRow = styled.div`
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: 2rem 2rem auto;
    height: 2rem;
    cursor: pointer;
    transition: .3s;
    :hover {
        transform: translateY(.2rem);
    }
`

const Line = styled.div`
    background-color: ${p => p.theme.light};
    height: .08rem;
    width: 100%;
`

const SWelcomeImage = styled.div`
    height: 20rem;
    width: 20rem;
    border: .3rem solid ${p => p.theme.light};
`

// const TABS = ['Opciones', 'Links', 'Textos', 'Branding', 'Formulario', 'Organizadores', 'Expositores']

export default function Configurations() {
	const [state, setState] = useState({})
	const [store, dispatch] = useStore()
	const [tab, setTab] = useState('Opciones')
	const notifications = useNotifications()
	const auth = useAuth()

	// Init objects if undefined.
	if (!store.configurations.otherLinks) store.configurations.otherLinks = {}
	if (!store.configurations.customTexts) store.configurations.customTexts = {}
	if (!store.configurations.limitations) store.configurations.limitations = {}

	// Save config to store.
	const save = config => dispatch({ type: 'set-configurations', payload: config })

	// Get available tabs for this user. Depending on permissions.
	function getTabs() {
		const isAdmin = auth.isAdmin()
		const isOrganizer = auth.isOrganizer()

		function isFeatureEnabled(feature) {
			if (!store?.configurations) return false
			return store.configurations[feature]
		}

		const tabs = []

		if (isAdmin || (isOrganizer && isFeatureEnabled('featureOptions'))) tabs.push('Opciones')
		if (isAdmin || (isOrganizer && isFeatureEnabled('featureLinks'))) tabs.push('Links')
		if (isAdmin || (isOrganizer && isFeatureEnabled('featureTexts'))) tabs.push('Textos')
		if (isAdmin || (isOrganizer && isFeatureEnabled('featureColors'))) tabs.push('Branding')
		if (isAdmin || (isOrganizer && isFeatureEnabled('featureForm'))) tabs.push('Formulario')
		if (isAdmin) tabs.push('Organizadores')
		if (isAdmin || (isOrganizer && isFeatureEnabled('featureExpositorConfig'))) tabs.push('Expositores')
		if (isAdmin || (isOrganizer && isFeatureEnabled('featureBuscador'))) tabs.push('Buscador')

		return tabs
	}

	const uploadIcon = async () => {
		const icon = state.iconImage
		setState({ ...state, iconImage: null, loading: true })
		try { await uploadImage(icon, 'logo.png') }
		catch (error) { notifications.notify(error) }
		setState({ ...state, loading: false })
	}

	// Configurations UI, toggles, inputfields, etc.
	function getContent() {
		if (tab === 'Expositores') return [
			<Text key='title2' H2 bold>Acceso de Expositores</Text>,
			<div key='div3' />,
			<ToggleRow key='jifaosdjf' onClick={() => save({ ...store.configurations, efeatureEditStandButton: !store.configurations.efeatureEditStandButton })}>
				<Checkbox selected={store.configurations.efeatureEditStandButton} />
				<SVG image={registerIcon} />
				<Text middle H2 bold>Habilitar Botón Editar Stand</Text>
			</ToggleRow>,
			<ToggleRow key='fffddfsswsws' onClick={() => save({ ...store.configurations, efeatureTrackUsers: !store.configurations.efeatureTrackUsers })}>
				<Checkbox selected={store.configurations.efeatureTrackUsers} />
				<SVG image={analyticsIcon} />
				<Text middle H2 bold>Mostrar usuarios conectados al Stand</Text>
			</ToggleRow>,
			<Text key='title' H2 bold>Acceso de Expositores en Panel de Control</Text>,
			<div key='div1' />,
			<ToggleRow key='fjiaosdfj' onClick={() => save({ ...store.configurations, efeatureStands: !store.configurations.efeatureStands })}>
				<Checkbox selected={store.configurations.efeatureStands} />
				<SVG image={menuIcon} />
				<Text middle H2 bold>Habilitar Stands</Text>
			</ToggleRow>,
			<ToggleRow key='jofiajon' onClick={() => save({ ...store.configurations, efeatureCompanies: !store.configurations.efeatureCompanies })}>
				<Checkbox selected={store.configurations.efeatureCompanies} />
				<SVG image={menuIcon} />
				<Text middle H2 bold>Habilitar Empresas</Text>
			</ToggleRow>,
			<ToggleRow key='fjaisodjfinb' onClick={() => save({ ...store.configurations, efeatureAnalytics: !store.configurations.efeatureAnalytics })}>
				<Checkbox selected={store.configurations.efeatureAnalytics} />
				<SVG image={registerIcon} />
				<Text middle H2 bold>Habilitar Estadisticas</Text>
			</ToggleRow>,
		]
		if (tab === 'Organizadores') return [
			<Text key='title2' H2 bold>Acceso de Organizadores</Text>,
			<div key='div3' />,
			<ToggleRow key='jfaosdifj' onClick={() => save({ ...store.configurations, featureEditAreaButton: !store.configurations.featureEditAreaButton })}>
				<Checkbox selected={store.configurations.featureEditAreaButton} />
				<SVG image={registerIcon} />
				<Text middle H2 bold>Habilitar Botón Editar Área</Text>
			</ToggleRow>,
			<ToggleRow key='jfaosdifj' onClick={() => save({ ...store.configurations, featureTrackUsers: !store.configurations.featureTrackUsers })}>
				<Checkbox selected={store.configurations.featureTrackUsers} />
				<SVG image={registerIcon} />
				<Text middle H2 bold>Mostrar usuarios conectados por area</Text>
			</ToggleRow>,
			<ToggleRow key='fjaiosdfj' onClick={() => save({ ...store.configurations, featureDeleteMessage: !store.configurations.featureDeleteMessage })}>
				<Checkbox selected={store.configurations.featureDeleteMessage} />
				<SVG image={registerIcon} />
				<Text middle H2 bold>Habilitar Borrar Mensajes</Text>
			</ToggleRow>,
			<ToggleRow key='deletusers' onClick={() => save({ ...store.configurations, featureDeleteUsers: !store.configurations.featureDeleteUsers })}>
				<Checkbox selected={store.configurations.featureDeleteUsers} />
				<SVG image={registerIcon} />
				<Text middle H2 bold>Habilitar Eliminar Usuarios</Text>
			</ToggleRow>,
			<ToggleRow key='downloadallusers' onClick={() => save({ ...store.configurations, featureDownloadAllUsers: !store.configurations.featureDownloadAllUsers })}>
				<Checkbox selected={store.configurations.featureDownloadAllUsers} />
				<SVG image={registerIcon} />
				<Text middle H2 bold>Habilitar Descargar tabla de usuarios</Text>
			</ToggleRow>,
			<ToggleRow key='gasbdfub' onClick={() => save({ ...store.configurations, featureEditStandButton: !store.configurations.featureEditStandButton })}>
				<Checkbox selected={store.configurations.featureEditStandButton} />
				<SVG image={registerIcon} />
				<Text middle H2 bold>Habilitar Botón Editar Stand</Text>
			</ToggleRow>,
			<ToggleRow key='sdijfoi' onClick={() => save({ ...store.configurations, featureEditPavillionButton: !store.configurations.featureEditPavillionButton })}>
				<Checkbox selected={store.configurations.featureEditPavillionButton} />
				<SVG image={registerIcon} />
				<Text middle H2 bold>Habilitar Botón Editar Pabellón</Text>
			</ToggleRow>,
			<ToggleRow key='jifaosdjf' onClick={() => save({ ...store.configurations, featureEditVideoRoomButton: !store.configurations.featureEditVideoRoomButton })}>
				<Checkbox selected={store.configurations.featureEditVideoRoomButton} />
				<SVG image={registerIcon} />
				<Text middle H2 bold>Habilitar Botón Editar Sala de Video</Text>
			</ToggleRow>,
			<Text key='title' H2 bold>Acceso de Organizadores en Panel de Control</Text>,
			<div key='div1' />,
			<ToggleRow key='fjasidjfaosdifjoij' onClick={() => save({ ...store.configurations, featureExpositorConfig: !store.configurations.featureExpositorConfig })}>
				<Checkbox selected={store.configurations.featureExpositorConfig} />
				<SVG image={registerIcon} />
				<Text middle H2 bold>Habilitar Control de Expositores</Text>
			</ToggleRow>,
			<ToggleRow key='fjaosidfjoasdijf' onClick={() => save({ ...store.configurations, featureUsers: !store.configurations.featureUsers })}>
				<Checkbox selected={store.configurations.featureUsers} />
				<SVG image={registerIcon} />
				<Text middle H2 bold>Habilitar Usuarios</Text>
			</ToggleRow>,
			<ToggleRow key='fjffffff' onClick={() => save({ ...store.configurations, featureAreas: !store.configurations.featureAreas })}>
				<Checkbox selected={store.configurations.featureAreas} />
				<SVG image={menuIcon} />
				<Text middle H2 bold>Habilitar Areas</Text>
			</ToggleRow>,
			<ToggleRow key='fjiaosdfj' onClick={() => save({ ...store.configurations, featureStands: !store.configurations.featureStands })}>
				<Checkbox selected={store.configurations.featureStands} />
				<SVG image={menuIcon} />
				<Text middle H2 bold>Habilitar Stands</Text>
			</ToggleRow>,
			<ToggleRow key='jofiajon' onClick={() => save({ ...store.configurations, featureCompanies: !store.configurations.featureCompanies })}>
				<Checkbox selected={store.configurations.featureCompanies} />
				<SVG image={menuIcon} />
				<Text middle H2 bold>Habilitar Empresas</Text>
			</ToggleRow>,
			<ToggleRow key='fjaisodjfinb' onClick={() => save({ ...store.configurations, featureAnalytics: !store.configurations.featureAnalytics })}>
				<Checkbox selected={store.configurations.featureAnalytics} />
				<SVG image={registerIcon} />
				<Text middle H2 bold>Habilitar Estadisticas</Text>
			</ToggleRow>,
			<ToggleRow key='fiaosdjf' onClick={() => save({ ...store.configurations, featureRanking: !store.configurations.featureRanking })}>
				<Checkbox selected={store.configurations.featureRanking} />
				<SVG image={menuIcon} />
				<Text middle H2 bold>Habilitar Ranking</Text>
			</ToggleRow>,
			<ToggleRow key='notifi' onClick={() => save({ ...store.configurations, featureNotifications: !store.configurations.featureNotifications })}>
				<Checkbox selected={store.configurations.featureNotifications} />
				<SVG image={bellIcon} />
				<Text middle H2 bold>Habilitar Notificaciones</Text>
			</ToggleRow>,
			<ToggleRow key='ofibefasid' onClick={() => save({ ...store.configurations, featureConfigurations: !store.configurations.featureConfigurations })}>
				<Checkbox selected={store.configurations.featureConfigurations} />
				<SVG image={menuIcon} />
				<Text middle H2 bold>Habilitar Configuración</Text>
			</ToggleRow>,
			<Text key='title1' H2 bold>Acceso de Organizadores en Configuración</Text>,
			<div key='div2' />,
			<ToggleRow key='fiaosbne' onClick={() => save({ ...store.configurations, featureOptions: !store.configurations.featureOptions })}>
				<Checkbox selected={store.configurations.featureOptions} />
				<SVG image={registerIcon} />
				<Text middle H2 bold>Habilitar Opciones</Text>
			</ToggleRow>,
			<ToggleRow key='fjaoisdfbe' onClick={() => save({ ...store.configurations, featureLinks: !store.configurations.featureLinks })}>
				<Checkbox selected={store.configurations.featureLinks} />
				<SVG image={menuIcon} />
				<Text middle H2 bold>Habilitar Links</Text>
			</ToggleRow>,
			<ToggleRow key='randomKey3' onClick={() => save({ ...store.configurations, featureTexts: !store.configurations.featureTexts })}>
				<Checkbox selected={store.configurations.featureTexts} />
				<SVG image={registerIcon} />
				<Text middle H2 bold>Habilitar Textos</Text>
			</ToggleRow>,
			<ToggleRow key='randomKey4' onClick={() => save({ ...store.configurations, featureColors: !store.configurations.featureColors })}>
				<Checkbox selected={store.configurations.featureColors} />
				<SVG image={menuIcon} />
				<Text middle H2 bold>Habilitar Colores</Text>
			</ToggleRow>,
			<ToggleRow key='randomKey5' onClick={() => save({ ...store.configurations, featureForm: !store.configurations.featureForm })}>
				<Checkbox selected={store.configurations.featureForm} />
				<SVG image={menuIcon} />
				<Text middle H2 bold>Habilitar Formulario</Text>
			</ToggleRow>,
		]
		if (tab === 'Opciones') return [
			<Text key='title' H2 bold>Habilitar/Deshabilitar</Text>,
			<div key='div1' />,
			<ToggleRow key='randomKey' onClick={() => save({ ...store.configurations, allowRegister: !store.configurations.allowRegister })}>
				<Checkbox selected={store.configurations.allowRegister} />
				<SVG image={registerIcon} />
				<Text middle H2 bold>Habilitar registro de nuevos usuarios</Text>
			</ToggleRow>,
			<ToggleRow key='fjdjdjflechitas' onClick={() => save({ ...store.configurations, hideStandArrows: !store.configurations.hideStandArrows })}>
				<Checkbox selected={!store.configurations.hideStandArrows} />
				<SVG image={registerIcon} />
				<Text middle H2 bold>Habilitar flechas de navegación de Stands</Text>
			</ToggleRow>,
			<ToggleRow key='fjisdjdiddiiii' onClick={() => save({ ...store.configurations, requireEmailVerification: !store.configurations.requireEmailVerification })}>
				<Checkbox selected={store.configurations.requireEmailVerification} />
				<SVG image={registerIcon} />
				<Text middle H2 bold>Requerir verificación de email</Text>
			</ToggleRow>,
			<ToggleRow key='langugekey' onClick={() => save({ ...store.configurations, allowEnglishVersion: !store.configurations.allowEnglishVersion })}>
				<Checkbox selected={store.configurations.allowEnglishVersion} />
				<SVG image={registerIcon} />
				<Text middle H2 bold>Habilitar Versión en Ingles</Text>
			</ToggleRow>,
			<ToggleRow key='randomKeyasdifj' onClick={() => save({ ...store.configurations, trackUsers: !store.configurations.trackUsers })}>
				<Checkbox selected={store.configurations.trackUsers} />
				<SVG image={analyticsIcon} />
				<Text middle H2 bold>Mostrar usuarios conectados por área</Text>
			</ToggleRow>,
			<ToggleRow key='jfiaososososo' onClick={() => save({ ...store.configurations, showGlobalSearch: !store.configurations.showGlobalSearch })}>
				<Checkbox selected={store.configurations.showGlobalSearch} />
				<SVG image={menuIcon} />
				<Text middle H2 bold>Mostrar buscador global</Text>
			</ToggleRow>,
			<ToggleRow key='googleLogin' onClick={() => save({ ...store.configurations, allowGoogleSignin: !store.configurations.allowGoogleSignin })}>
				<Checkbox selected={store.configurations.allowGoogleSignin} />
				<SVG image={registerIcon} />
				<Text middle H2 bold>Habilitar sesión con Google</Text>
			</ToggleRow>,
			<ToggleRow key='faceBook' onClick={() => save({ ...store.configurations, allowFacebookSignin: !store.configurations.allowFacebookSignin })}>
				<Checkbox selected={store.configurations.allowFacebookSignin} />
				<SVG image={registerIcon} />
				<Text middle H2 bold>Habilitar sesión con Facebook</Text>
			</ToggleRow>,
			<ToggleRow key='randomKeyb' onClick={() => save({ ...store.configurations, disableAnalytics: !store.configurations.disableAnalytics })}>
				<Checkbox selected={store.configurations.disableAnalytics} />
				<SVG image={analyticsIcon} />
				<Text middle H2 bold>Deshabilitar estadisticas</Text>
			</ToggleRow>,
			<ToggleRow key='randomKey2' onClick={() => save({ ...store.configurations, showMainMenuButton: !store.configurations.showMainMenuButton })}>
				<Checkbox selected={store.configurations.showMainMenuButton} />
				<SVG image={menuIcon} />
				<Text middle H2 bold>Mostrar botón de menu principal</Text>
			</ToggleRow>,
			<ToggleRow key='randomKey3a-1' onClick={() => save({ ...store.configurations, enableChatForUnregisteredUsers: !store.configurations.enableChatForUnregisteredUsers })}>
				<Checkbox selected={store.configurations.enableChatForUnregisteredUsers} />
				<SVG image={chatIcon} />
				<Text middle H2 bold>Habilitar chat para usuarios no logueados</Text>
			</ToggleRow>,
			<ToggleRow key='randomKey3' onClick={() => save({ ...store.configurations, showPersonalChatButton: !store.configurations.showPersonalChatButton })}>
				<Checkbox selected={store.configurations.showPersonalChatButton} />
				<SVG image={chatIcon} />
				<Text middle H2 bold>Chat de Visitantes solo por comunicación directa</Text>
			</ToggleRow>,
			<ToggleRow key='randomKey3a' onClick={() => save({ ...store.configurations, expositorsChatOnlyDirect: !store.configurations.expositorsChatOnlyDirect })}>
				<Checkbox selected={store.configurations.expositorsChatOnlyDirect} />
				<SVG image={chatIcon} />
				<Text middle H2 bold>Chat de Expositores solo por comunicación directa</Text>
			</ToggleRow>,
			<ToggleRow key='randomKey3b' onClick={() => save({ ...store.configurations, organizersChatOnlyDirect: !store.configurations.organizersChatOnlyDirect })}>
				<Checkbox selected={store.configurations.organizersChatOnlyDirect} />
				<SVG image={chatIcon} />
				<Text middle H2 bold>Chat de Organizadores solo por comunicación directa</Text>
			</ToggleRow>,
			<ToggleRow key='randomKey4' onClick={() => save({ ...store.configurations, forceLogin: !store.configurations.forceLogin })}>
				<Checkbox selected={store.configurations.forceLogin} />
				<SVG image={loginIcon} />
				<Text middle H2 bold>Forzar inicio de sesión</Text>
			</ToggleRow>,
			<ToggleRow key='randomKey5' onClick={() => save({ ...store.configurations, allowVideoCalls: !store.configurations.allowVideoCalls })}>
				<Checkbox selected={store.configurations.allowVideoCalls} />
				<SVG image={videoIcon} />
				<Text middle H2 bold>Habilitar Video Llamadas</Text>
			</ToggleRow>,
			<ToggleRow key='randomKey6' onClick={() => save({ ...store.configurations, chatOnlyWithAdmins: !store.configurations.chatOnlyWithAdmins })}>
				<Checkbox selected={store.configurations.chatOnlyWithAdmins} />
				<SVG image={chatIcon} />
				<Text middle H2 bold>Chat solo con Admins/Organizadores/Expositores</Text>
			</ToggleRow>,
			<ToggleRow key='randomKey7' onClick={() => save({ ...store.configurations, registerFirst: !store.configurations.registerFirst })}>
				<Checkbox selected={store.configurations.registerFirst} />
				<SVG image={loginIcon} />
				<Text middle H2 bold>Mostrar el registro primero</Text>
			</ToggleRow>,
			<ToggleRow key='randomKey8' onClick={() => save({ ...store.configurations, allowPrivateInfo: !store.configurations.allowPrivateInfo })}>
				<Checkbox selected={store.configurations.allowPrivateInfo} />
				<SVG image={loginIcon} />
				<Text middle H2 bold>Permitir perfil privado</Text>
			</ToggleRow>,
			<ToggleRow key='randomKey9' onClick={() => save({ ...store.configurations, allowHiddenInChat: !store.configurations.allowHiddenInChat })}>
				<Checkbox selected={store.configurations.allowHiddenInChat} />
				<SVG image={loginIcon} />
				<Text middle H2 bold>Permitir usuario oculto en chat</Text>
			</ToggleRow>,
			<ToggleRow key='randomKey10' onClick={() => save({ ...store.configurations, showPoweredBy: !store.configurations.showPoweredBy })}>
				<Checkbox selected={store.configurations.showPoweredBy} />
				<SVG image={loginIcon} />
				<Text middle H2 bold>Mostrar "Powered by"</Text>
			</ToggleRow>,
			<Line key='fjaosdifjline' />,
			<Text bold H2 key='limites'>Limites</Text>,
			<InputField
				key='fjifjjjjd'
				placeholder='Limitar Stands'
				value={store.configurations.limitations.stands}
				onChange={v => save({ ...store.configurations, limitations: { ...store.configurations.limitations, stands: v } })}
			/>,
			<InputField
				key='fjasdifj'
				placeholder='Limitar Areas'
				value={store.configurations.limitations.areas}
				onChange={v => save({ ...store.configurations, limitations: { ...store.configurations.limitations, areas: v } })}
			/>,
			<InputField
				key='fabsdfu'
				placeholder='Limitar Salas de Videos'
				value={store.configurations.limitations.videoRooms}
				onChange={v => save({ ...store.configurations, limitations: { ...store.configurations.limitations, videoRooms: v } })}
			/>,
			<InputField
				key='ohasdfi'
				placeholder='Limitar Pabellones'
				value={store.configurations.limitations.pavillions}
				onChange={v => save({ ...store.configurations, limitations: { ...store.configurations.limitations, pavillions: v } })}
			/>,
			<InputField
				key='hfauisduh'
				placeholder='Limitar Botones'
				value={store.configurations.limitations.buttons}
				onChange={v => save({ ...store.configurations, limitations: { ...store.configurations.limitations, buttons: v } })}
			/>,
			<InputField
				key='jfoifbasdu'
				placeholder='Limitar Paneles'
				value={store.configurations.limitations.views}
				onChange={v => save({ ...store.configurations, limitations: { ...store.configurations.limitations, views: v } })}
			/>,
		]

		if (tab === 'Links') return [
			<Text key='title' H2 bold>Otros: Links</Text>,
			<div key='div' />,
			<InputField
				key='randomKey'
				placeholder='Contacto'
				value={store.configurations.otherLinks.contact}
				onChange={v => save({ ...store.configurations, otherLinks: { ...store.configurations.otherLinks, contact: v } })}
			/>,
			<InputField
				key='randomKey2'
				placeholder='Soporte'
				value={store.configurations.otherLinks.support}
				onChange={v => save({ ...store.configurations, otherLinks: { ...store.configurations.otherLinks, support: v } })}
			/>,
			<InputField
				key='randomKey3'
				placeholder='Términos y Condiciones'
				value={store.configurations.otherLinks.termsAndConditions}
				onChange={v => save({ ...store.configurations, otherLinks: { ...store.configurations.otherLinks, termsAndConditions: v } })}
			/>,
			<InputField
				key='randomKey4'
				placeholder='Políticas de Privacidad'
				value={store.configurations.otherLinks.privacy}
				onChange={v => save({ ...store.configurations, otherLinks: { ...store.configurations.otherLinks, privacy: v } })}
			/>
		]

		if (tab === 'Textos') return [
			<Text key='title' H2 bold>Otros: Textos</Text>,
			<div key='div' />,

			<InputField
				key='randomKey'
				placeholder='Mensaje de pie Login/Register'
				value={store.configurations.customTexts.loginFoot}
				onChange={v => save({ ...store.configurations, customTexts: { ...store.configurations.customTexts, loginFoot: v } })}
			/>,

			<InputField
				key='randomKey2'
				placeholder='Mensaje de Bienvenida'
				value={store.configurations.customTexts.welcomeText}
				onChange={v => save({ ...store.configurations, customTexts: { ...store.configurations.customTexts, welcomeText: v } })}
			/>,

			<InputField
				key='randomKey3'
				placeholder='Mensaje de Registro'
				value={store.configurations.customTexts.registerText}
				onChange={v => save({ ...store.configurations, customTexts: { ...store.configurations.customTexts, registerText: v } })}
			/>,

			<InputField
				key='fjairandomfjs'
				placeholder='Título del panel de contraseña'
				value={store.configurations.customTexts.codePanelTitle}
				onChange={v => save({ ...store.configurations, customTexts: { ...store.configurations.customTexts, codePanelTitle: v } })}
			/>,
		]

		if (tab === 'Branding') return [
			<Text key='title' H2 bold>Colores</Text>,
			<div key='div' />,

			<InputField
				key='randomKey'
				placeholder='Primario'
				value={store.configurations.theme?.primary}
				onChange={v => save({ ...store.configurations, theme: { ...store.configurations.theme, primary: v } })}
			/>,

			<InputField
				key='randomKey3'
				placeholder='Primario seleccionado'
				value={store.configurations.theme?.primaryHover}
				onChange={v => save({ ...store.configurations, theme: { ...store.configurations.theme, primaryHover: v } })}
			/>,

			<InputField
				key='randomKey2'
				placeholder='Oscuro'
				value={store.configurations.theme?.dark}
				onChange={v => save({ ...store.configurations, theme: { ...store.configurations.theme, dark: v } })}
			/>,

			<InputField
				key='randomKey1'
				placeholder='Claro'
				value={store.configurations.theme?.light}
				onChange={v => save({ ...store.configurations, theme: { ...store.configurations.theme, light: v } })}
			/>,

			<Line key='line' />,

			<Text key='subir' H2 bold>Subir Icono</Text>,
			state.loading ? <Text key='a' H2 bold>Subiendo...</Text> : <UploadFile key='yoqu' style={{ height: '2rem', width: '15rem' }} fileHanlder={file => setState({ ...state, iconImage: file })}>Subir nueva imagen</UploadFile>,
			state.iconImage && <Button key='jfia' onClick={uploadIcon} style={{ height: '2rem', width: '15rem' }}>Subir</Button>,

			<Text key='faffff' H2 bold>Email de Bienvenida</Text>,
			<ToggleRow key='welcomeEmail' onClick={() => save({ ...store.configurations, sendWelcomeEmail: !store.configurations.sendWelcomeEmail })}>
				<Checkbox selected={store.configurations.sendWelcomeEmail} />
				<SVG image={registerIcon} />
				<Text middle H2 bold>Enviar email de Bienvenida</Text>
			</ToggleRow>,
			<InputField
				key='subject'
				placeholder='Asunto del email'
				value={store.configurations.welcomeEmailSubject}
				onChange={v => save({ ...store.configurations, welcomeEmailSubject: v })}
			/>,

			<Text key='emasfsdfildef' H2 bold>Definir Email de mensaje privado</Text>,
			<InputField
				key='emaildeffggfjjs'
				placeholder='example@gmail.com'
				value={store.configurations.cloudFunctionPrivateMessageEmail}
				onChange={v => save({ ...store.configurations, cloudFunctionPrivateMessageEmail: v })}
			/>,
			<InputField
				key='passwordfiosdefd'
				placeholder=''
				value={store.configurations.cloudFunctionPrivateMessageEmailPass}
				onChange={v => save({ ...store.configurations, cloudFunctionPrivateMessageEmailPass: v })}
			/>,

			<Text key='emaildef' H2 bold>Definir Email de Bienvenida</Text>,
			<InputField
				key='emaildeffs'
				placeholder='example@gmail.com'
				value={store.configurations.cloudFunctionSenderEmail}
				onChange={v => save({ ...store.configurations, cloudFunctionSenderEmail: v })}
			/>,
			<InputField
				key='passwordefd'
				placeholder=''
				value={store.configurations.cloudFunctionSenderEmailPass}
				onChange={v => save({ ...store.configurations, cloudFunctionSenderEmailPass: v })}
			/>,
			<Text key='fasdf' H3 bold onClick={() => window.open('https://myaccount.google.com/lesssecureapps')}>Habilitar cuenta gmail</Text>,

			<Text key='subirdfs' H2 bold>Definir HTML</Text>,
			<InputField
				key='welcomeemail'
				placeholder='<html> ... </html>'
				value={store.configurations.welcomeEmailHTML}
				onChange={v => save({ ...store.configurations, welcomeEmailHTML: v })}
			/>,
			<SWelcomeImage>{ReactHtmlParser(store.configurations.welcomeEmailHTML)}</SWelcomeImage>
		]

		if (tab === 'Formulario') {
			// Formulario, Strings.
			const list = [
				<Text key='title' H1 bold>Formulario</Text>,
				<Text key='title' H2>NOTA: puede editarse el orden de cómo aparecerán en el formulario, cambiando el número al lado del campo de texto. Cuanto menor el número, antes aparecerá el campo pedido al usuario.</Text>,
				<Text key='stringTitle' H2 bold>Textos</Text>,
			]

			if (store?.configurations?.userExtraInfo) {
				list.push(Object.keys(store.configurations.userExtraInfo)
					.filter(id => store.configurations.userExtraInfo[id].type === 'string' || !store.configurations.userExtraInfo[id].type)
					.map(id => <UserFormValue key={id} id={id} type='string' />))
			}

			list.push(<Button style={{ height: '2rem' }} onClick={() => {
				const id = key()
				if (!store.configurations.userExtraInfo) store.configurations.userExtraInfo = {}
				store.configurations.userExtraInfo[id] = { name: '', id: id, order: 1, required: true, type: 'string' }
				save(store.configurations)
			}}>Agregar Nuevo Texto</Button>)

			// Habilitar Link Adicional
			list.push(<>
				<div style={{display: 'inline-flex'}}>
				<ToggleRow key='additionalLinkToggle' onClick={() => {
					const id = 'additionalLink';
					if (!store.configurations.userExtraInfo) store.configurations.userExtraInfo = {}
					store.configurations.userExtraInfo[id] = { name: '', id: id, required: false, type: 'additionalLink' }

					save(store.configurations);

					save({ ...store.configurations, 
						additionalLinkEnable: !store?.configurations?.additionalLinkEnable})
				}
				}>
					<Checkbox selected={store.configurations?.additionalLinkEnable} />
					<SVG image={loginIcon} />
					<Text middle H2 bold>Habilitar Link Adicional</Text>
				</ToggleRow >
				</div>
			</>)
			
			if (store?.configurations?.additionalLinkEnable) {
				list.push(Object.keys(store.configurations.userExtraInfo)
					.filter(id => store.configurations.userExtraInfo[id].type === 'additionalLink')
					.map(id => <UserFormValue key={id} id={id} type='additionalLink' />))
			}

			// Toggles.
			list.push(<Text key='boolsTtiel' H2 bold>Toggles</Text>)

			if (store?.configurations?.userExtraInfo) {
				list.push(Object.keys(store.configurations.userExtraInfo)
					.filter(id => store.configurations.userExtraInfo[id].type === 'boolean')
					.map(id => <UserFormValue key={id} id={id} type='boolean' />))
			}

			list.push(<Button style={{ height: '2rem' }} onClick={() => {
				const id = key()
				if (!store.configurations.userExtraInfo) store.configurations.userExtraInfo = {}
				store.configurations.userExtraInfo[id] = { name: '', id: id, order: 1, required: false, type: 'boolean' }
				save(store.configurations)
			}}>Agregar Nuevo Toggle</Button>)

			// Multi dropdown.
			list.push(<Text key='multipleopciontitle' H2 bold>Multiple Opción</Text>)

			if (store?.configurations?.userExtraInfo) {
				list.push(Object.keys(store.configurations.userExtraInfo)
					.filter(id => store.configurations.userExtraInfo[id].type === 'dropdown')
					.map(id => <DropdownForm id={id} key={id} />))
			}

			list.push(<Button style={{ height: '2rem' }} onClick={() => {
				const id = key()
				if (!store.configurations.userExtraInfo) store.configurations.userExtraInfo = {}
				store.configurations.userExtraInfo[id] = { name: '', id: id, required: false, order: 1, type: 'dropdown' }
				save(store.configurations)
			}}>Agregar Nuevo Multiple Opción</Button>)

			// Palabras clave.
			list.push(<Text key='emmmultiopti' H2 bold>Palabras Clave</Text>)

			if (store?.configurations?.userKeyWords) {
				list.push(Object.keys(store.configurations.userKeyWords).map(id => <UserKeyWord key={id} id={id} />))
			}

			list.push(<Button style={{ height: '2rem' }} onClick={() => {
				const id = key()
				if (!store.configurations.userKeyWords) store.configurations.userKeyWords = {}
				store.configurations.userKeyWords[id] = { name: '', id: id, order: 1 }
				save(store.configurations)
			}}>Agregar Nuevo</Button>)

			return list
		}

		if (tab === 'Buscador') {
			const list = [
				<Text key='title' H2 bold>Configurar categorias</Text>,
				<div key='div' />,

				<InputField
					key='fajdidslk'
					placeholder='Stands'
					value={store.configurations?.globalSearchConfig?.standsName}
					onChange={v => save({ ...store.configurations, globalSearchConfig: { ...store.configurations.globalSearchConfig, standsName: v } })}
				/>,
				<ToggleRow key='jfijjjd' onClick={() => save({ ...store.configurations, globalSearchConfig: { ...store.configurations.globalSearchConfig, standsEnabled: !store?.configurations?.globalSearchConfig?.standsEnabled } })}>
					<Checkbox selected={store.configurations?.globalSearchConfig?.standsEnabled} />
					<SVG image={registerIcon} />
					<Text middle H2 bold>Habilitar Stands</Text>
				</ToggleRow >,

				<InputField
					key='jfsidjfijss'
					placeholder='Productos'
					value={store.configurations?.globalSearchConfig?.productsName}
					onChange={v => save({ ...store.configurations, globalSearchConfig: { ...store.configurations.globalSearchConfig, productsName: v } })}
				/>,
				<ToggleRow key='jifsssfl' onClick={() => save({ ...store.configurations, globalSearchConfig: { ...store.configurations.globalSearchConfig, productsEnabled: !store?.configurations?.globalSearchConfig?.productsEnabled } })}>
					<Checkbox selected={store.configurations?.globalSearchConfig?.productsEnabled} />
					<SVG image={registerIcon} />
					<Text middle H2 bold>Habilitar Products</Text>
				</ToggleRow >,

				<InputField
					key='jfissiii'
					placeholder='Empresas'
					value={store.configurations?.globalSearchConfig?.companiesName}
					onChange={v => save({ ...store.configurations, globalSearchConfig: { ...store.configurations.globalSearchConfig, companiesName: v } })}
				/>,
				<ToggleRow key='saaa' onClick={() => save({ ...store.configurations, globalSearchConfig: { ...store.configurations.globalSearchConfig, companiesEnabled: !store?.configurations?.globalSearchConfig?.companiesEnabled } })}>
					<Checkbox selected={store.configurations?.globalSearchConfig?.companiesEnabled} />
					<SVG image={registerIcon} />
					<Text middle H2 bold>Habilitar Empresas</Text>
				</ToggleRow >,
			]

			return list
		}
	}

	return (
		<Container>
			<Text H1 bold>Configuración</Text>
			<Tabs selected={tab} tabs={getTabs()} selectHandler={setTab} />
			{getContent()}
			<div /> <div /> <div /> <div />
		</Container>
	)
}

const SDropdownForm = styled.div`
	display: grid;
	grid-template-rows: 3rem 10rem;
`

const SGridHolder = styled.div`
	padding: 1rem;
	overflow: auto;
`

const SGrid = styled.div`
	display: grid;
	grid-gap: 1rem;
`

const SDropdownOption = styled.div`
	display: grid;
	grid-gap: 1rem;
	grid-template-columns: auto 3rem min-content;
`

function DropdownForm({ id }) {
	const [store, dispatch] = useStore()
	const [state, set] = useState({})

	useEffect(() => set(store.configurations.userExtraInfo[id] ?? { id }), [])

	// If value changes, save it.
	useEffect(() => {
		if (state.id) {
			store.configurations.userExtraInfo[id] = state
			save(store.configurations)
		}
	}, [state])

	// Save configurations to store.
	function save(config) {
		dispatch({ type: 'set-configurations', payload: config })
	}

	function deleteHandler() {
		delete store.configurations.userExtraInfo[id]
		save(store.configurations)
	}

	function getOptions() {
		if (!state.options) return []

		function editOptionValue(id, key, value) {
			set(p => ({
				...p,
				options: {
					...p.options,
					[id]: {
						...p.options[id],
						[key]: value
					}
				}
			}))
		}

		return Object.values(state.options)
			.map(o => <SDropdownOption key={o.id}>
				<InputField
					value={o.name}
					onChange={v => editOptionValue(o.id, 'name', v)}
					placeholder='Nombre...'
				/>
				<InputField
					value={o.order}
					onChange={v => editOptionValue(o.id, 'order', v)}
					placeholder='0'
				/>
				<Button onClick={() => set(p => ({ ...p, options: { ...p.options, [o.id]: {} } }))}>Eliminar</Button>
			</SDropdownOption>)
	}

	return (
		<SDropdownForm>
			<UserFormValueContainer isDropdown>
				<InputField
					value={state.name}
					onChange={v => set(p => ({ ...p, name: v }))}
					placeholder='Título...'
				/>
				<Text oneline H2 middle bold>Habilitar multi selección: </Text>
				<Checkbox selected={state.multi} onClick={() => set(p => ({ ...p, multi: !p.multi }))} />
				<InputField
					value={state.order}
					onChange={v => set(p => ({ ...p, order: v }))}
					placeholder='0'
				/>
				<Button onClick={() => {
					const newOptionId = key()
					set(p => ({ ...p, options: { ...p.options ?? {}, [newOptionId]: { id: newOptionId } } }))
				}}>Nueva</Button>
				<Button light={store.configurations.userExtraInfo[id].required} onClick={() => {
				store.configurations.userExtraInfo[id].required = !store.configurations.userExtraInfo[id].required
				save(store.configurations)
			}}>Obligatorio</Button>
				<Button onClick={() => deleteHandler()}>Eliminar</Button>
			</UserFormValueContainer>
			<SGridHolder>
				<SGrid>
					{getOptions()}
				</SGrid>
			</SGridHolder>
		</SDropdownForm>
	)
}

const UserFormValueContainer = styled.div`
    height: 2rem;
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: auto 3rem min-content min-content min-content;
	${p => p.isBoolean && css`
		grid-template-columns: auto 3rem min-content min-content;
	`}
	${p => p.isDropdown && css`
		grid-template-columns: auto min-content 2rem 3rem min-content min-content min-content;
	`}
	${p => p.isAdditionalLink && css`
		grid-template-columns: auto min-content;
	`}
`

function UserFormValue({ id, type }) {
	const [store, dispatch] = useStore()
	const [value, setValue] = useState('')
	const [order, setOrder] = useState('')

	const isBoolean = type === 'boolean'
	const isAdditionalLink = type === 'additionalLink';

	// Save configurations to store.
	function save(config) {
		dispatch({ type: 'set-configurations', payload: config })
	}

	// Init UI values if undefined.
	useEffect(() => {
		setValue(store.configurations.userExtraInfo[id].name || '')
		setOrder(store.configurations.userExtraInfo[id].order || 1)
	}, [])

	// If value or order changes, save it.
	useEffect(() => {
		store.configurations.userExtraInfo[id].name = value
		store.configurations.userExtraInfo[id].order = order
		save(store.configurations)
	}, [value, order])

	function deleteHandler() {
		delete store.configurations.userExtraInfo[id]
		save(store.configurations)
	}

	// Only one primary toggle can be active.
	function primaryHandler() {
		Object.keys(store.configurations.userExtraInfo).forEach(e => {
			if (e === id) store.configurations.userExtraInfo[e].primary = !store.configurations.userExtraInfo[e].primary
			else store.configurations.userExtraInfo[e].primary = false
		})
		save(store.configurations)
	}

	return (
		<UserFormValueContainer isBoolean={isBoolean} isAdditionalLink= {isAdditionalLink}>
			<InputField
				value={value}
				onChange={v => setValue(v)}
				placeholder='Nombre...'
			/>
			{!isAdditionalLink && <InputField
				value={order}
				onChange={v => setOrder(v)}
				placeholder='0'
			/> }

			<Button light={store.configurations.userExtraInfo[id].required} onClick={() => {
				store.configurations.userExtraInfo[id].required = !store.configurations.userExtraInfo[id].required
				save(store.configurations)
			}}>Obligatorio</Button>

			{(!isBoolean && !isAdditionalLink) && <Button light={store.configurations.userExtraInfo[id].primary} onClick={() => primaryHandler()}>Principal</Button>}

			{!isAdditionalLink && <Button onClick={() => deleteHandler()}>Eliminar</Button>} 
		</UserFormValueContainer>
	)
}

const SUserKeyWord = styled.div`
    height: 2rem;
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: auto 3rem min-content;
`

function UserKeyWord({ id }) {
	const [store, dispatch] = useStore()
	const [value, setValue] = useState('')
	const [order, setOrder] = useState('')

	// Save configurations to store.
	const save = config => dispatch({ type: 'set-configurations', payload: config })

	// Init UI values if undefined.
	useEffect(() => {
		setValue(store.configurations.userKeyWords[id].name || '')
		setOrder(store.configurations.userKeyWords[id].order || 1)
	}, [])

	// If value or order changes, save it.
	useEffect(() => {
		store.configurations.userKeyWords[id].name = value
		store.configurations.userKeyWords[id].order = order
		save(store.configurations)
	}, [value, order])

	function deleteHandler() {
		delete store.configurations.userKeyWords[id]
		save(store.configurations)
	}

	return (
		<SUserKeyWord>
			<InputField
				value={value}
				onChange={v => setValue(v)}
				placeholder='Nombre...'
			/>
			<InputField
				value={order}
				onChange={v => setOrder(v)}
				placeholder='0'
			/>
			<Button onClick={() => deleteHandler()}>Eliminar</Button>
		</SUserKeyWord>
	)
}

const TabContainer = styled.div`
    height: 2rem;
    display: grid;
    grid-auto-flow: column;
    grid-gap: 1rem;
`

const TabButton = styled.div`
    background-color: ${p => p.selected ? p.theme.primary : p.theme.dark};
    :hover { background-color: ${p => p.theme.primary}; }
    cursor: pointer;
`

function Tabs({ tabs, selected, selectHandler }) {
	return (
		<TabContainer>
			{tabs.map(t => <TabButton selected={t === selected} onClick={() => selectHandler(t)}><Text bold center middle>{t}</Text></TabButton>)}
		</TabContainer>
	)
}