import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useStore, useLanguage } from '../../hooks'
import List from 'react-virtualized/dist/commonjs/List'
import AutoSizer from 'react-virtualized/dist/commonjs/AutoSizer'
import { Text, Button, FadeInUp, InputField, IconButton, TextArea } from '../UI'
import closeIcon from '../../graphics/icons/arrow-left.svg'
import { addUpdatePackages} from '../../services/packages';
import DatePicker from 'react-date-picker';
import './DatePicker.css';

const CloseButton = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 2.5rem;
    height: 2.5rem;
`;

const ImageHolder = styled.div`
    width: 20rem;
    height: 12rem;
    border: .1rem solid ${p => p.theme.light};
`;

const EditContainer = styled.div`
    display: grid;
    grid-gap: 2rem;
`;

const WhiteListContainer = styled.div`
    width: 30rem;
    height: 20rem;
    overflow: auto;
    border: .1rem solid ${p => p.theme.light};
    padding: 1rem;
`;

const WhiteListItem = styled.div`
    display: grid;
    grid-template-columns: auto 1.5rem;
    grid-gap: 1rem;
    cursor: pointer;
`;

const Checkbox = styled.div`
    height: 100%;
    background-color: ${p => p.selected ? p.theme.primary : p.theme.light};
    border: .2rem solid ${p => p.theme.light};
    box-sizing: border-box;
`;

const SelectAllRow = styled.div`
    height: 2rem;
    width: 30rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1rem;
`;

const Event_Default = {
	id: null,
	name: '',
	location: '',
	departure_date: '',
	days: 0,
	hotels: [],
	description: '',
	flight_cost: 0,
    enabled: false,
	infant_cost: 0,
	flight_total: 0,
    flight_available: 0,
}

export default function EditEvent({type, event, backHandler}) {
	const [eventState, setEventState] = useState({
        ...Event_Default,
        ...event
    });
	const [store, dispatch] = useStore();
	const language = useLanguage();

	// Save event to store and local eveny.
	const saveEvent = () => {
		addUpdatePackages(eventState.id, eventState);
	}

	const deleteEvent = () => {
		// To do: add remove event/package method
		backHandler()
	}

	function isHotelSelected(hotelId) {
		return eventState.hotels.find((id) => id === hotelId);
	}

	function checkHotel(hotel) {

		if ( eventState.hotels.some( id => id === hotel.id ) ){
			const updatedHotels = eventState.hotels.filter(id => id !== hotel.id);
    		setEventState({ ...eventState, hotels: updatedHotels });
		} else {
			const updatedHotels = [...eventState.hotels, hotel.id];
		    setEventState({ ...eventState, hotels: updatedHotels });
		}
	}

	function getAllHotels() {

		if( !store?.hotels){
            //todo: retrieve hotels
            return [];
        }

		return Object.values(store.hotels).filter(u => u?.enabled);
	}

	const hotelsList = getAllHotels() 
        .map( h => 
            <WhiteListItem onClick={() => checkHotel(h)}>
                <Text middle H2 bold>{h.name}</Text>
                <Checkbox selected={isHotelSelected(h.id)} />
            </WhiteListItem>
        );

	const rowRenderer = ({ key, index, style }) => {

		return (
			<div key={key} style={style}>
				{hotelsList[index]}
			</div>
		)
	}

    const handleOnClose = () => {
        saveEvent();
        backHandler();
    };

	return eventState ? (
		<EditContainer>
			<CloseButton>
                <IconButton onClick={handleOnClose} image={closeIcon} />
            </CloseButton>

			<Text H1 bold>{(eventState.name ?? '')}</Text>

            <div style={{ width: '20rem' }}>
				<WhiteListItem >
                    <Text H2 bold>{language.getText('Nombre')}</Text>
	    	    	<InputField
		    	    	style={{ width: '20rem' }}
			    	    value={eventState.name}
		    		    onChange={v => setEventState({ ...eventState, name: v })}
			        />
                </WhiteListItem>
            </div>
			
			<div style={{ width: '20rem' }}>
				<WhiteListItem onClick={() => setEventState({ ...eventState, enabled: !eventState.enabled })}>
					<Text middle H2 bold>{language.getText('EV_ENABLED')}</Text>
					<Checkbox selected={eventState.enabled} />
				</WhiteListItem>
			</div>

            <div style={{ width: '20rem' }}>
				<WhiteListItem >
					<Text middle H2 bold>{language.getText('EV_LOCATION')}</Text>
					<InputField
				        style={{ width: '20rem' }}
	    			    value={eventState.location}
		    		    onChange={v => setEventState({ ...eventState, location: v })}
			        />
				</WhiteListItem>
			</div>

            <div style={{ width: '20rem' }}>
				<WhiteListItem >
					<Text middle H2 bold>{language.getText('EV_DEPARTURE_DATE')}</Text>
                    <div>
                        <DatePicker onChange={v => setEventState({ ...eventState, departure_date: v.toString() }) } value={eventState.departure_date} minDate={new Date()} />
                    </div>
				</WhiteListItem>
			</div>

            <div style={{ width: '20rem' }}>
				<WhiteListItem >
					<Text middle H2 bold>{language.getText('EV_DAYS')}</Text>
					<InputField
                        type="number"
		        		style={{ width: '20rem' }}
				        value={eventState.days}
			        	onChange={v => setEventState({ ...eventState, days: (v >= 1 ? v : 1) })}
			        />
				</WhiteListItem>
			</div>

            <div style={{ width: '20rem', height: '3rem', marginBottom: '10px' }}>
				<WhiteListItem >
					<Text middle H2 bold>{language.getText('EV_DESCRIPTION')}</Text>
                    <TextArea
	    				style={{height: '5rem', overflowY: 'auto', padding: '.5rem', border: 'none', outline: 'none', color: 'white', width: '20rem'}}
		    			placeholder={language.getText('EV_DESCRIPTION')}
			    		value={eventState.description}
				    	onChange={ v => setEventState({ ...eventState, description: v }) }
					    resize={false}
				    />
				</WhiteListItem>
			</div>

            <div style={{ width: '20rem' }}>
                <WhiteListItem >
					<Text middle bold>{language.getText('EV_FLIGHT_COST')}</Text>
                    <InputField
                        type="number"
		        		style={{ width: '10rem' }}
				        value={ (eventState.flight_cost)/100.0 }
						placeholder={"cost per passenger"}
			        	onChange={v => setEventState({ ...eventState, flight_cost: v >= 0 ? parseInt(v * 100) : 0 })}
			        />
				</WhiteListItem>
			</div>

			<div style={{ width: '20rem' }}>
                <WhiteListItem >
					<Text middle bold>{language.getText('EV_INFANT_COST')}</Text>
                    <InputField
                        type="number"
		        		style={{ width: '10rem' }}
				        value={ (eventState.infant_cost)/100.0 }
						placeholder={"cost per infant"}
			        	onChange={v => setEventState({ ...eventState, infant_cost: v >= 0 ? parseInt(v * 100) : 0 })}
			        />
				</WhiteListItem>
			</div>

            <div style={{ width: '20rem' }}>
				<WhiteListItem >
					<Text middle H2 bold>{language.getText('EV_STOCK')}</Text>
				</WhiteListItem>
			</div>

			<div style={{ width: '20rem' }}>
                <WhiteListItem >
					<Text middle bold>{language.getText('EV_FLIGHT_TOTAL')}</Text>
                    <InputField
                        type="number"
		        		style={{ width: '10rem' }}
				        value={ eventState.flight_total || 0}
						placeholder="0"
			        	onChange={v => 
							setEventState((prevState) => ({
								...prevState, 
								flight_total: v >= 0 ? parseInt(v) : 0,
								flight_available: v >= 0 ? ( 
									prevState.flight_available + parseInt(v) - parseInt(prevState.flight_total)
									) : prevState.flight_available,
							}))
						}
			        />
				</WhiteListItem>
			</div>

			<div style={{ width: '20rem' }}>
                <WhiteListItem >
					<Text middle bold>{language.getText('EV_FLIGHT_AVAILABLE')}</Text>
                    <InputField
                        type="number"
		        		style={{ width: '10rem' }}
				        value={ eventState.flight_available || 0 }
						placeholder="0"
			        	onChange={v => {}}
						readOnly
			        />
				</WhiteListItem>
			</div>

            <div style={{ width: '20rem' }}>
				<WhiteListItem >
					<Text middle H2 bold>{language.getText('EV_HOTELS')}</Text>
				</WhiteListItem>
			</div>

            <WhiteListContainer>
				<AutoSizer>
					{
						({ height, width }) => (
							<FadeInUp>
								<List
									height={height}
									rowCount={ hotelsList ? hotelsList.length : 0}
									rowHeight={64}
									rowRenderer={rowRenderer}
									width={width}
								/>
							</FadeInUp>
						)
					}
				</AutoSizer>
			</WhiteListContainer>

			{/* <Button onClick={editEventHandler} style={{ height: '2rem', width: '15rem' }}>Editar Área</Button> */}
            { <Button onClick={saveEvent} style={{ height: '2rem', width: '15rem' }}>{language.getText('EV_SAVE_EVENT')}</Button>}
            { <Button onClick={backHandler} style={{ height: '2rem', width: '15rem' }}>{language.getText('EV_CANCEL')}</Button>}
            {/* { <Button onClick={deleteEvent} style={{ height: '2rem', width: '15rem' }}>Eliminar Evento</Button>} */}


		</EditContainer >
	) : (<></>);
}