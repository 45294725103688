import React, { useState } from 'react'
import styled from 'styled-components'
import { useStore, useAuth, useLanguage } from '../../hooks'
import { key } from 'firebase-key'
import { useHistory } from 'react-router-dom'
import List from 'react-virtualized/dist/commonjs/List'
import AutoSizer from 'react-virtualized/dist/commonjs/AutoSizer'

import { Text, Button, Background, Image, FadeInUp, UploadFile, InputField, IconButton } from '../UI'
import PresentationImages from './PresentationImages'

import closeIcon from '../../graphics/icons/arrow-left.svg'
import { URLs } from '../../context/constants'

const LIST = 0
const SELECTED = 1

export default function Areas() {
	const [state, setState] = useState({
		menu: LIST, // List areas or selected view state.
		type: '', // Pavillions / Area / Video room.
		selected: null, // Selected area.
	})

	// Get List of areas or Edit area UI.
	const getContent = () => {
		if (state.menu === LIST) return <AreaList
			selectArea={(a, t) => setState({ ...state, selected: a, menu: SELECTED, type: t })} />
		if (state.menu === SELECTED)
			return <Edit
				type={state.type}
				area={state.selected}
				backHandler={() => {
					setState({ ...state, selected: null, menu: LIST })
				}}
			/>

	}

	return getContent()
}

const ListContainer = styled.div`
    padding: 2rem 1rem;
    display: grid;
    grid-gap: 2rem;
`

const Row = styled.div`
    display: grid;
    width: min-content;
    grid-gap: 1rem;
    grid-auto-flow: column;
    height: 3rem;
`

const AreaGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 15rem);
    grid-auto-rows: 9rem;
    grid-gap: 2rem;
`

function AreaList(props) {
	const [store, dispatch] = useStore()
	const language = useLanguage()

	// Create area functions.
	const createPavillionHandler = () => {
		// New area object.
		const newPavillion = {
			id: key(),
			name: 'Nuevo Pabellón',
		}

		// Dispatch new area to store.
		dispatch({ type: 'set-pavillion', payload: newPavillion })

		// Select new area for edit.
		props.selectArea(newPavillion, 'pavillion')
	}

	const createVideoRoomHandler = () => {
		const newVideoRoom = {
			id: key(),
			name: 'Nueva sala de video',
		}

		dispatch({ type: 'set-videoRoom', payload: newVideoRoom })

		props.selectArea(newVideoRoom, 'videoRoom')
	}

	const createAreaHandler = () => {
		const newArea = {
			id: key(),
			name: 'Nueva Área',
		}

		dispatch({ type: 'set-area', payload: newArea })

		props.selectArea(newArea, 'area')
	}

	// Check limitations.
	function hasExceeded(type, object) {
		if (!store?.configurations?.limitations) return false
		const max = store?.configurations?.limitations[type]
		if (!max || max === '') return false

		if (!object) return false

		const amount = Object.values(object).length

		return amount >= max
	}

	// Sort areas function.
	function sort(a, b) {
		const valueA = a?.order ? parseFloat(a.order) : 9999
		const valueB = b?.order ? parseFloat(b.order) : 9999

		return valueA > valueB ? 1 : -1
	}

	return (
		<FadeInUp>
			<ListContainer>
				<Row>
					<Text oneline bold H1>{language.getText('Áreas')}</Text>
					{hasExceeded('areas', store.areas) ? <Text middle oneline bold H2>{language.getText('Límite excedido')}</Text> : <Button onClick={createAreaHandler}>{language.getText('Agregar nueva Área')}</Button>}
				</Row>
				<AreaGrid> {store.areas && Object.values(store.areas).sort(sort).map(a => <AreaPreview onClick={() => props.selectArea(a, 'area')} area={a} />)} </AreaGrid>
				<Row>
					<Text oneline bold H1>{language.getText('Pabellones')}</Text>
					{hasExceeded('pavillions', store.pavillions) ? <Text middle oneline bold H2>{language.getText('Límite excedido')}</Text> : <Button onClick={createPavillionHandler}>{language.getText('Agregar nuevo Pabellón')}</Button>}
				</Row>
				<AreaGrid> {store.pavillions && Object.values(store.pavillions).sort(sort).map(p => <AreaPreview onClick={() => props.selectArea(p, 'pavillion')} area={p} />)} </AreaGrid>
				<Row>
					<Text oneline bold H1>{language.getText('Salas de video')}</Text>
					{hasExceeded('videoRooms', store.videoRooms) ? <Text middle oneline bold H2>{language.getText('Límite excedido')}</Text> : <Button onClick={createVideoRoomHandler}>{language.getText('Agregar nueva Sala de Video')}</Button>}
				</Row>
				<AreaGrid> {store.videoRooms && Object.values(store.videoRooms).sort(sort).map(v => <AreaPreview onClick={() => props.selectArea(v, 'videoRoom')} area={v} />)} </AreaGrid>
			</ListContainer>
		</FadeInUp>
	)
}

const AreaPreviewContainer = styled.div`
    display: grid;
    grid-template-rows: auto 2rem;
    cursor: pointer;
    transition: ${p => p.theme.transitionDuration};
    :hover {
        transform: translateY(.3rem);
    }
`

function AreaPreview(props) {
	// Area background image.
	const getBackground = () => props.area.backgrounds ? Object.values(props.area.backgrounds.images)[0] : props.area.background
	const content = props.area ? (
		<AreaPreviewContainer onClick={props.onClick}>
			<Background medium><Image contain url={getBackground()} /></Background>
			<Background><Text dark H2 bold center>{props.area.name}</Text></Background>
		</AreaPreviewContainer>
	) : null

	return content
}

const CloseButton = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 2.5rem;
    height: 2.5rem;
`

const ImageHolder = styled.div`
    width: 20rem;
    height: 12rem;
    border: .1rem solid ${p => p.theme.light};
`

const EditContainer = styled.div`
    display: grid;
    grid-gap: 2rem;
`

const WhiteListContainer = styled.div`
    width: 30rem;
    height: 20rem;
    overflow: auto;
    border: .1rem solid ${p => p.theme.light};
    padding: 1rem;
`

const WhiteListItem = styled.div`
    display: grid;
    grid-template-columns: auto 1.5rem;
    grid-gap: 1rem;
    cursor: pointer;
`

const Checkbox = styled.div`
    height: 100%;
    background-color: ${p => p.selected ? p.theme.primary : p.theme.light};
    border: .2rem solid ${p => p.theme.light};
    box-sizing: border-box;
`

const SelectAllRow = styled.div`
    height: 2rem;
    width: 30rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1rem;
`

function Edit(props) {
	const [area, setArea] = useState(props.area) // Selected area.
	const [store, dispatch] = useStore()
	const history = useHistory()
	const [state, set] = useState({
		userSearch: '', // Search value.
		onlyAdmins: false,
		onlyOrganizers: false,
		onlyExpositors: false,
		onlyVisitors: false
	})

	const language = useLanguage(); 
	const auth = useAuth()
	const isAdmin = auth.isAdmin()
	const isOrganizer = auth.isOrganizer()

	// Save area to store and local area.
	const saveArea = a => {
		dispatch({ type: `set-${props.type}`, payload: a })
		setArea(a)
	}

	// Delete area from store and go back to list UI.
	const deleteArea = () => {
		if (area.id === 'home') return
		dispatch({ type: `delete-${props.type}`, payload: area })
		props.backHandler()
	}

	// Go to edit area page.
	const editAreaHandler = () => {
		history.push(`${URLs.areaEditor}?type=${`${props.type}s&id=${area.id}`}`)
	}

	function isUserSelected(user) {
		if (!area.whitelist) return false
		return user?.id in area.whitelist
	}

	function isAreaSelected(child) {
		if (!area.childAreas) return false
		return child?.id in area.childAreas
	}

	// Toggle user from whitelist.
	function userClickHandler(user) {
		if (!area.whitelist) area.whitelist = {}

		if (user?.id in area.whitelist) delete area.whitelist[user?.id]
		else area.whitelist[user?.id] = user?.id

		saveArea(area)
	}

	// Toggle user from whitelist.
	function areaClickHandler(child) {
		if (!area.childAreas) area.childAreas = {}
		const orderChilds = Object.values(area.childAreas)
			.sort((a, b) => a?.order > b?.order ? 1 : -1);

		const childsKeys = Object.keys(orderChilds);
		const latestChild = orderChilds[childsKeys[childsKeys.length - 1]];
		const order = (latestChild?.order ? latestChild.order : 0) + 1;

		if (child?.id in area.childAreas) delete area.childAreas[child?.id]
		else area.childAreas[child?.id] = { id: child?.id, order }

		saveArea(area)
	}

	// Get users list, filtered by search.
	function getUsers() {
		if (!store.users) return []

		let list = Object.values(store.users)
			.filter(u => u.email && u.name)
			.filter(u => (u.email.toLowerCase().includes(state.userSearch.toLowerCase()) || u.name.toLowerCase().includes(state.userSearch.toLowerCase())))
			.filter(u => {
				if (!state.onlyAdmins && !state.onlyOrganizers && !state.onlyExpositors && !state.onlyVisitors) return true
				if (u.type === 'admin') return state.onlyAdmins
				if (u.type === 'organizer') return state.onlyOrganizers
				if (u.type === 'expositor') return state.onlyExpositors
				if (u.type === 'normal') return state.onlyVisitors
			})

		return list
	}

	function getAreas() {
		if (!store.areas) return []

		let list = Object.values(store.areas)
			.filter(u => u.id !== area?.id)

		return list
	}

	// Toggle all users (that this user's permission can access) whitelist state.
	function setAllUsers(value) {
		if (!area.whitelist) area.whitelist = {}

		getUsers().forEach(u => {
			if (value) area.whitelist[u.id] = u.id
			else if (u.id in area.whitelist) delete area.whitelist[u.id]
		})

		saveArea(area)
	}

	// Map users to UI item.
	const getList = () => {
		return getUsers().map(u => <WhiteListItem onClick={() => userClickHandler(u)}><Text middle H2 bold>{u.email}</Text><Checkbox selected={isUserSelected(u)} /></WhiteListItem>)
	}

	const getAreaList = () => {
		return getAreas().map(u => <WhiteListItem onClick={() => areaClickHandler(u)}><Text middle H2 bold>{u.name}</Text><Checkbox selected={isAreaSelected(u)} /></WhiteListItem>)
	}

	const list = getList();
	
	const areaList = getAreaList();

	const rowRenderer = ({ key, index, style }) => {
		return (
			<div key={key} style={style}>
				{list[index]}
			</div>
		)
	}

	const rowAreaRenderer = ({ key, index, style }) => {
		return (
			<div key={key} style={style}>
				{areaList[index]}
			</div>
		)
	}

	const pavillionSpecific = [
		<Text key='ct' H2 bold>{language.getText('Columnas de la grilla')}</Text>,
		<InputField
			key='ci'
			style={{ width: '20rem' }}
			value={area.gridColumns}
			placeholder={language.getText('Ejemplo: 5')}
			onChange={v => saveArea({ ...area, gridColumns: v })}
		/>,
		<Text key='rt' H2 bold>{language.getText('Filas de la grilla')}</Text>,
		<InputField
			key='ri'
			style={{ width: '20rem' }}
			value={area.gridRows}
			placeholder={language.getText('Ejemplo: 5')}
			onChange={v => saveArea({ ...area, gridRows: v })}
		/>,
	]

	return (
		<EditContainer>
			<CloseButton><IconButton onClick={props.backHandler} image={closeIcon} /></CloseButton>
			<Text H1 bold>{area.name}</Text>
			<Text H2 bold>{language.getText('Nombre')}</Text>
			<InputField
				style={{ width: '20rem' }}
				value={area.name}
				onChange={v => saveArea({ ...area, name: v })}
			/>
			<div style={{ width: '20rem' }}>
				<WhiteListItem onClick={() => saveArea({ ...area, visibleInMenu: !area.visibleInMenu })}>
					<Text middle H2 bold>{language.getText('Visible en el Menu')}</Text>
					<Checkbox selected={area.visibleInMenu} />
				</WhiteListItem>
			</div>

			<Text bold H2>{language.getText('Orden')}</Text>
			<InputField
				style={{ width: '20rem' }}
				placeholder='Ejemplos: 0 | 4 | .3'
				value={area.order}
				onChange={v => saveArea({ ...area, order: v })}
			/>

			<Text H2 bold>{language.getText('Código de ingreso')}</Text>
			<InputField
				style={{ width: '20rem' }}
				value={area.password}
				onChange={v => saveArea({ ...area, password: v })}
			/>

			{area.password && <Text H2 bold>Título del panel de ingreso</Text>}
			{area.password && <InputField
				style={{ width: '20rem' }}
				value={area.passwordTitle}
				onChange={v => saveArea({ ...area, passwordTitle: v })}
			/>}

			<Text H2 bold>Emails de usuarios con acceso</Text>
			<InputField
				style={{ width: '20rem' }}
				value={state.userSearch}
				onChange={v => set(prev => { return { ...prev, userSearch: v } })}
				placeholder='Buscar usuario'
			/>

			<div style={{ width: '20rem' }}>
				<WhiteListItem onClick={() => set(prev => { return { ...prev, onlyAdmins: !prev.onlyAdmins } })}>
					<Text middle H2 bold>Administradores</Text>
					<Checkbox selected={state.onlyAdmins} />
				</WhiteListItem>
			</div>
			<div style={{ width: '20rem' }}>
				<WhiteListItem onClick={() => set(prev => { return { ...prev, onlyOrganizers: !prev.onlyOrganizers } })}>
					<Text middle H2 bold>Organizadores</Text>
					<Checkbox selected={state.onlyOrganizers} />
				</WhiteListItem>
			</div>
			<div style={{ width: '20rem' }}>
				<WhiteListItem onClick={() => set(prev => { return { ...prev, onlyExpositors: !prev.onlyExpositors } })}>
					<Text middle H2 bold>Expositores</Text>
					<Checkbox selected={state.onlyExpositors} />
				</WhiteListItem>
			</div>
			<div style={{ width: '20rem' }}>
				<WhiteListItem onClick={() => set(prev => { return { ...prev, onlyVisitors: !prev.onlyVisitors } })}>
					<Text middle H2 bold>Visitantes</Text>
					<Checkbox selected={state.onlyVisitors} />
				</WhiteListItem>
			</div>

			<SelectAllRow>
				<Button onClick={() => setAllUsers(true)}>Seleccionar Todos</Button>
				<Button onClick={() => setAllUsers(false)}>Deseleccioanr Todos</Button>
			</SelectAllRow>

			<WhiteListContainer>
				<AutoSizer>
					{
						({ height, width }) => (
							<FadeInUp>
								<List
									height={height}
									rowCount={list ? list.length : 0}
									rowHeight={64}
									rowRenderer={rowRenderer}
									width={width}
								/>
							</FadeInUp>
						)
					}
				</AutoSizer>
			</WhiteListContainer>

			<Text bold H2>Áreas hijas</Text>
			<WhiteListContainer>
				<AutoSizer>
					{
						({ height, width }) => (
							<FadeInUp>
								<List
									height={height}
									rowCount={areaList ? areaList.length : 0}
									rowHeight={64}
									rowRenderer={rowAreaRenderer}
									width={width}
								/>
							</FadeInUp>
						)
					}
				</AutoSizer>
			</WhiteListContainer>
			<Text small>* Toda área hija añadida será mostrada en el menú como subitems dentro del área, independientemente de si es visible o no en el mismo.</Text>

			{props.type === 'pavillion' && pavillionSpecific}

			<WhiteListItem style={{ width: '20rem' }} onClick={() => saveArea({ ...area, isVideoBackground: !area.isVideoBackground })}>
				<Text middle H2 bold>Usar Video de Fondo</Text>
				<Checkbox selected={area.isVideoBackground} />
			</WhiteListItem>

			<WhiteListItem style={{ width: '20rem' }} onClick={() => saveArea({ ...area, isIFrameBackground: !area.isIFrameBackground })}>
				<Text middle H2 bold>Usar Iframe de fondo</Text>
				<Checkbox selected={area.isIFrameBackground} />
			</WhiteListItem>

			{!area.isIFrameBackground && <PresentationImages saveArea={saveArea} area={area} />}

			{area.isIFrameBackground && <Text bold H2>URL del fondo.</Text>}
			{area.isIFrameBackground && <InputField
				style={{ width: '20rem' }}
				placeholder='https://...'
				value={area.iFrameBackgroundUrl}
				onChange={v => saveArea({ ...area, iFrameBackgroundUrl: v })}
			/>}

			<Button onClick={editAreaHandler} style={{ height: '2rem', width: '15rem' }}>Editar Área</Button>
			{ (area.id != 'home' && area.id != 'eventCalendar') && <Button onClick={deleteArea} style={{ height: '2rem', width: '15rem' }}>Eliminar Área</Button>}
		</EditContainer >
	)
}