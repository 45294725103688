function ArrowDown(props) {
  return (
    <svg
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      {...props}
    >
      <path
        d="M80.56 55.28a65 65 0 00-48.94 10.29C2.87 85.35-5.14 127.76 14.69 156.45l187.1 273.07a65.41 65.41 0 00107.81-.06l187.31-273.07A65.37 65.37 0 00389 82.62L255.76 277 122.52 82.68a64.9 64.9 0 00-41.96-27.4z"
        fill={props.fill}
      />
    </svg>
  )
}

export default ArrowDown;
