const fetch = require('node-fetch')

const baseUrl: string = 'https://sheets.googleapis.com/v4/spreadsheets'
const key: string = 'AIzaSyDawj77irT3Pzx06KNt55OUPw2inqCIlt0'
const cors: string = 'https://treeview-cors.herokuapp.com'

export function getSpreadsheet(id: string, sheet: string): Promise<any> {
	return new Promise(async (resolve, reject) => {
		try {
			const response = await (await fetch(`${baseUrl}/${id}/values/${sheet}?key=${key}`)).json()
			resolve(response.values)
		} catch (error) { console.log(error) }
	})
}

export function getSpreadsheetSheets(id: string): Promise<string[]> {
	return new Promise(async (resolve, reject) => {
		try {
			const response = await (await fetch(`${baseUrl}/${id}?key=${key}`)).json()
			resolve((Object.values(response.sheets) as any[]).map(sheet => sheet.properties.title))
		} catch (error) { resolve([]) }
	})
}