import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { key } from 'firebase-key'

import { Button, Text, InputField } from '../UI'
import { useStore } from '../../hooks'
const notificationsPath = 'notification/notifications/'

const SNotifications = styled.div`
    display: grid;
    grid-gap: 1rem;
`

const SGridHolder = styled.div`
    height: 30rem;
    overflow: auto;
`

const SGrid = styled.div`
    display: grid;
    grid-gap: 1rem;
`

function getNotificationPath(id: string): string { return `${notificationsPath}${id}` }

export interface INotification {
	id: string,
	text: string,
	timestamp: string
}

export default function Notifications() {
	const [message, setMessage] = useState<string>('')
	const [store, dispatch] = useStore()

	// Get all online users
	let onlineUsers = Object.keys(store.chat);

	// Send new notification.
	function sendHandler(text: string): void {
		if (text === '') return

		const notification: INotification = {
			id: key(),
			text: text,
			timestamp: Date.now().toString()
		}

		// Set unread notification for each online user
		if (onlineUsers) {
			onlineUsers.forEach((userId) => {
				let data = {
					id : `${userId}/${notification.id}`,
					data : {read : false}
				}
				dispatch({ type: 'new-unread', payload: data })
			})
		}

		dispatch({ type: 'set-notification', payload: notification })

		setMessage('')
	}

	// Delete notification.
	function deleteNotification(id: string): void { 
		dispatch({ type: 'delete-notification', payload: { id: id } }) 

		const userIds = Object.keys(store.chat);
		
		if (userIds) {
			userIds.forEach((userId) => {
				let data = {
					id : `${userId}/${id}`,
					data : {read : true}
				}
				dispatch({ type: 'new-unread', payload: data })
			})
		}
	}

	// Resend notification (Creates a new one with same text, and deletes old one).
	function resendNotification(notification: INotification): void {
		sendHandler(notification.text)
		deleteNotification(notification.id)
	}

	return (
		<SNotifications>
			<Text H4 bold>Enviar nueva notificación</Text>
			<InputField
				placeholder='Mensaje de la notificación...'
				value={message}
				onChange={v => setMessage(v)}
				style={{ width: '20rem' }}
			/>
			<Button style={{ width: '20rem', height: '2rem' }} onClick={() => sendHandler(message)}>Enviar</Button>
			<div /> <div />
			<Text H4 bold>Notificaciones activas</Text>
			<SGridHolder>
				<SGrid>
					{
						(Object.values(store?.notifications) as INotification[]).reverse().map(n =>
							<NotificationTag
								key={n.id}
								notification={n}
								deleteHandler={() => deleteNotification(n.id)}
								resendHandler={() => resendNotification(n)}
							/>)
					}
				</SGrid>
			</SGridHolder>
		</SNotifications>
	)
}

const SNotificationTag = styled.div`
    border-radius: ${p => p.theme.roundCorners};
    background-color: ${p => p.theme.light};
    padding: 1rem;
    grid-gap: 1rem;
    width: 20rem;
    display: grid;
`

const SRow = styled.div`
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: 1fr 1fr;
`

function NotificationTag(props: { notification: INotification, deleteHandler(): void, resendHandler(): void }) {
	return (
		<SNotificationTag>
			<Text dark H5>{props.notification.text}</Text>
			<SRow>
				<Button onClick={() => props.deleteHandler()}>Eliminar</Button>
				<Button onClick={() => props.resendHandler()}>Reenviar</Button>
			</SRow>
		</SNotificationTag>
	)
}
