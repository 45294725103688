import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useStore, useLanguage } from '../../hooks'
import { key } from 'firebase-key'

import { Text, Button, Background, Image, FadeInUp, UploadFile, InputField, IconButton } from '../UI'

import { addUpdatePackages, getPackage, getPackages } from '../../services/packages';
import EditEvent from './EditEvent';

const Event_Default = {
	id: null,
	name: '',
	location: '',
	departure_date: '',
	days: 0,
	hotels: [],
	description: '',
	flight_cost: 0
}

export default function Event() {
	const [state, setState] = useState({
		menu: 'LIST', // List areas or selected view state.
		type: 'event', // Pavillions / Area / Video room.
		selected: null, // Selected area.
	})

	const selectEvent = (e) => setState({ ...state, selected: e, menu: 'SELECTED', type: 'event' });

	// Get List of events or Edit event UI.
	const getContent = () => {
		if (state.menu === 'LIST') {
			return (
				<EventList selectEvent={selectEvent} />
			);
		} else if (state.menu === 'SELECTED') {
			return (
				<EditEvent
					type={state.type}
					event={state.selected}
					backHandler={() => {
						setState({ ...state, selected: null, menu: 'LIST' })
					}}
				/>
			);
		} else {
			return (<></>);
		}
	};

	return getContent();
}

const ListContainer = styled.div`
    padding: 2rem 1rem;
    display: grid;
    grid-gap: 2rem;
`

const Row = styled.div`
    display: grid;
    width: min-content;
    grid-gap: 1rem;
    grid-auto-flow: column;
    height: 3rem;
`

const Grid = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 15rem);
    grid-auto-rows: 9rem;
    grid-gap: 2rem;
`

function EventList({ selectEvent }) {
	const [store, dispatch] = useStore()
	const language = useLanguage()

	const createEventHandler = async () => {
		const newEvent = {
			...Event_Default,
			id: key(),
			name: "Nuevo Bloqueo",
		}

        await addUpdatePackages(newEvent.id, newEvent);
		selectEvent(newEvent);
	}

	return (
		<FadeInUp>
			<ListContainer className="ListContainer" >
				<Row>
					<Text oneline bold H1>{language.getText('EV_EVENT')}</Text>
					{<Button onClick={createEventHandler}>{language.getText('EV_ADD_EVENT')}</Button>}
				</Row>
				<Grid> 
					{store?.packages && Object.values(store.packages).map(e => 
						<EventPreview onClick={() => selectEvent(e)} event={e} />
					)} 
				</Grid>

			</ListContainer>
		</FadeInUp>
	)
}

const EventPreviewContainer = styled.div`
    display: grid;
    grid-template-rows: auto 2rem;
    cursor: pointer;
    transition: ${p => p.theme.transitionDuration};
    :hover {
        transform: translateY(.3rem);
    }
`

function EventPreview({event, onClick}) {
	// Area background image.
	const getBackground = () => event.backgrounds ? Object.values(event.backgrounds.images)[0] : event.background
	const content = event ? (
		<EventPreviewContainer onClick={onClick}>
			<Background medium><Image contain url={getBackground()} /></Background>
			<Background><Text dark H2 bold center>{event.name}</Text></Background>
		</EventPreviewContainer>
	) : null

	return content
}