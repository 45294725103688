import React, { useState, useEffect } from 'react'
import Peer from 'peerjs'
import fetch from 'node-fetch'

import { useAuth, useNotifications, useStore } from '../hooks'
import { setContent, subscribeToContent } from '../firebase/database'

const VideoCall = React.createContext()

const getCallPath = id => `calls/${id}`
const getCallFlagPath = id => `call/${id}`

const getTwilioConfig = () => new Promise((resolve, reject) => {
    fetch('https://us-central1-treeview-vc.cloudfunctions.net/getTreeviewTwilio')
        .then(res => res.json())
        .then(json => resolve(json))
        .catch(error => console.log(error))
})

//reveiw this
const getPeer = () => new Promise(async (resolve, reject) => {
    const twilioConfig = await getTwilioConfig()

    // Init Peer connection.
    const peer = new Peer({
        secure: true,
        host: 'treeview-peer.herokuapp.com',
        port: 443,
        config: twilioConfig
    })

    function init(id) {
        alert(id);
        resolve(peer)
    }

    peer.on('open', init);

    peer.on('error', function(error) { 
        console.error(error);
        throw error;
    });
})

export function VideoCallContext(props) {
    const auth = useAuth()
    const notifications = useNotifications()
    const [store, ] = useStore();
    const [localUser, setLocalUser] = useState(undefined);

    useEffect(() => {
        if (window) {
            const local = window.localStorage.getItem(TempUser);
            if (local !== "undefined")
                setLocalUser(JSON.parse(local || '{}'));
        }
    }, [store]);

    
    const TempUser = 'TempUser';
    // const [localUser, setLocalUser] = useLocalStorage(TempUser, undefined);

    const [state, setState] = useState({
        active: false, // Panel state.
        connecting: false,
        setFirebaseCall(call) {
            // Configure the destination flags to marks that he's in a call
            setContent(getCallFlagPath(call.to), { onACall: true })
            setContent(getCallPath(call.to), call)

            // Configure the origin flags to marks that he's in a call
            setContent(getCallFlagPath(call.from), { onACall: true })
            setContent(getCallPath(call.from), call)
            setState(prev => { return { ...prev, firebaseCall: call } })
        },
        deleteFirebaseCall() {
            setState(prev => {
                // Configure the destination flags to marks that he has finished the call
                if (prev?.firebaseCall?.to) {
                    setContent(getCallPath(prev.firebaseCall.to), {})
                    setContent(getCallFlagPath(prev.firebaseCall.to), { onACall: false })
                }

                // Configure the origin flags to marks that he has finished the call
                if (prev?.firebaseCall?.from) {
                    setContent(getCallPath(prev.firebaseCall.from), {})
                    setContent(getCallFlagPath(prev.firebaseCall.from), { onACall: false })
                }

                return { ...prev, firebaseCall: {} }
            })
        },
        // peerJS call someone.
        async call(stream, onAnswerStream, from) {
            if (!this.target || !stream) {
                // Alert.
                notifications.notify('No hay stream disponible para video chat')
                setState(prev => { return { ...prev, active: false, isCalling: false } })
                return
            }

            window.localStream = stream

            setState(prev => { return { ...prev, isCalling: false, connecting: false } })

            const peer = await getPeer()

            this.setFirebaseCall({ from: from, to: this.target, ringing: true, peerJSFrom: peer.id })

            // When someone calls, answer it.
            peer.on('call', call => {
                call.answer(stream)
                call.on('stream', onAnswerStream)

                setState(prev => { return { ...prev, currentCall: call } })
            })
        },
        startCall(target) { setState({ ...this, active: true, target: target, isCalling: true, connecting: true }) }, // Start Call.
        startAnswer() {
            setState({ ...this, active: true, isAnswering: true, target: this.firebaseCall.from, connecting: true })
            if (this.firebaseCall) this.setFirebaseCall({ ...this.firebaseCall, ringing: false })
        },
        async answer(stream, onCallerStream) {
            window.localStream = stream

            if (!this.firebaseCall) return

            const peer = await getPeer()
            const call = peer.call(this.firebaseCall.peerJSFrom, stream)

            call.on('stream', onCallerStream)
            setState(prev => { return { ...prev, currentCall: call, isAnswering: false, connecting: false } })
            this.setFirebaseCall({ ...this.firebaseCall, peerJSTO: peer.id })
        },
        hangup() {
            if (this.currentCall) this.currentCall.close()
            setState({ ...this, active: false, currentCall: null })
            this.deleteFirebaseCall()

            try {
                window.localStream.getVideoTracks()[0].stop()
                window.localStream.getAudioTracks()[0].stop()
            } catch (error) { console.log('ERRORRRR', error) }
        }
    })

    // Subscribe to database call events.
    useEffect(() => {
        let cancelIncomingCallSubscription = undefined
        let cancelCurrentCallSubscription = undefined

        const callback = value => {
            if (JSON.stringify(state.firebaseCall) != JSON.stringify(value)) {
                if (value && (value.from === auth?.user?.id || value.from === localUser?.id || value.to === auth?.user?.id || value.to === localUser?.id)) {
                    setState(prev => { return { ...prev, firebaseCall: value } })
                } else setState(prev => { return { ...prev, firebaseCall: undefined } })
            }
        }

        if (state?.firebaseCall?.to) {
            cancelCurrentCallSubscription = subscribeToContent(getCallPath(state.firebaseCall.to), callback)
        }
        else {
            if (auth?.user?.id) {
                cancelIncomingCallSubscription = subscribeToContent(getCallPath(auth.user.id), callback)
            } else if (localUser?.id) {
                cancelIncomingCallSubscription = subscribeToContent(getCallPath(localUser.id), callback)
            }
        }

        function cleanup() {
            if (cancelIncomingCallSubscription) cancelIncomingCallSubscription()
            if (cancelCurrentCallSubscription) cancelCurrentCallSubscription()
        }

        return cleanup
    }, [auth, state.firebaseCall, localUser])

    return <VideoCall.Provider value={[state, setState]}>{props.children}</VideoCall.Provider>
}

export default VideoCall