import React, { useState, useEffect } from "react";
import styled from "styled-components";

const DropDownContent = styled.div`
  display: none;
  position: absolute;
  background-color: ${p => p.theme.light};
  width: 100%;
  z-index: 1;
  bottom: 0;
  left: 0;
  transform: translateY(100%);
`;

const Item = styled.div`
  color: ${p => p.theme.dark};
  padding: .5rem 1rem;
  font-weight: bold;
  &:hover {
      color: ${p => p.theme.primary};
  }
  cursor: pointer;
`;

const Container = styled.div`
    position: relative;
    border: .1rem solid ${p => p.theme.light};
    color: ${p => p.theme.light};
    padding: .5rem 1rem;
    :hover {
        border-color: ${p => p.theme.primary};
        color: ${p => p.theme.primary};
    }
    &:hover ${DropDownContent} {
        display: block;
    }
`

export default function Dropdown(props) {
    const [selected, setSelected] = useState(props.selected)
    const clickHandler = o => {
        setSelected(o)
        if(props.onSelect) props.onSelect(o.id)
    }

    useEffect(() => {
        setSelected(props.selected)
    }, [props.selected])

    const placeholder = selected ? selected.name : props.placeholder
    const options = props.options ? props.options.map(o => <Item key={o.id} onClick={() => clickHandler(o)}>{o.name}</Item>) : []

    return (
        <Container style={props.style}>
            {placeholder}
            <DropDownContent>
                {options}
            </DropDownContent>
        </Container>
    )
}