import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useAuth, useParam } from '../hooks'
import { URLs } from '../context/constants'

import { setContent, onDisconnect, getContent, subscribeToContent } from '../firebase/database'

interface Dictionary<T> { [id: string]: T }

const TrackUser = React.createContext({})

function getPath(id: string): string {
	return `trackUsers/${id}`
}

interface IState {
	previousAreaId?: string,
	currentArea: Dictionary<string>,
}

export interface ITrackUser {
	getCount(): number,
	getEmails(): string[],
	getIds(): string[],
}

export function TrackUserContext(props) {
	const [state, set] = useState<IState>({
		currentArea: {}
	})
	const history = useHistory()
	const auth = useAuth()
	let areaId = useParam('id')
	if (!areaId) {
		if (history.location.pathname === URLs.controlPanel) areaId = 'control-panel'
		else areaId = 'home'
	}

	function addUserToArea(): void {
		setContent(`${getPath(areaId)}/${auth.user.id}`, auth.user.email)
	}

	function removeUserFromArea(id: string): void {
		setContent(`${getPath(id)}/${auth.user.id}`, {})
	}

	useEffect(() => {
		if (!auth?.user?.id) return

		// Check if there was a previous area, and clear user from it if true.
		if (state.previousAreaId) removeUserFromArea(state.previousAreaId)

		// Save new area as prevous area.
		set(p => ({ ...p, previousAreaId: areaId }))

		// Add user to tracking area.
		addUserToArea()

		let cancelSubscription = () => { }

		// Get current area database state.
		if (auth.user.type != 'normal') {
			//@ts-ignore
			cancelSubscription = subscribeToContent(getPath(areaId), currentArea => {
				set(p => ({ ...p, currentArea: currentArea ?? {} }))
			})
		}

		return () => { cancelSubscription() }
	}, [history.location, auth.user?.id, areaId])

	useEffect(() => {
		// Subscribe to onDisconnect callback, to remove user from area.
		if (state.previousAreaId && auth?.user?.id) onDisconnect(`${getPath(state.previousAreaId)}/${auth.user.id}`, {})
	}, [state.previousAreaId])

	const value: ITrackUser = {
		// Get current area connected users count.
		getCount: () => {
			return Object.values(state.currentArea).length
		},
		// Get current area connected users emails.
		getEmails: () => {
			return Object.values(state.currentArea)
		},
		// Get current area connected users emails.
		getIds: () => {
			return Object.keys(state.currentArea)
		}
	}

	return <TrackUser.Provider value={value}> {props.children} </ TrackUser.Provider>
}

export default TrackUser