import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

import { Image, Divider, Text } from '../components/UI'
import View from '../components/View'
import FloatingButton from '../components/FloatingButton'
import MobileArea from '../components/MobileArea'
import PresentationImages from '../components/PresentationImages'
import { 
	useIsMobile,
	useParam, 
	useStore,
	useIsPortrait, 
	useAuth, 
	useElementsManager, 
} from '../hooks'
import PasswordPanel from '../components/PasswordPanel'
import { logEvent } from '../firebase/analytics'
import { ScreenLoading } from '../components/Loading';
import { getUsers as getUsersFromFirebase, } from '../store';

const Container = styled.div`
    position: relative;
    height: ${p => p.isPortrait ? '40vh' : '100vh'};
	padding-top: ${p => (p.isPortrait || p.isMobile) ? ' 11.5vh' : '0'};
`

export default function VideoRoom() {
	const isPortrait = useIsPortrait()
	const isMobile = useIsMobile();
	const id = useParam('id')
	const [store, dispatch] = useStore()
	const [videoRoom, setVideoRoom] = useState({})
	const [components, setComponents] = useState([])
	const auth = useAuth()
	const elementsManager = useElementsManager()
	const [loadState, setLoadState] = useState(false);


	useEffect(() => {
		if (!store || !store.videoRooms || !store.videoRooms[id]) return
		let vr = store.videoRooms[id]
		setVideoRoom(vr)

		const c = []
		if (vr.backgrounds) c.push(<PresentationImages key='backgrounds' backgrounds={vr.backgrounds} />)
		if (vr.background) c.push(<Image fit={!isPortrait} cover={isPortrait} key='bg' url={vr.background} style={{ zIndex: '-1', position: 'relative', height: (isPortrait ? '90%' : '100%'), backgroundSize: (isPortrait ? 'contain' : '100% 100%') }} />)
		if (vr.password && !auth?.user?.universalAccess && (!vr.whitelist || !(auth?.user?.id in vr.whitelist))) c.push(<PasswordPanel password={vr.password} title={vr.passwordTitle} />)

		if (isPortrait) {
			if (vr.views || vr.buttons)
				c.push(<MobileArea
					views={elementsManager.state.panels ? vr.views : undefined}
					buttons={elementsManager.state.buttons ? vr.buttons : undefined} />
				)
		}
		else {
			if (vr.views && elementsManager.state.panels) c.push(Object.values(vr.views).map(view => <View key={view.id} view={view} />))
			if (vr.buttons && elementsManager.state.buttons) c.push(Object.values(vr.buttons).map(btn => <FloatingButton key={btn.id} button={btn} />))
		}
		setComponents(c)
	}, [store, elementsManager.state])

	useEffect(() => {
		if (videoRoom.name) logEvent(videoRoom.name)
	}, [videoRoom.id])

	async function retreiveUsers() {
		await getUsersFromFirebase(dispatch, store?.configurations?.enableChatForUnregisteredUsers);
	}

	useEffect(() => {
        if(store?.configurations && Object.keys(store?.configurations).length > 0 && !loadState){
            setLoadState(true);
        }
    }, [loadState, store?.configurations]);

	useEffect(() => {

		if(	loadState &&
			( !store?.users || (store?.users && Object.keys(store.users).length < 2) )
		){
			retreiveUsers();
		}
		
		return () => {};
	}, [loadState]);

	return loadState ? (
		<>
		<Container isPortrait={isPortrait} className="VideoRoom_Container">
				{ (isPortrait || isMobile) && (
					<>
						<Text center bold H12 style={{height: 'auto'}} >
							{ store?.videoRooms?.[id]?.name || 'Video Room' }
						</Text>
						<Divider primary width='90vw'/>
					</>
				)}
				{components}
			</Container>
		</>
	) : (<ScreenLoading />);
}