import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useStore, useAuth, useNotifications, useLanguage } from '../../hooks'

import { Text, InputField, Button, Image, SVG, UploadFile } from '../UI'
import { MultiDropdown } from '../NewUI'
import profileIcon from '../../graphics/icons/profile.svg'
import { uploadImage } from '../../firebase/storage'

const Container = styled.div`
    display: grid;
    grid-gap: 1rem;
`

const Line = styled.div`
    height: .05rem;
    background-color: ${p => p.theme.light};
    width: 22rem;
`

const ProfilePicture = styled.div`
    border-radius: 50%;
    height: 14rem;
    width: 14rem;
    background-color: ${p => p.theme.light};
    margin: 1rem 3rem; 
    overflow: hidden;
`

const ToggleRow = styled.div`
    display: grid;
    grid-template-columns: auto 1.5rem;
    grid-gap: 1rem;
    width: 20rem;
    cursor: pointer;
`

const Checkbox = styled.div`
    height: 100%;
    background-color: ${p => p.selected ? p.theme.primary : p.theme.light};
    border: .2rem solid ${p => p.theme.light};
    box-sizing: border-box;
`

export default function Profile(props) {
	const [store, dispatch] = useStore()
	const auth = useAuth()
	const [extraComponents, setExtraComponents] = useState([])
	const [editUser, setEditUser] = useState({})
	const [state, setState] = useState({})
	const notifications = useNotifications()
	const language = useLanguage()

	// Upload profile image.
	const uploadHandler = async () => {
		const profile = state.profileImage
		setState({ ...state, profileImage: null, loading: true })
		try {
			const id = await uploadImage(profile)
			saveUser({ ...editUser, profilePicture: id })
			setState({ ...state, loading: false })
		} catch (error) {
			setState({ ...state, loading: false })
			notifications.notify(error)
		}
	}

	const saveUser = u => {
		setEditUser(u)
		auth.editUser(u)
		dispatch({ type: 'set-user', payload: u })
	}

	// Set extra value of user.
	const setExtraValue = (value, id) => {
		setEditUser(prevUser => {
			let user = { ...prevUser }
			if (!user.extraInfo) user.extraInfo = {}
			user.extraInfo[id] = value
			saveUser(user)

			return user
		})
	}

	// Update local editable user from auth context.
	useEffect(() => {
		if (auth.user === null) return
		if (auth.user && !editUser.id || editUser.id != auth.user.id) setEditUser(auth.user)
	}, [auth])

	// Set extra info fields, sort them by order.
	useEffect(() => {
		if (store.configurations && store.configurations.userExtraInfo) {
			const components = []
			const extra = Object.values(store.configurations.userExtraInfo).sort((a, b) => {
				const av = a.order ? parseFloat(a.order) : Number.MAX_SAFE_INTEGER
				const bv = b.order ? parseFloat(b.order) : Number.MAX_SAFE_INTEGER
				return av > bv ? 1 : -1
			})

			for (const info of extra) {

				if (info.type === 'string' || !info.type) {
					components.push(<Text key={`${info.id} title`} H2 bold>{info.name} {info.required && <span style={{ color: 'red' }}>*</span>}</Text>)
					components.push(<InputField
						key={info.id}
						style={{ width: '20rem' }}
						value={editUser.extraInfo ? editUser.extraInfo[info.id] : ''}
						onChange={v => setExtraValue(v, info.id)}
					/>)
				}

				else if (info.type === 'boolean') components.push(
					<ToggleRow onClick={() => setExtraValue(!(editUser?.extraInfo[info.id] ? editUser.extraInfo[info.id] : false), info.id)}>
						<Text oneline middle H2 bold>{info.name}</Text>
						<Checkbox selected={editUser?.extraInfo ? editUser.extraInfo[info.id] : false} />
					</ToggleRow>)

				else if (info.type === 'dropdown' && info.options) components.push(
					<MultiDropdown
						singleOption={!info.multi}
						title={info.name}
						options={Object.values(info.options).reduce((obj, option) => ({
							...obj,
							[option.id]: {
								id: option.id,
								name: option.name,
								value: (editUser?.extraInfo && editUser?.extraInfo[info.id]) ? option.id in editUser?.extraInfo[info.id] : false
							}
						}), {})}
						clickHandler={id => {
							if (!editUser.extraInfo) editUser.extraInfo = {}
							if (!editUser.extraInfo[info.id]) editUser.extraInfo[info.id] = {}

							if (id in editUser.extraInfo[info.id]) delete editUser.extraInfo[info.id][id]
							else editUser.extraInfo[info.id][id] = id

							setExtraValue(editUser.extraInfo[info.id], info.id)
						}}
						style={{ width: '20rem' }}
					/>
				)
			}

			setExtraComponents(components)
		}
	}, [store.configurations, editUser])

	function userKeyWords() {
		if (!store?.configurations?.userKeyWords) return []

		if (!editUser.keyWords) editUser.keyWords = {}

		const options = {}

		// Get multi dropdown toggle options.
		Object.values(store.configurations.userKeyWords).forEach(keyWord => {
			options[keyWord.id] = {
				id: keyWord.id,
				name: keyWord.name,
				value: keyWord.id in editUser.keyWords,
			}
		})

		// Toggle option on user data.
		function clickHandler(id) {
			if (id in editUser.keyWords) delete editUser.keyWords[id]
			else editUser.keyWords[id] = id

			saveUser({ ...editUser })
		}

		const list = [
			<Text H2 bold key='1'>Intereses</Text>,

			<MultiDropdown
				key='1.1'
				title='Selecciona tus intereses'
				options={options}
				clickHandler={id => clickHandler(id)}
				style={{ width: '20rem'}}
				collapseText={true}
			/>,

			<div key='1' />,
			<Line key='2' />,
			<div key='3' />,
		]

		return list
	}

	return (
		auth.user ?
			<Container>
				<Text H1 bold>{language.getText('Perfil')}</Text>
				<Line /> <div />
				<ProfilePicture>
					{editUser.profilePicture ?
						<Image url={editUser.profilePicture} /> :
						<SVG style={{ backgroundColor: 'gray', maskSize: '70%', webkitMaskSize: '70%' }} image={profileIcon} />}
				</ProfilePicture>
				<Text oneline bold H2>{language.getText('Cambiar Foto de Perfil')}</Text>
				{state.loading ? <Text bold H2>{language.getText('Subiendo')}...</Text> : <UploadFile style={{ height: '2rem', width: '15rem' }} fileHanlder={file => setState({ ...state, profileImage: file })}>{language.getText('Subir nueva imagen')}</UploadFile>}
				{state.profileImage && <Button onClick={uploadHandler} style={{ height: '2rem', width: '15rem' }}>{language.getText('Subir')}</Button>}
				<Text H2 bold>Email</Text>
				<Text H3 bold>{auth.user.email}</Text>

				<div /> <Line /> <div />
				{
					userKeyWords()
				}

				{store?.configurations?.allowPrivateInfo && <ToggleRow onClick={() => saveUser({ ...editUser, isPrivate: !editUser.isPrivate })}>
					<Text oneline middle H2 bold>{language.getText('Perfil Público')}</Text>
					<Checkbox selected={!editUser.isPrivate} />
				</ToggleRow>}

				{store?.configurations?.allowHiddenInChat && <ToggleRow onClick={() => saveUser({ ...editUser, isHiddenInChat: !editUser.isHiddenInChat })}>
					<Text oneline middle H2 bold>{language.getText('Perfil Visible en Chat')}</Text>
					<Checkbox selected={!editUser.isHiddenInChat} />
				</ToggleRow>}

				{store?.configurations?.allowEnglishVersion && <ToggleRow onClick={() => saveUser({ ...editUser, language: language.state.selected === 'english' ? 'spanish' : 'english' })}>
					<Text oneline middle H2 bold>{language.getText('Versión en Inglés')}</Text>
					<Checkbox selected={language.state.selected === 'english'} />
				</ToggleRow>}

				<div /> <Line /> <div />

				<Text H2 bold>{language.getText('Link Adicional')}</Text>
				<InputField
					style={{ width: '20rem' }}
					value={editUser.aditionalLink}
					onChange={v => saveUser({ ...editUser, aditionalLink: v })}
				/>

				<div /> <Line /> <div />

				<Text H2 bold>{language.getText('Nombre')}<span style={{ color: 'red' }}>*</span></Text>
				<InputField
					style={{ width: '20rem' }}
					value={editUser.name}
					onChange={v => saveUser({ ...editUser, name: v })}
				/>
				<Text H2 bold>{language.getText('Apellido')}<span style={{ color: 'red' }}>*</span></Text>
				<InputField
					style={{ width: '20rem' }}
					value={editUser.lastName}
					onChange={v => saveUser({ ...editUser, lastName: v })}
				/>

				{extraComponents}

				<div />
				{props.showCloseButton() &&
					<Button
						style={{ width: '20rem', height: '2rem' }}
						onClick={props.onSave}>{language.getText('Guardar')}</Button>}

				<div /> <div /> <div /> <div /> <div /> <div /> <div />

				<Button style={{ width: '20rem', height: '2rem' }} onClick={() => auth.logOut()}>{language.getText('Cerrar Sesión')}</Button>

				<div /> <div /> <div /> <div /> <div /> <div /> <div />
				<div /> <div /> <div /> <div /> <div /> <div /> <div />
			</Container>
			: <div />
	)
}