import React, { useRef } from 'react'
import useState from 'react-usestateref'
import styled, { css } from 'styled-components/macro'

import { SBackground } from '../login/'
import { useAwakeBool, useAuth, useStore, useOutsideAlerter, useIsPortrait } from '../../hooks'
import { uploadImage } from '../../firebase/storage'
import { Image, Text, Button, SVG } from '../UI'
import { CloseButton, IconButton } from '../NewUI'
import { SGridHolder } from '../topbar/Menu'
import FormView from '../login/FormView'
import icons from '../../graphics/icons'
import Loading from '../Loading'
import Linkify from 'react-linkify'
import html2canvas from 'html2canvas'

import { useHistory } from 'react-router-dom'

const SProfile = styled.div<{ show: boolean, isPortrait }>`
    position: relative;
    padding: 1.5rem 2.5rem;
    background-color: ${p => p.theme.light};
    border-radius: ${p => p.theme.roundCorners};
    width: min-content;
    height: min-content;
    min-width: 20rem;
	z-index: 100;

    transition: .3s;

	${p => p.smallWidth && css`
		@media screen and (max-width: 330px) {
    		padding: 1.5rem 1.5rem;
		}
	`}

	${p => p.isPortrait && css`
		width: 90%;
		height: 95%;
		min-width: 0;
		box-sizing: border-box;
	`}

    ${p => !p.show && css`
        opacity: 0;    
        transform: translateY(1rem);
    `}
`

const SGrid = styled.div`
    display: grid;
    grid-gap: .5rem;
	overflow: visible;
	justify-content: center;
`

const SImage = styled.div<{ isPersonalProfile: boolean }>`
    border-radius: 50%;
    overflow: hidden;
    background-color: ${p => p.theme.darkerLight};
    width: 8rem;
    height: 8rem;
    margin: auto;
`

const SRow = styled.div`
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-gap: ${p => p.theme.gridGap};
`

const SButtons = styled.div<{ isPortrait: boolean }>`
    display: grid;
	${p => p.isPortrait ? css`
		grid-template-columns: repeat(3, auto);
	` : css`
		grid-auto-flow: column;
	`}
    grid-gap: .5rem;
`

const SButtonsHolder = styled.div`
    display: grid;
    place-items: center;
    height: min-content;
`

interface IState {
	view: 'overview' | 'more-info' | 'edit',
	isLoading: boolean,
	aditionalLink: boolean,
	screenshot: boolean,
}

const SAditionalLink = styled.div`
	position: fixed;
    height: 90vh;
    width: 95vw;

	background-color: ${p => p.theme.light};
	padding: 2rem;
	box-sizing: border-box;
	border-radius: ${p => p.theme.roundCorners};
	z-index: 6;
`

const SIconHolder = styled.div`
    display: ${p => p.isPersonalProfile ? 'flex' : 'none'};
	justify-content: center;
	align-items: center;
	width: 2.5rem;
	height: 2.5rem;
	background-color: ${p => p.theme.primary};
	border-radius: 100%;
	position: absolute;
	bottom: -5%;
	right: 5%;
	border: .15rem solid white;
	z-index: 100;
	margin: 0 auto;

	${p => p.isPersonalProfile && css`
		cursor: pointer;
		:hover {
			background-color: ${p => p.theme.primaryHover}
		}
	`}
`

export default function Profile(props: { initialView: string, smallWidth: boolean, onClose(): void, user?: any, canVideoCall: boolean, videoCallHandler(): void, messageHandler(): void, emailHandler(): void }) {
	const awake = useAwakeBool()
	const auth = useAuth()
	const history = useHistory()
	const ref = useRef()
	const isPortrait = useIsPortrait()
	const hiddenFileInput = useRef() as any
	const [state, set, stateRef] = useState<IState>({
		view: props.initialView || 'overview',
		isLoading: false,
		aditionalLink: false,
		screenshot: false,
	})
	const [store, dispatch] = useStore()

	const isPersonalProfile: boolean = !props.user

	const user = props.user ?? auth.user

	const callUsers = Object.keys(store?.call || {});
	let onACall;
		
	if (user?.id) {
		// Validates if is a 1 to 1 chat first to set icons
		onACall = callUsers.find(id => id === user?.id);
	}
	
	useOutsideAlerter(ref, () => {
		if (!stateRef?.current?.aditionalLink) props.onClose()
	})

	async function uploadHandlerChange(event) {
		if (!event.target.files[0] || !user?.id) return

		set(p => ({ ...p, isLoading: true }))
		const file = event.target.files[0]
		const id = await uploadImage(file)
		saveUser({ ...user, profilePicture: id })
		set(p => ({ ...p, isLoading: false }))
	}

	function saveUser(user: any): void {
		auth.editUser(user)
		dispatch({ type: 'set-user', payload: user })
	}

	// Take screenshot of panel, and save it as VCard png.
	function getVCardHandler() {
		if (!ref.current) return

		const setScreenshot = value => set(p => ({ ...p, screenshot: value, view: value ? 'more-info' : 'overview' }))
		setScreenshot(true)

		setTimeout(() => {
			html2canvas(ref.current, { scale: 1 }).then(canvas => {
				const element = document.createElement("a")
				element.href = canvas.toDataURL()
				element.download = `${user.name || ''}-vCard.png`
				document.body.appendChild(element);
				element.click();
				setScreenshot(false)
			}).catch(e => setScreenshot(false))
		}, 10)
	}

	function getView() {
		if (state.view === 'overview') return (
			<SGrid className="ProfileView">
				{!state.isLoading ? 
				<div style={{display: 'block', position: 'relative', maxWidth: '8rem', margin: '0 auto'}}>
					<input type="file" ref={hiddenFileInput} onChange={uploadHandlerChange} style={{ display: 'none' }} />
					<SImage isPersonalProfile={isPersonalProfile} >
						{
							user?.profilePicture ? 
							<Image trackPicture={isPersonalProfile} url={user?.profilePicture} />
							: <SVG gray image={icons.profile} />
						}
					</SImage> 
					<SIconHolder isPersonalProfile={isPersonalProfile} onClick={isPersonalProfile ? () => hiddenFileInput?.current?.click() : () => { }}>
						<SVG style={{width: '1rem', height: '1rem'}} light image={icons.edit}/>
					</SIconHolder>
				</div>
				: <Loading small style={{ position: 'relative' }} />}


				<Text center H1 dark bold>{user?.name ?? ''} {user?.lastName ?? ''}</Text>
				<Text center medium H2>{user?.email}</Text>
				<div /> <div />
				{
					props.user &&
					<SButtonsHolder>
						<SButtons isPortrait={isPortrait}>
							{!onACall && props.canVideoCall && <IconButton icon={icons.camera} onClick={() => props.videoCallHandler()} primary />}
							{!onACall && <IconButton icon={icons.message} onClick={() => props.messageHandler()} primary />}
							<IconButton icon={icons.email} onClick={() => props.emailHandler()} primary />
							<IconButton icon={icons.info} onClick={() => set(p => ({ ...p, view: 'more-info' }))} primary />
							<IconButton icon={icons.downlaod} onClick={() => getVCardHandler()} primary />
							{user.aditionalLink && <IconButton icon={icons.calendar} onClick={() => set(p => ({ ...p, aditionalLink: true }))} primary />}
						</SButtons>
					</SButtonsHolder>
				}
				{isPersonalProfile && <Button onClick={() => set(p => ({ ...p, view: 'edit' }))}>Editar</Button>}
			</SGrid>
		)

		if (state.view === 'more-info') return <MoreInfo screenshot={state.screenshot} user={user} backHandler={() => set(p => ({ ...p, view: 'overview' }))} />

		if (state.view === 'edit') return <FormView isEdit confirmHandler={(name, lastName, extraInfo, aditionalLink) => {
			const u = { ...user, name, lastName, extraInfo, aditionalLink }
			saveUser(u)
			set(p => ({ ...p, view: 'overview' }))
		}} />
	}

	return (
		<SBackground className="Profile_SBackground" {...props}>
			<SProfile smallWidth={props.smallWidth} show={awake} ref={ref} isPortrait={isPortrait}>
				{!state.screenshot && <CloseButton
					onClick={() => props.onClose()}
				/>}
				{getView()}
			</SProfile>

			{state.aditionalLink && <SAditionalLink>
				<CloseButton
					onClick={() => set(p => ({ ...p, aditionalLink: false }))}
				/>
				<iframe src={user.aditionalLink} width='100%' height='100%' frameBorder={0} />
			</SAditionalLink>}

		</SBackground>
	)
}

const SMoreInfoTitle = styled.div`
	display: grid;
	grid-template-columns: min-content 2.5rem auto;
	grid-gap: ${p => p.theme.gridGap};
`

function MoreInfo(props: { backHandler(): void, user: any, screenshot: boolean }) {
	const [store] = useStore()

	function getRow(data: any, value: any) {

		function getValue(): string {
			if (data.type === 'dropdown') {
				const info = store?.configurations?.userExtraInfo[data.id]
				if (value && info)
					return (Object.values(value) as string[])
						.reduce((text: string, current: string) => text += `${info.options[current]?.name}\n`, '')

				return '-'
			}

			if (data.type === 'boolean') {
				return value ? 'Si' : 'No'
			}

			return value
		}

		return (
			<SRow key={data.name}>
				<Text H5 medium>{data.name}</Text>
				<Linkify>
					<Text H5 dark bold>{getValue()}</Text>
				</Linkify>
			</SRow>
		)
	}

	function getExtraInfo() {
		if (!store?.configurations?.userExtraInfo) return []

		const extra = (Object.values(store.configurations.userExtraInfo) as any[])
			.sort((a, b) => {
				const av = a.order ? parseFloat(a.order) : Number.MAX_SAFE_INTEGER
				const bv = b.order ? parseFloat(b.order) : Number.MAX_SAFE_INTEGER
				return av > bv ? 1 : -1
			})

		return extra.map(data => getRow(data, props.user?.extraInfo ? props.user?.extraInfo[data.id] : ''))
	}


	return (
		<SGrid style={{ gridGap: '2rem' }}>
			<SMoreInfoTitle>
				{!props.screenshot && <IconButton primary icon={icons.back} onClick={() => props.backHandler()} />}
				<SImage style={{ width: '2.5rem', height: '2.5rem' }}>
					{props.user?.profilePicture ? <Image url={props.user?.profilePicture} /> : <SVG gray image={icons.profile} />}
				</SImage>
				<Text oneline H2 dark bold middle>{props.user?.name ?? ''} {props.user?.lastName ?? ''}</Text>
			</SMoreInfoTitle>
			<SGridHolder style={{ height: '13rem', padding: '0 2rem' }}>
				<SGrid>
					{getExtraInfo()}
				</SGrid>
			</SGridHolder>
		</SGrid>
	)
}
