import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Text, Divider } from '../components/UI'
import { useArea, useIsMobile, useIsPortrait, useStore } from '../hooks'
import { getUsers as getUsersFromFirebase, } from '../store';
import { ScreenLoading } from '../components/Loading';

const Container = styled.div`
    position: relative;
    height: ${p => p.isPortrait ? '40vh' : '100vh'};
	padding-top: ${p => (p.isPortrait || p.isMobile) ? ' 11.5vh' : '0'};
`

export default function Home() {
	const isPortrait = useIsPortrait()
	const isMobile = useIsMobile();
	const components = useArea('home')
	const [store, dispatch] = useStore();
    const [loadState, setLoadState] = useState(false);

	async function retreiveUsers() {
		await getUsersFromFirebase(dispatch, store?.configurations?.enableChatForUnregisteredUsers);
	}

	useEffect(() => {
        if(store?.configurations && Object.keys(store?.configurations).length > 0 && !loadState){
            setLoadState(true);
        }
    }, [loadState, store?.configurations]);

	useEffect(() => {

		if(	loadState &&
			( !store?.users || (store?.users && Object.keys(store.users).length < 2) )
		){
			retreiveUsers();
		}
		
		return () => {};
	}, [loadState]);

	return loadState ? (
		<>
			<Container className="Home_Container" isPortrait={isPortrait}>
				{ (isPortrait || isMobile) && (
					<>
						<Text center bold H12 style={{height: 'auto'}} >
							Home
						</Text>
						<Divider primary width='90vw'/>
					</>
				)}
			{components}
			</Container>
		</>
	) : (<ScreenLoading />);
}