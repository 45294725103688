// firebase/index
export const firebaseConfig = {
  apiKey: "AIzaSyDcB1fmSlM-oNKZDaEbnrwWxN3TVzssNuw",
  authDomain: "oficina-travelway.firebaseapp.com",
  projectId: "oficina-travelway",
  storageBucket: "oficina-travelway.appspot.com",
  messagingSenderId: "850979845169",
  appId: "1:850979845169:web:7297c6a71b217ab0081110",
  measurementId: "G-RWNJD2M2X2"
}

// firebase/storage
export const storageUrl = 'https://firebasestorage.googleapis.com/v0/b/oficina-travelway.appspot.com/o/'