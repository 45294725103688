import React from 'react'
import styled, { css } from 'styled-components/macro'

import { useHistory } from 'react-router-dom'
import { SBackground, SArrow } from './SearchBar'
import { Text, TextIcon, Button } from '../UI'
import icons from '../../graphics/icons'
import { URLs } from '../../context/constants'
import { useAuth, useIsPortrait, useLanguage } from '../../hooks'

const SProfileMenu = styled.div<{ active: boolean, isPortrait: boolean }>`
    position: absolute;
    min-width: 20rem;
    bottom: ${p => p.active ? '-.7rem' : 0};
    right: .1rem;
    transform: translateY(100%);
    transition: .3s;
    opacity: ${p => p.active ? 1 : 0};
    pointer-events: ${p => p.active ? 'all' : 'none'};

    display: grid;
    grid-gap: ${p => p.theme.gridGap};
    padding: .6rem;
    padding-top: 1rem;

	${p => p.isPortrait && css`
		min-width: 0;
		right: -1rem;
	`}
`

export default function ProfileMenu(props: { active: boolean, close(): void, profileHandler(): void }) {
	const history = useHistory()
	const auth = useAuth()
	const language = useLanguage() as any
	const isPortrait = useIsPortrait()

	// Open control panel page and close menu.
	function openControlPanel(): void {
		history.push(URLs.controlPanel)
		props.close()
	}

	// Logout and close menu.
	function logout(): void {
		auth.logOut()
		props.close()
	}

	return props.active ? (
		<SProfileMenu className="SProfileMenu" active={props.active} isPortrait={isPortrait}>
			<SBackground>
				<SArrow right='1.1rem' />
			</SBackground>
			<Text style={{ paddingLeft: '.8rem' }} bold H4 oneline>{`${auth?.user?.name ?? ''} ${auth?.user?.lastName ?? ''}`}</Text>
			<TextIcon onClick={() => props.profileHandler()} image={icons.profile}>{language.getText('Perfil')}</TextIcon>
			{auth?.user?.type != 'normal' && <TextIcon onClick={() => openControlPanel()} image={icons.gear}>{language.getText('Panel de control')}</TextIcon>}
			<Button onClick={() => logout()}>{language.getText('Cerrar Sesión')}</Button>
		</SProfileMenu>
		
	) : (<></>)
}
