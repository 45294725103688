import firebase from './'

const database = firebase.database()

export const getContent = path => new Promise(async (resolve, reject) => {
    try {
        const snapshot = await database.ref(path).once('value')

        if (snapshot) {
			resolve(snapshot.val())
		} else {
			resolve(undefined)
		}
    } catch(error) { reject(error) }
})

export const getContentEqualTo = (path, paramToFilterBy, filter) => new Promise(async (resolve, reject) => {
    try {
        const snapshot = await database.ref(path).orderByChild(paramToFilterBy).equalTo(filter).once('value')
        resolve(snapshot.val())
    } catch(error) { reject(error) }
})

export const getContentStartAt = (path, paramToFilterBy, filter) => new Promise(async (resolve, reject) => {
    try {
        const snapshot = await database.ref(path).orderByChild(paramToFilterBy).startAt(filter).once('value')
        resolve(snapshot.val())
    } catch(error) { reject(error) }
})

export const getContentNotNull = (path, paramToFilterBy) => new Promise(async (resolve, reject) => {
    try {
        const snapshot = await database.ref(path).orderByChild(paramToFilterBy).startAt(0).once('value')
        resolve(snapshot.val())
    } catch(error) { reject(error) }
})

export const subscribeToContent = (path, callback, cooldown = 0) => {
	const ref = database.ref(path)

	function cancelSubscription() { ref.off('value') }

	function set() {
		ref.on('value', (snapshot) => {
		// console.log(`subscribeToContent - ${path}`);

			if(snapshot) callback(snapshot.val())

			if (cooldown != 0) {
				cancelSubscription()
				setTimeout(() => {
					set()
				}, cooldown)
			}
		})
	}

	set()

	return cancelSubscription
}

export const subscribeStartAt = (path, callback, cooldown = 0, paramToFilterBy, filter) => {
	const ref = database.ref(path)
	function cancelSubscription() { ref.off('value') }
	
	function set() {
		ref
		.orderByChild(paramToFilterBy)
		.startAt(filter)
		.on('value', (snapshot) => {
		
			// console.log(`subscribeWithFilter - ${path}`);

			if(snapshot) callback(snapshot.val())

			if (cooldown != 0) {
				cancelSubscription()
				setTimeout(() => {
					set()
				}, cooldown)
			}
		})
	}

	set()

	return cancelSubscription
}

export const subscribeWithFilter = (path, callback, cooldown = 0, paramToFilterBy, filter) => {
	const ref = database.ref(path)
	function cancelSubscription() { ref.off('value') }

	function set() {
		ref
		.orderByChild(paramToFilterBy)
		.equalTo(filter)
		.on('value', (snapshot) => {
		
			// console.log(`subscribeWithFilter - ${path}`);

			if(snapshot) callback(snapshot.val())

			if (cooldown != 0) {
				cancelSubscription()
				setTimeout(() => {
					set()
				}, cooldown)
			}
		})
	}

	set()

	return cancelSubscription
}

export const setContent = (path, payload) => new Promise(async (resolve, reject) => {
	try {
        if (path) { 
            const snapshot = await database.ref(path).set(payload)
            resolve(snapshot)
        }
        else reject('null data')
    } catch(error) { reject(error) }
})

export const onDisconnect = (path, value) => {
    if (path && value != null) database.ref(path).onDisconnect().set(value)
}
