import React, { useState, useRef, useEffect } from 'react'
import styled, { css } from 'styled-components/macro'

import { useStore, useAuth, useUserMoreInfo, useVideoCall, useLanguage, useNotifications } from '../hooks'
import { uploadDownloadableFile } from '../firebase/storage'
import { Text, SVG, Image, TextArea } from './UI'
import { ProfilePictureContainer, ProfilePicture, StatusContainer } from './PersonalChat'
import Linkify from 'react-linkify'

import sendIcon from '../graphics/icons/send.svg'
import videoIcon from '../graphics/icons/video.svg'
import messageIcon from '../graphics/icons/mail.svg'
import deleteIcon from '../graphics/icons/delete.svg'
import downloadIcon from '../graphics/icons/download.svg'
import profileIcon from '../graphics/icons/profile.svg'
import linkIcon from '../graphics/icons/link.svg'
import arrowRightIcon from '../graphics/icons/arrow-right.svg'
import prevIcon from '../graphics/icons/arrow-left.svg'

import Loading from './Loading'

const Container = styled.div`
    background-color: rgba(0, 0, 0, ${p => p.personal ? 0 : .7});
	height: 83vh;
	height: calc(var(--vh, 1vh) * 83);
    width: 100%;
    box-sizing: border-box;

    display: grid;
    grid-template-rows: ${p => p.canChat ? '4rem .05rem auto .05rem 5rem' : '3rem .05rem auto .05rem'};

	${p => p.keyBoardOpen &&
	css`
		grid-template-rows: ${p => p.canChat ? '4rem .05rem 0.8fr .05rem 5rem' : '3rem .05rem 0.8fr .05rem'};
		height: ${p.keyBoardOpen}px;
	`
	};

	${p => ( !p.isPortrait && !p.isMobile) && css`
        height: 95vh;
		height: calc(var(--vh, 1vh) * 95);
		margin: auto;
    `}

	${p => (p.panelChat) && css`
        width: 90%;
        height: 95%;
		margin: auto;
    `}

	${p => (p.panelChat && ( p.isPortrait || p.isMobile)) && css`
		height: 95%;
		padding: 0.5rem 0;
	`}
`

const Line = styled.div` background-color: ${p => p.transparent ? 'transparent' : p.theme.light}; `

const PersonalTitle = styled.div`
    height: 100%;
    width: 90%;
	padding: 0 1rem;
    overflow: hidden;
    display: grid;
    grid-template-columns: ${p => (p.isPortrait || p.isMobile) ? '3rem auto .5fr .5fr .5fr' : '1rem 3rem auto .5fr .5fr .5fr'};
    grid-gap: 0 1rem;	
	align-items: center;
`
const ActionButtonContainer = styled.div`
    background-color: ${p => p.theme.primary};
	padding: .5rem;
    border-radius: .5rem;
    cursor: pointer;
    :hover {
		background-color: ${p => p.theme.darkerLight};
        transform: scale(1.1);
    }
    * {
        width: 70%;
        height: 70%;
        margin: auto;
    }
`

const GroupTitle = styled.div`
    height: 100%;
    width: 100%;
    overflow: hidden;
    display: grid;
    grid-template-columns: min-content auto 1rem 0.5rem;

	@media (max-width: 768px) {
        grid-template-columns: min-content auto 1rem 1rem 0.5rem;
    }

    grid-gap: 1rem;
`

// Get timestamp formatted for humans.
export const getTimeStamp = (timestamp, short) => {
	if (timestamp) {
		const date = new Date(timestamp)
		if (short) {
			return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
		} else {
			return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`
		}
	}
}

export default function ChatView(props) {
	const [store, dispatch] = useStore()
	const auth = useAuth()
	const [userMoreInfo] = useUserMoreInfo()
	const [videoCall, setVideoCall] = useVideoCall()
	const [state, set] = useState({ canChat: true })
	const [viewportHeight, setVh] = useState(window.innerHeight);
	const [keyBoardOpen, setKeyBoardState] = useState(false);
	const notifications = useNotifications();

	const {
		personal,
		id,
		target,
		name,
		isPortrait,
		isMobile,
		closeHandler,
		localUser,
		videoChat,
		onlyAdmins,
		panelChat,
	} = props;

	try {
		if(window){
			if(window.visualViewport){
				window.visualViewport.addEventListener('resize', () => keyBoardShow() );
			} else {
				window.addEventListener('resize', () => keyBoardShow() );
			}
		}
	} catch (e) {
		console.error('Error with window resize: ', e);
	}

	// If this is a only-admins chat, or this user is banned from chat. Dishable chat. (Can't send messages).
	useEffect(() => {
		let canChat = true
		if (props.onlyAdmins && auth.user && auth.user.type === 'normal') canChat = false
		if (auth?.user?.banFromChat) canChat = false

		set({ ...state, canChat: canChat })
	}, [auth.user])

	const keyBoardShow = () => {
		try {
			if( window.innerHeight <= (viewportHeight*0.7) ) setKeyBoardState(window.innerHeight);
			else setKeyBoardState(false);
		} catch(e){
			console.error('failed ', e);
		}
	}

	// Get chat group from database. (Group chat or personal chat (1-1 group combining user ids))
	const getGroup = () => {
		if (store.chatGroups && id) {
			if (id in store.chatGroups) {
				return store.chatGroups[id]
			} else if (personal) {
				const group = { id: id, }
				dispatch({ type: 'set-chatGroup', payload: group })
				return group
			}
		} else {
			return {}
		}
	}

	const group = getGroup();

	// Send new message to databse.
	const sendMessageHandler = message => {
		if (!group || message.trim() === '' ) return;

		dispatch({
			type: 'send-message', payload: {
				message: message,
				chatGroup: group.id,
				sender: auth.user ? auth.user?.id : localUser.id,
			}
		})
	}

	// Close conversation and return to UserList
	const closeChatHandler = () => {
		closeHandler()
	}

	// Access user info when clicking the name.
	const getTitleClickHandler = () => {
		if (personal) return () => {
			userMoreInfo.setUser(target)
		}
	}

	function canDownloadGroupChat() {
		return auth.isAdmin()
	}

	// Download chat.
	function downloadChatHandler() {
		try{
			if (!group?.messages) {
				notifications.notify('No se pudieron cargar los mensajes, intente recargar la pagina');
				return;
			} else if (!store?.users || Object.keys(store.users).length < 2){
				notifications.notify('No se pudieron cargar los ususarios, pruebe intentar abrir y cerrar el chat');
				return;
			}

			let text = ''

			Object.values(group.messages).forEach(m => {
				let user = store?.users[m.user];
				text += `${user?.name || 'no name'} ${user?.lastName || ''}: ${m.text}\n${getTimeStamp(m.timestamp)}\n\n`
			})

			const element = document.createElement('a')
			element.setAttribute('href', 'data:text/plain:charset=utf-8,' + encodeURIComponent(text))
			element.setAttribute('download', 'chat.txt')
			element.style.display = 'none'
			document.body.appendChild(element)
			element.click()
			document.body.removeChild(element)
		} catch(e){
			console.error('Error trying to download chats', e);
		}
	}

	const chatUsers = Object.keys(store?.chat || {});
	const callUsers = Object.keys(store?.call || {});
	let isOnline;
	let onACall;
	
	if (target?.id) {
		// Validates if is a 1 to 1 chat first to set icons
		isOnline = chatUsers.find(id => id === target?.id);
		onACall = callUsers.find(id => id === target?.id);
	}

	return (
		<Container 
			canChat={state.canChat} 
			personal={personal} 
			vhValue={viewportHeight} 
			keyBoardOpen={keyBoardOpen} 
			panelChat={panelChat} 
			isPortrait={isPortrait}
			isMobile={isMobile} 
		>
			{(personal && !props.videoChat) ?
				<PersonalTitle isPortrait={isPortrait} isMobile={isMobile}>
					{!isPortrait && <SVG onClick={() => {closeChatHandler()}} style={{ cursor: 'pointer', backgroundColor: 'white', maskSize: '75%', WebkitMaskSize: '75%' }} 
						image={prevIcon}
					/>}
					<ProfilePictureContainer>
						<ProfilePicture>
							{target?.profilePicture ? 
							<Image url={target?.profilePicture} />
							: <SVG style={{ backgroundColor: 'gray', maskSize: '100%', WebkitMaskSize: '100%' }} 
								image={profileIcon}
							/> }			
						</ProfilePicture>
						<StatusContainer status={isOnline} />
					</ProfilePictureContainer>
					<Text onClick={getTitleClickHandler()} style={{ marginLeft: '1rem' }} middle oneline truncated bold H2>{name}</Text>
					{(store.configurations.allowVideoCalls && (( auth.user && auth.user.id) || (!auth.user && localUser && localUser.name !== 'Unregistered User')) && !onACall && isOnline ) 
					? 
					<ActionButtonContainer>
						<SVG style={{ width: '1rem', height:'1rem', maxWidth: '1rem' }} onClick={() => videoCall.startCall(target.id)} contain image={videoIcon} />
					</ActionButtonContainer>
					: <div />}
					<ActionButtonContainer>
						<SVG style={{ width: '1rem', height:'1rem', maxWidth: '1rem' }} onClick={() => window.open(`mailto:${target.email}`)} contain image={messageIcon} />
					</ActionButtonContainer>
					<ActionButtonContainer>
						<SVG style={{ width: '1rem', height:'1rem', maxWidth:	'1rem' }} onClick={() => downloadChatHandler()} contain image={downloadIcon} />
					</ActionButtonContainer>
				</PersonalTitle> :
				<GroupTitle>
					<Text style={{ marginLeft: '1rem' }} oneline middle bold H3>{props.videoChat ? name : group?.name}</Text>
					<div />
					{canDownloadGroupChat() ? <SVG onClick={() => downloadChatHandler()} contain image={downloadIcon} /> : <div />}
					{props.handleChatClose && <SVG onlyMobile onClick={() => props.handleChatClose()} contain image={arrowRightIcon} /> }
					<div />
				</GroupTitle>
			}
			<Line transparent/>
			<MessagesView store={store} dispatch={dispatch} auth={auth} localUser={localUser} users={store.users} group={group} />
			<Line transparent/>
			{state.canChat && <InputText sendMessageHandler={sendMessageHandler} />}
		</Container>
	)
}

const InputTextContainer = styled.div`
	position: relative;
    display: grid;
    grid-template-columns: auto 2rem;
    grid-gap: .25rem;
	margin: 1rem .5rem;
`
const InputAndAttachedContainer = styled.div`
	display: grid;
	grid-template-columns: auto 2.5rem;
	border-radius: 1rem;
	background-color: white;
	align-items: center;
`

const SendButton = styled.div`
	max-width: 2.5rem;
    box-sizing: border-box;
	right: 2rem;
    padding: .6rem;
    * {
        cursor: pointer;
        :hover {
            background-color: ${p => p.theme.primary};
        }
    }
`

const SAddFile = styled.div`
	width: 2.5rem;
    max-width: 2.5rem;
    box-sizing: border-box;
    padding: 0.6rem;
    * {
        cursor: pointer;
        :hover {
            background-color: ${p => p.theme.primary};
        }
    }
`

function InputText(props) {
	const [text, setText] = useState('')
	const [sendPrimary, setSendPrimary] = useState(false)
	const [loading, setLoading] = useState(false)
	const hiddenFileInput = useRef()
	const language = useLanguage()

	const {
	} = props;

	useEffect(() => {
		const interval = setInterval(() => { setSendPrimary(prev => { return !prev }) }, 500)
		return () => clearInterval(interval)
	}, [])

	const sendHandler = () => {
		if (text && text.trim() !== '') props.sendMessageHandler(text);
		setText('');
		return;
	}

	const uploadHandlerClick =  () => {
		hiddenFileInput.current.click()
	}

	const uploadHandlerChange = async event => {
		try {
			setLoading(true)
			const file = event.target.files[0];
			const url = await uploadDownloadableFile(file)
			if (url === '') return
			await props.sendMessageHandler(url)
			setLoading(false)
		} catch (error) {
			console.error(error)
			setLoading(false)
		}
	}

	return (
		<InputTextContainer>
			<InputAndAttachedContainer>
				<TextArea
					autoSelect
					onKeyDown={e => { if (e.keyCode === 13 || e.which === 13) sendHandler(); }}
					style={{overflowY: 'auto', padding: '.5rem', border: 'none', outline: 'none', color: 'black'}}
					placeholder={!loading ?  language.getText('Escriba su mensaje') : language.getText('Enviando') + '...'}
					value={!loading ? text: language.getText('Enviando') + '...'}
					onChange={(o) => { setText(o);} }
					resize={false}
				/>
				<SAddFile>
					<SVG style={{height: '1rem'}} gray contain image={linkIcon} onClick={uploadHandlerClick}/>
				</SAddFile>
			</InputAndAttachedContainer>
				<input type="file" ref={hiddenFileInput} onChange={uploadHandlerChange} style={{display:'none'}} /> 
			<SendButton>
				<SVG style={{ transition: '.3s' }} primary={sendPrimary} onClick={sendHandler} contain image={sendIcon} />
			</SendButton>
		</InputTextContainer>
	)
}

const MessagesViewContainer = styled.div`
    overflow: auto;
	background-color: ${p => p.personal ? p.theme.light : 'white'};
	border-radius: 1rem;
	margin: 1rem .5rem;
`

const MessageGrid = styled.div`
    display: grid;
    grid-gap: 1rem;
    padding: 1rem;
    width: 100%;
    box-sizing: border-box;
`

const MessageBubbleContainer = styled.div`
    margin: auto;
    width: 70%;
    box-sizing: border-box;
    overflow-wrap: break-word;
    overflow: hidden;
    text-overflow: ellipsis;

	${p => p.loading ? css` visibility: hidden` : css`visibility: visible`};
    
	position: relative;

    ${p => p.mine ? css` margin-right: 0; ` : css` margin-left: 0; `}
`

const Bubble = styled.div`
    background-color: ${p => p.mine ? p.theme.primary : p.theme.darkerLight};
    padding: .5rem 2rem .5rem 1rem;
    border-radius: .5rem;
    border-bottom-left-radius: ${p => p.mine ? '.5rem' : 0};
    border-bottom-right-radius: ${p => !p.mine ? '.5rem' : 0};

    width: 100%;
    box-sizing: border-box;
`

const DeleteButton = styled.div`
    background-color: ${p => p.theme.primary};
    position: absolute;
    width: 1.5rem;
    height: 1.5rem;
    top: .3rem;
    right: .3rem;
    border-radius: 50%;
    cursor: pointer;
    :hover {
        transform: scale(1.1);
    }
    display: flex;
    * {
        width: 70%;
        height: 70%;
        margin: auto;
    }
`

const DowloadButton = styled.div`
    background-color: ${p => p.theme.primary};
    position: absolute;
    width: 1.5rem;
    height: 1.5rem;
    top: .5rem;
    right: 2.4rem;
    border-radius: 50%;
    cursor: pointer;
    :hover {
        transform: scale(1.1);
    }
    display: flex;
    * {
        width: 70%;
        height: 70%;
        margin: auto;
    }
`

const componentDecorator = (href, text, key) => (
	<a href={href} key={key} target="_blank">
		{text}
	</a>
)

function MessageBubble(props) {
	const [userMoreInfo] = useUserMoreInfo()
	const language = useLanguage()

	const {
		store,
		dispatch,
		auth,
		localUser,
		group,
		loading,
		message,
		sender,
	} = props;

	const isMine = ((auth.user && auth.user?.id === sender.id) || (localUser?.id === sender?.id));

	// Admins can delete messages.
	function canDeleteMessage() {
		if (auth.isAdmin()) return true
		else if (auth.isOrganizer()) return store?.configurations?.featureDeleteMessage
	}

	function deleteMessageHandler() {
		dispatch({ type: 'delete-message', payload: { chatGroup: group.id, messageId: message.id } })
	}
	return (
		<MessageBubbleContainer mine={isMine} loading={loading}> 
			{canDeleteMessage() && <DeleteButton onClick={() => deleteMessageHandler()}><SVG contain image={deleteIcon} /></ DeleteButton>}
			<Bubble mine={isMine}>
				{message.text.includes("https://firebasestorage.googleapis.com/") ?
				<Text dark={!isMine} H2 oneline truncated onClick={() => window.open(message.text, "_blank")} style={{color: 'white'}}>
					{!isMine && <Text onClick={() => userMoreInfo.setUser(sender)} dark bold> {`${sender.name} ${sender.lastName || ''}`} </Text>}
					{language.getText('Archivo')} <DowloadButton><SVG contain image={downloadIcon} /></DowloadButton>
					<Text dark={!isMine} style={{ textAlign: 'right', marginTop: '.5rem' }}>{getTimeStamp(message.timestamp)}</Text>
				</Text> 
				: 
				<Linkify componentDecorator={componentDecorator}>
					<Text dark={!isMine} H2>
						{!isMine && <Text onClick={() => userMoreInfo.setUser(sender)} dark bold> {`${sender.name} ${sender.lastName || ''}`} </Text>}
						{message.text}
						<Text dark={!isMine} style={{ textAlign: 'right', marginTop: '.5rem' }}>{getTimeStamp(message.timestamp)}</Text>
					</Text>
				</Linkify>}
			</Bubble>
		</MessageBubbleContainer>
	)
}

function MessagesView(props) {
	const [chatLoading, setChatLoading] = useState(true);
	const messagesEndRef = useRef(null);
	// Get list of messages, limit amount and sort them by timestamp.

	const {
		store,
		dispatch,
		auth,
		localUser,
		users,
		group,
	} = props;

	const getMessages = () => {
		try{
			if (group?.messages) {
				let messages = Object.values(group.messages)
				messages = messages.sort((a, b) => a.timestamp > b.timestamp ? 1 : -1)
				const length = messages.length
				if (length > 100) messages = messages.slice(length - 100)
				return messages.map(m => {
					const senderUser = m.user in users ? users[m.user] : (m.user === localUser.id ? localUser : {});
					return (<MessageBubble 
						store={store}
						dispatch={dispatch}
						auth={auth} 
						localUser={localUser}
						loading={chatLoading} 
						group={group} 
						sender={senderUser} 
						key={m.id} 
						message={m} 
					/>);
				});
			}
		} catch(e) {
			console.error(e);
		}
	}

	try {	
		if(window){
			if(window.visualViewport){
				window.visualViewport.addEventListener('resize', () => scrollView() );
			} else {
				window.addEventListener('resize', () => scrollView() );
			}
		}
	} catch (e) {
		console.error('Error with window resize: ', e);
	}

	const scrollView = () => {
		if(messagesEndRef?.current) scrollToBottom();
	};

	const scrollToBottom = () => {
		try{
			messagesEndRef.current.scrollIntoView()	
		} catch(e){
			console.error(e);
		}
		setChatLoading(false);
	}

	// On new messages, scroll view to bottom.
	useEffect(scrollToBottom, [group?.messages])

	return (
		<MessagesViewContainer name="MessagesView_MessagesViewContainer">
			{ (chatLoading || !users) && 
				<Loading />
			}
			<MessageGrid >
				{getMessages()}
				<div className="Ref_Div_Container" ref={messagesEndRef} />
			</MessageGrid>
		</MessagesViewContainer>
	);
}
