import React, { useState, useEffect } from 'react'
import styled, { css } from 'styled-components'
import { Analytics, AnalyticsBrowser, Context } from '@segment/analytics-next';
import { getContent } from '../../firebase/database';
import mixpanel from 'mixpanel-browser';

import { Button, Text, TextIcon, InputField, SVG, Image } from '../UI'
import { CheckBox, CloseButton } from '../NewUI'
import { useLanguage, useStore, useAwakeBool, useIsPortrait } from '../../hooks'
import Loading from '../Loading'

// Icons and images
import icons from '../../graphics/icons'
import testEventImage from '../../graphics/images/ExternalAnalytics-exampleEvent.jpg'

const SExternalAnalytics = styled.div`
    display: grid;
    grid-gap: 2rem;
	max-width: 50%
`
const SBackground = styled.div`
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 2;
    background-color: rgba(0, 0, 0, .8);
    display: grid;
    place-items: center;
`
const SModal = styled.div<{ show: boolean, isPortrait }>`
    position: relative;
    padding: 1.5rem 2.5rem;
    background-color: ${p => p.theme.light};
    border-radius: ${p => p.theme.roundCorners};
    width: min-content;
    height: min-content;
    min-width: 20rem;
    min-height: ${p => p.smallHeight ? '5rem' : '20rem'};

    transition: .3s;

	${p => p.smallWidth && css`
		@media screen and (max-width: 330px) {
    		padding: 1.5rem 1.5rem;
		}
	`}

	${p => p.isPortrait && css`
		width: 90%;
		height: 95%;
		min-width: 0;
		box-sizing: border-box;
	`}

    ${p => !p.show && css`
        opacity: 0;    
        transform: translateY(1rem);
    `}
`

const SMessageHolder = styled.div`
    display: flex;
    flex-direction: row;
	cursor: ${p=> p.pointer ? 'pointer' : 'default'};
`

const SGrid = styled.div`
    display: grid;
    grid-gap: 1.5rem;
`

export interface IAnalyticsProvider {
		providerName: string,
		workspaceName: string,
		writeKey: string,
		sourceId: string
		token: string
		verifiedAccount: boolean;
	}
export interface IExternalAnalytics {
		providerData: IAnalyticsProvider
	}

export default function ExternalAnalytics() {
	const [provider, setProvider] = useState<IAnalyticsProvider>(
		{ 	providerName : 'mixpanel',
			workspaceName: '',
			writeKey: '',
			sourceId: '',
			token: '',
			verifiedAccount: false
		});
	let tokenMixPanel;

	// mixpanel.init(tokenMixPanel);
	
	// // Test analytics MixPanel
	// mixpanel.track('Page View', {
	// 	'Page Name': 'External Analytics',
	// 	'Page URL': window.location.href
	// });
	
	const [store, dispatch] = useStore()
	const language = useLanguage()
	const [loading, setLoading] = useState(false);
	const [openModal, setOpenModal] = useState(false);
	const [isShown, setIsShown] = useState(false);
	const awake = useAwakeBool();
	const isPortrait = useIsPortrait();
	const [linkedProvider, setLinkedProvider] = useState(false);

	// Check if the provider is already linked and get workspaceName
	let workspaceName = store?.configurations?.externalAnalytics?.providerData?.workspaceName ? store?.configurations?.externalAnalytics?.providerData?.workspaceName : undefined;
	const mixPanelCredentialsUrl = 'https://help.mixpanel.com/hc/en-us/articles/115004502806-Find-Project-Token-'
	const segmentCredentialsUrl = 'https://segment.com/docs/connections/find-writekey/'
	const segmentAppPath = `https://app.segment.com/${workspaceName}`
	let segmentConnectionUrl = "http://localhost:5001/bigjonan-test/us-central1/segmentConnection"
	const mixPanelSignUpUrl = 'https://mixpanel.com/register/'
	const segmentSignUpUrl = 'https://segment.com/signup/'
	const mixPanelEventsLink = "http://mixpanel.com/report/events";

	// Open a new tab in browser and close the menu.
	function goToExternal(to: string): void {
		window.open(to)
	}

	const mixPanelTest = (token) => {
		if (!token) return;

		mixpanel.init(token);
		mixpanel.track('Tu primer evento trackeado!', {
			'Page Name': 'External Analytics',
			'Page URL': window.location.href
		});

		return false;
		// dispatch({ type: 'set-configurations', payload: {...store.configurations, externalAnalytics} })
	}


	const segmentTest = (token) => new Promise(async (resolve, reject) => {
		if (!token) reject();

		const data = {
			token: token,
		}

		const segmentResponse = await (await fetch(segmentConnectionUrl, {
			method: 'POST',
			body: JSON.stringify(data),
			headers: { 'Content-Type': 'application/json' }
		})).json()
	})

	// Validate Credentials for Segment
	function connectWithProvider(provider: IAnalyticsProvider): void {
		if (!provider || !provider.token) return 

		// Opens modal and setLoading to true
		setLoading(true);
		setOpenModal(true);

		let externalAnalytics: IExternalAnalytics = {
			providerData: {
				providerName: provider['providerName'],
				workspaceName: provider['workspaceName'],
				writeKey: provider['writeKey'],
				sourceId: provider['sourceId'],
				token: provider['token'],
				verifiedAccount: provider['verifiedAccount']
			}
		}
		let providerToken = externalAnalytics?.providerData?.token ? externalAnalytics?.providerData?.token : undefined; 
		
		// Sent Test Event to MixPanel
		mixPanelTest(providerToken)
	
		// const endpointWorkspace = "https://platform.segmentapis.com/v1beta/workspaces/"
		// const endpointSources = `https://platform.segmentapis.com/v1beta/workspaces/${workspaceName}/sources/js`

		// let headers = new Headers();
		// headers.append("Authorization", providerToken);
		// headers.append("Content-Type", "application/json");

		// let requestOptions = {
		// method: 'GET',
		// headers: headers,
		// redirect: 'follow'
		// };

		// // Get workspace name from token
		// fetch(endpointWorkspace, requestOptions as any)
		// .then(response => response.text())
		// .then(result => console.log(result))
		// .catch(error => console.log('error', error));

		// // Get Workspace sources
		// fetch(endpointSources, requestOptions as any)
		// .then(response => response.text())
		// .then(result => console.log(result))
		// .catch(error => console.log('error', error));

		// Validate writeKey and sourceId before writing them in database


		// TODO : Validate credentials before writing them in database
		// Review the config-api documentation to see if sourceId and writeKey are valid.
		// Use generated token to hit the API endpoint.

		// https://reference.segmentapis.com/#c741bea9-bb77-428a-b8a8-078d49471fc4
		// setLinkedProvider(true);
		
		// dispatch({ type: 'set-configurations', payload: {...store.configurations, externalAnalytics} })

		setLoading(false);
	}

	return (
		<SExternalAnalytics>
			{
				openModal && <SBackground>
					<SModal smallHeight={ store?.configurations?.externalAnalytics } smallWidth={false} show={awake} isPortrait={isPortrait}>
						<CloseButton
							onClick={() => setOpenModal(false)}
						/>
						<Text center H1 primary bold>{
							!store?.configurations?.externalAnalytics ? 
							language.getText('Validación de token'): 
							language.getText('Datos guardados correctamente')}
						</Text>
						{
							!store?.configurations?.externalAnalytics &&
							<>
								<Text center H2 dark  style={{marginTop: '1.5rem'}}>{language.getText('El token ingresado es: ')}</Text>
								<Text center H2 dark bold  style={{marginTop: '.5rem'}}>{provider?.token ? provider?.token : 'token_45454'}</Text>
								<Text center H3 dark style={{margin: '1rem'}}>
									{language.getText('Enviamos un evento de prueba a tu cuenta de MixPanel para validar tu token. Este puede tardar hasta 60seg')}
								</Text>
								{/* Image to show an example event */}
								<SMessageHolder pointer 
								onMouseEnter={() => {setIsShown(true)}}
								>
									<Text H3 primary>{language.getText('¿Cómo luce este evento?')}</Text> 
									<SVG primary style={{ height: '1rem', width: '1rem', marginLeft: ".5rem" }} contain image={icons.info} />
								</SMessageHolder>
								{ isShown && <div style={{ backgroundImage: `url(${testEventImage})`, 
									backgroundRepeat: 'no-repeat',
									width:'20rem',
									height: '7rem',
									backgroundSize: 'cover'
								}}></div> }
								<div style={{margin: '2rem'}}>
									<TextIcon
										selected
										inverse 
										key='checkPanel'
										interactable 
										onClick={() => {goToExternal(mixPanelEventsLink)}} 
										image={icons.monitor}>
											{language.getText('Ir a chequear mi panel de eventos')}
									</TextIcon>
								</div>
								<div style={{marginTop: '3rem'}}>
									<CheckBox
										key={'validationToken'}
										isRequired={false}
										onClick={() => {setProvider({...provider, verifiedAccount : true})}}
										title={language.getText('He recibido mi primer evento')}
										value={provider?.verifiedAccount ? provider.verifiedAccount : false}
									/>
								</div>
						</> 			
						}
						<Button
							style={{ width: '20rem', height: '2rem', marginTop: '2rem'}}
							onClick={() => {store?.configurations?.externalAnalytics ? goToExternal('https://mixpanel.com/') : dispatch({ type: 'set-configurations', payload: {...store.configurations, externalAnalytics: provider} })}}>
							{store?.configurations?.externalAnalytics ?
							language.getText('Ir a MixPanel')
							: language.getText('Guardar')}
						</Button>
					</SModal>
				</SBackground>
			}
			<SGrid>
				<Text H1 bold>{language.getText('Integrar analíticas externas')}</Text>
				<br></br>
				<Text H2 bold>{language.getText('Integración con MixPanel')}</Text>
				{!store?.configurations?.externalAnalytics &&	<>
					<SMessageHolder pointer onClick={() => {goToExternal(mixPanelSignUpUrl)}}>
						<Text H3>{language.getText('¿No tienes cuenta? Crea una dando click aquí')}</Text> 
						<SVG light style={{ height: '1rem', width: '1rem', marginLeft: ".5rem" }} contain image={''} />
					</SMessageHolder>
					<SMessageHolder pointer onClick={() => {goToExternal(mixPanelCredentialsUrl)}}>
						<Text H3>{language.getText('¿Dónde encuentro mi token?')}</Text> 
						<SVG light style={{ height: '1rem', width: '1rem', marginLeft: ".5rem" }} contain image={icons.info} />
					</SMessageHolder>
				</>}
			</SGrid>
			{
			!store?.configurations?.externalAnalytics &&  <SGrid>
				<Text H4 bold>{language.getText('Tu token de MixPanel')}</Text>
				<InputField
					placeholder={language.getText('MixPanel Token')}
					value={provider.token}
					onChange={(v) => setProvider({...provider, token: v })}
					type={'text'}
					style={{ width: '20rem' }}
				/>
			</SGrid>
			}
			<SGrid>
				<Button
					style={{ width: '20rem', height: '2rem' }}
					onClick={() => {!store?.configurations?.externalAnalytics
					? connectWithProvider(provider)
					: goToExternal(mixPanelEventsLink)}}>
						{store?.configurations?.externalAnalytics 
						? language.getText('Ir a mi panel de MixPanel')
						: language.getText('Conectar con MixPanel')}
				</Button>
			</SGrid>
			{loading && <Loading />}
			{store?.configurations?.externalAnalytics ? <SMessageHolder>
				<Text H2 bold>{language.getText('MixPanel integrado con éxito')}</Text>
				<SVG light style={{ height: '1rem', width: '1rem', marginLeft: ".5rem"}} contain image={icons.checkmark} />
			</SMessageHolder> 
			: <SMessageHolder>
				<Text H2 bold>{language.getText('Aún no tienes una cuenta conectada')}</Text>
			</SMessageHolder> }
				
			{/* <SGrid>
				<Text H1 bold>{language.getText('Integrar analíticas externas')}</Text>
				<br></br>
				<Text H2 bold>{language.getText('Integración con Twillio Segment')}</Text>
				{!linkedProvider &&	<>
					<SMessageHolder pointer onClick={() => {goToExternal(segmentSignUpUrl)}}>
						<Text H3>{language.getText('¿No tienes cuenta? Crea una dando click aquí')}</Text> 
						<SVG light style={{ height: '1rem', width: '1rem', marginLeft: ".5rem" }} contain image={''} />
					</SMessageHolder>
					<SMessageHolder pointer onClick={() => {goToExternal(segmentCredentialsUrl)}}>
						<Text H3>{language.getText('¿Dónde encuentro mi token?')}</Text> 
						<SVG light style={{ height: '1rem', width: '1rem', marginLeft: ".5rem" }} contain image={icons.info} />
					</SMessageHolder>
				</>}
			</SGrid>
			{
			!linkedProvider &&  <SGrid>
				<Text H4 bold>{language.getText('Tu token de Segment')}</Text>
				<InputField
					placeholder={language.getText('Segment Token')}
					value={provider.token}
					onChange={(v) => setProvider({...provider, token: v })}
					type={'text'}
					style={{ width: '20rem' }}
				/>
			</SGrid>
			}
			<SGrid>
				<Button style={{ width: '20rem', height: '2rem' }} onClick={() => {!linkedProvider ? connectWithProvider(provider) : goToExternal('https://google.com')}}>
					{linkedProvider ? language.getText('Ir a mi panel de Segment') : language.getText('Conectar con Segment')}
				</Button>
			</SGrid>
			{loading && <Loading />}
			{linkedProvider ? <SMessageHolder>
				<Text H2 bold>{language.getText('Segment integrado con éxito')}</Text>
				<SVG light style={{ height: '1rem', width: '1rem', marginLeft: ".5rem"}} contain image={icons.checkmark} />
			</SMessageHolder> 
			: <SMessageHolder>
				<Text H2 bold>{language.getText('Aún no tienes una cuenta conectada')}</Text>
				
			</SMessageHolder> } */}
			
		</SExternalAnalytics>
	)
}
