import React from 'react'
import styled from 'styled-components'
import { useElementsManager, useLanguage } from '../hooks'
import { IElementsManager } from '../context/ElementsManagerContext'

import { Text } from './UI'

const SMultipleLoginAlert = styled.div<{ active: boolean }>`
    position: fixed;
    z-index: 999;
    background-color: ${p => p.theme.dark};
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: ${p => p.active ? 'block' : 'none'};
`

export default function MultipleLoginAlert() {
    const elementsManager = useElementsManager() as IElementsManager
    const language = useLanguage();
    return (
        <SMultipleLoginAlert active={elementsManager.state.blocked}>
            <Text H1 light center middle style={{ height: '100%' }}>{language.getText('Entraste desde otro dispositivo.')}</Text>
        </SMultipleLoginAlert>
    )
}
