import React, { useState } from 'react'
import styled from 'styled-components'

import { Text, Button, InputField, SVG } from '../UI'
import icons from '../../graphics/icons/'
import { sendResetPasswordEmail } from '../../firebase/auth'
import { useLanguage } from '../../hooks'

const SResetPassword = styled.div`

`

const SGrid = styled.div`
	display: grid;
	grid-gap: ${p => p.theme.gridGap};
`

interface IState {
	sent: boolean,
	email: string,
	message?: string,
	loading: boolean,
}

export default function ResetPassword(props: { backHandler(): void }) {
	const [state, set] = useState<IState>({
		sent: false,
		email: '',
		loading: false
	})
	const language = useLanguage() as any

	function continueHandler(): void {
		if (state.email === '' || !state.email.includes('@')) {
			set(p => ({ ...p, message: language.getText('Email Inválido') }))
		}
		set(p => ({ ...p, loading: true }))
		sendResetPasswordEmail(state.email)
			.then(() => set(p => ({ ...p, sent: true })))
			.catch(e => set(p => ({ ...p, message: language.getText('No se encontró ese email registrado.'), loading: false })))
	}

	function getView() {
		// Type email panel.
		if (!state.sent) return (
			<SGrid>
				<Text oneline dark bold center>{language.getText('¿Olvidaste tu contraseña?')}</Text>
				<div />
				<InputField
					title={language.getText('Correo Electrónico')}
					value={state.email}
					onChange={v => set(p => ({ ...p, email: v }))}
					onKeyDown={e => { if (e.keyCode === 13) continueHandler() }}
				/>
				{state.message && <Text style={{ color: 'red' }}>{state.message}</Text>}
				<div />
				<Button onClick={() => continueHandler()}>{language.getText(state.loading ? 'Enviando...' : 'Reestablecer')}</Button>
				<Text center primary bold onClick={() => props.backHandler()}>{language.getText('Cancelar')}</Text>
			</SGrid>
		)

		// Sent message.
		return (
			<SGrid>
				<div /> <div />
				<SVG style={{ height: '5rem' }} contain primary image={icons.email} />
				<Text oneline dark bold center H2>{language.getText('¡Revisa tu correo!')}</Text>
				<div />
				<Text dark center>{language.getText('Se ha enviado un mail de recuperación a')}</Text>
				<Text oneline primary center H2 bold>{state.email}</Text>
				<div /> <div /> <div /> <div />
				<Text center primary bold onClick={() => props.backHandler()}>{language.getText('Volver')}</Text>
			</SGrid>
		)
	}

	return (
		<SResetPassword>
			{getView()}
		</SResetPassword>
	)
}
